import Swal from 'sweetalert2'
import { Link } from "react-router-dom";
import React from "react";
import deletePresiniestro from '../../services/presiniestro/deletePresiniestro';
import updateEstatusPresiniestro from '../../services/presiniestro/updateEstatusPresiniestro';

export default function TableElementPreSiniestro({ element, permisosUsuario, getReloadElements }) {

    let { 
        id_pre_siniestro, 
        fecha_alta, 
        nombre_permisionario, 
        apellido_paterno_permisionario, 
        apellido_materno_permisionario, 
        clave_permisionario, 
        numero_serie, 
        placas, 
        numero_economico, 
        clave_prefijo_economico, 
        modalidad_prefijo_economico, 
        activo,
        revisado, 
        nombre_operador,
        nombre_gestor
     } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(activo);

    const onClickEstatusAcceso = (activo) => {
        let msg = (activo) ? 'Quieres habilitarlo?' : 'Quieres deshabilitarlo?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        let txtMessage = (activo) ? "El gestor ahora podrá confirmar este pre siniestro" : 'El gestor no podrá confirmar este pre siniestro';
        Swal.fire({
            title: msg,
            text: txtMessage,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_pre_siniestro', id_pre_siniestro)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                updateEstatusPresiniestro({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getReloadElements()
                        setEstadoEstatus(activo)
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    } 

    const onClickDelete = () => {
        Swal.fire({
            title: 'Seguro que quieres eliminarlo?',
            text: "Tendrás que dar de alta de nuevo el pre siniestro",
            showCancelButton: true,
            confirmButtonText: 'Si, seguro',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_pre_siniestro', id_pre_siniestro)
                let objectValues = Object.fromEntries(values);
                console.log({objectValues})
                deletePresiniestro({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getReloadElements()
                        Swal.fire({
                            title:'Eliminado',
                            timer:1000,
                            showConfirmButton:false,
                            icon:'success'
                        })
                    }
                    
                    if (_respuesta.response === "error") {
                        Swal.fire({
                            title:'No se puede eliminar',
                            text:"Existen registros vinculados a este dato, no se puede eliminar hasta que elimines todos los registros vinculados a él",
                            showConfirmButton:true,
                            icon:'info'
                        })
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_pre_siniestro}>
            <th>
                <div className="table__name">
                    {
                    ( revisado === '0' && ((permisosUsuario[0].editar === '1') || (permisosUsuario[0].eliminar === '1')) )  &&
                        <div className="dropdown">
                            <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {
                                    estadoEstatus === '1' ?
                                    <a href className="dropdown-item text-black" onClick={() => onClickEstatusAcceso(false)}>Deshabilitar</a>
                                    : null
                                }

                                {
                                    permisosUsuario[0].eliminar === '1' &&
                                        <a href className="dropdown-item bg-danger text-white" onClick={() => onClickDelete()}>
                                            Eliminar
                                        </a>
                                }
                            </div>
                        </div>
                    }
                    <div className="table__estatus">
                        <p>
                            {fecha_alta}
                            <br/>
                            <small>
                                Gestor asignado: {nombre_gestor}
                            </small>
                        </p>
                    </div>
                </div>
            </th>

            <td>
                <div className="table__fechaAlta">
                    <p>
                        {nombre_permisionario} {apellido_paterno_permisionario} {apellido_materno_permisionario}
                        <br/>   
                        Clave: <span className='font-weight-bold'>{clave_permisionario}</span>
                        <br/>
                        { clave_prefijo_economico }{ numero_economico } &nbsp;
                        <span className='text-muted'>
                            ({ modalidad_prefijo_economico })
                        </span>
                    </p>
                </div>
            </td>
            
            <td>
                <p>
                    No. serie: { numero_serie }
                    <br/>
                    Placas: { placas }
                    <br/>
                    Operador: { nombre_operador }
                </p>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ revisado === '1' ? '' : 'table__estatus-inactive' }>
                        { revisado === '1' ? 'Confirmado' : 'No confirmado' }
                    </span>
                    {/* <span className={ estadoEstatus === '1' ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus === '1' ? 'Activo' : 'Inactivo' }
                    </span> */}
                </div>
            </td>
        </tr>
    )
    
}