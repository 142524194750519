import React from 'react';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import changeEstatusTrabajadorService from '../../services/changeEstatusTrabajadorService';

export default function TableElementTrabajadores({ element, permisosUsuario }) {

    let { id_trabajador, urlFotoPerfil, nombre, fecha_alta, acceso, activo } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(activo);

    const onClickDisableTrabajador = (activo) => {
        let msg = (activo) ? '¿Seguro que deseas deshabilitar al trabajador?' : '¿Seguro que deseas habilitar al trabajador?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_trabajador', id_trabajador)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                changeEstatusTrabajadorService({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo)
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_trabajador}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={`/trabajador/${id_trabajador}`} className="dropdown-item" >Ver</Link>
                            {
                                permisosUsuario[0].editar == 1 &&
                                (estadoEstatus == 1 ?
                                <a className="dropdown-item bg-danger text-white" onClick={() => onClickDisableTrabajador(false)}>Deshabilitar</a>
                                :
                                <a className="dropdown-item bg-success text-white" onClick={() => onClickDisableTrabajador(true)}>Habilitar</a>)
                            }
                        </div>
                    </div>
                    {
                        (urlFotoPerfil) &&
                        (<img src={urlFotoPerfil} className="pedidos__imgTabla img-fluid rounded-circle "/>)
                    }
                    <div className="table__nameDescription">
                        <p>{nombre}</p>
                        <small>{id_trabajador}</small>
                    </div>
                </div>
            </th>

            <td>
                <div className="table__fechaAlta">
                    <p>{fecha_alta}</p>
                </div>
            </td>

            <td>
                <p className="table__unidades">{
                    (acceso) ? 'Si' : 'No'
                }</p>
            </td>

            <td>
                <div className="table__estatus">
                   <span className={ estadoEstatus == 1 ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus == 1 ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}