import $ from 'jquery'
import config from './../config'
const URL_API = config.urlApi+'controlador/getTrabajador.controlador.php'

export default function getTrabajadorService({values}){
  return $.ajax({
    url: URL_API,
    type: 'POST',
    data: values,
  })
}