import React from "react";
import{ validateNoNumbers } from './../../../validators/validateNoNumbers'

export default function ModalAddProrrataSiniestro(props){

    return(
        <div className="modal" id="modalAddProrrataSiniestro" tabIndex="-1" role="dialog" aria-labelledby="modalAddProrrataSiniestroLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={props.onSubmit} id='formAddProrrata' className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddProrrataSiniestroLabel">Agrega nueva prorrata de siniestro</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="nombre">
                                    Nombre:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    id="nombre"
                                    name="nombre"
                                    required
                                />
                            </div>
                            <div className="col-12">
                                <label htmlFor="porcentaje_permisionario">
                                    Porcentaje del permisionario:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    id="porcentaje_permisionario"
                                    name="porcentaje_permisionario"
                                    onKeyPress={ validateNoNumbers }
                                    maxLength='3'
                                    placeholder="%"
                                    required
                                />
                            </div>
                            <div className="col-12">
                                <label htmlFor="porcentaje_operador">
                                    Porcentaje del operador:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    id="porcentaje_operador"
                                    name="porcentaje_operador"
                                    onKeyPress={ validateNoNumbers }
                                    maxLength='3'
                                    placeholder="%"
                                    required
                                />
                            </div>
                            <div className="col-12">
                                <label htmlFor="porcentaje_empresa">
                                    Porcentaje de la empresa:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    id="porcentaje_empresa"
                                    name="porcentaje_empresa"
                                    onKeyPress={ validateNoNumbers }
                                    placeholder="%"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primario">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}