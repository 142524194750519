import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

// Estilos
import './App.css'

// Páginas
import HomePage from './pages/HomePage'
import Login from './pages/Login'
import RecoverPassword from './pages/RecoverPassword'

export default function App(){

  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    // Consultar localstorage para la sesión
    if(localStorage.getItem('user')){
      setUser({
        isLoggedIn: true,
        typeUser: 1,
        user: JSON.parse(localStorage.getItem('user'))
      })
      setLoading(false)
    } else {
      setLoading(false)
    }

  },[])

  const [user, setUser] = React.useState({
    isLoggedIn: false,
    typeUser: 0,
    user : null
  })

  return(

    !loading &&

    <React.Fragment>

      {
        user.isLoggedIn && <HomePage user={user} setUser={setUser} />
      }

      {
        !user.isLoggedIn && 
          <Switch>
            <Route path="/recovery-password/:id" exact> 
              <RecoverPassword />
            </Route>

            <Route path="/" exact
              render={ ()=>{
                  return <Login setUser={setUser} />
              } } />

            <Redirect to="/" />
            
          </Switch>
      }
    </React.Fragment>
  )
}