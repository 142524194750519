import React from 'react'
import getHistorialAltasBajas from '../services/permisionario/getHistorialAltasBajas'

export default function usePermisionario(){

    const [permisionario, setPermisionario] = React.useState(null)
    const [loadingPermisionario, setLoadingPermisionario] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [historialBajasAltas, setHistorialBajasAltas] = React.useState([])

    const getHistorialBajasAltasService = ({id_permisionario}) => {
        let data = { id_permisionario }
        getHistorialAltasBajas({values: data})
            .then(res => {
                console.log("/////////////////////////////////////////////////")
                // console.log(res)
                let _res = JSON.parse(res)
                console.log(_res)
                if(_res.response === 'success'){
                    setHistorialBajasAltas(_res.data)
                } else {
                    setHistorialBajasAltas([])
                }
            }).catch(err => {
                console.log(err)
            })
    }

    return { 
        // permisionario, 
        // loadingPermisionario, 
        // error,
        getHistorialBajasAltasService,
        historialBajasAltas
    }

}