import $ from 'jquery';

import config from '../../../config';
const URL_API = config.urlApi+'controlador/catalogos/metodo_pago/addMetodoPago.php';

export default function addMetodoPago({values}){
    const user = JSON.parse(localStorage.getItem("user"))
    const id = user.id_usuario
    values = {...values, id: id}
    
    return $.ajax({
        url: URL_API,
        type: 'POST',
        data: values
    })
}