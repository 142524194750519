import $ from 'jquery'
import config from './../config'
const URL_API = config.urlApi+'controlador/derrotero/searchDerroteros.controlador.php'

export default function searchDerroterosService({values}){
  return $.ajax({
    url: URL_API,
    type: 'POST',
    data: values
  })
}