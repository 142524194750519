import React from 'react';
import "./CardAccordionSearch.css";
import Icon from '../../components/Icon';

export default function CardAccordionSearch({ children, titulo, show, id, idAccordion }){

    const [shown, setShown] = React.useState(show)

    return(
        <div className="card shadow">
            <div className="card-header" id={`heading${id}`}>
                <h2 className="mb-0">
                    <button className="btn btn-block text-left text-white" type="button" data-toggle="collapse" data-target={`#card${id}`} aria-expanded="true" aria-controls={`#card${id}`} onClick={ () => setShown(!shown)}>
                        {
                            shown ?
                            <Icon icon="fa-solid fa-chevron-up mr-3" /> :
                            <Icon icon="fa-solid fa-chevron-down mr-3" /> 

                        }
                    { titulo }
                    </button>
                </h2>
            </div>

            <div id={ `card${id}` } className={`collapse ${show && "show"}`} aria-labelledby={`heading${id}`} data-parent={`#${idAccordion}`}>
                <div className="card-body">
                    { children }
                </div>
            </div>
        </div>
    );
}