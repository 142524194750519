import React from "react";
import "./PageView.css";

function PageView({ children }) {
    return(
        <div>
            { children }
        </div>
    );
}

export default PageView;