import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Icon from '../../components/Icon';
import getClaveService from "./../../services/getClaveService.js";
import changeInformacionClaveService from './../../services/changeInformacionClaveService';
import changeEstatusClaveService from './../../services/changeEstatusClaveService';
import FormularioClave from '../../components/FormularioClave';
import Swal from 'sweetalert2'
import $ from 'jquery';

import ModalAsignacion from '../../components/ModalAsignacion';
import Table from "./../../components/Table";
import TableHead from "./../../components/Table/TableHead";
import TableBodyHistorial from "../../components/Table/TableBodyHistorial";
import getPermisionariosActivosService from "./../../services/getPermisionariosActivosService.js";
import getPermisionariosByClaveService from "./../../services/getPermisionariosByClaveService.js";
import addAsignacionPermisionarioByClaveService from "./../../services/addAsignacionPermisionarioByClaveService";

export default function ViewClave({permisosUsuario}) {

    const location = useLocation();
    const clave = location.pathname.split('/')[2];

    const [dataClave, setClave] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    const [permisionarios, setPermisionarios] = React.useState([]);
    const [permisionariosByClave, setPermisionariosByClave] = React.useState([]);

    const rowsTablePermisionarios = [
        { id: 1, name: "Permisionario" },
        { id: 2, name: "Fecha asignación" },
        { id: 3, name: "Estatus" }
    ];

    const getClave = () => {
        let data = {
            clave: clave
        }
        getClaveService({values:data})
            .then(response => {
                let _response = JSON.parse(response);
                // console.log(_response)
                setClave(_response.clave);
            })
            .catch(error => {
                console.log(`Ha petado getClave(): ${error}`)
            })
    }

    const onSubmitUpdateClave = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('clave', clave);
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        changeInformacionClaveService({values:objectValues})
            .then(response => {
                console.log(response)
                let _response = JSON.parse(response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: 'Exito',
                        text: 'Actualizada correctamente',
                        icon: 'success',
                        timer: 1200,
                        showConfirmButton: false
                    })
                    getClave();
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No actualizaste ningún dato',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error en el servidor',
                    text: 'Inténtalo de nuevo más tarde o contacta al desarrollador',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
    }

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitar la clave?' : 'Quieres deshabilitar la clave?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('clave', clave)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                changeEstatusClaveService({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getClave();
                        Swal.fire({
                            text: '',
                            title: 'Estatus actualizado',
                            icon: 'success',
                            timer: 1200,
                            showConfirmButton: false
                        })
                    }
                    
                })
            }
        })
    }

    const getPermisionariosActivos = () =>{
        getPermisionariosActivosService()
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response)
                // console.log(_response)
                if(_response.response === 'error'){}else{
                    setPermisionarios(_response)
                }
            })
            .catch(error => {
                console.log("ha petado getPermisionarios() de ViewClave.js: ", error)
            })
    }

    const getPermisionariosByClave = () =>{
        let data = {
            clave: clave
        }
        getPermisionariosByClaveService({values:data})
            .then(response => {
                let _response = JSON.parse(response)
                // console.log(_response)
                if(_response.response === 'error'){
                } else {
                    setPermisionariosByClave(_response)
                }
                setLoading(false);
            })
            .catch(error => {
                console.log("ha petado getPermisionariosByClave() de ViewClave.js: ", error)
            })
    }

    const onSubmitAsignacionPermisionario = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('clave', clave)
        let objectValues = Object.fromEntries(values);
        Swal.fire({
            title: "¿Seguro que deseas asignar el permisionario?",
            text: "De ahora en adelante, este permisionario será el dueño sólo de esta clave.",
            showCancelButton: true,
            confirmButtonText: "Asignar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                addAsignacionPermisionarioByClaveService({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getPermisionariosByClave();
                        $("#modalAsignacionPermisionario").modal('hide');
                        Swal.fire({
                            title: 'Exito',
                            text: 'Asignado correctamente',
                            icon: 'success',
                            timer: 1200,
                            showConfirmButton: false
                        })
                    }
                    
                })
            }
        })
    }

    React.useEffect(() => {
        getClave()
        getPermisionariosActivos();
        getPermisionariosByClave();
    } , []);

    if(!loading){
        return(
            <section>
                <input
                    action="action"
                    onClick={() => {window.history.go(-1); return false;}}
                    type="submit"
                    value="Regresar"
                    className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
                />
    
                <h1 className="my-4">Información de la clave:</h1>
    
                <hr></hr>
    
                <div className="row">
                    <div className="col-3 text-center">
    
                        <h2 className="my-2">
                            {
                                clave
                            }
                        </h2>
    
                        <div className="d-flex flex-column align-items-center">
                            <span className={`flag ${ dataClave.activo == 1 ? '' : 'flag-inactive' }`}>
                                { dataClave.activo == 1 ? 'Activo' : 'Inactivo' }
                            </span>
                            <small className="mt-2">
                                Fecha alta: {dataClave.fecha_alta_con_formato}
                            </small>
                        </div>

                        <hr></hr>

                        { 
                            (dataClave.id_permisionario) ?
                                <React.Fragment>
                                    <p className="mt-4">
                                        Clave asignada a:&nbsp;
                                        <Link to={`/permisionarios/${dataClave.id_permisionario}`}>
                                            {dataClave.nombre_permisionario}
                                        </Link>
                                    </p>
                                    <small className="text-muted">
                                        Asignación: {dataClave.fecha_asignacion}
                                    </small>
                                </React.Fragment>
                            :
                                <p className="mt-4">
                                    Clave no asignada a ningún permisionario
                                </p>
                                
                        }

                        <hr></hr>
    
                        <div className="my-2 text-left nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                <Icon icon="fas fa-key mr-2" />
                                Clave
                            </a>
                            <a className="nav-link" id="v-pills-permisionarios-tab" data-toggle="pill" href="#v-pills-permisionarios" role="tab" aria-controls="v-pills-permisionarios" aria-selected="true">
                                <Icon icon="fa-solid fa-user mr-2" />
                                Permisionarios
                            </a>
                            
                            {
                                permisosUsuario.clave[0].editar == 1 &&
                                    (dataClave.activo == 1 ?
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(false)}>
                                        <Icon icon="fa-solid fa-circle-minus mr-2" />
                                        Deshabilitar
                                    </a>
                                    :
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(true)}>
                                        <Icon icon="fa-solid fa-circle-plus mr-2" />
                                        Habilitar
                                    </a>)
                            }
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                {
                                    Object.keys(dataClave).length !== 0 && 
                                        <FormularioClave
                                            clave={dataClave}
                                            onSubmit={onSubmitUpdateClave}
                                            permisosUsuario={permisosUsuario.clave}
                                        />
                                }
                            </div>
                            <div className="tab-pane fade" id="v-pills-permisionarios" role="tabpanel" aria-labelledby="v-pills-permisionarios-tab">

                                <div className="d-flex justify-content-between align-items-center">
                                    <h3>
                                        Permisionarios que han tenido la clave:
                                    </h3>

                                    {
                                        permisosUsuario.clave[0].escribir == 1 &&
                                        (
                                        <React.Fragment>
                                            <button className="btnPrimario btnPrimario-sm" data-toggle="modal" data-target="#modalAsignacionPermisionario">
                                                <Icon icon="fas fa-user-plus mr-2" />
                                                Asignar permisionario
                                            </button>
                                            <ModalAsignacion
                                                titulo={`Asignar un permisionario a la clave: ${clave}`}
                                                labelSelect="Seleccione un permisionario"
                                                dataSelect={permisionarios}
                                                idSelect="id_permisionario"
                                                idModal="modalAsignacionPermisionario"
                                                onSubmit={onSubmitAsignacionPermisionario}
                                            />
                                        </React.Fragment>
                                        )
                                    }
                                </div>

                                {
                                    permisionariosByClave.length == 0 &&
                                    <p className="text-muted">
                                        No existen permisionarios asignados a esta clave
                                    </p>
                                }

                                {
                                    permisionariosByClave.length >= 1 && 
                                    <React.Fragment>
                                        <Table>
                                            <TableHead rows={rowsTablePermisionarios} />

                                            <TableBodyHistorial elements={permisionariosByClave} type="permisionarios" permisosUsuario={permisosUsuario.clave}  />
                                        </Table>
                                    </React.Fragment>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

    } else {
        return(
            <div className="text-center mt-5">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }

}