import React from "react";
import "./Proyectos.css"
import $ from 'jquery';
import { validateNoNumbers } from './../../validators/validateNoNumbers';

export default function AddProyecto({}){

    React.useEffect(() => {
        $('.selectpicker').selectpicker();
    } ,[]);

    const onSubmit = (e) => {
        e.preventDefault();
    }

    return(
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
            />

            <h2 className="mt-5">Agregar un proyecto</h2>

            <p>
                Por favor, completa el siguiente formulario para agregar un proyecto a la base de datos:
            </p>

            <hr></hr>

            <form className="formulario" onSubmit={ onSubmit } id="formularioProyecto">
                {/* ****************************************** */}
                {/* BLOQUE DE LOS DATOS GENERALES DEL PROYECTO */}
                {/* ****************************************** */}

                <div className="proyecto__formulario row">
                    <div className="col-6">
                        <label htmlFor="clave_proyecto">
                            Clave del proyecto:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="clave_proyecto"
                            name="clave_proyecto"
                            required
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="nombre">
                            Nombre del proyecto:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="nombre"
                            name="nombre"
                            required
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="fecha_inicio">
                            Fecha de inicio:
                        </label>
                        <input 
                            type="date" 
                            className="input"
                            id="fecha_inicio"
                            name="fecha_inicio"
                            required
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="costo_por_accion">
                            Costo por acción:
                        </label>
                        <input 
                            type="num" 
                            className="input"
                            id="costo_por_accion"
                            name="costo_por_accion"
                            onKeyPress={ validateNoNumbers }
                            maxLength="15"
                            placeholder="$"
                            required
                        />
                    </div>
                    <div className="col-12">
                        <label htmlFor="descripcion">
                            Descripción amplia del proyecto:
                        </label>
                        <textarea 
                            rows={5}
                            cols={5}
                            type="text"
                            placeholder="Explica detalladamente lo que conlleva este proyecto, los objetivos, los resultados esperados, etc." 
                            className="input textarea"
                            id="descripcion"
                            name="descripcion"
                            required
                        />
                    </div>
                </div>

                <hr></hr>

                {/* ************************************** */}
                {/* BLOQUE DE PORCENTAJE DE UTILIDAD ANUAL */}
                {/* ************************************** */}


                <div className="proyecto__formulario row">
                    <div className="col-4">
                        <h3 className="mt-4">
                            Porcentaje de utilidad anual
                        </h3>

                        <label htmlFor="porcentaje_utilidad_anual">
                            Porcentaje:
                        </label>
                        <input 
                            type="num" 
                            className="input"
                            id="porcentaje_utilidad_anual"
                            name="porcentaje_utilidad_anual"
                            placeholder="Del 1 al 99"
                            onKeyPress={ validateNoNumbers }
                            maxLength="2"
                        />
                    </div>
                    <div className="col-8">
                        <h3 className="mt-4">
                            Vehículos asignados a este proyecto
                        </h3>

                        <label htmlFor="porcentaje_utilidad_anual">
                            Vehículos:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="vehiculos_proyecto"
                            name="vehiculos_proyecto"
                            data-live-search="true"
                            multiple
                            required 
                        >   
                            <option>Número de serie: 01237268713647182</option>
                            <option>Número de serie: 17362198428</option>
                            <option>Número de serie: 71624128347231834091290</option>
                            <option>Número de serie: 6351872</option>
                            <option>Número de serie: 19823748929</option>
                        </select>
                    </div>
                </div>

                <hr></hr>

                {/* ******************************************* */}
                {/* BLOQUE DE LOS DATOS DE ALCANCE DEL PROYECTO */}
                {/* ******************************************* */}


                <div className="proyecto__formulario row">
                    <div className="col-6">
                        <h3 className="mt-4">
                            ¿Cuántas acciones vas a emitir?
                        </h3>

                        <label htmlFor="total_acciones">
                            Total de acciones:
                        </label>
                        <input 
                            type="num" 
                            className="input"
                            id="total_acciones"
                            name="total_acciones"
                            placeholder="Opcional"
                            onKeyPress={ validateNoNumbers }
                            maxLength="6"
                        />
                    </div>
                    <div className="col-6">
                        <h3 className="mt-4">
                            ¿Existe alguna fecha prevista de culminación?
                        </h3>
                        <label htmlFor="fecha_culminacion">
                            Fecha de culminación:
                        </label>
                        <input 
                            type="date" 
                            className="input"
                            id="fecha_culminacion"
                            name="fecha_culminacion"
                            placeholder="Opcional"
                        />
                    </div>
                    <div className="col-6">
                        <h3 className="mt-4">
                            ¿Quieres fijar un monto límite de inversión?
                        </h3>
                        <label htmlFor="fecha_culminacion">
                            Monto total de inversión:
                        </label>
                        <input 
                            type="num" 
                            className="input"
                            id="fecha_culminacion"
                            name="fecha_culminacion"
                            placeholder="Opcional"
                            onKeyPress={ validateNoNumbers }
                            maxLength="15"
                        />
                    </div>
                    <div className="col-6">
                        <h3 className="mt-4">
                            ¿La empresa se llevará un porcentaje de comisión?
                        </h3>
                        <label htmlFor="fecha_culminacion">
                            Porcentaje de comisión:
                        </label>
                        <input 
                            type="num" 
                            className="input"
                            id="fecha_culminacion"
                            name="fecha_culminacion"
                            placeholder="Opcional"
                            onKeyPress={ validateNoNumbers }
                            maxLength="2"
                        />
                    </div>
                </div>

            </form>
        </section>
    )
}