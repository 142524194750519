import React from "react";
import SaldosPermisionarios from "./SaldosPermisionarios";
import SaldosTrabajadores from "./SaldosTrabajadores";

export default function SaldosSiniestros({permisosUsuario}) {

    const [switchAccounts, setSwitchAccounts] = React.useState(true)

    const handleSwitchAccounts = ()=>{
        setSwitchAccounts(!switchAccounts)
    }

    return (
        <section>

            <h1>Saldos de siniestros</h1>
            
            <input className="tgl tgl-flip" id="switchAccounts" type="checkbox" onClick={handleSwitchAccounts} />
            <label className="tgl-btn" data-tg-off="Permisonarios" data-tg-on="Trabajadores" htmlFor="switchAccounts"></label>

            {
                switchAccounts && permisosUsuario.saldosPermisionarios[0].leer === '1' &&
                    <SaldosPermisionarios 
                        permisosUsuario={permisosUsuario}
                    />
            }
            
            {
                !switchAccounts && permisosUsuario.saldosOperadores[0].leer === '1' &&
                    <SaldosTrabajadores 
                        permisosUsuario={permisosUsuario}
                    />
            }


        </section>
    );
}