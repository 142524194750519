import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2'

export default function TableElementMovimientoSaldoSiniestros({ element, permisosUsuario }) {

    let { cantidad, saldo, descripcion, descripcion_prorrata, concepto, id_banco_digital, fecha_alta, fecha_movimiento } = element;
    const history = useHistory();

    // const handleViewMovimientos = ()=>{
    //     if(id_banco_digital !== null)
    //         history.push(`/saldos-siniestro/${id_banco_digital}`)

    //     if(id_banco_digital === null)
    //         Swal.fire({
    //             title: 'No existen movimientos',
    //             text: 'El permisionario no tiene movimientos hasta la fecha',
    //             icon: 'info',
    //         })
        
    // }

    return (
        <tr key={id_banco_digital}>
            <th className='table-minwidth'>
                <div className="table__name">
                    {/* <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <button onClick={handleViewMovimientos} className="dropdown-item" >Ver detalles de movimientos</button>
                            <Link to={`/saldos-siniestro/${id_banco_digital}`} className="dropdown-item bg-color-naranja">Generar PDF</Link>
                        </div>
                    </div> */}

                    <div className="table__nameDescription">
                        <p>{fecha_alta}</p>
                    </div>
                </div>
            </th>
            
            <td>
                { concepto ? concepto : '-' }
                {
                    descripcion_prorrata && 
                    <React.Fragment>
                        <br/>
                        <small className='text-muted'>
                            Descripción prorrata: { descripcion_prorrata ? descripcion_prorrata : '-' }
                        </small>
                    </React.Fragment>

                }
            </td>

            <td className="font-size-lg color-rojo">
                {
                    parseFloat(cantidad) < 0 &&
                        parseFloat(cantidad).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                }
            </td>

            <td className="font-size-lg color-verde">
                {
                    parseFloat(cantidad) >= 0 &&
                        parseFloat(cantidad).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                }
            </td>

            <td className={`font-size-lg ${saldo >= 0 ? 'color-verde' : 'color-rojo'}`}>
                { parseFloat(saldo).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
            </td>
        </tr>
    )
    
}