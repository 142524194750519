import TableElement from './TableElement';
import TableElementConcesion from './TableElementConcesion';
import TableElementUnidad from './TableElementUnidad';
import TableElementUsuario from './TableElementUsuario';
import TableElementTrabajadores from './TableElementTrabajadores';
import TableElementDerroteros from './TableElementDerroteros';
import TableElementEconomicos from './TableElementEconomicos';
import TableElementClave from './TableElementClave';
import TableElementOperador from './TableElementOperador';
import TableElementProyecto from './TableElementProyecto';
import TableElementTerminal from './TableElementTerminal';
import TableElementLesionado from './TableElementLesionado';
import TableElementVehiculoAfectado from './TableElementVehiculoAfectado';
import TableElementBienAfectado from './TableElementBienAfectado';
import TableElementPreSiniestro from './TableElementPreSiniestro';
import TableElementSiniestro from './TableElementSiniestro';
import TableElementComentario from './TableElementComentario';
import TableElementGastoSiniestro from './TableElementGastoSiniestro';
import TableElementGastoContralorSiniestro from './TableElementGastoContralorSiniestro';
import TableElementPermisionarioSaldoSiniestros from './TableElementPermisionarioSaldoSiniestros';
import TableElementTrabajadorSaldoSiniestros from './TableElementTrabajadorSaldoSiniestros';
import TableElementMovimientoSaldoSiniestros from './TableElementMovimientoSaldoSiniestros';
import TableElementEntregaRecursoAprobadoContalor from './TableElementEntregaRecursoAprobadoContalor';
import TableElementMovimientoSaldoAreaEmpresa from './TableElementMovimientoSaldoAreaEmpresa';

export default function TableBody({ elements, typeElement, permisosUsuario, setElements, updateElements, idEstadoSiniestro, prorratasSiniestro, saldoTotalSiniestros }){

    function renderTableElements(){
        if(typeElement === "permisionarios"){
            return elements.map((element) => <TableElement key={element.id_permisionario} element={element} permisosUsuario={permisosUsuario} />);
        }
        
        if(typeElement === "concesiones"){
            return elements.map((element) => <TableElementConcesion key={element.id_concesion} element={element} permisosUsuario={permisosUsuario} />);
        }
        
        if(typeElement === "unidades"){
            return elements.map((element) => <TableElementUnidad key={element.id_vehiculo} element={element} permisosUsuario={permisosUsuario} />);
        }
        
        if(typeElement === "usuarios"){
            return elements.map((element) => <TableElementUsuario key={element.id_usuario} element={element} permisosUsuario={permisosUsuario} />);
        }
        
        if(typeElement === "trabajadores"){
            return elements.map((element) => <TableElementTrabajadores key={element.id_trabajador} element={element}  permisosUsuario={permisosUsuario} />);
        }

        if(typeElement === "derroteros"){
            return elements.map((element) => <TableElementDerroteros key={element.id_derrotero} element={element}  permisosUsuario={permisosUsuario} />);
        }

        if(typeElement === "economicos"){
            return elements.map((element) => <TableElementEconomicos key={element.id_economico} element={element}  permisosUsuario={permisosUsuario} />);
        }

        if(typeElement === "claves"){
            return elements.map((element) => <TableElementClave key={element.clave} element={element}  permisosUsuario={permisosUsuario} />);
        }

        if(typeElement === "operadores"){
            return elements.map((element) => <TableElementOperador key={element.id_operador} element={element}  permisosUsuario={permisosUsuario} />);
        }

        if(typeElement === "proyectos"){
            return elements.map((element) => <TableElementProyecto key={element.id_proyecto} element={element}  permisosUsuario={permisosUsuario} />);
        }

        if(typeElement === "terminales"){
            return elements.map((element) => <TableElementTerminal key={element.id_terminal} element={element}  permisosUsuario={permisosUsuario} />);
        }

        if(typeElement === "lesionados"){
            return elements.map((element, i) => 
                <TableElementLesionado 
                    key={i}
                    index={i}
                    element={element}
                    elements={elements}
                    setElements={setElements}
                    permisosUsuario={permisosUsuario}
                    idEstadoSiniestro={idEstadoSiniestro}
                />);
        }

        if(typeElement === "vehiculos_afectados"){
            return elements.map((element, i) => 
                <TableElementVehiculoAfectado
                    key={i}
                    index={i}
                    element={element}
                    elements={elements}
                    setElements={setElements}
                    permisosUsuario={permisosUsuario}
                    idEstadoSiniestro={idEstadoSiniestro}
                />);
        }

        if(typeElement === "bienes_afectados"){
            return elements.map((element, i) => 
                <TableElementBienAfectado
                    key={i}
                    index={i}
                    element={element}
                    elements={elements}
                    setElements={setElements}
                    permisosUsuario={permisosUsuario}
                    idEstadoSiniestro={idEstadoSiniestro}
                />);
        }

        if(typeElement === "pre_siniestros"){
            return elements.map((element) => 
                <TableElementPreSiniestro
                    key={element.id_pre_siniestro}
                    element={element}
                    permisosUsuario={permisosUsuario}
                />);
        }

        if(typeElement === "siniestro"){
            return elements.map((element) => 
                <TableElementSiniestro
                    key={element.id_pre_siniestro}
                    element={element}
                    permisosUsuario={permisosUsuario}
                />);
        }

        if(typeElement === "comentarios"){
            return elements.map((element, i) => 
                <TableElementComentario
                    key={i}
                    index={i}
                    element={element}
                    elements={elements}
                    setElements={setElements}
                    permisosUsuario={permisosUsuario}
                    idEstadoSiniestro={idEstadoSiniestro}
                />);
        }

        if(typeElement === "gastos"){
            return elements.map((element, i) => 
                <TableElementGastoSiniestro
                    key={i}
                    index={i}
                    element={element}
                    elements={elements}
                    setElements={setElements}
                    permisosUsuario={permisosUsuario}
                    updateElements={updateElements}
                    idEstadoSiniestro={idEstadoSiniestro}
                    prorratasSiniestro={prorratasSiniestro}
                />);
        }

        if(typeElement === "gasto_contralor_siniestro"){
            return elements.map((element, i) => 
                <TableElementGastoContralorSiniestro
                    key={i}
                    element={element}
                    permisosUsuario={permisosUsuario}
                    updateElements={updateElements}
                    saldoTotalSiniestros={saldoTotalSiniestros}
                />);
        }

        if(typeElement === "permisionario_saldo_siniestros"){
            return elements.map((element, i) => 
                <TableElementPermisionarioSaldoSiniestros
                    key={i}
                    element={element}
                    permisosUsuario={permisosUsuario}
                />);
        }

        if(typeElement === "trabajador_saldo_siniestros"){
            return elements.map((element, i) => 
                <TableElementTrabajadorSaldoSiniestros
                    key={i}
                    element={element}
                    permisosUsuario={permisosUsuario}
                />);
        }

        if(typeElement === "movimiento_saldo_siniestros"){
            return elements.map((element, i) => 
                <TableElementMovimientoSaldoSiniestros
                    key={i}
                    element={element}
                    permisosUsuario={permisosUsuario}
                />);
        }

        if(typeElement === "movimiento_saldo_area_empresa"){
            return elements.map((element, i) => 
                <TableElementMovimientoSaldoAreaEmpresa
                    key={i}
                    element={element}
                    permisosUsuario={permisosUsuario}
                />);
        }

        if(typeElement === "entrega_recurso_aprobado_contralor"){
            return elements.map((element, i) => 
                <TableElementEntregaRecursoAprobadoContalor
                    key={i}
                    element={element}
                    permisosUsuario={permisosUsuario}
                    updateElements={updateElements}
                />);
        }
    }

    return(
        <tbody>

            {   
                renderTableElements()
            }

        </tbody>
    )
}