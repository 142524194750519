import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2'
import PDFGeneratorEstadoCuentaSiniestro from '../PDFGeneratorEstadoCuentaSiniestro';
import { BlobProvider } from "@react-pdf/renderer";

export default function TableElementTrabajadorSaldoSiniestros({ element, permisosUsuario }) {

    let { id_trabajador, id_banco_digital, nombre, apellido_materno, apellido_paterno, nombre_tipo_permisionario, urlImageProfile, key, units, date, hour, saldo_total, activo } = element;
    const history = useHistory();

    const [showPDF, setShowPDF] = React.useState(false)

    const handleViewMovimientos = ()=>{
        if(id_banco_digital !== null)
            history.push(`/saldos-siniestro/${id_banco_digital}`)

        if(id_banco_digital === null)
            Swal.fire({
                title: 'No existen movimientos',
                text: 'El trabajador no tiene movimientos hasta la fecha',
                icon: 'info',
            })
        
    }

    const blob = () =>{
        return (
            <BlobProvider document={<PDFGeneratorEstadoCuentaSiniestro id_banco_digital={id_banco_digital}/>}>
                {({url}) =>{
                    if(url === null){
                        return (
                            <button className="btn btn-sm btn-light mt-2 d-flex align-items-center justify-content-center color-naranja">
                                <i className="fa-solid fa-spinner fa-spin mr-2"></i>
                                Generando estado de cuenta...
                            </button>
                        )
                    }
                    
                    if(url !== null){
                        // Cuando se genera el PDF, lo abrimos en una nueva ventana
                        const pdfWindow = window.open();
                        pdfWindow.location.href = url;
                    }
                    
                    setShowPDF(false);
                    // Retornamos algo si no peta
                    return false;
                }}
            </BlobProvider>
        )
    }

    const generateEstadoCuenta = ()=>{
        if(id_banco_digital === null)
            Swal.fire({
                title: 'No existen movimientos',
                text: 'El trabajador no tiene movimientos hasta la fecha',
                icon: 'info',
            })

        if(id_banco_digital !== null)
            setShowPDF(true)
    }

    return (
        <tr key={id_banco_digital}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <button onClick={handleViewMovimientos} className="dropdown-item" >Ver detalles de movimientos</button>
                            <button onClick={generateEstadoCuenta} className="dropdown-item bg-color-naranja" >Generar PDF estado de cuenta</button>
                        </div>
                    </div>

                    <div className="table__nameDescription">
                        <p>{nombre} {apellido_paterno} {apellido_materno}</p>
                        {
                            showPDF && blob()
                        }
                    </div>
                </div>
            </th>
            
            <td className={`font-size-lg ${saldo_total > 0 && 'color-verde'} ${saldo_total < 0 && 'color-rojo'} ${saldo_total === 0 && 'text-black'}`}>
                { parseFloat(saldo_total).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ activo === '1' ? '' : 'table__estatus-inactive' }>
                        { activo === '1' ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}