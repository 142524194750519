import Swal from 'sweetalert2'
import config from '../../config';
import $ from 'jquery';
const URL_API = config.urlApi;

export const AddClave = async() => {

    const steps = ['1', '2']
    const preguntas = ['Escribe la nueva clave', '¿Cuándo se emitió o emitirá la clave?']
    const swalQueueStep = Swal.mixin({
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Atrás',
        progressSteps: steps,
        buttonsStyling: false,
        input: 'text',
        inputAttributes: {
            required: true
        },
        reverseButtons: true,
        validationMessage: 'Este campo es requerido',
    })

    const values = []
    let currentStep

    for (currentStep = 0; currentStep < steps.length;) {
        const result = await swalQueueStep.fire({
            title: preguntas[currentStep],
            text: (currentStep === 0) ? '' : 'Asegúrate de escribir bien el formato de la fecha aaaa/mm/dd',
            inputValue: values[currentStep],
            showCancelButton: currentStep > 0,
            currentProgressStep: currentStep,
            customClass: {
                confirmButton: 'btnPrimario',
                cancelButton: 'btnPrimario btnPrimario-colorSecundario',
                input: (currentStep === 1) ? 'fechaEmision' : ''
            },
            inputPlaceholder: (currentStep === 1) ? 'aaaa/mm/dd' : '',
            showLoaderOnConfirm: true,
            preConfirm: (clave) => {
                if(currentStep === 0){
                    // VERIFICAR SI EXISTE LA CLAVE
                    return $.ajax({
                        data:{clave:clave},
                        url: URL_API+'controlador/existClave.controlador.php',
                        type: 'POST',
                    }).then((response)=>{
                        let _respuesta = JSON.parse(response)
                        if(_respuesta.response === 'error'){
                            Swal.showValidationMessage('La clave ya existe')
                        }
                        return clave;
                    },()=>{
                        Swal.showValidationMessage('Error del sistema, contacte al desarrollador')
                    })
                }
                
            },
        })

        if (result.value) {
            values[currentStep] = result.value
            currentStep++
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            currentStep--
        } else {
            break
        }
    }

    if (currentStep === steps.length) {
        let nuevaClave = values[0]
        let fechaEmision = values[1]
        const user = JSON.parse(localStorage.getItem("user"))
        const id = user.id_usuario

        $.ajax({
            data:{clave:nuevaClave, fechaEmision:fechaEmision, id: id},
            url: URL_API+'controlador/addClave.controlador.php',
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            success: function(response){
                console.log(response);
                let _respuesta = JSON.parse(response)
                if(_respuesta.response === 'success'){
                    Swal.fire(
                        'Clave agregada',
                        '',
                        'success'
                    ).then(()=>{
                        return true;
                    })
                } else {
                    Swal.fire(
                        'Ocurrió un error',
                        'Vuelve a intentarlo',
                        'error'
                    )
                }

            }
        })
    }
}