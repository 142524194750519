import React from 'react';

// Componentes
import { BrowserRouter, Route, Switch, Redirect, Link } from 'react-router-dom';
import LateralMenu from "./../../components/LateralMenu";
import Header from "./../../components/Header";
import PageView from "./../../components/PageView";

// Páginas
import Permisionario from '../Permisionarios';
import AddPermisionario from '../Permisionarios/AddPermisionario';
import ViewPermisionario from '../Permisionarios/ViewPermisionario';

import Concesiones from '../Concesiones';
import AddConcesion from '../Concesiones/AddConcesion';
import ViewConcesion from '../Concesiones/ViewConcesion';

import Vehiculos from '../Vehiculos';
import AddVehiculo from '../Vehiculos/AddVehiculo';
import ViewVehiculo from '../Vehiculos/ViewVehiculo';

import Dashboard from './../Dashboard';

import Usuarios from '../Usuarios';
import AddUsuario from '../Usuarios/AddUsuario';
import ViewUsuario from '../Usuarios/ViewUsuario';

import Trabajadores from '../Trabajadores';
import AddTrabajador from '../Trabajadores/AddTrabajador';
import ViewTrabajador from '../Trabajadores/ViewTrabajador';

import Derroteros from '../Derroteros';
import AddDerrotero from '../Derroteros/AddDerrotero';
import ViewDerrotero from '../Derroteros/ViewDerrotero';

import Economicos from '../Economicos';
import AddEconomico from '../Economicos/AddEconomico';
import ViewEconomico from '../Economicos/ViewEconomico';

import Claves from '../Claves';
import ViewClave from '../Claves/ViewClave';

import Operadores from '../Operadores';

import Proyectos from '../Proyectos';
import AddProyecto from '../Proyectos/AddProyecto';

import Checadores from '../Checadores';
// import AddProyecto from '../Checadores/AddProyecto';

import Resultados from '../Resultados';
import Terminales from '../Terminales';
import AddTerminal from '../Terminales/AddTerminal';
import Presiniestros from '../Presiniestros';
import TiposVehiculos from '../Catalogos/TiposVehiculos';
import PrefijosEconomicos from '../Catalogos/PrefijosEconomicos';
import Religiones from '../Catalogos/Religiones';
import MarcasVehiculos from '../Catalogos/MarcasVehiculos';
import SubmarcasVehiculos from '../Catalogos/SubmarcasVehiculos';
import Aseguradoras from '../Catalogos/Aseguradoras';
import TiposLicenciaConducir from '../Catalogos/TiposLicenciaConducir';
import UMA from '../Catalogos/UMA';
import ReglasProrrateo from '../Catalogos/ReglasProrrateo';
import ProrratasSiniestro from '../Catalogos/ProrratasSiniestro';
import TiposCombustible from '../Catalogos/TiposCombustible';
import EstadosCiviles from '../Catalogos/EstadosCiviles';
import Generos from '../Catalogos/Generos';
import TiposPermisionario from '../Catalogos/TiposPermisionario';
import TiposTrabajadores from '../Catalogos/TiposTrabajadores';
import AddPresiniestro from '../Presiniestros/AddPresiniestro';
import TiposDeBienes from '../Catalogos/TiposDeBienes';
import Siniestros from '../Siniestros';
import ViewSiniestro from '../Siniestros/ViewSiniestro';
import MetodoPago from '../Catalogos/MetodoPago';
import TipoGastoSiniestro from '../Catalogos/TipoGastoSiniestro';
import ContraloriaSiniestro from '../Siniestros/ContraloriaSiniestro';
import EntregaRecurso from '../EntregaRecurso';
import SaldosDepartamentos from '../Finanzas/SaldosDepartamentos';
import SaldosSiniestros from '../Siniestros/SaldosSiniestros';
import RecoverPassword from '../RecoverPassword';
import CantidadFianza from '../Catalogos/CantidadFianza';
import EntidadLegalSiniestro from '../Catalogos/EntidadLegalSiniestro';
import ViewMovimientosSaldoSiniestro from '../Siniestros/ViewMovimientosSaldoSiniestro';

export default function HomePage({user, setUser}) {

    function logout(){
        window.history.pushState({}, "", "/")
        // window.location.replace('/')
        setUser({
            isLoggedIn: false,
            typeUser: 0,
            user : null
        })
        localStorage.removeItem('user');
    }

    const getPermiso = ({modulo}) => {
        let _modulo = user.user.permisos.filter(
            permiso=>permiso.nombre_modulo === modulo)

        return (_modulo.length >= 1) ? _modulo : []
    }

    // Estado para saber sí ha buscado algo o no
    const [loadingSearch, setLoadingSearch] = React.useState(true);

    const permisoConcesion = getPermiso({modulo: "Concesiones"});
    const permisoPermisionario = getPermiso({modulo: "Permisionarios"});
    const permisoVehiculo = getPermiso({modulo: "Vehiculos"});
    const permisoUsuario = getPermiso({modulo: "Usuarios"});
    const permisoTrabajador = getPermiso({modulo: "Trabajadores"});
    const permisoEconomico = getPermiso({modulo: "Economicos"});
    const permisoDerrotero = getPermiso({modulo: "Derroteros"});
    const permisoClave = getPermiso({modulo: "Claves"});
    const permisoOperador = getPermiso({modulo: "Operadores"});
    const permisoProyecto = getPermiso({modulo: "Proyectos"});
    const permisoChecador = getPermiso({modulo: "Checadores"});
    const permisoTerminal = getPermiso({modulo: "Terminales"});
    const permisoPresiniestro = getPermiso({modulo: "Presiniestros"});
    const permisoSiniestro = getPermiso({modulo: "Siniestro"});
    const permisoCatalogos = getPermiso({modulo: "Catálogos"});
    const permisoContraloria = getPermiso({modulo: "Contraloría"});
    const permisoFinanzasEntregaRecurso = getPermiso({modulo: "Finanzas: Entrega de recurso"});
    const permisoSaldosDepartamentos = getPermiso({modulo: "Saldos: Departamentos"});
    const permisoSaldosSiniestros = getPermiso({modulo: "Saldos: Siniestros (empresa)"});
    const permisoSaldosPermisionarios = getPermiso({modulo: "Saldos: Siniestros (permisionarios)"});
    const permisoSaldosOperadores = getPermiso({modulo: "Saldos: Siniestros (operadores)"});

    const permisosUsuario = {
        concesion : permisoConcesion,
        permisionario : permisoPermisionario,
        vehiculo : permisoVehiculo,
        usuario : permisoUsuario,
        trabajador : permisoTrabajador,
        economico : permisoEconomico,
        derrotero : permisoDerrotero,
        clave : permisoClave,
        operador: permisoOperador,
        proyecto: permisoProyecto,
        checador: permisoChecador,
        terminal: permisoTerminal,
        presiniestro: permisoPresiniestro,
        siniestro: permisoSiniestro,
        catalogos: permisoCatalogos,
        contraloria: permisoContraloria,
        finanzasEntregaRecurso: permisoFinanzasEntregaRecurso,
        saldosDepartamentos: permisoSaldosDepartamentos,
        saldosSiniestros: permisoSaldosSiniestros,
        saldosPermisionarios: permisoSaldosPermisionarios,
        saldosOperadores: permisoSaldosOperadores
    }

    console.log({permisosUsuario});

    return (
        <div id="body" className="body_move">
            <LateralMenu logout={logout}>

                {
                    ( (permisoClave.length && permisoClave[0].leer === '1') ||
                    (permisoPermisionario.length && permisoPermisionario[0].leer === '1') ||
                    (permisoVehiculo.length && permisoVehiculo[0].leer === '1' ) ||
                    (permisoDerrotero.length && permisoDerrotero[0].leer === '1') ||
                    (permisoConcesion.length && permisoConcesion[0].leer === '1') ||
                    (permisoEconomico.length && permisoEconomico[0].leer === '1') ||
                    (permisoUsuario.length && permisoUsuario[0].leer === '1') ) ?
                    <div>
                        <div className="option" type="button" data-toggle="collapse" data-target="#collapseAdministracion" aria-expanded="false" aria-controls="collapseAdministracion">
                            <i className="fas fa-crown" title="Administracion"></i>
                            <p>Presidencia</p>
                            <i className="fas fa-chevron-right ml-3"></i>
                        </div>
                        <div className="collapse bg-black" id="collapseAdministracion">
                            {
                                permisoClave.length && permisoClave[0].leer == 1 ?
                                <Link to={"/claves"} className="option_enlace clavesLink">
                                    <div className="option">
                                        <i className="fa-solid fa-key" title="Claves"></i>
                                        <p>Claves</p>
                                    </div>
                                </Link> : null
                            }
                            {
                                permisoPermisionario.length && permisoPermisionario[0].leer == 1 ? 
                                <Link to={"/permisionarios"} className="option_enlace permisionariosLink">
                                    <div className="option">
                                        <i className="fa-solid fa-user" title="Permisionarios"></i>
                                        <p>Permisionarios</p>
                                    </div>
                                </Link> : null
                            }
                            {
                                permisoVehiculo.length && permisoVehiculo[0].leer === '1' ? 
                                <Link to={"/vehiculos"} className="option_enlace vehiculosLink">
                                    <div className="option">
                                        <i className="fa-solid fa-truck-moving" title="Vehículos"></i>
                                        <p>Vehículos</p>
                                    </div>
                                </Link> : null
                            }
                            {
                                permisoDerrotero.length && permisoDerrotero[0].leer == 1 ?
                                <Link to={"/derrotero"} className="option_enlace derroteroLink">
                                    <div className="option">
                                        <i className="fa-solid fa-signs-post" title="Derroteros"></i>
                                        <p>Derroteros</p>
                                    </div>
                                </Link> : null
                            }
                            {
                                permisoConcesion.length && permisoConcesion[0].leer == 1 ?
                                <Link to={"/concesiones"} className="option_enlace concesionesLink">
                                    <div className="option">
                                        <i className="fa-solid fa-file-invoice" title="Concesiones"></i>
                                        <p>Concesiones</p>
                                    </div>
                                </Link> : null
                            }
                            {
                                permisoEconomico.length && permisoEconomico[0].leer == 1 ?
                                <Link to={"/economicos"} className="option_enlace economicosLink">
                                    <div className="option">
                                        <i className="fa-solid fa-hashtag" title="Económicos"></i>
                                        <p>Económicos</p>
                                    </div>
                                </Link> : null
                            }
                            {/* {
                                permisoProyecto.length && permisoProyecto[0].leer == 1 ? 
                                <Link to={"/proyectos"} className="option_enlace proyectosLink">
                                    <div className="option">
                                        <i className="fa-solid fa-hands-holding-circle" title="Proyectos"></i>
                                        <p>Proyectos</p>
                                    </div>
                                </Link> : null
                            } */}
                            {
                                permisoUsuario.length && permisoUsuario[0].leer == 1 ?
                                <Link to={"/usuarios"} className="option_enlace usuariosLink">
                                    <div className="option">
                                        <i className="fa-solid fa-circle-user" title="Usuarios"></i>
                                        <p>Usuarios</p>
                                    </div>
                                </Link> : null
                            }
                        </div>
                    </div> : null
                }
                
                
                {
                    ( (permisoChecador.length && permisoChecador[0].leer === '1') || 
                    (permisoTerminal.length && permisoTerminal[0].leer === '1') ) ?
                    <div>
                        <div className="option" type="button" data-toggle="collapse" data-target="#collapseServicio" aria-expanded="false" aria-controls="collapseServicio">
                            <i className="fas fa-route" title="Administracion"></i>
                            <p>Servicio</p>
                            <i className="fas fa-chevron-right ml-3"></i>
                        </div>
                        <div className="collapse bg-black" id="collapseServicio">
                            {
                                permisoChecador.length && permisoChecador[0].leer == 1 ? 
                                <Link to={"/checadores"} className="option_enlace checadoresLink">
                                    <div className="option">
                                        <i className="fa-solid fa-id-card" title="checadores"></i>
                                        <p>Checadores</p>
                                    </div>
                                </Link> : null
                            }
                            {
                                permisoTerminal.length && permisoTerminal[0].leer == 1 ? 
                                <Link to={"/terminales"} className="option_enlace terminalesLink">
                                    <div className="option">
                                        <i className="fa-solid fa-business-time" title="terminales"></i>
                                        <p>Terminales</p>
                                    </div>
                                </Link> : null
                            }
                        </div>
                    </div> : null
                }
                
                {
                    permisoTrabajador.length && permisoTrabajador[0].leer === '1' ?
                    <div>
                        <div className="option" type="button" data-toggle="collapse" data-target="#collapseRecursosHumanos" aria-expanded="false" aria-controls="collapseRecursosHumanos">
                            <i className="fas fa-users" title="Administracion"></i>
                            <p>RH</p>
                            <i className="fas fa-chevron-right ml-3"></i>
                        </div>
                        <div className="collapse bg-black" id="collapseRecursosHumanos">
                            {
                                permisoTrabajador.length && permisoTrabajador[0].leer == 1 ?
                                <Link to={"/trabajador"} className="option_enlace trabajadorLink">
                                    <div className="option">
                                        <i className="fa-solid fa-briefcase" title="Trabajadores"></i>
                                        <p>Trabajadores</p>
                                    </div>
                                </Link> : null
                            }
                        </div>
                    </div> : null
                }
                
                {
                    ( (permisoPresiniestro.length && permisoPresiniestro[0].leer === '1') ||
                    (permisoSiniestro.length && permisoSiniestro[0].leer === '1') ||   
                    (permisoSaldosOperadores.length && permisoSaldosOperadores[0].leer === '1') || 
                    (permisoSaldosPermisionarios.length && permisoSaldosPermisionarios[0].leer === '1') || 
                    (permisoContraloria.length && permisoContraloria[0].leer === '1') ) ?
                    <div>
                        <div className="option" type="button" data-toggle="collapse" data-target="#collapseCAS" aria-expanded="false" aria-controls="collapseCAS">
                            <i className="fas fa-car-burst" title="CAS"></i>
                            <p>CAS</p>
                            <i className="fas fa-chevron-right ml-3"></i>
                        </div>
                        <div className="collapse bg-black" id="collapseCAS">
                            {
                                permisoPresiniestro.length && permisoPresiniestro[0].leer === '1' ?
                                <Link to={"/pre-siniestros"} className="option_enlace pre-siniestrosLink">
                                    <div className="option">
                                        <i className="fa-solid fa-car-on" title="Pre siniestro"></i>
                                        <p>Pre siniestros</p>
                                    </div>
                                </Link> : null
                            }
                            {
                                permisoSiniestro.length && permisoSiniestro[0].leer === '1' ? 
                                <Link to={"/siniestros"} className="option_enlace siniestrosLink">
                                    <div className="option">
                                        <i className="fa-solid fa-car-burst" title="Permisionarios"></i>
                                        <p>Siniestros</p>
                                    </div>
                                </Link> : null
                            }
                            {
                                permisoContraloria.length && permisoContraloria[0].leer === '1' ? 
                                <Link to={"/contraloria-siniestros"} className="option_enlace contraloria-siniestrosLink">
                                    <div className="option">
                                        <i className="fa-solid fa-circle-dollar-to-slot" title="Contraloría siniestros"></i>
                                        <p>Contraloría siniestros</p>
                                    </div>
                                </Link> : null
                            }
                            {
                                // permisoSaldosDepartamentos.length && permisoSaldosDepartamentos[0].leer === '1' ? 
                                ( (permisoSaldosOperadores.length && permisoSaldosOperadores[0].leer === '1') || 
                                (permisoSaldosPermisionarios.length && permisoSaldosPermisionarios[0].leer === '1') ) ?
                                <Link to={"/saldos-siniestro"} className="option_enlace saldos-siniestroLink">
                                    <div className="option">
                                        <i className="fa-solid fa-dollar-sign" title="Permisionarios"></i>
                                        <p>Saldos de siniestros</p>
                                    </div>
                                </Link> : null
                            }
                            {/* {
                                permisoVehiculo.length && permisoVehiculo[0].leer == 1 ? 
                                <Link to={"/vehiculos"} className="option_enlace vehiculosLink">
                                    <div className="option">
                                        <i className="fa-solid fa-truck-moving" title="Vehículos"></i>
                                        <p>Vehículos</p>
                                    </div>
                                </Link> : null
                            } */}
                        </div>
                    </div> : null
                }
                
                {
                    ( (permisoFinanzasEntregaRecurso.length && permisoFinanzasEntregaRecurso[0].leer === '1') ||
                    (permisoSaldosDepartamentos.length && permisoSaldosDepartamentos[0].leer === '1') ) ?
                    <div>
                        <div className="option" type="button" data-toggle="collapse" data-target="#collapseFinanzas" aria-expanded="false" aria-controls="collapseFinanzas">
                            <i className="fas fa-sack-dollar" title="CAS"></i>
                            <p>Finanzas</p>
                            <i className="fas fa-chevron-right ml-3"></i>
                        </div>
                        <div className="collapse bg-black" id="collapseFinanzas">
                            {
                                ( (permisoSaldosDepartamentos.length && permisoSaldosDepartamentos[0].leer === '1') ||
                                (permisoSaldosSiniestros.length && permisoSaldosSiniestros[0].leer === '1') ) ?
                                <Link to={"/saldos-departamentos"} className="option_enlace saldos-departamentosLink">
                                    <div className="option">
                                        <i className="fa-solid fa-dollar-sign" title="Saldos de áreas de la empresa"></i>
                                        <p>Saldos de departamentos</p>
                                    </div>
                                </Link> : null
                            }
                            {
                                permisoFinanzasEntregaRecurso.length && permisoContraloria[0].leer === '1' ? 
                                <Link to={"/entrega-recurso"} className="option_enlace entrega-recursoLink">
                                    <div className="option">
                                        <i className="fa-solid fa-hand-holding-dollar" title="Contraloría siniestros"></i>
                                        <p>Entrega de recursos</p>
                                    </div>
                                </Link> : null
                            }
                            {/* {
                                permisoVehiculo.length && permisoVehiculo[0].leer == 1 ? 
                                <Link to={"/vehiculos"} className="option_enlace vehiculosLink">
                                    <div className="option">
                                        <i className="fa-solid fa-truck-moving" title="Vehículos"></i>
                                        <p>Vehículos</p>
                                    </div>
                                </Link> : null
                            } */}
                        </div>
                    </div> : null
                }

                {
                    (permisoCatalogos.length && permisoCatalogos[0].leer === '1') ?
                    <div>
                        <div className="option" type="button" data-toggle="collapse" data-target="#collapseCatalogos" aria-expanded="false" aria-controls="collapseCatalogos">
                            <i className="fas fa-gears" title="Catalogos"></i>
                            <p>Catálogos</p>
                            <i className="fas fa-chevron-right ml-3"></i>
                        </div>
                        <div className="collapse bg-black" id="collapseCatalogos">
                            <Link to={"/catalogos/cantidad-fianza"} className="option_enlace cantidad-fianzaLink">
                                <div className="option">
                                    <p>Cantidades de fianza</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/entidades-legales"} className="option_enlace entidades-legalesLink">
                                <div className="option">
                                    <p>Entidades legales</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/tipos-vehiculos"} className="option_enlace tipos-vehiculosLink">
                                <div className="option">
                                    <p>Tipos de vehículos</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/prefijos-economicos"} className="option_enlace prefijos-economicosLink">
                                <div className="option">
                                    <p>Prefijos de económicos</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/religiones"} className="option_enlace religionesLink">
                                <div className="option">
                                    <p>Religiones</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/marcas-vehiculos"} className="option_enlace marcas-vehiculosLink">
                                <div className="option">
                                    <p>Marcas de vehículos</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/submarcas-vehiculos"} className="option_enlace submarcas-vehiculosLink">
                                <div className="option">
                                    <p>Submarcas de vehículos</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/metodo-pago"} className="option_enlace metodo-pagoLink">
                                <div className="option">
                                    <p>Métodos de pago</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/tipos-gasto-siniestro"} className="option_enlace tipos-gastos-siniestroLink">
                                <div className="option">
                                    <p>Tipos de gasto del siniestro</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/aseguradoras"} className="option_enlace aseguradorasLink">
                                <div className="option">
                                    <p>Aseguradoras</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/tipos-licencia-conducir"} className="option_enlace tipos-licencia-conducirLink">
                                <div className="option">
                                    <p>Tipos de licencia conducir</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/uma"} className="option_enlace umaLink">
                                <div className="option">
                                    <p>UMA's</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/reglas-prorrateo"} className="option_enlace reglas-prorrateoLink">
                                <div className="option">
                                    <p>Reglas del prorrateo</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/prorratas-siniestro"} className="option_enlace prorratas-siniestroLink">
                                <div className="option">
                                    <p>Prorratas de siniestros</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/tipos-combustible"} className="option_enlace tipos-combustibleLink">
                                <div className="option">
                                    <p>Tipos de combustible</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/estados-civiles"} className="option_enlace estados-civilesLink">
                                <div className="option">
                                    <p>Estados civiles</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/generos"} className="option_enlace generosLink">
                                <div className="option">
                                    <p>Géneros</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/tipos-permisionario"} className="option_enlace tipos-permisionarioLink">
                                <div className="option">
                                    <p>Tipos de permisionario</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/tipos-trabajadores"} className="option_enlace tipos-trabajadoresLink">
                                <div className="option">
                                    <p>Tipos de trabajadores</p>
                                </div>
                            </Link>
                            <Link to={"/catalogos/tipos-bienes"} className="option_enlace tipos-bienesLink">
                                <div className="option">
                                    <p>Tipos de bienes</p>
                                </div>
                            </Link>
                        </div>
                    </div> : null
                }
            </LateralMenu>
            <Header logout={logout} />
            <PageView>
                <Switch>
                    <Route path="/" exact> 
                        <Dashboard />
                    </Route>
                    <Route path="/permisionarios" exact
                    render={ ()=>{
                        return permisoPermisionario.length && permisoPermisionario[0].leer == 1 ?
                        <Permisionario permisosUsuario={permisoPermisionario} />
                        :
                        <Redirect to="/"/>
                    } }></Route>

                    <Route path="/permisionarios/agregar" exact
                    render={ ()=>{
                        return (permisoPermisionario.length && permisoPermisionario[0].escribir == 1) ?
                        <AddPermisionario />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/permisionarios/:id" exact
                    render={ ()=>{
                        return (permisoPermisionario.length && permisoPermisionario[0].leer == 1) ?
                        <ViewPermisionario permisosUsuario={permisosUsuario} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/concesiones" exact
                    render={ ()=>{
                        return permisoConcesion.length && permisoConcesion[0].leer == 1 ?
                        <Concesiones permisosUsuario={permisoConcesion} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/concesiones/agregar" exact
                    render={ ()=>{
                        return (permisoConcesion.length && permisoConcesion[0].escribir == 1) ?
                        <AddConcesion />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/concesiones/:id" exact
                    render={ ()=>{
                        return (permisoConcesion.length && permisoConcesion[0].leer == 1) ?
                        <ViewConcesion permisosUsuario={permisosUsuario} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/vehiculos" exact
                    render={ ()=>{
                        return permisoVehiculo.length && permisoVehiculo[0].leer == 1 ? 
                        <Vehiculos permisosUsuario={permisoVehiculo} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/vehiculos/agregar" exact
                    render={ ()=>{
                        return (permisoVehiculo.length && permisoVehiculo[0].escribir == 1) ?
                        <AddVehiculo />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/vehiculos/:id" exact
                    render={ ()=>{
                        return (permisoVehiculo.length && permisoVehiculo[0].leer == 1) ?
                        <ViewVehiculo permisosUsuario={permisosUsuario}  />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/usuarios" exact
                    render={ ()=>{
                        return permisoUsuario.length && permisoUsuario[0].leer == 1 ?
                        <Usuarios permisosUsuario={permisoUsuario} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/usuarios/agregar" exact
                    render={ ()=>{
                        return (permisoUsuario.length && permisoUsuario[0].escribir == 1) ?
                        <AddUsuario permisosUsuario={permisoUsuario} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    
                    <Route path="/usuarios/:id" exact
                    render={ ()=>{
                        return (permisoUsuario.length && permisoUsuario[0].leer == 1) ?
                        <ViewUsuario permisosUsuario={permisosUsuario} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    
                    <Route path="/trabajador" exact
                    render={ ()=>{
                        return permisoTrabajador.length && permisoTrabajador[0].leer == 1 ?
                        <Trabajadores permisosUsuario={permisoTrabajador} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/trabajador/agregar" exact
                    render={ ()=>{
                        return (permisoTrabajador.length && permisoTrabajador[0].escribir == 1) ?
                        <AddTrabajador />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/trabajador/:id" exact
                    render={ ()=>{
                        return (permisoTrabajador.length && permisoTrabajador[0].leer == 1) ?
                        <ViewTrabajador permisosUsuario={permisosUsuario} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    
                    <Route path="/derrotero" exact
                    render={ ()=>{
                        return permisoDerrotero.length && permisoDerrotero[0].leer == 1 ?
                        <Derroteros permisosUsuario={permisoDerrotero} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/derrotero/agregar" exact
                    render={ ()=>{
                        return (permisoDerrotero.length && permisoDerrotero[0].escribir == 1) ?
                        <AddDerrotero />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/derrotero/:id" exact
                    render={ ()=>{
                        return (permisoDerrotero.length && permisoDerrotero[0].leer == 1) ?
                        <ViewDerrotero permisosUsuario={permisosUsuario} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    
                    <Route path="/economicos" exact
                    render={ ()=>{
                        return permisoEconomico.length && permisoEconomico[0].leer == 1 ?
                        <Economicos permisosUsuario={permisoEconomico} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/economicos/agregar" exact
                    render={ ()=>{
                        return (permisoEconomico.length && permisoEconomico[0].escribir == 1) ?
                        <AddEconomico />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/economicos/:id" exact
                    render={ ()=>{
                        return (permisoEconomico.length && permisoEconomico[0].leer == 1) ?
                        <ViewEconomico permisosUsuario={permisosUsuario} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    
                    <Route path="/operadores" exact
                    render={ ()=>{
                        return permisoOperador.length && permisoOperador[0].leer == 1 ?
                        <Operadores permisosUsuario={permisoOperador} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>

                    <Route path="/proyectos" exact
                    render={ ()=>{
                        return permisoProyecto.length && permisoProyecto[0].leer == 1 ?
                        <Proyectos permisosUsuario={permisoProyecto} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/proyectos/agregar" exact
                    render={ ()=>{
                        return permisoProyecto.length && permisoProyecto[0].escribir == 1 ?
                        <AddProyecto permisosUsuario={permisoProyecto} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    
                    <Route path="/claves" exact
                    render={ ()=>{
                        return permisoClave.length && permisoClave[0].leer == 1 ?
                        <Claves permisosUsuario={permisoClave} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/claves/:id" exact
                    render={ ()=>{
                        return (permisoClave.length && permisoClave[0].leer == 1) ?
                        <ViewClave permisosUsuario={permisosUsuario} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>

                    <Route path="/resultados/:q" exact
                    render={ ()=>{
                        return <Resultados loadingSearch={loadingSearch} setLoadingSearch={setLoadingSearch} />
                    } }>
                    </Route>

                    <Route path="/checadores" exact
                    render={ ()=>{
                        return permisoChecador.length && permisoChecador[0].leer == 1 ?
                        <Checadores permisosUsuario={permisoChecador} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/checadores/:id" exact
                    render={ ()=>{
                        return (permisoChecador.length && permisoChecador[0].leer == 1) ?
                        <Checadores permisosUsuario={permisosUsuario} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>

                    <Route path="/terminales" exact
                    render={ ()=>{
                        return permisoTerminal.length && permisoTerminal[0].leer == 1 ?
                        <Terminales permisosUsuario={permisoTerminal} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/terminales/agregar" exact
                    render={ ()=>{
                        return (permisoTerminal.length && permisoTerminal[0].escribir == 1) ?
                        <AddTerminal permisosUsuario={permisosUsuario} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>

                    <Route path="/contraloria-siniestros" exact
                    render={ ()=>{
                        return permisoContraloria.length && permisoContraloria[0].leer === '1' ?
                        <ContraloriaSiniestro permisosUsuario={permisoContraloria} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>

                    <Route path="/siniestros" exact
                    render={ ()=>{
                        return permisoSiniestro.length && permisoSiniestro[0].leer === '1' ?
                        <Siniestros permisosUsuario={permisoSiniestro} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>

                    <Route path="/siniestros/:id" exact
                    render={ ()=>{
                        return (permisoSiniestro.length && permisoSiniestro[0].leer == 1) ?
                        <ViewSiniestro permisosUsuario={permisoSiniestro} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>

                    <Route path="/pre-siniestros" exact
                    render={ ()=>{
                        return permisoPresiniestro.length && permisoPresiniestro[0].leer === '1' ?
                        <Presiniestros permisosUsuario={permisoPresiniestro} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    
                    <Route path="/pre-siniestros/agregar" exact
                    render={ ()=>{
                        return permisoPresiniestro.length && permisoPresiniestro[0].escribir === '1' ?
                        <AddPresiniestro permisosUsuario={permisoPresiniestro} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    
                    <Route path="/saldos-departamentos" exact
                    render={ ()=>{
                        return ( (permisoSaldosDepartamentos.length && permisoSaldosDepartamentos[0].leer === '1') || 
                        (permisoSaldosSiniestros.length && permisoSaldosSiniestros[0].leer === '1') ) ?
                        <SaldosDepartamentos permisosUsuario={permisosUsuario} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    
                    <Route path="/saldos-siniestro" exact
                    render={ ()=>{
                        return ( (permisoSaldosOperadores.length && permisoSaldosOperadores[0].leer === '1') || 
                        (permisoSaldosPermisionarios.length && permisoSaldosPermisionarios[0].leer === '1') ) ?
                        <SaldosSiniestros permisosUsuario={permisosUsuario} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    
                    <Route path="/saldos-siniestro/:id" exact
                    render={ ()=>{
                        return ( (permisoSaldosOperadores.length && permisoSaldosOperadores[0].leer === '1') || 
                        (permisoSaldosPermisionarios.length && permisoSaldosPermisionarios[0].leer === '1') ) ?
                        <ViewMovimientosSaldoSiniestro permisosUsuario={permisosUsuario} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    
                    <Route path="/entrega-recurso" exact
                    render={ ()=>{
                        return permisoFinanzasEntregaRecurso.length && permisoFinanzasEntregaRecurso[0].leer === '1' ?
                        <EntregaRecurso permisosUsuario={permisoFinanzasEntregaRecurso} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>

                    {/* AQUI ESTARAN LAS RUTAS DE LOS CATALOGOS */}
                    <Route path="/catalogos/tipos-vehiculos" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <TiposVehiculos permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/prefijos-economicos" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <PrefijosEconomicos permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/religiones" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <Religiones permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/marcas-vehiculos" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <MarcasVehiculos permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/submarcas-vehiculos" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <SubmarcasVehiculos permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/aseguradoras" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <Aseguradoras permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/tipos-licencia-conducir" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <TiposLicenciaConducir permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/uma" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <UMA permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/reglas-prorrateo" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <ReglasProrrateo permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/prorratas-siniestro" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <ProrratasSiniestro permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/tipos-combustible" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <TiposCombustible permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/estados-civiles" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <EstadosCiviles permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/generos" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <Generos permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/tipos-permisionario" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <TiposPermisionario permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/tipos-trabajadores" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <TiposTrabajadores permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/tipos-bienes" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <TiposDeBienes permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/metodo-pago" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <MetodoPago permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/tipos-gasto-siniestro" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <TipoGastoSiniestro permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/cantidad-fianza" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <CantidadFianza permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/catalogos/entidades-legales" exact
                    render={ ()=>{
                        return permisoCatalogos.length && permisoCatalogos[0].leer === '1' ?
                        <EntidadLegalSiniestro permisos={permisoCatalogos} />
                        :
                        <Redirect to="/"/>
                    } }>
                    </Route>
                    <Route path="/recovery-password/:id" exact
                    render={ ()=>{
                        return <RecoverPassword />
                    } } />

                    <Redirect to="/"/>
                </Switch>
            </PageView>
        </div>
    );
}