import Swal from 'sweetalert2'
export const handleErrorsInputFile = (value, nameInput)=>{
    const err = {};
    if(!value.length > 0 || value === null){
        err.msg = `El documento "${nameInput}" es obligatorio`;
    } else if((value.item(0).size / 1024 / 1024) > 3){
        err.msg = `El documento "${nameInput}" debe ser máximo de 3MB`;
    }


    if(err && Object.keys(err).length === 0){
        return false;
    } else {
        Swal.fire(
            'Advertencia, revisa lo siguiente',
            err.msg,
            'warning'
        )
        return true;
    }
}