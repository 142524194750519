import React from 'react';
import "./FormularioDocumentosTrabajador.css";
import Icon from '../../components/Icon';
import $ from 'jquery';
import changeDocumentosTrabajadorService from './../../services/changeDocumentosTrabajadorService';
import Swal from 'sweetalert2'

export default function FormularioDocumentosTrabajador({trabajador, permisosUsuario}) {

    let{
        id_trabajador,
        url_acta_matrimonio,
        url_acta_nacimiento,
        url_antecedentes_penales,
        url_boleta_predial,
        url_cartas_referencias_comerciales,
        url_cartas_referencias_personales,
        url_comprobante_domicilio,
        url_ine_vista_frontal,
        url_ine_vista_trasera,
        url_investigacion_socioeconomica,
        url_licencia_vista_frontal,
        url_licencia_vista_trasera,
        url_rfc,
        url_solicitud_ingreso,
        rutaServidor
    } = trabajador;

    function onChangeFileInput(e, nombreInput){
        const file = e.target.files[0];
        document.getElementById(nombreInput+'Preview').src = URL.createObjectURL(file);
        $('#'+nombreInput+'Preview').removeClass("hidden")
        $('#'+nombreInput+'Preview').addClass("wasChanged")
    }
    
    const onSubmitUpdateDocumentosTrabajador = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_trabajador', id_trabajador)
        // let objectValues = Object.fromEntries(values);
        // console.log({objectValues})
        changeDocumentosTrabajadorService({values:values})
            .then(response => {
                // console.log(response);
                let _response = JSON.parse(response);
                // console.log(_response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: '¡Listo!',
                        text: 'Se han actualizado los documentos del trabajador',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No haz actualizado ningún documento',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => {})
    }

    return(
        <form onSubmit={ onSubmitUpdateDocumentosTrabajador } className="subidaDocumentos mb-5">
            <h3>
                Documentos personales
            </h3>
            
            <p>
                Tienes que subir cada documento por separado, da clic en el botón del pdf que quieres subir y selecciona el archivo.
            </p>

            <div className="subidaDocumentos__formulario row mt-3">
                <div className="col-4">
                    <input type="file" id="actaDeNacimiento" className="hidden" accept="application/pdf,application/vnd.ms-excel" name="url_acta_nacimiento" onChange={ (e) => onChangeFileInput(e, "actaDeNacimiento") } />
                    <label htmlFor="actaDeNacimiento">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Subir acta de nacimiento
                        </a>
                    </label>
                    <embed
                        id="actaDeNacimientoPreview"
                        src={`${rutaServidor}${url_acta_nacimiento}`}
                        className={`previewPDF ${url_acta_nacimiento ? "" : "hidden"}`}
                        width="250"
                        height="200"
                        type="application/pdf"
                        >
                    </embed>
                </div>
                
                <div className="col-4">
                    <input type="file" id="rfcDocumento" name="url_rfc" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "rfcDocumento") } />
                    <label htmlFor="rfcDocumento">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Subir documento de RFC
                        </a>
                    </label>
                    <embed
                        id="rfcDocumentoPreview"
                        src={`${rutaServidor}${url_rfc}`}
                        className={`previewPDF ${url_rfc ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-4">
                    <input type="file" id="comprobanteDomicilio" name="url_comprobante_domicilio" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "comprobanteDomicilio") } />
                    <label htmlFor="comprobanteDomicilio">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Subir comprobante de domicilio
                        </a>
                    </label>
                    <embed
                        id="comprobanteDomicilioPreview"
                        src={`${rutaServidor}${url_comprobante_domicilio}`}
                        className={`previewPDF ${url_comprobante_domicilio ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-4">
                    <input type="file" id="INEVistaFrontal" name="url_ine_vista_frontal" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "INEVistaFrontal") } />
                    <label htmlFor="INEVistaFrontal">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            INE (vista frontal)
                        </a>
                    </label>
                    <embed
                        id="INEVistaFrontalPreview"
                        src={`${rutaServidor}${url_ine_vista_frontal}`}
                        className={`previewPDF ${url_ine_vista_frontal ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-4">
                    <input type="file" id="INEVistaTrasera" name="url_ine_vista_trasera" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "INEVistaTrasera") } />
                    <label htmlFor="INEVistaTrasera">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            INE (vista trasera)
                        </a>
                    </label>
                    <embed
                        id="INEVistaTraseraPreview"
                        src={`${rutaServidor}${url_ine_vista_trasera}`}
                        className={`previewPDF ${url_ine_vista_trasera ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-4">
                    <input type="file" id="boletaPredial" name="url_boleta_predial" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "boletaPredial") } />
                    <label htmlFor="boletaPredial">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Boleta predial
                        </a>
                    </label>
                    <embed
                        id="boletaPredialPreview"
                        src={`${rutaServidor}${url_boleta_predial}`}
                        className={`previewPDF ${url_boleta_predial ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-4">
                    <input type="file" id="cartaReferenciaComercial" name="url_cartas_referencias_comerciales" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "cartaReferenciaComercial") } />
                    <label htmlFor="cartaReferenciaComercial">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Carta de referencia comercial
                        </a>
                    </label>
                    <embed
                        id="cartaReferenciaComercialPreview"
                        src={`${rutaServidor}${url_cartas_referencias_comerciales}`}
                        className={`previewPDF ${url_cartas_referencias_comerciales ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-4">
                    <input type="file" id="cartasReferenciaPersonal" name="url_cartas_referencias_personales" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "cartasReferenciaPersonal") } />
                    <label htmlFor="cartasReferenciaPersonal">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Cartas de referencia personal
                        </a>
                    </label>
                    <embed
                        id="cartasReferenciaPersonalPreview"
                        src={`${rutaServidor}${url_cartas_referencias_personales}`}
                        className={`previewPDF ${url_cartas_referencias_personales ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-4">
                    <input type="file" id="actaMatrimonio" name="url_acta_matrimonio" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "actaMatrimonio") } />
                    <label htmlFor="actaMatrimonio">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Acta de matrimonio
                        </a>
                    </label>
                    <embed
                        id="actaMatrimonioPreview"
                        src={`${rutaServidor}${url_acta_matrimonio}`}
                        className={`previewPDF ${url_acta_matrimonio ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-4">
                    <input type="file" id="estudioSocioeconomico" name="url_investigacion_socioeconomica" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "estudioSocioeconomico") } />
                    <label htmlFor="estudioSocioeconomico">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Estudio socioeconómico
                        </a>
                    </label>
                    <embed
                        id="estudioSocioeconomicoPreview"
                        src={`${rutaServidor}${url_investigacion_socioeconomica}`}
                        className={`previewPDF ${url_investigacion_socioeconomica ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-4">
                    <input type="file" id="antecedentesPenales" name="url_antecedentes_penales" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "antecedentesPenales") } />
                    <label htmlFor="antecedentesPenales">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Antecendentes penales
                        </a>
                    </label>
                    <embed
                        id="antecedentesPenalesPreview"
                        src={`${rutaServidor}${url_antecedentes_penales}`}
                        className={`previewPDF ${url_antecedentes_penales ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-4">
                    <input type="file" id="solicitudDeIngreso" name="url_solicitud_ingreso" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "solicitudDeIngreso") } />
                    <label htmlFor="solicitudDeIngreso">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Solicitud de ingreso
                        </a>
                    </label>
                    <embed
                        id="solicitudDeIngresoPreview"
                        src={`${rutaServidor}${url_solicitud_ingreso}`}
                        className={`previewPDF ${url_solicitud_ingreso ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-6">
                    <input type="file" id="licenciaConducirFrontal" name="url_licencia_vista_frontal" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "licenciaConducirFrontal") } />
                    <label htmlFor="licenciaConducirFrontal">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Licencia de conducir (vista frontal)
                        </a>
                    </label>
                    <embed
                        id="licenciaConducirFrontalPreview"
                        src={`${rutaServidor}${url_licencia_vista_frontal}`}
                        className={`previewPDF ${url_licencia_vista_frontal ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-6">
                    <input type="file" id="licenciaConducirTrasera" name="url_licencia_vista_trasera" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "licenciaConducirTrasera") } />
                    <label htmlFor="licenciaConducirTrasera">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Licencia de conducir (vista trasera)
                        </a>
                    </label>
                    <embed
                        id="licenciaConducirTraseraPreview"
                        src={`${rutaServidor}${url_licencia_vista_trasera}`}
                        className={`previewPDF ${url_licencia_vista_trasera ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

            </div>

            {
                permisosUsuario[0].editar !== "0" &&
                <React.Fragment>
                    <hr></hr>
                    <div className="d-flex justify-content-end">
                        <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                            <Icon icon="fa-solid fa-pen-to-square mr-3" />
                            Actualizar documentos del trabajador
                        </button>
                    </div>
                </React.Fragment>
            }

        </form>
    )
}