import React from 'react';
import "./CardAccordion.css";

export default function CardAccordion({ children, titulo, show, id, idAccordion }){
    return(
        <div className="card">
            <div className="card-header" id={`heading${id}`}>
                <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target={`#card${id}`} aria-expanded="true" aria-controls={`#card${id}`}>
                    { titulo }
                    </button>
                </h2>
            </div>

            <div id={ `card${id}` } className={`collapse ${show && "show"}`} aria-labelledby={`heading${id}`} data-parent={`#${idAccordion}`}>
                <div className="card-body">
                    { children }
                </div>
            </div>
        </div>
    );
}