import React from "react";
import $ from 'jquery'
import getTipoDeBienesActivos from "../../services/catalogos/tipos_de_bien/getTipoDeBienesActivos";

export default function ModalAddBienAfectado(props){

    const [tipoBienes, setTipoBienes] = React.useState([]);

    React.useEffect(()=>{
        $(".selectpicker").selectpicker('refresh');
    },[])

    React.useEffect(()=>{
        getTipoDeBienesActivos()
            .then(res => JSON.parse(res))
            .then(res => setTipoBienes(res.data))
            .then(() => $('.selectpicker').selectpicker('refresh'))
            .catch(err => { console.log({err}) })
    },[])

    return(
        <div className="modal" id="modalAddBienAfectado" tabIndex="-1" role="dialog" aria-labelledby="modalAddBienAfectadoLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={props.onSubmit} id='formAddBienAfectado' className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddBienAfectadoLabel">Agrega un bien afectado</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="id_tipo_de_bien">
                                    Bien afectado:
                                </label>
                                <select 
                                    className="input selectpicker"
                                    id="id_tipo_de_bien"
                                    name="id_tipo_de_bien"
                                    data-live-search="true"
                                    required
                                >
                                    <option value=''>
                                        Selecciona un bien afectado
                                    </option>
                                    {
                                        tipoBienes && 
                                        tipoBienes.map( tipo_de_bien => (
                                            <option key={ tipo_de_bien.id_tipo_de_bien } value={ tipo_de_bien.id_tipo_de_bien }>
                                                { tipo_de_bien.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-12">
                                <label htmlFor="descripcion">
                                    Descripción:
                                </label>
                                <textarea 
                                    type="text" 
                                    className="input"
                                    id="descripcion"
                                    name="descripcion"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primario" id="btnSubmitModalAddBienAfectado">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}