import React from "react";
import deleteBienAfectadoSiniestro from "../../services/siniestro/deleteBienAfectadoSiniestro";
import Swal from "sweetalert2";

export default function TableElementBienAfectado({ index, element, elements, setElements, permisosUsuario, idEstadoSiniestro }) {

    let { id_bien_afectado_siniestro, nombre_tipo_de_bien, descripcion } = element;

    const onClickDelete = () => {

        // Eliminamos el elemento del array principal y lo actualizamos
        // if( id_bien_afectado_siniestro !== undefined && id_bien_afectado_siniestro !== null ){
        //     deleteBienAfectadoSiniestroService()
        // }

        // Eliminamos el elemento del array principal y lo actualizamos
        let newElements = elements.filter((e, i)=>{
            return ( i !== index )
        })
        setElements(newElements)
    }

    const deleteBienAfectadoSiniestroService = ()=>{
        // Eliminamos el lesionado de la tabla vehiculos_afectados_siniestro con el id_bien_afectado_siniestro
        let data = { id_bien_afectado_siniestro }

        deleteBienAfectadoSiniestro({values:data})
            .then(res=>{
                console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    onClickDelete()
                    return true
                }

                if (_res.response === "error") {
                    Swal.fire({
                        title:'No se puede eliminar',
                        text:"Existen registros vinculados a este dato (podrían ser egresos del siniestro), no se puede eliminar hasta que elimines todos los registros vinculados a él",
                        showConfirmButton:true,
                        icon:'info'
                    })
                }

                return false;
            })
            .catch(err => ()=>{
                console.error(err)
                return false
            })
    }

    return (
        <tr>
            <th>
                <div className="table__name">
                    {
                        // PRIMERO VALIDAMOS QUE EL SINIESTRO SIGA ABIERTO, PARA PODER HACER CUALQUIER COSA
                        idEstadoSiniestro === '1' &&
                        (id_bien_afectado_siniestro !== undefined && id_bien_afectado_siniestro !== null) &&
                            permisosUsuario[0].eliminar === '1' &&
                            <div className="dropdown">
                                <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item bg-danger text-white" onClick={() => deleteBienAfectadoSiniestroService()}>
                                        Eliminar
                                    </a>
                                </div>
                            </div>
                    }
                    {
                        (id_bien_afectado_siniestro === undefined || id_bien_afectado_siniestro === null) &&
                            <div className="dropdown">
                                <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item bg-danger text-white" onClick={() => onClickDelete()}>
                                        Eliminar
                                    </a>
                                </div>
                            </div>
                    }
                    <p>
                        {nombre_tipo_de_bien}
                    </p>
                </div>
            </th>

            <td>
                <div className="table__fechaAlta">
                    <p>
                        { descripcion ? descripcion : "Sin descripción" }
                    </p>
                </div>
            </td>
        </tr>
    )
    
}