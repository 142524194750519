import React from 'react';
import logo from "./../../assets/images/logo.png";

// Componentes
import { Document, Page, Image, Text, View, BlobProvider, Font } from "@react-pdf/renderer";
import Swal from 'sweetalert2';

export default function PDFGeneratorEstadoCuentaArea({ dataGeneralCuenta, gastos }){

    const [showPDF, setShowPDF] = React.useState(false)

    Font.register({
        family: 'Open Sans',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 }
        ]
    });

    const SubtituloPDF = (text)=>{
        return (
            <View 
                style={{backgroundColor: "#FF7925", height: 20, width: '100%', borderTopLeftRadius: 10, borderTopRightRadius: 10}}
            >
                <Text style={{ color: 'white', textAlign: 'left', paddingLeft: 10, fontSize: 13, fontWeight: 'bold' }}>
                    { text }
                </Text>
            </View>
        )
    }

    const HeaderPDF = ()=>{
        return (
            <View 
                style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'top', marginBottom: 20 }}
                fixed
            >   
                <View 
                    style={{ width: '100%', alignItems: 'flex-start' }}
                >
                    <Image src={logo} style={{ width: '70px', height: 'auto'}} />
                </View>

                <View style={{alignItems: 'center', justifyContent: 'center', width:'100%'}}>
                    <Text style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 16 }}>
                        ESTADO DE CUENTA
                    </Text>
                    <Text style={{ color: 'gray', fontSize: 9 }}>
                        {dataGeneralCuenta.nombre_area_empresa.toUpperCase()}
                    </Text>
                </View>

                <View style={{ justifyContent: 'center', width: '100%'}}>
                    {/* <Text style={{ fontWeight: 'bold', textAlign: 'right', fontSize: 10 }}>
                        {user.clave !== null && user.clave} {user.nombre}
                    </Text>
                    <Text style={{ textAlign: 'right', fontSize: 10 }}>
                        {user.tipo_usuario} - {user.activo === '1' ? 'Activo' : "Inactivo"}
                    </Text> */}
                    <Text style={{ color: 'gray', textAlign: 'right', fontSize: 9 }}>Impresión:
                        <Text style={{ color: 'black', fontSize: 9 }}> { new Date().toLocaleString() } </Text>
                    </Text>
                </View>
            </View>
              
        )
    }
    
    const ItemTableGastoPDF = (gasto) =>{

        return (
            <View 
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    alignItems: 'center', 
                    fontSize: 9, 
                    marginTop: 5, 
                    marginBottom: 5, 
                    paddingLeft:10, 
                    paddingRight: 10 
                }}
            >
                <View style={{ width: '20%', display: 'flex', flexDirection: 'column'  }}>
                    <Text>
                        { gasto.fecha_alta !== undefined && gasto.fecha_alta.split(" ")[0] }
                        <Text style={{ color: '#818181' }}>
                            &nbsp;{ gasto.fecha_alta !== undefined && gasto.fecha_alta.split(" ")[1] }
                        </Text>
                    </Text>
                </View>
                <Text style={{ width: '35%', paddingRight: 5 }}>
                    { gasto.concepto }
                </Text>
                <Text style={{ width: '15%', fontWeight: 'bold', fontSize: 10, color: '#D15253' }}>
                    {
                        (gasto.id_movimiento_egreso !== undefined) &&
                            parseFloat(gasto.cantidad).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                    }
                </Text>
                <Text style={{ width: '15%', fontWeight: 'bold', fontSize: 10, color: '#659226' }}>
                    {
                        (gasto.id_movimiento_ingreso !== undefined) &&
                            parseFloat(gasto.cantidad).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                    }
                </Text>
                <Text style={{ width: '15%', fontWeight: 'bold', fontSize: 10, color: `${gasto.saldo > 0 ? '#659226' : '#D15253'}` }}>
                    { parseFloat(gasto.saldo).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                </Text>
            </View>
        )

    }


    const GeneratePDFTest = (props) => {
        return (
            <Document>
                <Page 
                    size="A4"
                    style={{ padding: 25, fontSize: 13, fontFamily: 'Open Sans' }}
                >

                    {/* CABECERA DE LA PÁGINA */}
                    { HeaderPDF() }
                    {/* ************************ */}

                    {/* SUBTITULO: GASTOS DEL SINIESTRO */}
                    { SubtituloPDF("Detalles de movimientos") }
                    {/* ********************************* */}

                    {/* TABLA DE GASTOS DEL SINIESTRO */}
                    <View style={{ marginBottom: 10 }}>
                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 10, fontWeight: 'bold', height: 18, backgroundColor: 'gray', color: 'white', paddingLeft: 10, paddingRight: 10 }}>
                            <Text style={{ width: '20%' }}>
                                Fecha
                            </Text>
                            <Text style={{ width: '35%' }}>
                                Concepto
                            </Text>
                            <Text style={{ width: '15%' }}>
                                Cargo
                            </Text>
                            <Text style={{ width: '15%' }}>
                                Abono
                            </Text>
                            <Text style={{ width: '15%' }}>
                                Saldo
                            </Text>
                        </View>
                        
                        {
                            gastos !== null && gastos.length > 0 && 
                                gastos.map((gasto)=>{
                                    return ItemTableGastoPDF(gasto)
                                })
                        }
                    </View>
                    {/* ******************* */}
                    
                    {/* PIE DE PÁGINA */}
                    <Text style={{ position: 'absolute', fontSize: 9, bottom: 15, left: 0, right: 0, textAlign: 'center', color:'grey' }} render={({ pageNumber, totalPages}) => (`${pageNumber} / ${totalPages}`)} fixed/>
                    {/* ********************************* */}
                </Page>
            </Document>
        )
    }

    const blob = () =>{
        return (
            <BlobProvider document={<GeneratePDFTest/>}>
                {({ blob, url, loading, error }) =>{
                    try{

                        if (loading) {
                            return (
                                <button className="btn btn-sm btn-light mt-2 d-flex align-items-center justify-content-center">
                                    <i className="fa-solid fa-spinner fa-spin mr-2"></i>
                                    Generando estado de cuenta...
                                </button>
                            )
                        } else if (error) {
                            return <div>Ocurrió un error: {error.message}</div>;
                        } else {
                            return (
                                <button 
                                    onClick={()=>{
                                        const pdfWindow = window.open();
                                        pdfWindow.location.href = url;
                                        setTimeout(()=>{
                                            setShowPDF(false);
                                        }, 200)
                                    }}
                                    className="btn btn-sm btn-light mt-2 d-flex align-items-center justify-content-center"
                                >
                                    <i className="fa-solid fa-download mr-2"></i>
                                    Descargar PDF
                                </button>
                            )
                        }
                    } catch(err){
                        Swal.fire({
                            icon:"warning",
                            title: "Hubo un error al generar el PDF",
                            text:'Vuelve a intentarlo'
                        }).then(()=>{
                            window.location.reload()
                        })
                    }
                }}
            </BlobProvider>
        )
    }

    return(
        <>
            {
                showPDF && blob()
            }
            {
                !showPDF && 
                    <button 
                        className="btn btn-sm btn-light mt-2 d-flex align-items-center justify-content-center text-muted" 
                        onClick={() => setShowPDF(true)}
                    >
                        <i className="fa-solid fa-print mr-2"></i>
                        Imprimir
                    </button>
            }
        </>
    );
}