import React from 'react';
import "./FormularioVehiculo.css";
import Icon from '../../components/Icon';
import $ from 'jquery';
import { validateNoNumbers } from './../../validators/validateNoNumbers';
import getTiposCombustible from '../../services/catalogos/tipo_combustible/getTiposCombustible';
import getTiposVehiculos from '../../services/catalogos/tipo_vehiculo/getTiposVehiculos';
import getMarcasVehiculos from '../../services/catalogos/marca_vehiculo/getMarcasVehiculos';
import getSubmarcasVehiculosByMarca from '../../services/catalogos/submarca_vehiculo/getSubmarcasVehiculosByMarca';

export default function  FormularioVehiculo({ vehiculo, permisosUsuario, onSubmit }){

    const [marcasVehiculo, setMarcasVehiculo] = React.useState([]);
    const [submarcasVehiculo, setSubmarcasVehiculo] = React.useState([]);
    const [tiposVehiculos, setTiposVehiculos] = React.useState([]);
    const [tiposCombustibles, setTiposCombustibles] = React.useState([]);

    const getMarcasVehiculosService = ()=>{
        getMarcasVehiculos().then(res=>{
            let _res = JSON.parse(res);
            console.log(_res)
            if(!(_res.response === 'error')){
                setMarcasVehiculo(_res.data);
            } else {
                setMarcasVehiculo([])
            }
            $('.selectpicker').selectpicker('refresh')
            setDataFormulario()
        })
    }

    const getSubmarcasVehiculosService = (id_marca_vehiculo)=>{
        let form = new FormData()
        form.append('id_marca_vehiculo',id_marca_vehiculo)
        let objectValues = Object.fromEntries(form);
        getSubmarcasVehiculosByMarca({values:objectValues}).then(res=>{
            let _res = JSON.parse(res);
            console.log(_res)
            // Cuando cargue las submarcas, habilitar el select. Aunque no tenga ningún valor.
            // Esto porque si está deshabilitado, el formulario no lo valida y no va a indicar
            // que falta seleccionar una submarca.
            $("#id_submarca_vehiculo").attr("disabled",false)
            if(!(_res.response === 'error')){
                setSubmarcasVehiculo(_res.data);
                if(vehiculo.id_submarca_vehiculo !== undefined && vehiculo.id_submarca_vehiculo !== null)
                    document.getElementById('id_submarca_vehiculo').value = vehiculo.id_submarca_vehiculo;
            } else {
                setSubmarcasVehiculo([])
            }
            $('.selectpicker').selectpicker('refresh')
            // setDataFormulario()
        })
    }

    const onChangeMarca = (value)=>{
        getSubmarcasVehiculosService(value)
    }

    const getTiposCombustibleService = ()=>{
        getTiposCombustible().then(res => {
            let _res = JSON.parse(res);
            console.log(_res)
            if(!(_res.response === 'error')){
                setTiposCombustibles(_res.data);
            } else {
                setTiposCombustibles([])
            }
            $('.selectpicker').selectpicker('refresh')
            setDataFormulario()
        })
    }

    const getTiposVehiculosService = ()=>{
        getTiposVehiculos().then(res => {
            let _res = JSON.parse(res);
            console.log(_res)
            if(!(_res.response === 'error')){
                setTiposVehiculos(_res.data);
            } else {
                setTiposVehiculos([])
            }
            $('.selectpicker').selectpicker('refresh')
        })
    }
    React.useEffect(getTiposCombustibleService,[])
    React.useEffect(getTiposVehiculosService,[])
    React.useEffect(getMarcasVehiculosService,[])

    function setDataFormulario(){
        document.getElementById('marca').value = vehiculo.marca;
        document.getElementById('submarca').value = vehiculo.submarca;
        document.getElementById('modeloUnidad').value = vehiculo.modelo;
        document.getElementById('id_tipo_vehiculo').value = vehiculo.id_tipo_vehiculo;
        document.getElementById('id_tipo_combustible').value = vehiculo.id_tipo_combustible;
        document.getElementById('id_marca_vehiculo').value = vehiculo.id_marca_vehiculo;
        onChangeMarca(vehiculo.id_marca_vehiculo)
        document.getElementById('numeroSerie').value = vehiculo.numero_serie;
        document.getElementById('numeroMotor').value = vehiculo.numero_motor;
        document.getElementById('numeroPasajeros').value = vehiculo.numero_pasajeros;
        document.getElementById('vigenciaSeguro').value = vehiculo.fecha_vigencia_seguro;
        document.getElementById('numero_poliza_seguro').value = vehiculo.numero_poliza_seguro;
        document.getElementById('placas').value = vehiculo.placas;
        document.getElementById('numeroTarjetaCirculacion').value = vehiculo.numero_tarjeta_circulacion;
        document.getElementById('fechaOperacion').value = vehiculo.fecha_operacion;
        document.getElementById('colorEngomado').value = vehiculo.color_engomado;
        document.getElementById('colorUnidad').value = vehiculo.color_unidad;
        document.getElementById('anotacionesUnidad').value = vehiculo.anotaciones;

        $('.selectpicker').selectpicker('refresh');

        if(permisosUsuario[0].editar === "0"){
            $("form :input, :input[form]").prop("readonly", true);
        }
    }

    React.useEffect(() => {
        // setDataFormulario();
        $('.selectpicker').selectpicker();
    }, []);

    return(
        <form className="formulario" onSubmit={ onSubmit }>
            
                {/* ******************************** */}
                {/* BLOQUE DE LOS DATOS DE LA UNIDAD */}
                {/* ******************************** */}
            
                <div className="datosPersonales__formulario row">
                    <div className="col-4">
                        <label htmlFor="marca">
                            Marca:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="marca"
                            name="marca"
                        />
                        <label htmlFor="id_marca_vehiculo">
                            Marca:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_marca_vehiculo"
                            name="id_marca_vehiculo"
                            onChange = { (e) => onChangeMarca(e.target.value) }
                            required
                            data-live-search="true"
                            >   
                            <option value="">Elige una marca</option>
                            {
                                marcasVehiculo.map( (marca) => (
                                    <option key={ marca.id_marca_vehiculo } value={ marca.id_marca_vehiculo } >
                                        { marca.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="submarca">
                            Submarca:
                        </label>
                        <input 
                            type="text" 
                            className="input" 
                            id="submarca"
                            name="submarca"
                        />
                        <label htmlFor="id_submarca_vehiculo">
                            Submarca:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_submarca_vehiculo"
                            name="id_submarca_vehiculo"
                            data-live-search="true"
                            disabled
                            required
                        >   
                            {/* <option value="">Elige una submarca</option> */}
                            {
                                submarcasVehiculo.map( (submarca) => (
                                    <option key={ submarca.id_submarca_vehiculo } value={ submarca.id_submarca_vehiculo } >
                                        { submarca.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="modeloUnidad">
                            Modelo:
                        </label>
                        <input 
                            type="text"
                            className="input" 
                            onKeyPress={ validateNoNumbers }
                            id="modeloUnidad"
                            name="modeloUnidad"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="id_tipo_vehiculo">
                            Tipo de vehículo:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_tipo_vehiculo"
                            name="id_tipo_vehiculo"
                            required
                            data-live-search="true"
                        >   
                            <option value="">Elige tipo de vehículo</option>
                            {
                                tiposVehiculos.map( (tipo_vehiculo) => (
                                    <option key={ tipo_vehiculo.id_tipo_vehiculo } value={ tipo_vehiculo.id_tipo_vehiculo } >
                                        { tipo_vehiculo.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="id_tipo_combustible">
                            Tipo de combustible:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_tipo_combustible"
                            name="id_tipo_combustible"
                            required
                            data-live-search="true"
                        >   
                            <option value="">Elige un tipo de combustible</option>
                            {
                                tiposCombustibles.map( (tipo_combustible) => (
                                    <option key={ tipo_combustible.id_tipo_combustible } value={ tipo_combustible.id_tipo_combustible } >
                                        { tipo_combustible.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="numeroSerie">
                            Número de serie:
                        </label>
                        <input 
                            type="text"
                            className="input"
                            id="numeroSerie"
                            name="numeroSerie"
                            required
                            />
                    </div>
                    <div className="col-4">
                        <label htmlFor="numeroMotor">
                            Número de motor:
                        </label>
                        <input 
                            type="text"
                            className="input"
                            id="numeroMotor"
                            name="numeroMotor"
                            required
                            />
                    </div>
                    <div className="col-4">
                        <label htmlFor="numeroPasajeros">
                            Número de pasajeros:
                        </label>
                        <input 
                            type="num"
                            onKeyPress={ validateNoNumbers }
                            className="input"
                            id="numeroPasajeros"
                            name="numeroPasajeros"
                            required
                            />
                    </div>
                    <div className="col-4">
                        <label htmlFor="placas">
                            Placas:
                        </label>
                        <input 
                            type="text" 
                            className="input" 
                            id="placas"
                            name="placas"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="vigenciaSeguro">
                            Vigencia del seguro:
                        </label>
                        <input 
                            type="date" 
                            className="input"
                            id="vigenciaSeguro"
                            name="vigenciaSeguro"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="numero_poliza_seguro">
                            Número de póliza del seguro:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="numero_poliza_seguro"
                            name="numero_poliza_seguro"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="numeroTarjetaCirculacion">
                            Número de tarjeta de circulación:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="numeroTarjetaCirculacion"
                            name="numeroTarjetaCirculacion"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="fechaOperacion">
                            Fecha cuando comienza a operar:
                        </label>
                        <input 
                            type="date" 
                            className="input"
                            id="fechaOperacion"
                            name="fechaOperacion"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="colorEngomado">
                            Color de engomado:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="colorEngomado"
                            name="colorEngomado"
                            required
                        >
                            <option value="A" defaultValue>
                                Amarillo
                            </option>
                            <option value="R">
                                Rosa
                            </option>
                            <option value="RO">
                                Rojo
                            </option>
                            <option value="V">
                                Verde
                            </option>
                            <option value="AZ">
                                Azul
                            </option>
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="colorUnidad">
                            Color de la unidad:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="colorUnidad"
                            name="colorUnidad"
                            required
                        />
                    </div>
                    <div className="col-12">
                            <label htmlFor="permisionarioGenero">
                                Anotaciones:
                            </label>
                            <textarea 
                                rows={5}
                                cols={5}
                                type="text"
                                placeholder="(Opcional) Aquí puedes anotar cualquier detalle o comentario extra sobre la unidad" 
                                className="input textarea"
                                id="anotacionesUnidad"
                                name="anotacionesUnidad"
                            />
                        </div>
                </div>
               
                {/* ******************************* */}
                {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
                {/* ******************************* */}


                {
                (permisosUsuario[0].editar !== "0") &&
                <React.Fragment>
                    <hr></hr>
                    <div className="d-flex justify-content-end">
                        <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                            <Icon icon="fa-solid fa-plus" />
                            <Icon icon="fa-solid fa-truck-moving mr-3" />
                            Actualizar información de la unidad
                        </button>
                    </div>
                </React.Fragment>
                }


            </form>
    );
}