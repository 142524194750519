import React from "react";
import deleteSeguimientoSiniestro from "../../services/siniestro/deleteSeguimientoSiniestro";


export default function TableElementComentario({ index, element, elements, setElements, permisosUsuario, idEstadoSiniestro }) {

    let { id_seguimiento_siniestro, mensaje, fecha_alta } = element;

    const onClickDelete = () => {
        // Eliminamos el elemento del array principal y lo actualizamos
        // if( id_seguimiento_siniestro !== undefined && id_seguimiento_siniestro !== null ){
        //     deleteSeguimientoSiniestroService()
        // }
        
        let newElements = elements.filter((e, i)=>{
            return ( i !== index )
        })
        setElements(newElements)
    }

    const deleteSeguimientoSiniestroService = ()=>{
        // Eliminamos el lesionado de la tabla lesionados_siniestro con el id_seguimiento_siniestro
        let data = { id_seguimiento_siniestro }

        deleteSeguimientoSiniestro({values:data})
            .then(res=>{
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    onClickDelete()
                    return true
                }

                return false;
            })
            .catch(err => ()=>{
                console.error(err)
                return false
            })
    }

    return (
        <tr>
            <th>
                <div className="table__name">

                    {
                        // PRIMERO VALIDAMOS QUE EL SINIESTRO SIGA ABIERTO, PARA PODER HACER CUALQUIER COSA
                        idEstadoSiniestro === '1' &&
                        (id_seguimiento_siniestro !== undefined && id_seguimiento_siniestro !== null) &&
                            permisosUsuario[0].eliminar === '1' &&
                            <div className="dropdown">
                                <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item bg-danger text-white" onClick={() => deleteSeguimientoSiniestroService()}>
                                        Eliminar
                                    </a>
                                </div>
                            </div>
                    }
                    {
                        (id_seguimiento_siniestro === undefined || id_seguimiento_siniestro === null) &&
                        <div className="dropdown">
                            <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item bg-danger text-white" onClick={() => onClickDelete()}>
                                    Eliminar
                                </a>
                            </div>
                        </div>
                    }
                    <p>
                        <small className="text-muted">{fecha_alta}</small>
                        <br/>
                        {mensaje}
                    </p>
                </div>
            </th>

            {/* <td>
                <div className="table__fechaAlta">
                    <p>
                        { fecha_alta }
                    </p>
                </div>
            </td> */}
        </tr>
    )
    
}