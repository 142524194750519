import React from 'react';
import $ from 'jquery';
import Swal from 'sweetalert2'
import FormularioTerminal from '../../components/FormularioTerminal';
import addTerminalService from '../../services/addTerminalService';
import { useHistory } from 'react-router';

export default function AddTerminal({ permisosUsuario }){

    const history = useHistory()
    
    React.useEffect(() => {
        $('.selectpicker').selectpicker();
    }, []);

    const onSubmitTerminal = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        addTerminal(objectValues)
    }

    const addTerminal = (objectValues)=>{
        addTerminalService({values:objectValues}).then((response) => {
            let _response = JSON.parse(response);
            if(_response.response === "error"){
                Swal.fire(
                    'Ocurrió un error',
                    'Revisar la conexión a internet e inténtalo de nuevo.',
                    'error'
                )
            } else {
                $("#formularioTerminal")[0].reset();
                $('.selectpicker').selectpicker('refresh');
                Swal.fire({
                    timer:1200,
                    title:'Terminal agregada',
                    showConfirmButton: false,
                    icon: 'success'
                }).then(()=>{
                    history.push('/terminales')
                })
            }
        }).catch((error) => {
            console.log(error)
            console.log("ha petado")
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    return(
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
            />

            <h2 className="mt-5">Agregar nueva terminal</h2>

            <p>
                Debes completar el siguiente formulario:
            </p>

            <hr></hr>

            <FormularioTerminal
                onSubmit={onSubmitTerminal}
                permisosUsuario={permisosUsuario}
            />
        </section>
    );
}