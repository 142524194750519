import React from "react";
import Icon from "./../../components/Icon";
import './Siniestros.css';
import Table from "./../../components/Table";
import TableHead from "./../../components/Table/TableHead";
import TableBody from "./../../components/Table/TableBody";
import InputSearch from "./../../components/InputSearch";
import AddButton from "./../../components/AddButton";
import LoadingSearchIcon from "../../components/LoadingSearchIcon";
import AlertWarningSearch from "../../components/AlertWarningSearch";
import searchTerminalesService from "../../services/searchTerminalesService";
import FilterButton from "../../components/FilterButton";
import getTerminalesService from "../../services/getTerminalesService";
import getGastosContralorSiniestro from "../../services/gasto_contralor/getGastosContralorSiniestro";
import searchGastosContralorSiniestroService from "../../services/gasto_contralor/searchGastosContralorSiniestro";
import getSaldoGeneralAreaEmpresa from "../../services/banco_digital/getSaldoGeneralAreaEmpresa";

export default function ContraloriaSiniestro({permisosUsuario}) {

    const [elements, setElements] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filters, setFilters] = React.useState([])
    const [orderBy, setOrderBy] = React.useState({})
    const [terminoBusqueda, setTerminoBusqueda] = React.useState("")
    const [showDeleteFilters, setShowDeleteFilters] = React.useState(false)
    const [loadingFilters, setLoadingFilters] = React.useState(false)

    const rows = [
        { id: 1, name: "Gasto" },
        { id: 2, name: "Siniestro" },
        { id: 3, name: "Cantidad" },
        { id: 4, name: "" }
    ];

    function getGastosContralorService(){
        setLoading(true)
        setElements([])
        if(filters.length !== 0 || Object.keys(orderBy).length !== 0 || terminoBusqueda !== ""){
            search()
        } else {
            getGastosContralorSiniestro()
                .then(res=>{
                    console.log(res)
                    let _res = JSON.parse(res)
                    // console.log(_res)
                    if(_res.response === 'success'){
                        setTimeout(()=>setElements(_res.data),300)
                    }
                    setLoading(false)
                })
        }
    }
    
    const search = () =>{
        setLoading(true)
        let data = {}

        if(terminoBusqueda !== "")
            data.q = terminoBusqueda
        if(filters.length !== 0)
            data.filtros = filters
        if(Object.keys(orderBy).length !== 0)
            data.ordenar = orderBy

        console.log(filters);
        searchGastosContralorSiniestroService({values:data})
            .then((response) =>{
                console.log(response)
                let _response = JSON.parse(response)
                if(_response.response === "success"){
                    setElements(_response.data)
                } else {
                    setElements([])
                }
                setLoading(false)
            })
            .catch((error)=>{
                console.log(error)
            })
    }

    // const onSubmitSearch = (e) =>{
    //     e.preventDefault()
    //     let values = new FormData(e.target)
    //     let objectValues = Object.fromEntries(values)
    //     let terminoBusqueda = objectValues.q
    //     setTerminoBusqueda(terminoBusqueda)
    // }

    const onSubmitFilter = (tipoFiltro, e) => {
        e.preventDefault()
        setShowDeleteFilters(true)
        let values = new FormData(e.target)
        let object = Object.fromEntries(values)

        let nuevosFiltros = [...filters]

        let newFiltros = nuevosFiltros.filter((value) =>{
            return value.campo !== tipoFiltro
        })

        newFiltros.push({ campo: tipoFiltro, data: object })
        setFilters(newFiltros)
    }

    const onClickDeleteFilters = () => {
        setFilters([])
        setOrderBy({})
        setShowDeleteFilters(false)
        // Ocultamos y mostramos de nuevo los filtros para limpiar los valores seleccionados
        setLoadingFilters(true)
        setTimeout(()=>{
            setLoadingFilters(false)
        }, 50)
    }

    const onClickOrderBy = (campo, orden) => {
        let data = {
            campo: campo,
            orden: orden
        }
        setOrderBy(data)
        setShowDeleteFilters(true)
    }

    // /////////////////////////////////////////////
    // FUNCIONES PARA OBTENER EL SALDO DE SINIESTROS
    // /////////////////////////////////////////////

    const [dataGeneralCuenta, setDataGeneralCuenta] = React.useState({});
    const [saldoTotalSiniestros, setSaldoTotalSiniestros] = React.useState({});
    
    const getDetallesSaldoAreaEmpresaService = ()=>{
        setLoading(true)
        setElements([])
        setDataGeneralCuenta([])
        let data = {
            id_area_empresa: 3
        }
        console.log(data)
        getSaldoGeneralAreaEmpresa({values:data})
            .then(res=>{
                console.log(res)
                let _res = JSON.parse(res)
                console.log(_res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    setDataGeneralCuenta(_res.data)
                    let saldo_total_siniestros = (parseFloat(_res.data.saldo_total) - parseFloat(_res.data.total_sin_entregar));
                    setSaldoTotalSiniestros(saldo_total_siniestros)
                }
                setLoading(false)
            })
    }
    
    // /////////////////////////////////////////////

    const reload = ()=>{
        getGastosContralorService()
        getDetallesSaldoAreaEmpresaService()
    }

    React.useEffect(() => {
        getGastosContralorService()
    },[filters, terminoBusqueda, orderBy]);

    React.useEffect(()=>{
        getDetallesSaldoAreaEmpresaService()
    },[])

    return (
        <section>

            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h1 className="">Contraloría siniestros</h1>

                    <p>
                        Los recursos enlistados aquí han sido solicitados para retirarlos en ventailla.
                    </p>
                    <p>
                        Debes <span className='font-weight-bold'>aprobar o rechazar</span> esa solicitud, al ser rechazada, debes escribir el motivo.
                    </p>
                </div>

                {
                    Object.keys(dataGeneralCuenta).length > 0 &&
                        <div className="text-right">
                            <div className='d-flex align-items-center justify-content-end'>
                                <h2 className='m-0'>
                                    Saldo siniestros:
                                </h2>
                                <h2 className={`m-0 ml-2 ${saldoTotalSiniestros > 0 && 'color-verde'} ${saldoTotalSiniestros < 0 && 'color-rojo'} ${saldoTotalSiniestros === 0 && 'text-black'}`}>
                                    { saldoTotalSiniestros
                                        .toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                                </h2>
                            </div>

                            <small className="text-muted">
                                Pendiente de entregar por finanzas: { parseFloat(dataGeneralCuenta.total_sin_entregar)
                                        .toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                            </small>

                        </div>
                }


            </div>
            

            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {
                        !loadingFilters &&
                            <>
                                <FilterButton text="Aprobado" type="aprobado" onSubmit={onSubmitFilter} />
                                <FilterButton text="Fecha alta" type="fecha_alta" onSubmit={onSubmitFilter} />
                                <FilterButton text="Siniestro" type="siniestro" onSubmit={onSubmitFilter} />
                            </>
                    }
                </div>

                <div className="d-flex align-items-center">

                    <button className={`btn btn-sm btn-light text-muted ${(!showDeleteFilters) && 'hidden'}`} onClick={()=>onClickDeleteFilters()}>
                        <Icon icon='fa-solid fa-x' /> Eliminar filtros
                    </button>

                    <div className="dropdown mx-2">
                        <button className="btn btn-sm btn-light dropdown-toggle text-muted" data-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-arrow-down-short-wide mr-2"></i>
                                Ordenar
                        </button>

                        <div className="dropdown-menu">
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('id_siniestro','ASC')}>ID Siniestro de A-Z</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('id_siniestro','DESC')}>ID Siniestro de Z-A</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_alta','ASC')}>Fecha alta de A-Z</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_alta','DESC')}>Fecha alta de Z-A</button>
                        </div>
                    </div>
                    
                </div>

            </div>

            <hr className="my-3" />

            <LoadingSearchIcon show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rows} />

                        <TableBody 
                            elements={elements} 
                            typeElement="gasto_contralor_siniestro" 
                            permisosUsuario={permisosUsuario}
                            updateElements={reload}
                            saldoTotalSiniestros={saldoTotalSiniestros}
                        />
                    </Table>
            }

            {
                ( Object.keys(elements).length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }

        </section>
    );
}