import React from "react";
import { useHistory } from "react-router-dom"
import "./Header.css";
import Icon from "./../Icon";

function Header({ logout }){
    
    const history = useHistory();

    //Evento para mostrar y ocultar menú
    function open_close_menu(){
        document.getElementById("menu_side").classList.toggle("menu__side_move");
        document.getElementById("body").classList.toggle("body_move");
        document.querySelector(".name__page").classList.toggle("img_menu_move");
        document.querySelector(".functions__menu").classList.toggle("functions__menu-margin");
    }
    
    const onSubmitSearch = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values)
        const terminoBusqueda = objectValues.q
        history.push(`/resultados/${terminoBusqueda}`)
    }

    return(
        <header className="shadow">
            <div className="icon__menu" 
                onClick={ () => open_close_menu() }>

                    <Icon 
                        icon="fas fa-bars"
                        id="btn_open"
                    />

            </div>

            <div className="functions__menu">
                <form className="search__menu" onSubmit={onSubmitSearch} autoComplete="off">
                    <Icon 
                        icon="fa-solid fa-search"
                    />
                    <input className="search" name="q" type="text" placeholder="Buscar" autoComplete="off"/>
                </form>
                <button id="btnLogout" className="btn option d-flex align-items-center" onClick={logout}>
                    <i className="fa-solid fa-right-from-bracket mr-2" title="Salir del sistema"></i>
                    <small>Salir</small>
                </button>
            </div>
        </header>
    );
}

export default Header;