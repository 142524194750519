import React from 'react';
import $ from 'jquery';
import Swal from 'sweetalert2'
import GoogleMapReact from 'google-map-react';
import getVehiculosToPresiniestro from '../../services/presiniestro/getVehiculosToPresiniestro';
import Icon from '../../components/Icon';
import AddButton from '../../components/AddButton';
import { useHistory } from 'react-router';
import Table from '../../components/Table';
import TableHead from '../../components/Table/TableHead';
import TableBody from '../../components/Table/TableBody';
import LoadingSearchIcon from '../../components/LoadingSearchIcon';
import getAseguradoras from '../../services/catalogos/aseguradora/getAseguradoras';
import getTiposLicencias from '../../services/catalogos/tipo_licencia/getTiposLicencias';
import getGestores from '../../services/trabajador/getGestores';
import ModalAddLesionados from '../../components/ModalAddLesionados';
import getOperadoresByIdHistorialVehiculoConcesionEconomico from '../../services/trabajador/getOperadoresByIdHistorialVehiculoConcesionEconomico';
import AlertNoData from '../../components/AlertNoData';
import ModalAddVehiculoAfectado from '../../components/ModalAddVehiculoAfectado';
import ModalAddBienAfectado from '../../components/ModalAddBienAfectado';
import addPresiniestro from '../../services/presiniestro/addPresiniestro';

export default function AddPresiniestro({ permisosUsuario }){

    const history = useHistory()

    let hoy = new Date();
    let fechaHoy = hoy.getFullYear() + "-" + (hoy.getMonth() + 1) + "-" + hoy.getDate();

    // Variables para que el funcionamiento del formulario esté correcto
    const [dataVehiculos, setDataVehiculos] = React.useState([])
    const [operadorRegistrado, setOperadorRegistrado] = React.useState(null)
    const [vehiculoSelected, setVehiculoSelected] = React.useState()
    const [idVehiculo, setIdVehiculo] = React.useState()
    
    // Catalogos
    const [aseguradoras, setAseguradoras] = React.useState([])
    const [tipoLicencias, setTipoLicencias] = React.useState([])
    const [operadores, setOperadores] = React.useState([])
    const [gestores, setGestores] = React.useState([])

    // Loading para cuando se cargue la información del formulario
    const [loading, setLoading] = React.useState(false);

    // Lesionados, daños a unidades y bienes se manejan aquí
    const [lesionados, setLesionados] = React.useState([])
    const [bienesAfectados, setBienesAfectados] = React.useState([])
    const [vehiculosAfectados, setVehiculosAfectados] = React.useState([])

    const rows = [
        { id: 1, name: "Nombre" },
        { id: 2, name: "Descripción" },
    ];

    const [latitud, setLatitud] = React.useState(0)
    const [longitud, setLongitud] = React.useState(0)

    const defaultProps = {
        center: {
            lat: 19.533587,
            lng: -99.035560
        },
        zoom: 11
    };

    const getVehiculosToPresiniestroService = () =>{
        getVehiculosToPresiniestro()
            .then(res => JSON.parse(res))
            .then(res => (res.response === 'success') && setDataVehiculos(res.data))
            .then(() => $('.selectpicker').selectpicker('refresh'))
            .catch(err => { console.log({err}) })
    }

    const onSubmitVehiculoPresiniestro = (e) => {
        e.preventDefault();
        setLoading(true)

        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        setVehiculoSelected(objectValues.id_historial_vehiculo_concesion_economico)
        
        // Obtenemos los operadores
        getOperadoresByIdHistorialVehiculoConcesionEconomico({
            values:{ 
                id_historial_vehiculo_concesion_economico: objectValues.id_historial_vehiculo_concesion_economico 
            }
        })
            .then(res => JSON.parse(res))
            .then(res => setOperadores(res.data))
            .then(() => $('.selectpicker').selectpicker('refresh'))
            .catch(err => { console.log({err}) })

        // Obtenemos las aseguradoras
        getAseguradoras()
            .then(res => JSON.parse(res))
            .then(res => setAseguradoras(res.data))
            .then(() => $('.selectpicker').selectpicker('refresh'))
            .catch(err => { console.log({err}) })

        // Obtenemos los tipos de licencias
        getTiposLicencias()
            .then(res => JSON.parse(res))
            .then(res => setTipoLicencias(res.data))
            .then(() => $('.selectpicker').selectpicker('refresh'))
            .catch(err => { console.log({err}) })
        
        // Obtenemos los gestores disponibles
        getGestores()
            .then(res => JSON.parse(res))
            .then(res => setGestores(res.data))
            .then(() => $('.selectpicker').selectpicker('refresh'))
            .catch(err => { console.log({err}) })

        // Obtenemos el id del vehiculo que está involucrado en el presiniestro
        let vehiculo = dataVehiculos.filter((vehiculo)=>{
            return vehiculo.id_historial_vehiculo_concesion_economico === objectValues.id_historial_vehiculo_concesion_economico
        })
        setIdVehiculo(vehiculo[0].id_vehiculo)

        setTimeout(()=>{
            setLoading(false)
            document.getElementById("fecha_siniestro").value = fechaHoy
            $('.selectpicker').selectpicker('refresh');
        }, 1000)
    }

    const onClickMap = (e) =>{
        let lat = e.lat
        let long = e.lng
        setLatitud(lat)
        setLongitud(long)
        const geocoder = new window.google.maps.Geocoder();

        const latlng = {
            lat: parseFloat(lat),
            lng: parseFloat(long),
        };
        
        geocoder
            .geocode({ location: latlng })
            .then((response) => {
              if (response.results[0]) {
                document.getElementById('direccion_hechos_siniestro').value = response.results[0].formatted_address
                // console.log(response.results[0].formatted_address);
              } else {
                window.alert("No results found");
              }
            })
            .catch((e) => window.alert("Geocoder falló, contactar al developer, motivo: " + e));
    }

    const Marcador = () => <i className="fa-solid fa-location-crosshairs" style={{fontSize: "3em", color:"var(--color-rojo)", margin:"-20px"}}></i>

    // Metodos para los Submit de los modales
    const onSubmitModalAddLesionado = (e)=>{
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);

        let nombreGenero = $("#id_genero option:selected").text()
        objectValues.nombre_genero = nombreGenero

        setLesionados([...lesionados, objectValues])

        $("#modalAddLesionados").modal('hide')
        e.target.reset()
        
        $('.selectpicker').selectpicker('refresh')
    }
    
    const onSubmitModalAddVehiculoAfectado = (e)=>{
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);

        let marcaVehiculo = $("#id_marca_vehiculo option:selected").text()
        if(marcaVehiculo !== 'Elige una marca')
            objectValues.nombre_marca = marcaVehiculo

        let submarcaVehiculo = $("#id_submarca_vehiculo option:selected").text()
        if(submarcaVehiculo !== 'Elige una submarca')
            objectValues.nombre_submarca = submarcaVehiculo

        setVehiculosAfectados([...vehiculosAfectados, objectValues])

        $("#modalAddVehiculoAfectado").modal('hide')

        // Con esta instrucción se resetea todo el formulario donde provienen los datos
        e.target.reset()
        $('.selectpicker').selectpicker('refresh')
    }
    
    const onSubmitModalAddBienAfectado = (e)=>{
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);

        let bienAfectado = $("#id_tipo_de_bien option:selected").text()
        if(bienAfectado !== 'Elige una marca')
            objectValues.nombre_tipo_de_bien = bienAfectado

        setBienesAfectados([...bienesAfectados, objectValues])

        $("#modalAddBienAfectado").modal('hide')

        // Con esta instrucción se resetea todo el formulario donde provienen los datos
        e.target.reset()
        $('.selectpicker').selectpicker('refresh')
    }

    // onSubmit del formulario completo del presiniestro
    const onSubmitPresiniestro = (e) =>{
        $('#buttonSubmitPresiniestro').prop('disabled',true)
        e.preventDefault()
        let values = new FormData(e.target)
        values.append("latitud", latitud)
        values.append("longitud", longitud)
        values.append("id_vehiculo", idVehiculo)
        values.append("id_historial_vehiculo_concesion_economico", $("#id_historial_vehiculo_concesion_economico option:selected").val())
        let objectValues = Object.fromEntries(values);
        objectValues.lesionados = lesionados
        objectValues.bienes_afectados = bienesAfectados
        objectValues.vehiculos_afectados = vehiculosAfectados
        console.log({objectValues})
        addPresiniestro({values:objectValues}).then((response) => {
            console.log(response)
            let _response = JSON.parse(response);
            if(_response.response === "error"){
                Swal.fire(
                    'Ocurrió un error',
                    'Al agregar la informacion del presiniestro, contacta al desarrollador',
                    'error'
                );
                $('#buttonSubmitPresiniestro').prop('disabled',false)
            } else {
                $("#formAddPresiniestro")[0].reset();
                e.target.reset()
                $('.selectpicker').selectpicker('refresh');
                Swal.fire({
                    title:'Presiniestro agregado',
                    icon:"success",
                    timer: 1200,
                    showConfirmButton:false
                }).then(()=>{
                    history.push('/pre-siniestros')
                })
            }
        }).catch((error) => {
            console.log(error)
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
            $('#buttonSubmitPresiniestro').prop('disabled',false)
        })
    }

    React.useEffect(getVehiculosToPresiniestroService, []);
    React.useEffect(() => $('.selectpicker').selectpicker(), []);

    return(
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
            />

            <h2 className="mt-5">
                Agregar un Pre siniestro
            </h2>

            <p>
                Para agregar un pre siniestro, deberás: 
            </p>
            <ul>
                <li className='ml-5 mt-3'>
                    <span className="font-weight-bold">Elegir el vehículo</span> que lo ha tenido.
                </li>
                <li className='ml-5'>
                    Buscarlo por número de económico, permisionario o número de serie de la unidad, sí no lo encuentras, <span className="font-weight-bold">verifica que esté correctamente asignada la unidad con el permisionario, concesión y económico.</span>
                </li>
                <li className='ml-5'>
                    Asegúrate que se encuentre <span className="font-weight-bold">activa</span> la unidad.
                </li>
            </ul>

            <form onSubmit={onSubmitVehiculoPresiniestro} className='row mt-5'>
                <div className="col-10">
                    <label htmlFor="id_historial_vehiculo_concesion_economico">
                        Unidad del siniestro:
                    </label>
                    <select 
                        className="input selectpicker"
                        id="id_historial_vehiculo_concesion_economico"
                        name="id_historial_vehiculo_concesion_economico"
                        data-live-search="true"
                        required
                    >
                        <option value=''>
                            Elige un vehìculo
                        </option>
                        {
                            dataVehiculos &&
                            dataVehiculos.map( (vehiculos) => (
                                <option key={ vehiculos.id_historial_vehiculo_concesion_economico } value={ vehiculos.id_historial_vehiculo_concesion_economico }>
                                    Num. de serie: { vehiculos.numero_serie } | Marca: { vehiculos.nombre_marca }, {vehiculos.nombre_submarca} | Económico: {vehiculos.clave_prefijo_economico}{vehiculos.numero_economico}, {vehiculos.modalidad_prefijo_economico} | {vehiculos.nombre_permisionario} {vehiculos.apellido_paterno_permisionario} {vehiculos.apellido_materno_permisionario}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className="col-2 d-flex align-items-end">
                    <button className='btn btn-naranja btn-block' style={{ height: "2.6em", marginBottom: "1em" }}>
                        <Icon icon={'fa-solid fa-circle-check mr-2'} />
                        Elegir
                    </button>
                </div>
            </form>

            <LoadingSearchIcon show={loading} />

            {
            (vehiculoSelected && !loading) &&
            <form onSubmit={onSubmitPresiniestro} id='formAddPresiniestro'>

                <h3 className="mt-5">
                    Datos del operador
                </h3>
                <div className='row'>
                    <div className='col-3'>
                        <label>
                            El operador está registrado?
                        </label>

                        <br />

                        <div className="form-check form-check-inline">
                            <input 
                                type="radio"
                                name="operador_registrado"
                                value={1}
                                id={'operador-registrado'}
                                onChange={(e) => {
                                    setOperadorRegistrado(e.target.value)
                                    $("#id_operador").prop("required",true)
                                    $("#nombre_operador_no_registrado").prop("required",false)
                                    $("#apellido_paterno_operador_no_registrado").prop("required",false)
                                    $("#apellido_materno_operador_no_registrado").prop("required",false)
                                    document.getElementById('nombre_operador_no_registrado').value = ''
                                    document.getElementById('apellido_paterno_operador_no_registrado').value = ''
                                    document.getElementById('apellido_materno_operador_no_registrado').value = ''
                                }}
                                required
                            />
                            <label className="form-check-label ml-1" htmlFor="operador-registrado">
                                Si
                            </label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input 
                                type="radio"
                                name="operador_registrado"
                                value={0}
                                id={'operador-no-registrado'}
                                onChange={(e) => { 
                                    setOperadorRegistrado(e.target.value)
                                    $("#id_operador").prop("required",false)
                                    $("#nombre_operador_no_registrado").prop("required",true)
                                    $("#apellido_paterno_operador_no_registrado").prop("required",true)
                                    $("#apellido_materno_operador_no_registrado").prop("required",true)
                                    document.getElementById('id_operador').value = ''
                                    $("#id_operador").selectpicker("refresh")
                                }}
                                required
                            />
                            <label className="form-check-label ml-1" htmlFor="operador-no-registrado">
                                No
                            </label>
                        </div>
                    </div>
                    <div className='col-3'>
                        <label htmlFor="fecha_nacimiento_operador">
                            Fecha de nacimiento:
                        </label>
                        <input 
                            className="input"
                            id="fecha_nacimiento_operador"
                            name="fecha_nacimiento_operador"
                            type="date"
                            required
                        />
                    </div>
                    <div className='col-6'>
                        {/* Si esta registrado el operador, debe seleccionarlo */}
                        <div className={`${(operadorRegistrado === '0' || operadorRegistrado === null) && 'hidden'}`}>
                            <label htmlFor="id_operador">
                                Elige el operador:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="id_operador"
                                name="id_operador"
                                data-live-search="true"
                                onChange={(e)=>{
                                    let operador = operadores.filter((operador)=>{
                                        return operador.id_operador === e.target.value
                                    })
                                    document.getElementById('fecha_nacimiento_operador').value = operador[0].fecha_nacimiento
                                }}
                            >
                                <option value=''>
                                    Elige un operador
                                </option>
                                {
                                    operadores &&
                                    operadores.map( (operador) => (
                                        <option key={ operador.id_operador } value={ operador.id_operador }>
                                            {operador.nombre} {operador.apellido_paterno} {operador.apellido_materno}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>

                        {/* Si no está registrado el operador, debe registrar el nombre completo */}
                        <div className={`${(operadorRegistrado === '1' || operadorRegistrado === null) && 'hidden'}`}>
                            <label>
                                Nombre completo del operador:
                            </label>
                            <br/>
                            <input 
                                type="text" 
                                className="input"
                                id="nombre_operador_no_registrado"
                                name="nombre_operador_no_registrado"
                                placeholder='Nombre(s)'
                            />
                            <div className='row'>
                                <div className='col-6'>
                                    <input 
                                        type="text" 
                                        className="input" 
                                        id="apellido_paterno_operador_no_registrado"
                                        name="apellido_paterno_operador_no_registrado"
                                        placeholder='Apellido paterno'
                                    />
                                </div>
                                <div className='col-6'>
                                    <input 
                                        type="text"
                                        className="input" 
                                        id="apellido_materno_operador_no_registrado"
                                        name="apellido_materno_operador_no_registrado"
                                        placeholder='Apellido materno'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className='my-3' />

                <h3>
                    Datos del siniestro
                </h3>

                <div className='row'>
                    <div className='col-4'>
                        <label htmlFor="fecha_siniestro">
                            Fecha del siniestro:
                        </label>
                        <input 
                            className="input"
                            id="fecha_siniestro"
                            name="fecha_siniestro"
                            type="date"
                            required
                        />
                    </div>

                    <div className='col-4'>
                        <label htmlFor="id_aseguradora">
                            Aseguradora:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_aseguradora"
                            name="id_aseguradora"
                            data-live-search="true"
                            required
                        >
                            <option value=''>
                                Elige una aseguradora
                            </option>
                            {
                                aseguradoras &&
                                aseguradoras.map( (aseguradora) => (
                                    <option key={ aseguradora.id_aseguradora } value={ aseguradora.id_aseguradora }>
                                        {aseguradora.nombre}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    <div className='col-4'>
                        <label htmlFor="id_gestor">
                            Gestor del siniestro:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_gestor"
                            name="id_gestor"
                            data-live-search="true"
                            required
                        >
                            <option value=''>
                                Elige al gestor asignado
                            </option>
                            {
                                gestores &&
                                gestores.map( (gestor) => (
                                    <option key={ gestor.id_trabajador } value={ gestor.id_trabajador }>
                                        {gestor.nombre} {gestor.apellido_paterno} {gestor.apellido_materno}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    <div className='col-4'>
                        <label htmlFor="id_tipo_licencia">
                            Tipo de licencia:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_tipo_licencia"
                            name="id_tipo_licencia"
                            data-live-search="true"
                            required
                        >
                            <option value=''>
                                Elige una opción
                            </option>
                            {
                                tipoLicencias &&
                                tipoLicencias.map( (tipo_licencia) => (
                                    <option key={ tipo_licencia.id_tipo_licencia } value={ tipo_licencia.id_tipo_licencia }>
                                        {tipo_licencia.nombre}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    <div className='col-8'>
                        <label>
                            La licencia está vigente?
                        </label>

                        <br />

                        <div className="form-check form-check-inline">
                            <input 
                                type="radio"
                                name="validez_licencia"
                                value={1}
                                id={'validez_licencia'}
                                required
                                // onChange={onChangeOperadorRegistrado}
                            />
                            <label className="form-check-label ml-1" htmlFor="validez_licencia">
                                Si, está vigente
                            </label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input 
                                type="radio"
                                name="validez_licencia"
                                value={0}
                                id={'validez_licencia_no'}
                                required
                                // onChange={onChangeOperadorRegistrado}
                            />
                            <label className="form-check-label ml-1" htmlFor="validez_licencia_no">
                                No está vigente
                            </label>
                        </div>
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className='col-7' style={{ height: '400px', width: '100%' }}>

                        <label className="mb-2">
                            Selecciona la dirección del siniestro en el mapa:
                        </label>

                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyDSmxYQ1X3h8KhSTXzMfGz_HLK3gH396oo" }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            onClick={onClickMap}
                        >
                            <Marcador
                                lat={latitud}
                                lng={longitud}
                                icon={"http://maps.google.com/mapfiles/ms/icons/blue.png"}
                                text="Siniestro"
                            />
                        </GoogleMapReact>
                    </div>

                    <div className='col-5'>

                        <label htmlFor='direccion_hechos_siniestro'>
                            Lugar de los hechos:
                        </label>
                        <textarea 
                            type="text"
                            className="input" 
                            id="direccion_hechos_siniestro"
                            name="direccion_hechos_siniestro"
                            placeholder='Selecciona una dirección en el mapa'
                            readOnly={true}
                            required
                        />
                        
                        <label htmlFor='nombre_persona_reporta_siniestro'>
                            Nombre de quién reporta el siniestro:
                        </label>
                        <input 
                            type="text"
                            className="input" 
                            id="nombre_persona_reporta_siniestro"
                            name="nombre_persona_reporta_siniestro"
                            placeholder='Nombre, apellido paterno, apellido materno'
                            required
                        />

                        <label htmlFor='descripcion'>
                            Descripción del siniestro:
                        </label>
                        <textarea 
                            type="text"
                            className="input" 
                            id="descripcion"
                            name="descripcion"
                            placeholder='Realiza una descripción completa de los hechos según la persona que está reportando.'
                            required
                        />
                    </div>
                </div>

                <hr className='mt-5 mb-3' />

                <div className='row'>
                    <div className='col-6'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h3 className='noMargin'>
                                Lesionados
                            </h3>

                            <AddButton text="Agregar lesionado" onClick={ () => {
                                $("#modalAddLesionados").modal('show')
                                // history.push("/permisionarios/agregar")
                            } } />
                        </div>
                        
                        {
                        ( lesionados.length === 0 ) &&
                            
                            <AlertNoData
                                textAlert={"No hay lesionados"}
                            />
                        }

                        {
                        ( lesionados.length !== 0 ) &&
                            <Table>
                                <TableHead rows={rows} />

                                <TableBody 
                                    elements={lesionados}
                                    typeElement={"lesionados"}
                                    permisosUsuario={permisosUsuario}
                                    setElements={setLesionados}
                                />
                            </Table>
                        }

                    </div>
                    <div className='col-6'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h3 className='noMargin'>
                                Daños a bienes
                            </h3>
                            <AddButton text="Agregar bien" onClick={ () => {
                                $("#modalAddBienAfectado").modal('show')
                            } } />
                        </div>
                        {
                        ( bienesAfectados.length === 0 ) &&
                            
                            <AlertNoData
                                textAlert={"No hay daños a bienes de terceros"}
                            />
                        }

                        {
                        ( bienesAfectados.length !== 0 ) &&
                            <Table>
                                <TableHead rows={rows} />

                                <TableBody 
                                    elements={bienesAfectados}
                                    typeElement={"bienes_afectados"}
                                    permisosUsuario={permisosUsuario}
                                    setElements={setBienesAfectados}
                                />
                            </Table>
                        }
                    </div>
                    <div className='col-12'>
                        <hr className='mt-5 mb-3' />
                        <div className='d-flex align-items-center justify-content-between'>
                            <h3 className='noMargin'>
                                Daños a vehículos
                            </h3>
                            <AddButton text="Agregar vehículo" onClick={ () => {
                                $("#modalAddVehiculoAfectado").modal('show')
                            } } />
                        </div>

                        {
                        ( vehiculosAfectados.length === 0 ) &&
                            
                            <AlertNoData
                                textAlert={"No hay daños a vehículos"}
                            />
                        }

                        {
                        ( vehiculosAfectados.length !== 0 ) &&
                            <Table>
                                <TableHead rows={
                                    [
                                        { id: 1, name: "Conductor" },
                                        { id: 2, name: "Vehículo" },
                                        { id: 3, name: "Descripción" }
                                    ]
                                } />

                                <TableBody 
                                    elements={vehiculosAfectados}
                                    typeElement={"vehiculos_afectados"}
                                    permisosUsuario={permisosUsuario}
                                    setElements={setVehiculosAfectados}
                                />
                            </Table>
                        }

                    </div>
                </div>

                <hr />
                <div className="d-flex justify-content-end">
                    <button id='buttonSubmitPresiniestro' className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus" />
                        <Icon icon="fa-solid fa-car-on mr-3" />
                        Agregar pre siniestro
                    </button>
                </div>

            </form>
            }

            <ModalAddLesionados 
                onSubmit={onSubmitModalAddLesionado}
            />
            
            <ModalAddVehiculoAfectado
                onSubmit={onSubmitModalAddVehiculoAfectado}
            />
            
            <ModalAddBienAfectado
                onSubmit={onSubmitModalAddBienAfectado}
            />

        </section>
    );
}