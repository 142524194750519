import React from "react";
import "./Login.css";
import { validateEmail } from "./../../validators/validateEmail";
import logo from "../../assets/images/logo.png"
import login from "./../../services/login.js";
import Swal from 'sweetalert2'
import recoverPassword from "../../services/usuario/recoverPassword";

export default function Login({setUser}){

    // Estado del manejo de errores en el input
    const [errors, setErrors] = React.useState({});

    // Función que hará para validar todos los input
    const handleErrors = (values)=>{
        const err = {};
        if(!values.email){
            err.email = "El correo es obligatorio";
        }else if(!validateEmail(values.email)){
            err.email = "El email no es válido";
        }
        if(!values.password){
            err.password = "La contraseña es obligatoria";
        }
        setErrors(err);
        return (err && Object.keys(err).length === 0) ? false : true;
    }

    // Función que manejará el comportamiento del login
    const handleSubmitLogin = (e) =>{
        e.preventDefault();
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        if(!handleErrors(objectValues)){
            // Aquí se hará el llamado a la API
            login({values:objectValues}).then((response) => {
                console.log(JSON.parse(response))
                let _response = JSON.parse(response);
                if(_response.response === "error"){
                    Swal.fire(
                        'Datos incorrectos',
                        'Correo o contraseña no válidos',
                        'error'
                    )
                } else {
                    localStorage.setItem('user', JSON.stringify(_response.user));
                    setUser({
                        isLoggedIn: true,
                        typeUser: _response.user.tipo_trabajador_seleccionados,
                        user: _response.user
                    })
                }
            }).catch((error) => {
                // console.log(error)
                // console.log("ha petado")
                Swal.fire(
                    'Error del servidor',
                    'Inténtalo de nuevo en unos momentos.',
                    'error'
                )
            })
        }
    }

    // Función que borra un error del input
    const cleanError = (e) =>{
        setErrors({...errors, [e.target.name]: ""})
    }

    const forgotPassword = ()=>{
        Swal.fire({
            title: 'Introduce tu correo para restablecer la contraseña:',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Recuperar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            preConfirm: async (correo) => {
                return recoverPassword({values: { correo }})
                    .then((respuesta) => {
                        console.log(respuesta)
                        let _respuesta = JSON.parse(respuesta)
                        if(_respuesta.response === 'success')
                            return true;
                        
                        if(_respuesta.response === 'error')
                            Swal.showValidationMessage(
                                `El correo no está asociado a ningún usuario`
                            )
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    })
            }
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'info',
                    title: 'Te envíamos un correo',
                    text: 'Abre el enlace que te envíamos al correo para restablecer la contraseña'
                })
            }
        })
    }

    return (
        <div className="login">
            <div className="login__background" />
            <div className="login__welcomeText">
                <img src={logo} alt="AUSE" className="img-fluid" />
                {/* <h1>Bienvenido</h1> */}
                <p>Por favor ingresa tus credenciales de acceso:</p>
            </div>

            <div className="login__form">
                <form onSubmit={ handleSubmitLogin }>
                    <input 
                        className={`input ${(errors.email !== "" && errors.email !== undefined) && "input-error"}`} 
                        type="email" 
                        placeholder="Correo electrónico" 
                        name="email" 
                        onChange={ cleanError } />
                    <small className="error"> { errors.email } </small>
                    
                    <input 
                        className={`input ${(errors.password !== "" && errors.password !== undefined) && "input-error"}`} 
                        type="password" 
                        placeholder="Contraseña" 
                        name="password" 
                        onChange={ cleanError } />
                    <small className="error"> { errors.password } </small>

                    <button className="btn" type="submit">Entrar</button>

                    <hr className="my-3" />

                    <span 
                        className="btn btn-sm"
                        onClick={ forgotPassword }
                    >
                        ¿Olvidaste la contraseña?
                    </span>
                </form>
            </div>

            <div className="login__footer">
                <p>© 2023 AUSE</p>
                <a href="mailto:ricardosandoval@cursodontic.com">Contactar al desarrollador</a>
            </div>

        </div>
    );
}