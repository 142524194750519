import $ from 'jquery';
import config from '../../config';
const URL_API = config.urlApi+'controlador/siniestro/searchSaldosTrabajadoresSiniestro.php';

export default function searchSaldosTrabajadoresSiniestro({values}){
    return $.ajax({
        url: URL_API,
        type: 'POST',
        data: values,
    })
}