import React from 'react'
import './AddButton.css'

export default function AddButton({ text, onClick }){
    return (
        <button type='button' className="icon-btn add-btn" onClick={ onClick }>
            <div className="add-icon"></div>
            <div className="btn-txt">{ text }</div>
        </button>
    )
}