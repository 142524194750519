import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Icon from '../../components/Icon';
import FormularioUsuario from '../../components/FormularioUsuario';
import getUsuarioService from "./../../services/getUsuarioService.js";
import $ from 'jquery';
import changeInformacionUsuarioService from './../../services/changeInformacionUsuarioService';
import changePasswordService from './../../services/changePasswordService';
import changeEstatusUsuarioService from './../../services/changeEstatusUsuarioService';
import Swal from 'sweetalert2'

export default function ViewUsuario({permisosUsuario}) {

    const location = useLocation();
    const id_usuario = location.pathname.split('/')[2];

    const [usuario, setUsuario] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    const getUsuario = () => {
        let data = {
            id_usuario: id_usuario
        }
        getUsuarioService({values:data})
            .then(response => {
                let _response = JSON.parse(response);
                console.log(_response.usuario);
                setUsuario(_response.usuario);
                setLoading(false)
            })
            .catch(error => {
                console.log(`Ha petado getUsuario(): ${error}`)
            })
    }

    const onSubmitUpdateUsuario = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        let permisosSistemaSelected = $("#permisosSistema").val();
        values.append("permisosSistemaSelected",JSON.stringify(permisosSistemaSelected));
        values.append('id_usuario', usuario.id_usuario);
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        changeInformacionUsuarioService({values:objectValues})
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response);

                if(_response.response === "exist"){
                    Swal.fire({
                        icon: 'warning',
                        title: "El correo ya está registrado",
                        text: "No puedes registrar un usuario dos veces con el mismo correo"
                    })
                }

                if(_response.response === 'success'){
                    Swal.fire({
                        title: 'Exito',
                        text: 'Actualizado correctamente',
                        icon: 'success',
                        timer: 1200,
                        showConfirmButton: false
                    })
                } 
                
                if(_response.response === 'error'){
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No actualizaste ningún dato',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error en el servidor',
                    text: 'Inténtalo de nuevo más tarde o contacta al desarrollador',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
    }

    const onClickChangePassword = () => {
        Swal.fire({
            title: 'Escribe la nueva contraseña',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Actualizar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_usuario', id_usuario)
                values.append('password', result.value)
                let objectValues = Object.fromEntries(values);
                changePasswordService({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        Swal.fire(
                            'Contraseña actualizada',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    const onClickEstatusAcceso = (activo) => {
        let msg = (activo) ? 'Quieres activar el acceso al sistema?' : 'Quieres revocarle el acceso al sistema?';
        let txt = (activo) ? 'Activar' : 'Revocar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_usuario', id_usuario)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                changeEstatusUsuarioService({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getUsuario();
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    React.useEffect(() => {
        getUsuario()
    } , []);

    if(!loading){
        return(
            <section>
                <input
                    action="action"
                    onClick={() => {window.history.go(-1); return false;}}
                    type="submit"
                    value="Regresar"
                    className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
                />
    
                <h1 className="my-4">Información del usuario:</h1>
    
                <hr></hr>
    
                <div className="row">
                    <div className="col-3">
    
                        <h4 className="my-2">
                            {
                                usuario.correo
                            }
                        </h4>
    
                        <div className="d-flex flex-column">
                            <span className={`flag ${ usuario.activo == 1 ? '' : 'flag-inactive' }`}>
                                { usuario.activo == 1 ? 'Activo' : 'Inactivo' }
                            </span>
                            <p className="mt-2">
                                <small>
                                    Fecha alta: {usuario.fecha_alta}
                                </small>
                            </p>
                            {
                                (usuario.id_trabajador !== null) &&
                                    <p className="mt-4">
                                        Este usuario le pertenece al trabajador:&nbsp;
                                        <Link to={`/trabajador/${usuario.id_trabajador}`}>
                                            {usuario.nombre} {usuario.apellido_paterno} {usuario.apellido_materno}
                                        </Link>
                                    </p>
                                    
                            }
                        </div>

    
                        <hr></hr>
    
                        <div className="my-2 text-left nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                <Icon icon="fa-solid fa-user mr-2" />
                                Usuario
                            </a>
                            
                            {
                                permisosUsuario.usuario[0].editar == 1 &&
                                <a className="nav-link" href="#" onClick={onClickChangePassword}>
                                    <Icon icon="fa-solid fa-lock-open mr-2" />
                                    Cambiar password
                                </a>
                            }
                            
                            {
                                permisosUsuario.usuario[0].editar == 1 &&
                                    (usuario.activo == 1 ?
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatusAcceso(false)}>
                                        <Icon icon="fa-solid fa-user-slash mr-2" />
                                        Revocar acceso
                                    </a>
                                    :
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatusAcceso(true)}>
                                        <Icon icon="fa-solid fa-user-plus mr-2" />
                                        Activar acceso
                                    </a>)
                            }
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">

                                <h3>
                                    Datos de acceso
                                </h3>
                                {
                                    Object.keys(usuario).length !== 0 && 
                                        <FormularioUsuario 
                                            usuario={usuario}
                                            permisosUsuario={permisosUsuario.usuario}
                                            onSubmit={onSubmitUpdateUsuario}
                                        />
    
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

    } else {
        return(
            <div className="text-center mt-5">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }

}