import React from 'react';

import Icon from '../../components/Icon';

import $ from 'jquery';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min.js';
import addEconomicoService from './../../services/addEconomicoService';
import getDerroterosActivosService from './../../services/getDerroterosActivosService';
import Swal from 'sweetalert2'
import getPrefijosEconomicos from '../../services/catalogos/prefijos_economico/getPrefijosEconomicos';
import { useHistory } from 'react-router';


export default function AddEconomico(){

    let history = useHistory()

    React.useEffect(() => {
        getDerroteros()
        getPrefijosService()
        $('.selectpicker').selectpicker();
    } ,[]);

    const [derroteros, setDerroteros] = React.useState([]);
    const [prefijos, setPrefijos] = React.useState([]);

    function onSubmit(e){
        e.preventDefault();
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        addEconomicoService({values:objectValues})
            .then(response => {
                console.log(response)
                let _respuesta = JSON.parse(response);
                if(_respuesta.response === 'existe'){
                    Swal.fire({
                        title:"El número económico ya existe y no puedes volver a registrarlo",
                        icon: 'info',
                        showCancelButton:true,
                        confirmButtonText:'Entendido'
                    })
                    return;
                }

                if(_respuesta.response === 'success'){
                    Swal.fire({
                        title: 'Economico agregado',
                        icon: "success",
                        timer: 1200,
                        showConfirmButton: false,
                    })
                    $("#formularioEconomico")[0].reset();
                    history.push('/economicos')
                } else {
                    Swal.fire(
                        'Ocurrió un error',
                        'Vuelve a intentarlo',
                        'error'
                    )
                }
            }).catch((error) => {
                console.log("ha petao AddEconomico");
                console.log(error)
                Swal.fire(
                    'Error del servidor',
                    'Inténtalo de nuevo en unos momentos.',
                    'error'
                )
            })
    }

    function getDerroteros(){
        getDerroterosActivosService()
            .then(response => {
                let _respuesta = JSON.parse(response);
                if(_respuesta.response == 'success'){
                    setDerroteros(_respuesta.derroteros);
                    $('.selectpicker').selectpicker('refresh');
                }
            })
            .catch((error) => {
                console.log(error)
                console.log("ha petado getDerroteros()")
                Swal.fire(
                    'Error del servidor',
                    'Inténtalo de nuevo en unos momentos.',
                    'error'
                )
            })
    }

    function getPrefijosService(){
        getPrefijosEconomicos()
            .then(response => {
                let _respuesta = JSON.parse(response);
                if(_respuesta.response === 'success'){
                    setPrefijos(_respuesta.data);
                    $('.selectpicker').selectpicker('refresh');
                }
            })
            .catch((error) => {
                console.log(error)
                console.log("ha petado getPrefijosService()")
                Swal.fire(
                    'Error del servidor',
                    'Inténtalo de nuevo en unos momentos.',
                    'error'
                )
            })
    }

    return (
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
            />

            <h2 className="mt-5">Agregar una económico</h2>

            <p>
                Para agregar un económico, selecciona a que <b>derrotero pertenece</b> y asigna un número. Toma en cuenta que el estatus por defecto del económico será <span className="text-success">activo</span>.
            </p>
            
            <hr></hr>

            <form className="formulario" onSubmit={ onSubmit } id="formularioEconomico">

                {/* ****************************** */}
                {/* BLOQUE DE LOS DATOS PERSONALES */}
                {/* ****************************** */}

                <div className="economico__formulario row">
                    <div className="col-4">
                        <label htmlFor="numero">
                            Número de económico:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="numero"
                            name="numero"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="id_derrotero">
                            Derrotero:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_derrotero"
                            name="id_derrotero"
                            data-live-search="true"
                            required
                        >
                            {
                                derroteros &&
                                derroteros.map( (derrotero) => (
                                    <option key={ derrotero.id_derrotero } value={ derrotero.id_derrotero }>{ derrotero.nombre }</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="id_prefijo_economico">
                            Clave del económico:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_prefijo_economico"
                            name="id_prefijo_economico"
                            data-live-search="true"
                            required
                        >
                            {
                                prefijos &&
                                prefijos.map( (prefijo) => (
                                    <option key={ prefijo.id_prefijo_economico } value={ prefijo.id_prefijo_economico }>
                                        { `${prefijo.clave} - ${prefijo.modalidad}` }
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
               
                {/* ******************************* */}
                {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
                {/* ******************************* */}

                <hr></hr>
                <div className="d-flex justify-content-end">
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus" />
                        <Icon icon="fa-solid fa-hashtag mr-3" />
                        Agregar económico
                    </button>
                </div>


            </form>


        </section>
    );
}