import React from 'react';

import Icon from '../../components/Icon';

import $ from 'jquery';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min.js';
import addDerroteroService from './../../services/addDerroteroService';
import { handleErrorsInputFile } from '../../validators/handleErrorsInputFile';
import Swal from 'sweetalert2'
import { useHistory } from 'react-router';
import { validateNoNumbers } from '../../validators/validateNoNumbers';

export default function AddDerrotero(){
    
    const history = useHistory()

    function onChangeFileInput(e, nombreInput){
        const file = e.target.files[0];
        document.getElementById(nombreInput+'Preview').src = URL.createObjectURL(file);
        $('#'+nombreInput+'Preview').removeClass("hidden")
    }

    function onSubmit(e){
        e.preventDefault();
        // Verificar que el documento PDF no pese mas de X mb y exista
        let documentoPDF = document.getElementById("documentoPDF").files;
        if( handleErrorsInputFile(documentoPDF, "Documento PDF") ){
            return;
        }

        let values = new FormData(e.target)
        // Agregamos el id del usuario para añadir historial en la bd
        const user = JSON.parse(localStorage.getItem("user"))
        const id = user.id_usuario
        values.append('id', id)

        addDerroteroService({values:values})
            .then(response => {
                let _respuesta = JSON.parse(response);
                if(_respuesta.response === 'success'){
                    Swal.fire({
                        title: 'Derrotero agregado',
                        icon: "success",
                        timer: 1500,
                        showConfirmButton: false,
                    })
                    history.push('/derrotero')
                } else {
                    Swal.fire(
                        'Ocurrió un error',
                        'Vuelve a intentarlo',
                        'error'
                    )
                }
            }).catch((error) => {
                console.log("ha petao AddDerrotero");
                console.log(error)
                Swal.fire(
                    'Error del servidor',
                    'Inténtalo de nuevo en unos momentos.',
                    'error'
                )
            })
    }

    return (
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
            />

            <h2 className="mt-5">Agregar un derrotero</h2>

            <p>
                Para agregar un derrotero sólo debes asignarle un nombre y su clave. Toma en cuenta que el estatus por defecto del derrotero será <span className="text-success">activo</span>.
            </p>
            
            <hr></hr>

            <form className="formulario" onSubmit={ onSubmit } id="formularioDerrotero">

                <div className="perfil__fotoPerfil justify-content-center flex-column">
                    <input type="file" id="documentoPDF" className="hidden" accept="application/pdf,application/vnd.ms-excel" name='documentoPDF' onChange={ (e) => onChangeFileInput(e, "documentoPDF") } />
                    <div>
                        <label htmlFor="documentoPDF" className="perfil__cambiarFoto text-center">
                            <h4>
                                Documento PDF
                            </h4>
                            <p>
                                Da clic aquí para cargar el pdf del derrotero
                            </p>
                        </label>
                    </div>
                    <embed id="documentoPDFPreview" className="previewPDF hidden" type="application/pdf" />
                </div>

                {/* ****************************** */}
                {/* BLOQUE DE LOS DATOS PERSONALES */}
                {/* ****************************** */}

                <div className="derrotero__formulario row">
                    <div className="col-6">
                        <label htmlFor="clave">
                            Clave:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="clave"
                            name="clave"
                            required
                        />
                    </div>

                    <div className="col-6">
                        <label htmlFor="nombre">
                            Nombre:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="nombre"
                            name="nombre"
                            required
                        />
                    </div>

                    <div className="col-6">
                        <label htmlFor="descripcion">
                            Descripción:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="descripcion"
                            name="descripcion"
                            placeholder='Opcional'
                        />
                    </div>
                    <div className="col-3">
                        <label htmlFor="fecha_autorizacion">
                            Fecha de autorización:
                        </label>
                        <input 
                            type="date" 
                            className="input"
                            id="fecha_autorizacion"
                            name="fecha_autorizacion"
                            placeholder='Opcional'
                        />
                    </div>
                    <div className="col-3">
                        <label htmlFor="capacidad_vehicular">
                            Capacidad vehicular:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="capacidad_vehicular"
                            name="capacidad_vehicular"
                            maxLength={4}
                            onKeyPress={ validateNoNumbers }
                            placeholder='Opcional'
                        />
                    </div>
                </div>
               
                {/* ******************************* */}
                {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
                {/* ******************************* */}

                <hr></hr>
                <div className="d-flex justify-content-end">
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus" />
                        <Icon icon="fa-solid fa-signs-post mr-3" />
                        Agregar derrotero
                    </button>
                </div>


            </form>


        </section>
    );
}