import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Icon from '../../components/Icon';
import getDerroteroService from "./../../services/getDerroteroService.js";
import changeInformacionDerroteroService from './../../services/changeInformacionDerroteroService';
import changeEstatusDerroteroService from './../../services/changeEstatusDerroteroService';
import FormularioDerrotero from '../../components/FormularioDerrotero';
import FormularioDocumentosDerrotero from '../../components/FormularioDocumentosDerrotero';
import Swal from 'sweetalert2'
import Table from '../../components/Table';
import TableHead from '../../components/Table/TableHead';
import TableBodyHistorial from '../../components/Table/TableBodyHistorial';
import getEconomicosByDerroteroService from '../../services/getEconomicoByDerroteroService';
import ModalAddAsignacionDerroteroEconomico from '../../components/ModalAddAsignacionDerroteroEconomico';
import getEconomicosService from '../../services/getEconomicosService';
import addAsignacionDerroteroEconomico from '../../services/derrotero/addAsignacionDerroteroEconomico';
import $ from 'jquery'

export default function ViewDerrotero({permisosUsuario}) {

    const location = useLocation();
    const id_derrotero = location.pathname.split('/')[2];

    const [derrotero, setDerrotero] = React.useState({});
    const [economicos, setEconomicos] = React.useState({});
    const [economicosByDerrotero, setEconomicosByDerrotero] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    
    const rowsTableEconomicos = [
        { id: 1, name: "Económico" },
        { id: 2, name: "Fecha asignación" },
        { id: 3, name: "Fecha baja" },
        { id: 4, name: "Estatus" }
    ];

    function getEconomicos(){
        getEconomicosService().then(response => {
            console.log(response)
            let _respuesta = JSON.parse(response);
            if(!(_respuesta.response === "error")){
                setEconomicos(_respuesta.data);
            }
        }).catch((error) => {
            console.log(error)
            console.log("ha petado")
        })
    }

    const getDerrotero = () => {
        let data = {
            id_derrotero: id_derrotero
        }
        getDerroteroService({values:data})
            .then(response => {
                let _response = JSON.parse(response);
                console.log(_response.derrotero);
                setDerrotero(_response.derrotero);
                setLoading(false)
            })
            .catch(error => {
                console.log(`Ha petado getDerrotero(): ${error}`)
            })
    }

    const onSubmitUpdateDerrotero = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_derrotero', derrotero.id_derrotero);
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        changeInformacionDerroteroService({values:objectValues})
            .then(response => {
                console.log(response)
                let _response = JSON.parse(response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: 'Exito',
                        text: 'Actualizado correctamente',
                        icon: 'success',
                        timer: 1200,
                        showConfirmButton: false
                    })
                    getDerrotero();
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No actualizaste ningún dato',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error en el servidor',
                    text: 'Inténtalo de nuevo más tarde o contacta al desarrollador',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
    }

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitar el derrotero?' : 'Quieres deshabilitar el derrotero?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_derrotero', id_derrotero)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                changeEstatusDerroteroService({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getDerrotero();
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    const getEconomicosByDerrotero = ()=>{
        let objectValues = {
            id_derrotero: id_derrotero
        }
        setEconomicosByDerrotero([])
        getEconomicosByDerroteroService({values:objectValues}).then(res=>{
            let _res = JSON.parse(res)
            if(!(_res.response === 'error'))
                setEconomicosByDerrotero(_res)
                $(".selectpicker").selectpicker('refresh');
        })
    }

    const onSubmit = (e)=>{
        e.preventDefault()
        let values = new FormData(e.target)
        values.append('id_derrotero',id_derrotero)
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        addAsignacionDerroteroEconomico({values:objectValues}).then(res=>{
            console.log({res})
            let _res = JSON.parse(res)
            if(_res.response === 'existe'){
                let message = "El económico ya está asignado a la concesión, deshabilitala o eliminala para volver a asignarla."
                Swal.fire({
                    title:message,
                    icon: 'info',
                    showCancelButton:true,
                    confirmButtonText:'Entendido'
                })
            } else if(_res.response === 'success'){
                Swal.fire({
                    title:'Agregado correctamente',
                    timer:1200,
                    showConfirmButton:false,
                    icon:'success'
                })
                $("#modalAddAsignacionDerroteroEconomico").modal("hide")
                $("#formAddAsignacionDerroteroEconomico")[0].reset();
                getEconomicosByDerrotero()
            }
        })
    }

    React.useEffect(getEconomicos, []);
    React.useEffect(getDerrotero, []);
    React.useEffect(getEconomicosByDerrotero, []);

    if(!loading){
        return(
            <section>
                <input
                    action="action"
                    onClick={() => {window.history.go(-1); return false;}}
                    type="submit"
                    value="Regresar"
                    className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
                />
    
                <h1 className="my-4">Información del derrotero:</h1>
    
                <hr></hr>
    
                <div className="row">
                    <div className="col-3">
    
                        <h4 className="my-2">
                            {
                                derrotero.nombre
                            }
                        </h4>
    
                        <div className="d-flex flex-column">
                            <span className={`flag ${ derrotero.activo == 1 ? '' : 'flag-inactive' }`}>
                                { derrotero.activo == 1 ? 'Activo' : 'Inactivo' }
                            </span>
                            <p className="mt-2">
                                <small>
                                    Fecha alta: {derrotero.fecha_alta}
                                </small>
                            </p>
                        </div>

    
                        <hr></hr>
    
                        <div className="my-2 text-left nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                <Icon icon="fas fa-signs-post mr-2" />
                                Derrotero
                            </a>

                            <a className="nav-link" id="v-pills-documentos-tab" data-toggle="pill" href="#v-pills-documentos" role="tab" aria-controls="v-pills-documentos" aria-selected="true">
                                <Icon icon="fa-solid fa-file-circle-check fa- mr-2" />
                                Documentos
                            </a>
                            
                            <a className="nav-link" id="v-pills-economicos-tab" data-toggle="pill" href="#v-pills-economicos" role="tab" aria-controls="v-pills-economicos" aria-selected="true">
                                <Icon icon="fa-solid fa-hashtag fa- mr-2" />
                                Económicos
                            </a>
                            
                            {
                                permisosUsuario.derrotero[0].editar == 1 &&
                                    (derrotero.activo == 1 ?
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(false)}>
                                        <Icon icon="fa-solid fa-circle-minus mr-2" />
                                        Deshabilitar
                                    </a>
                                    :
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(true)}>
                                        <Icon icon="fa-solid fa-circle-plus mr-2" />
                                        Habilitar
                                    </a>)
                            }
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">

                                <FormularioDerrotero
                                    derrotero={derrotero}
                                    onSubmit={onSubmitUpdateDerrotero}
                                    permisosUsuario={permisosUsuario.derrotero}
                                />
                            </div>

                            <div className="tab-pane fade" id="v-pills-documentos" role="tabpanel" aria-labelledby="v-pills-documentos-tab">

                                <h3>
                                    Documentos:
                                </h3>

                                {
                                    Object.keys(derrotero).length !== 0 && 
                                        <FormularioDocumentosDerrotero
                                            derrotero={derrotero}
                                            permisosUsuario={permisosUsuario.derrotero}
                                            // rutaServidor={derrotero.rutaServidor}
                                        />
                                    
                                }

                            </div>

                            <div className="tab-pane fade" id="v-pills-economicos" role="tabpanel" aria-labelledby="v-pills-economicos-tab">

                                <h3>
                                    Económicos del derrotero:
                                </h3>

                                <p>
                                    Aquí se muestran los económicos asignados al derrotero.
                                </p>

                                {
                                    (permisosUsuario.derrotero[0].escribir == 1 && Object.keys(economicos).length !== 0) && 
                                    (
                                    <React.Fragment>
                                        <button className="btnPrimario btnPrimario-sm" data-toggle="modal" data-target="#modalAddAsignacionDerroteroEconomico">
                                            <Icon icon="fas fa-circle-plus mr-2" />
                                            Asignar económico
                                        </button>
                                        <ModalAddAsignacionDerroteroEconomico
                                            economicos={economicos}
                                            onSubmit={onSubmit}
                                        />
                                    </React.Fragment>
                                    )
                                }

                                <hr className='my-3'/>
                                
                                {
                                    economicosByDerrotero.length == 0 &&
                                    <p className="text-muted">
                                        No existen económicos asignados al derrotero
                                    </p>
                                }

                                {
                                    economicosByDerrotero.length >= 1 && 
                                    <React.Fragment>
                                        <Table>
                                            <TableHead rows={rowsTableEconomicos} />

                                            <TableBodyHistorial 
                                                elements={economicosByDerrotero} 
                                                type="economicosByDerrotero" 
                                                permisosUsuario={permisosUsuario.vehiculo}
                                                getReloadElements={getEconomicosByDerrotero}
                                            />
                                        </Table>
                                    </React.Fragment>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

    } else {
        return(
            <div className="text-center mt-5">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }

}