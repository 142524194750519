import React from "react";

export default function ModalAddPrefijoEconomico(props){

    return(
        <div className="modal" id="modalAddPrefijoEconomico" tabIndex="-1" role="dialog" aria-labelledby="modalAddPrefijoEconomicoLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={props.onSubmit} id='formAddPrefijoEconomico' className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddPrefijoEconomicoLabel">Agrega un nuevo prefijo económico</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="clave">
                                    Clave:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    id="clave"
                                    name="clave"
                                    placeholder="XXX"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="modalidad">
                                    Modalidad:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    id="modalidad"
                                    name="modalidad"
                                    placeholder="Urbano, colectivo, mexibus..."
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primario">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}