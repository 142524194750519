import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Icon from '../../components/Icon';
import getEconomicoService from "./../../services/getEconomicoService.js";
import changeInformacionEconomicoService from './../../services/changeInformacionEconomicoService';
import changeEstatusEconomicoService from './../../services/changeEstatusEconomicoService';
import getPermisionariosByEconomico from './../../services/economico/getPermisionariosByEconomico';
import FormularioEconomico from '../../components/FormularioEconomico';
import Swal from 'sweetalert2'
import Table from '../../components/Table';
import TableHead from '../../components/Table/TableHead';
import TableBodyHistorial from '../../components/Table/TableBodyHistorial';

export default function ViewEconomico({permisosUsuario}) {

    const location = useLocation();
    const id_economico = location.pathname.split('/')[2];

    const [economico, setEconomico] = React.useState({});
    const [permisionarios, setPermisionarios] = React.useState([]);
    const [loading, setLoading] = React.useState(true);


    const rowsTablePermisionarios = [
        { id: 1, name: "Permisionario" },
        { id: 2, name: "Fecha asignación" },
        { id: 3, name: "Estatus" }
    ];

    const getEconomico = () => {
        let data = {
            id_economico: id_economico
        }
        getEconomicoService({values:data})
            .then(response => {
                let _response = JSON.parse(response);
                console.log(_response.economico);
                setEconomico(_response.economico);
                setLoading(false)
            })
            .catch(error => {
                console.log(`Ha petado getEconomico(): ${error}`)
            })
    }

    const onSubmitUpdateEconomico = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_economico', economico.id_economico);
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        changeInformacionEconomicoService({values:objectValues})
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: 'Exito',
                        text: 'Actualizado correctamente',
                        icon: 'success',
                        timer: 1200,
                        showConfirmButton: false
                    })
                    getEconomico();
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No actualizaste ningún dato',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error en el servidor',
                    text: 'Inténtalo de nuevo más tarde o contacta al desarrollador',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
    }

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitar el economico?' : 'Quieres deshabilitar el economico?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_economico', id_economico)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                changeEstatusEconomicoService({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getEconomico();
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    const getPermisionariosByEconomicoService = () =>{
        let data = {
            id_economico: id_economico
        }
        getPermisionariosByEconomico({values:data})
            .then(response => {
                console.log(response)
                let _response = JSON.parse(response)
                console.log(_response)
                if(_response.response === 'error'){
                } else {
                    setPermisionarios(_response)
                }
            })
            .catch(error => {
                console.log("ha petado getPermisionariosByVehiculo() de ViewVehiculo.js: ", error)
            })
    }

    React.useEffect(() => {
        getEconomico()
        getPermisionariosByEconomicoService()
    } , []);

    if(!loading){
        return(
            <section>
                <input
                    action="action"
                    onClick={() => {window.history.go(-1); return false;}}
                    type="submit"
                    value="Regresar"
                    className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
                />
    
                <h1 className="my-4">Información del económico:</h1>
    
                <hr></hr>
    
                <div className="row">
                    <div className="col-3">
    
                        <h4 className="my-2">
                            {
                                economico.clave_prefijo_economico
                            }
                            {
                                economico.numero
                            }
                        </h4>
    
                        <div className="d-flex flex-column">
                            <span className={`flag ${ economico.activo == 1 ? '' : 'flag-inactive' }`}>
                                { economico.activo == 1 ? 'Activo' : 'Inactivo' }
                            </span>
                            <p className="my-2">
                                Derrotero:&nbsp;
                                <Link to={`/derrotero/${economico.derrotero.id_derrotero}`}>
                                    { economico.derrotero.nombre }
                                </Link>
                            </p>
                            <p className="mt-2">
                                <small>
                                    Fecha alta: {economico.fecha_alta}
                                </small>
                            </p>
                        </div>

    
                        <hr></hr>
    
                        <div className="my-2 text-left nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                <Icon icon="fas fa-hashtag mr-2" />
                                Economico
                            </a>
                            
                            <a className="nav-link" id="v-pills-permisionarios-tab" data-toggle="pill" href="#v-pills-permisionarios" role="tab" aria-controls="v-pills-permisionarios" aria-selected="true">
                                <Icon icon="fas fa-user mr-2" />
                                Permisionarios
                            </a>
                            
                            {
                                permisosUsuario.economico[0].editar == 1 &&
                                    (economico.activo == 1 ?
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(false)}>
                                        <Icon icon="fa-solid fa-circle-minus mr-2" />
                                        Deshabilitar
                                    </a>
                                    :
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(true)}>
                                        <Icon icon="fa-solid fa-circle-plus mr-2" />
                                        Habilitar
                                    </a>)
                            }
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">

                                <FormularioEconomico
                                    economico={economico}
                                    onSubmit={onSubmitUpdateEconomico}
                                    permisosUsuario={permisosUsuario.economico}
                                />
                            </div>
                            <div className="tab-pane fade" id="v-pills-permisionarios" role="tabpanel" aria-labelledby="v-pills-permisionarios-tab">

                                <h3>
                                    Permisionarios del económico:
                                </h3>

                                {
                                    permisionarios.length == 0 &&
                                    <p className="text-muted">
                                        No existen permisionarios vinculados al económico
                                    </p>
                                }

                                {
                                    permisionarios.length >= 1 && 
                                    <React.Fragment>
                                        <Table>
                                            <TableHead rows={rowsTablePermisionarios} />

                                            <TableBodyHistorial elements={permisionarios} type="permisionarios" permisosUsuario={permisosUsuario.vehiculo}  />
                                        </Table>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

    } else {
        return(
            <div className="text-center mt-5">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }

}