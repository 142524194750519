import React from 'react';
import logo from "./../../assets/images/logo.png";
import { calculateAfterProrrata } from '../../validators/calculateAfterProrrata';
import $ from 'jquery'
// Componentes
import { Document, Page, Image, Text, View, BlobProvider, Font } from "@react-pdf/renderer";
import Swal from 'sweetalert2';

export default function PDFGeneratorSiniestro({ siniestro, gastosTotales, gastos, presiniestro, setShowPDF, showPDF, selectedImages, setSelectedImages }){

    // const [showPDF, setShowPDF] = React.useState(false)

    const styles = {
        // Tus estilos existentes
        imageContainer: {
          width: "100%",
          height: "80%",
        },
        image: {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
    };

    const chunkArray = (array, size) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += size) {
            chunks.push(array.slice(i, i + size));
        }
        return chunks;
    };
    const prorratasChunks = (siniestro.prorratas !== undefined && siniestro.prorratas !== null && siniestro.prorratas.length > 0) ? chunkArray(siniestro.prorratas, 2) : null

    Font.register({
        family: 'Open Sans',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 }
        ]
    });

    const SubtituloPDF = (text)=>{
        return (
            <View 
                style={{backgroundColor: "#FF7925", height: 20, width: '100%', borderTopLeftRadius: 10, borderTopRightRadius: 10}}
            >
                <Text style={{ color: 'white', textAlign: 'left', paddingLeft: 10, fontSize: 13, fontWeight: 'bold' }}>
                    { text }
                </Text>
            </View>
        )
    }

    const HeaderPDF = ()=>{
        return (
            <View 
                style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'top', marginBottom: 20 }}
                fixed
            >   
                <View 
                    style={{ width: '100%', alignItems: 'flex-start' }}
                >
                    <Image src={logo} style={{ width: '70px', height: 'auto'}} />
                </View>

                <View style={{alignItems: 'center', justifyContent: 'center', width:'100%'}}>
                    <Text style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 16 }}>
                        Reporte del Siniestro
                    </Text>
                    <Text style={{ color: 'gray', fontSize: 9 }}>
                        {siniestro.modalidad_prefijo_economico}
                    </Text>
                </View>

                <View style={{ justifyContent: 'center', width: '100%'}}>
                    <Text style={{ fontWeight: 'bold', textAlign: 'right', fontSize: 11 }}>
                        ID { siniestro.id_siniestro }
                    </Text>
                    {
                        siniestro.numero_poliza !== null &&
                            <Text style={{ fontWeight: 'bold', textAlign: 'right', fontSize: 11 }}>
                                Póliza: {siniestro.numero_poliza}
                            </Text>
                    }
                    <Text style={{ color: 'gray', textAlign: 'right', fontSize: 9 }}>Impresión:
                        <Text style={{ color: 'black', fontSize: 9 }}> { new Date().toLocaleString() } </Text>
                    </Text>
                </View>
            </View>
              
        )
    }

    const ItemTableVehiculoPDF = ({marca, submarca, modelo, placas, conductor, observacion}) =>{
        return (
            <View 
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    alignItems: 'flex-start', 
                    fontSize: 10, 
                    marginTop: 5, 
                    marginBottom: 5, 
                    paddingLeft:10, 
                    paddingRight: 10 
                }}
            >
                <Text style={{ width: '25%' }}>
                    {`${marca ? marca : '-'}`}
                    { (submarca !== null && `, ${submarca}`) }
                </Text>
                <Text style={{ width: '10%' }}>
                    { modelo }
                </Text>
                <Text style={{ width: '15%' }}>
                    { placas }
                </Text>
                <Text style={{ width: '20%' }}>
                    { conductor }
                </Text>
                <Text style={{ width: '30%' }}>
                    { observacion ? observacion : '-' }
                </Text>
            </View>
        )
    }

    const ItemTableLesionadoPDF = ({nombre, genero, observacion}) =>{
        return (
            <View 
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    alignItems: 'flex-start', 
                    fontSize: 10, 
                    marginTop: 5, 
                    marginBottom: 5, 
                    paddingLeft:10, 
                    paddingRight: 10 
                }}
            >
                <Text style={{ width: '50%' }}>
                    { nombre }
                </Text>
                <Text style={{ width: '20%' }}>
                    { genero }
                </Text>
                <Text style={{ width: '30%' }}>
                    { observacion ? observacion : '-' }
                </Text>
            </View>
        )
    }
    
    const ItemTableBienPDF = ({nombre, observacion}) =>{
        return (
            <View 
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    alignItems: 'flex-start', 
                    fontSize: 10, 
                    marginTop: 5, 
                    marginBottom: 5, 
                    paddingLeft:10, 
                    paddingRight: 10 
                }}
            >
                <Text style={{ width: '50%' }}>
                    { nombre }
                </Text>
                <Text style={{ width: '50%' }}>
                    { observacion ? observacion : '-' }
                </Text>
            </View>
        )
    }
    
    const ItemTableGastoPDF = (gasto) =>{

        if( gasto.es_aportacion === '1' || 
            (gasto.aprobado === '1' && gasto.gasto_contralor !== undefined && gasto.gasto_contralor.aprobado === '1')
        ){
            return (
                <View 
                    style={{ 
                        display: 'flex', 
                        flexDirection: 'row', 
                        alignItems: 'center', 
                        fontSize: 9, 
                        marginTop: 5, 
                        marginBottom: 5, 
                        paddingLeft:10, 
                        paddingRight: 10 
                    }}
                >
                    <View style={{ width: '40%', display: 'flex', flexDirection: 'column'  }}>
                        <Text>
                            { `${gasto.fecha_alta.split(" ")[0]} ` }
                            <Text style={{ color: '#818181' }}>
                                { `${gasto.fecha_alta.split(" ")[1]}` }
                            </Text>
                        </Text>
                        <Text style={{ fontSize: 10 }}>
                            { gasto.nombre_tipo_gasto_siniestro }
                        </Text>
                        <Text style={{ color: '#818181' }}>
                            Método de pago: { gasto.nombre_metodo_pago }
                        </Text>
                    </View>
                    <Text style={{ width: '15%', fontWeight: 'bold', fontSize: 10 }}>
                        { parseFloat(gasto.cantidad).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                    </Text>
                    <Text style={{ width: '15%', paddingRight: 5 }}>
                        { gasto.nombre_abonante_siniestro }
                    </Text>
                    <View style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                        <Text>
                            { (gasto.descripcion !== null && gasto.descripcion !== '') ? gasto.descripcion : '-' }
                        </Text>
                        {
                        (gasto.lesionado !== null && gasto.id_lesionado_siniestro !== null) &&
                            <Text style={{ fontSize: 7 }}>
                                {`Lesionado seleccionado: ${gasto.lesionado.nombre_completo}`}
                            </Text>
                        }
                        {
                        (gasto.vehiculo_afectado !== null && gasto.id_vehiculo_afectado_siniestro !== null) &&
                            <Text style={{ fontSize: 7 }}>
                                {`Vehiculo seleccionado: ${gasto.vehiculo_afectado.nombre_conductor}, Modelo: ${gasto.vehiculo_afectado.modelo}, Placas: ${gasto.vehiculo_afectado.placas}, ${gasto.vehiculo_afectado.nombre_marca_vehiculo ? gasto.vehiculo_afectado.nombre_marca_vehiculo : 'Sin marca'},${gasto.vehiculo_afectado.nombre_submarca_vehiculo ? gasto.vehiculo_afectado.nombre_submarca_vehiculo : 'Sin submarca'}`}
                            </Text>
                        }
                        {
                        (gasto.bien_afectado !== null && gasto.id_bien_afectado_siniestro !== null) &&
                            <Text style={{ fontSize: 7 }}>
                                {`Bien afectado: ${gasto.bien_afectado.nombre_tipo_de_bien}, ${gasto.bien_afectado.descripcion}`}
                            </Text>
                        }
                    </View>
                </View>
            )
        }

        return;

    }

    const TextEstatusPDF = ({estatus}) => {
        let color = '#135290';

        if(estatus === '2')
            color = '#D15253';
        
        if(estatus === '3')
            color = '#FF7925';

        return <Text style={{ marginBottom: 5, fontWeight: 'bold',  color: color}}>
            { (estatus === '1') && 'ABIERTO' }
            { (estatus === '2') && 'CERRADO' }
            { (estatus === '3') && 'INFORMATIVO' }
        </Text>
    }

    const handleMostrarGastos = (values)=>{
        if(values.length === 0)
            return false;

        // Si existen gastos que son aportaciones, mostrarlos.
        if(values.some((value) => (value.es_aportacion === '1')))
            return true;
        
        // Si existen gastos no aprobados, no deben mostrarse
        if(!values.some((value) => 
            (value.gasto_contralor !== undefined && value.gasto_contralor.aprobado === '1')
        ))
            return false;

        return true;
    }

    const GeneratePDFTest = (props) => {
        return (
            <Document>
                <Page 
                    size="A4"
                    style={{ padding: 25, fontSize: 13, fontFamily: 'Open Sans' }}
                >

                    {/* CABECERA DE LA PÁGINA */}
                    { HeaderPDF() }
                    {/* ************************ */}

                    {/* SUBTITULO: DATOS DE LA POLIZA */}
                    { SubtituloPDF("DATOS DE LA PÓLIZA") }
                    {/* ***************************** */}
                    
                    {/* DATOS DE LA POLIZA */}
                    <View style={{ marginTop: 15, marginBottom: 25, marginLeft: 10, marginRight: 10, display: 'flex', justifyContent: 'flex-start', flexDirection: 'row'}}>
                        <View 
                            style={{ width: '18%', fontWeight: 'bold', fontSize: 10,}} 
                        >
                            <Text style={{ marginBottom: 5 }}>
                                Reportado:
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                Siniestro:
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                Permisionario:
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                Operador:
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                Fecha nacimiento:
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                Gestor:
                            </Text>
                        </View>
                        <View 
                            style={{ width: '45%', fontSize: 10 }} 
                        >
                            <Text style={{ marginBottom: 5 }}>
                                { presiniestro.fecha_alta }
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                { siniestro.fecha_siniestro }
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                {siniestro.clave_permisionario} {siniestro.nombre_permisionario} {siniestro.apellido_paterno_permisionario} {siniestro.apellido_materno_permisionario}
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                { siniestro.nombre_operador }
                                { 
                                siniestro.operador_registrado === '1' &&
                                    <Text style={{ color: '#659226', fontWeight: 'bold'}}>
                                        &nbsp;&nbsp;REGISTRADO
                                    </Text>
                                }
                                { 
                                siniestro.operador_registrado === '0' &&
                                    <Text style={{ color: '#D15253', fontWeight: 'bold'}}>
                                        &nbsp;&nbsp;NO REGISTRADO
                                    </Text>
                                }
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                { siniestro.fecha_nacimiento_operador }
                                <Text style={{ color: '#659226', fontWeight: 'bold'}}>
                                    {(siniestro.validez_licencia === '1') && ' LICENCIA VIGENTE '}
                                    {(siniestro.validez_licenciA === '0') && ' LICENCIA NO VIGENTE '}
                                    {siniestro.nombre_tipo_licencia} 
                                </Text>
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                { siniestro.nombre_gestor }
                            </Text>
                        </View>
                        <View 
                            style={{ width: '15%',  fontSize: 10, fontWeight: 'bold'}} 
                        >
                            <Text style={{ marginBottom: 5 }}>
                                Estatus:
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                Unidad:
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                Placas:
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                Número serie:
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                Aseguradora:
                            </Text>
                        </View>
                        <View 
                            style={{ width: '22%',  fontSize: 10, textAlign: 'right' }} 
                        >
                            { TextEstatusPDF({estatus: siniestro.id_estado_siniestro}) }
                            <Text style={{ marginBottom: 5 }}>
                                {siniestro.clave_prefijo_economico}{siniestro.numero_economico}
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                { siniestro.placas }
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                { siniestro.numero_serie }
                            </Text>
                            <Text style={{ marginBottom: 5 }}>
                                { siniestro.nombre_aseguradora }
                            </Text>
                        </View>
                    </View>
                    {/* ****************** */}
                    
                    { 
                    siniestro.vehiculos_afectados !== null && 
                        <>
                            {/* SUBTITULO: VEHÍCULOS RELACIONADOS */}
                            { SubtituloPDF("VEHICULOS RELACIONADOS") }
                            {/* ********************************* */}

                            {/* TABLA DE VEHICULOS RELACIONADOS */}
                            <View style={{ marginBottom: 25 }}>
                                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 10, fontWeight: 'bold', height: 18, backgroundColor: 'gray', color: 'white', paddingLeft: 10, paddingRight: 10 }}>
                                    <Text style={{ width: '25%' }}>
                                        Marca
                                    </Text>
                                    <Text style={{ width: '10%' }}>
                                        Modelo
                                    </Text>
                                    <Text style={{ width: '15%' }}>
                                        Placas
                                    </Text>
                                    <Text style={{ width: '20%' }}>
                                        Conductor
                                    </Text>
                                    <Text style={{ width: '30%' }}>
                                        Observación
                                    </Text>
                                </View>
                                {
                                    siniestro.vehiculos_afectados !== null && siniestro.vehiculos_afectados.length > 0 && 
                                        siniestro.vehiculos_afectados.map((vehiculo)=>{
                                            return ItemTableVehiculoPDF({
                                                marca: vehiculo.nombre_marca_vehiculo,
                                                submarca: vehiculo.nombre_submarca_vehiculo,
                                                modelo: vehiculo.modelo,
                                                placas: vehiculo.placas,
                                                conductor: vehiculo.nombre_conductor,
                                                observacion: vehiculo.descripcion
                                            })
                                        })
                                }

                            </View>
                            {/* ******************************* */}
                        </>
                    }


                    {
                    siniestro.lesionados !== null &&
                        <>
                            {/* SUBTITULO: LESIONADOS */}
                            { SubtituloPDF("LESIONADOS") }
                            {/* ********************************* */}
                            
                            {/* TABLA DE LESIONADOS */}
                            <View style={{ marginBottom: 25 }}>
                                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 10, fontWeight: 'bold', height: 18, backgroundColor: 'gray', color: 'white', paddingLeft: 10, paddingRight: 10 }}>
                                    <Text style={{ width: '50%' }}>
                                        Nombre
                                    </Text>
                                    <Text style={{ width: '20%' }}>
                                        Género
                                    </Text>
                                    <Text style={{ width: '30%' }}>
                                        Observación
                                    </Text>
                                </View>
                                {
                                    siniestro.lesionados !== null && siniestro.lesionados.length > 0 && 
                                        siniestro.lesionados.map((lesionado)=>{
                                            return ItemTableLesionadoPDF({
                                                nombre: lesionado.nombre_completo,
                                                genero: lesionado.nombre_genero,
                                                observacion: lesionado.descripcion
                                            })
                                        })
                                }
                            </View>
                            {/* ******************* */}
                        </>
                    }
                    
                    { 
                    siniestro.bienes_afectados !== null && 
                        <>
                            {/* SUBTITULO: BIENES AFECTADOS */}
                            { SubtituloPDF("BIENES AFECTADOS") }
                            {/* ********************************* */}

                            {/* TABLA DE BIENES AFECTADOS */}
                            <View style={{ marginBottom: 25 }}>
                                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 10, fontWeight: 'bold', height: 18, backgroundColor: 'gray', color: 'white', paddingLeft: 10, paddingRight: 10 }}>
                                    <Text style={{ width: '50%' }}>
                                        Nombre
                                    </Text>
                                    <Text style={{ width: '50%' }}>
                                        Observación
                                    </Text>
                                </View>
                                {
                                    siniestro.bienes_afectados !== null && siniestro.bienes_afectados.length > 0 && 
                                        siniestro.bienes_afectados.map((bien)=>{
                                            return ItemTableBienPDF({
                                                nombre: bien.nombre_tipo_de_bien,
                                                observacion: bien.descripcion
                                            })
                                        })
                                }
                            </View>
                            {/* ******************* */}
                        </>
                    }


                    {/* SUBTITULO: LUGAR DE LOS HECHOS */}
                    { SubtituloPDF("LUGAR DE LOS HECHOS") }
                    {/* ********************************* */}
                    <Text style={{ fontSize: 10, paddingLeft: 10, paddingRight: 10, marginTop: 15, marginBottom: 25 }}>
                        { siniestro.direccion_hechos_siniestro }
                    </Text>

                    {/* SUBTITULO: INFORMES */}
                    { SubtituloPDF("INFORMES") }
                    {/* ********************************* */}
                    <Text style={{ fontSize: 10, paddingLeft: 10, paddingRight: 10, marginTop: 15, marginBottom: 15 }}>
                        <Text style={{ fontWeight: 'bold' }}>
                            Detalle de lo ocurrido:&nbsp;
                        </Text>
                        { presiniestro.descripcion }
                    </Text>

                    <Text style={{ fontSize: 10, paddingLeft: 10, paddingRight: 10, marginTop: 15, marginBottom: 25 }}>
                        <Text style={{ fontWeight: 'bold' }}>
                            Observación cierre (gestor):&nbsp;
                        </Text>
                        { siniestro.descripcion }
                    </Text>

                    {
                    handleMostrarGastos(gastos) &&
                        <>
                            <View break />
                            
                            {/* SUBTITULO: GASTOS DEL SINIESTRO */}
                            { SubtituloPDF("GASTOS DEL SINIESTRO") }
                            {/* ********************************* */}

                            {/* TABLA DE GASTOS DEL SINIESTRO */}
                            <View style={{ marginBottom: 10 }}>
                                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 10, fontWeight: 'bold', height: 18, backgroundColor: 'gray', color: 'white', paddingLeft: 10, paddingRight: 10 }}>
                                    <Text style={{ width: '40%' }}>
                                        Gasto
                                    </Text>
                                    <Text style={{ width: '15%' }}>
                                        Cantidad
                                    </Text>
                                    <Text style={{ width: '15%' }}>
                                        Abonante
                                    </Text>
                                    <Text style={{ width: '30%' }}>
                                        Descripción
                                    </Text>
                                </View>
                                
                                {
                                    gastos !== null && gastos.length > 0 && 
                                        gastos.map((gasto)=>{
                                            return ItemTableGastoPDF(gasto)
                                        })
                                }
                            </View>
                            {/* ******************* */}

                            {/* TOTAL DE LOS GASTOS DEL SINIESTRO */}
                            <View style={{ borderBottom: '1px black solid', width: '100%', marginBottom: 8 }} />
                            <View style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, fontSize: 10, marginBottom: 25 }}>
                                <Text style={{ width: '40%', fontWeight: 'bold' }}>
                                    TOTAL
                                </Text>
                                <Text style={{ width: '15%' }}>
                                    { parseFloat(gastosTotales.total_siniestro).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                                </Text>
                            </View>
                            {/* ********************************* */}
                        </>
                    }

                    {
                    selectedImages !== null && selectedImages.length > 0 &&
                        <React.Fragment>
                        <View break />
                        {SubtituloPDF("FOTOS DEL SINIESTRO")}

                        {
                            selectedImages.map((element, i) => {
                                return (
                                  <React.Fragment>
                                    <View style={styles.imageContainer} key={i}>
                                      <Image src={element} style={styles.image} />
                                    </View>
                                    {
                                      (i < selectedImages.length - 1) &&
                                        <View break key={`break-${i}`} />
                                    }
                                  </React.Fragment>
                                );
                              })
                        }
                        </React.Fragment>
                    }


                    <View break />

                    {/* SUBTITULO: TOTAL ACCIDENTE */}
                    <View>
                        { SubtituloPDF("TOTAL ACCIDENTE") }
                    </View>
                    {/* ********************************* */}

                    {/* INFORMACIÓN DEL TOTAL DEL ACCIDENTE Y PRORRATA */}
                    <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 25, marginTop: 15 }}>
                        <Text style={{ width: '30%', fontWeight: 'bold', textAlign: 'center' }}>
                            Costo total:
                        </Text>
                        <Text style={{ width: '30%', textAlign: 'center' }}>
                            { parseFloat(gastosTotales.total_siniestro).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                        </Text>
                        <Text style={{ width: '30%', fontWeight: 'bold', textAlign: 'center' }}>
                            Apoyo prorrata:
                        </Text>
                        { 
                            siniestro.prorratas === null && 
                                <Text style={{ width: '10%', color: '#D15253', textAlign: 'center' }}>
                                    NO
                                </Text>
                        }
                        { 
                            siniestro.prorratas !== undefined && siniestro.prorratas !== null && siniestro.prorratas.some((value) => value.porcentaje_empresa === '0') && !siniestro.prorratas.some((value) => value.porcentaje_empresa !== '0') &&
                                <Text style={{ width: '10%', color: '#D15253', textAlign: 'center' }}>
                                    NO
                                </Text>
                        }
                        { 
                            siniestro.prorratas !== undefined && siniestro.prorratas !== null && siniestro.prorratas.some((value) => value.porcentaje_empresa !== '0') &&
                                <Text style={{ width: '10%', color: '#659226', textAlign: 'center' }}>
                                    SI
                                </Text>
                        }
                    </View>
                    {/* ********************************************** */}


                    {/* VERIFICAMOS PRIMERO SI EL SINIESTRO YA ESTA CERRADO Y YA TIENE ALGUNAS PRORRATAS ELEGIDAS */}
                    {
                        siniestro.prorratas !== undefined && siniestro.prorratas !== null && siniestro.prorratas.length > 0 &&
                        <>
                            {/* SUBTITULO: LUGAR DE LOS HECHOS */}
                            { SubtituloPDF("PASÓ A UNA ENTIDAD LEGAL?") }
                            {/* ********************************* */}
                            <Text style={{ fontSize: 10, paddingLeft: 10, paddingRight: 10, marginTop: 15, marginBottom: 25 }}>
                                {
                                siniestro.id_entidad_legal_siniestro === null ?
                                    'No, el siniestro no fue a una entidad legal'
                                    :
                                    `Si, el siniestro fue a la entidad legal: ${siniestro.nombre_entidad_legal_siniestro}`
                                        
                                }
                            </Text>
                        </>
                    }
                    
                    {/* APORTACIONES Y DEDUCIBLE DE PRORRATA */}
                    <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 25, marginTop: 15 }}>
                        <View style={{ width: '100%' }}>
                            {/* SUBTITULO: TOTAL ACCIDENTE */}
                            { SubtituloPDF("APORTACIONES ANTES DE PRORRATA") }
                            {/* ********************************* */}
                            <View style={{ marginLeft: 10, marginRight: 10, marginTop: 15, fontSize: 11 }}>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text>
                                        Permisionario
                                    </Text>
                                    <Text>
                                        { parseFloat(gastosTotales.total_permisionario).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                                    </Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text>
                                        Operador
                                    </Text>
                                    <Text>
                                        { parseFloat(gastosTotales.total_operador).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                                    </Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text>
                                        Empresa
                                    </Text>
                                    <Text>
                                        { parseFloat(gastosTotales.total_empresa).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                                    </Text>
                                </View>
                                <View style={{ borderBottom: '1px black solid', width: '100%', marginTop:5,  marginBottom: 5 }} />
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontWeight: 'bold' }}>
                                    <Text>
                                        TOTAL
                                    </Text>
                                    <Text>
                                        { parseFloat(gastosTotales.total_siniestro).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                                    </Text>
                                </View>
                            </View>
                        </View>
                        {
                        // siniestro.prorratas !== undefined && siniestro.prorratas !== null && siniestro.prorratas.length === 1 &&
                            // <View style={{ width: '50%', paddingLeft: 10 }}>
                            //     {/* SUBTITULO: TOTAL ACCIDENTE */}
                            //     { SubtituloPDF("DEDUCIBLE PRORRATA A PAGAR") }
                            //     {/* ********************************* */}
                            //     {/* <View style={{ marginLeft: 10, marginRight: 10, marginTop: 15, fontSize: 11 }}>
                            //         <View style={{ display: 'flex', flexDirection: 'row' }}>
                            //             <Text style={{ width: '40%' }}>
                            //                 Permisionario
                            //             </Text>
                            //             <Text style={{ width: '30%', textAlign: 'center' }}>
                            //                 { `${parseFloat(siniestro.prorratas[0].porcentaje_permisionario).toLocaleString('en-US')} %` }
                            //             </Text>
                            //             <Text style={{ width: '30%', textAlign: 'right' }}>
                            //                 { 
                            //                 parseFloat(
                            //                     calculateAfterProrrata(
                            //                         gastosTotales.total_siniestro, 
                            //                         siniestro.prorratas[0].porcentaje_permisionario))
                            //                     .toLocaleString('en-US', { style: 'currency', currency: 'USD' }) 
                            //                 }
                            //             </Text>
                            //         </View>
                            //         <View style={{ display: 'flex', flexDirection: 'row' }}>
                            //             <Text style={{ width: '40%' }}>
                            //                 Operador
                            //             </Text>
                            //             <Text style={{ width: '30%', textAlign: 'center' }}>
                            //                 { `${parseFloat(siniestro.prorratas[0].porcentaje_operador).toLocaleString('en-US')} %` }
                            //             </Text>
                            //             <Text style={{ width: '30%', textAlign: 'right' }}>
                            //                 { 
                            //                 parseFloat(
                            //                     calculateAfterProrrata(
                            //                         gastosTotales.total_siniestro, 
                            //                         siniestro.prorratas[0].porcentaje_operador))
                            //                     .toLocaleString('en-US', { style: 'currency', currency: 'USD' }) 
                            //                 }
                            //             </Text>
                            //         </View>
                            //         <View style={{ display: 'flex', flexDirection: 'row' }}>
                            //             <Text style={{ width: '40%' }}>
                            //                 Empresa
                            //             </Text>
                            //             <Text style={{ width: '30%', textAlign: 'center' }}>
                            //                 { `${parseFloat(siniestro.prorratas[0].porcentaje_empresa).toLocaleString('en-US')} %` }
                            //             </Text>
                            //             <Text style={{ width: '30%', textAlign: 'right' }}>
                            //                 { 
                            //                 parseFloat(
                            //                     calculateAfterProrrata(
                            //                         gastosTotales.total_siniestro, 
                            //                         siniestro.prorratas[0].porcentaje_empresa))
                            //                     .toLocaleString('en-US', { style: 'currency', currency: 'USD' }) 
                            //                 }
                            //             </Text>
                            //         </View>
                            //         <View style={{ borderBottom: '1px black solid', width: '100%', marginTop:5, marginBottom: 8 }} />
                            //         <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontWeight: 'bold' }}>
                            //             <Text style={{ width: '40%' }}>
                            //                 TOTAL
                            //             </Text>
                            //             <Text style={{ width: '30%', textAlign: 'center' }}>
                            //                 100.00 %
                            //             </Text>
                            //             <Text style={{ width: '30%', textAlign: 'right' }}>
                            //                 { parseFloat(gastosTotales.total_siniestro).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                            //             </Text>
                            //         </View>
                            //     </View> */}
                            // </View>
                        }
                    </View>
                    {
                    siniestro.prorratas !== undefined && siniestro.prorratas !== null && siniestro.prorratas.length >= 1 &&
                        <View>
                            {prorratasChunks.map((chunk, chunkIndex) => (
                            <View
                                key={`chunk-${chunkIndex}`}
                                style={{ display: "flex", flexDirection: "row", marginBottom: 25, marginTop: 15 }}
                            >
                                {chunk.map((prorrata, i) => (
                                <View
                                    key={prorrata.nombre}
                                    style={{ width: "50%" }}
                                >
                                    {/* SUBTITULO: TOTAL ACCIDENTE */}
                                    { SubtituloPDF(`PRORRATA: ${prorrata.nombre}`) }
                                    {/* ********************************* */}
                                    <View style={{ marginLeft: 10, marginRight: 10, marginTop: 15, fontSize: 11 }}>
                                        <Text style={{ marginBottom: 10 }}>
                                                Aplicada el : {prorrata.fecha_cierre_siniestro}
                                        </Text>
                                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Text style={{ width: '40%' }}>
                                                Permisionario
                                            </Text>
                                            <Text style={{ width: '30%', textAlign: 'center' }}>
                                                { `${parseFloat(prorrata.porcentaje_permisionario).toLocaleString('en-US')} %` }
                                            </Text>
                                            <Text style={{ width: '30%', textAlign: 'right' }}>
                                                { 
                                                parseFloat(
                                                    prorrata.total_permisionario_prorrata)
                                                    .toLocaleString('en-US', { style: 'currency', currency: 'USD' }) 
                                                }
                                            </Text>
                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Text style={{ width: '40%' }}>
                                                Operador
                                            </Text>
                                            <Text style={{ width: '30%', textAlign: 'center' }}>
                                                { `${parseFloat(prorrata.porcentaje_operador).toLocaleString('en-US')} %` }
                                            </Text>
                                            <Text style={{ width: '30%', textAlign: 'right' }}>
                                                { 
                                                parseFloat(
                                                    prorrata.total_operador_prorrata)
                                                    .toLocaleString('en-US', { style: 'currency', currency: 'USD' }) 
                                                }
                                            </Text>
                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Text style={{ width: '40%' }}>
                                                Empresa
                                            </Text>
                                            <Text style={{ width: '30%', textAlign: 'center' }}>
                                                { `${parseFloat(prorrata.porcentaje_empresa).toLocaleString('en-US')} %` }
                                            </Text>
                                            <Text style={{ width: '30%', textAlign: 'right' }}>
                                                { 
                                                parseFloat(
                                                    prorrata.total_empresa_prorrata)
                                                    .toLocaleString('en-US', { style: 'currency', currency: 'USD' }) 
                                                }
                                            </Text>
                                        </View>
                                        <View style={{ borderBottom: '1px black solid', width: '100%', marginTop:5, marginBottom: 8 }} />
                                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontWeight: 'bold' }}>
                                            <Text style={{ width: '40%' }}>
                                                TOTAL
                                            </Text>
                                            <Text style={{ width: '30%', textAlign: 'center' }}>
                                                100.00 %
                                            </Text>
                                            <Text style={{ width: '30%', textAlign: 'right' }}>
                                                { parseFloat(prorrata.total_siniestro_prorrata).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                ))}
                            </View>
                            ))}
                        </View>
                    }
                    {/* ************************************ */}

                    {
                    siniestro.prorratas !== undefined && siniestro.prorratas !== null && siniestro.prorratas.length >= 3 &&
                        <View break />
                    }

                    {/* FIRMAS DEL DOCUMENTO */}
                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: 50, fontSize: 10 }}>
                        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '33%', marginLeft: 10, marginRight: 10 }}>
                            <Text style={{ fontWeight: 'bold' }}>
                                Gestor
                            </Text>
                            <Text>
                                { siniestro.nombre_gestor }
                            </Text>
                            <View style={{ borderBottom: '1px black solid', width: '100%', marginBottom: 5, marginTop: 40 }} />
                            <Text>
                                Firma
                            </Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '33%', marginLeft: 10, marginRight: 10 }}>
                            <Text style={{ fontWeight: 'bold' }}>
                                Vo.Bo. Permisionario
                            </Text>
                            <Text>
                                {siniestro.clave_permisionario} {siniestro.nombre_permisionario} {siniestro.apellido_paterno_permisionario} {siniestro.apellido_materno_permisionario}
                            </Text>
                            <View style={{ borderBottom: '1px black solid', width: '100%', marginBottom: 5, marginTop: 40 }} />
                            <Text>
                                Firma
                            </Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '33%', marginLeft: 10, marginRight: 10 }}>
                            <Text style={{ fontWeight: 'bold' }}>
                                Vo.Bo. Comisión Revisadora
                            </Text>
                            <Text>
                                &nbsp;
                            </Text>
                            <View style={{ borderBottom: '1px black solid', width: '100%', marginBottom: 5, marginTop: 40 }} />
                            <Text>
                                Nombre y firma
                            </Text>
                        </View>

                    </View>
                    {/* ******************** */}
                    
                    {/* PIE DE PÁGINA */}
                    <Text style={{ position: 'absolute', fontSize: 9, bottom: 15, left: 0, right: 0, textAlign: 'center', color:'grey' }} render={({ pageNumber, totalPages}) => (`${pageNumber} / ${totalPages}`)} fixed/>
                    {/* ********************************* */}
                </Page>
            </Document>
        )
    }

    const blob = () =>{
        return (
            <BlobProvider document={<GeneratePDFTest/>}>
                {({ blob, url, loading, error }) =>{
                    try{

                        if (loading) {
                            return (
                                <button className="btn btn-sm btn-light mt-2 d-flex align-items-center justify-content-center text-muted w-100">
                                    <i className="fa-solid fa-spinner fa-spin mr-2"></i>
                                    Generando...
                                </button>
                            )
                        } else if (error) {
                            return <div>Ocurrió un error: {error.message}</div>;
                        } else {
                            // Swal.hideLoading()
                            // Swal.fire({title: "PDF GENERADO"})
                            return (
                                <button 
                                    onClick={()=>{
                                        const pdfWindow = window.open();
                                        pdfWindow.location.href = url;
                                        setTimeout(()=>{
                                            setShowPDF(false);
                                            setSelectedImages([])
                                        }, 200)
                                    }}
                                    className="btn btn-sm btn-light mt-2 d-flex align-items-center justify-content-center text-muted w-100"
                                >
                                    <i className="fa-solid fa-download mr-2"></i>
                                    Descargar PDF
                                </button>
                            )
                        }
                    } catch(err){
                        Swal.fire({
                            icon:"warning",
                            title: "Hubo un error al generar el PDF",
                            text:'Vuelve a intentarlo'
                        }).then(()=>{
                            window.location.reload()
                        })
                    }
                }}
            </BlobProvider>
        )
    }

    return(
        <>
            {
                showPDF && blob()
            }
        </>
    );
}