import React from 'react';
import "./FormularioPermisionario.css"
import $ from 'jquery';
import { validateNoNumbers } from './../../validators/validateNoNumbers';
import Icon from '../../components/Icon';
import config from '../../config';
const URL_API = config.urlApi;

export default function FormularioPermisionario({permisionario, permisosUsuario, onSubmit}) {

    const [tiposPermisionarios, setTiposPermisionarios] = React.useState([]);
    const [responsablesPermisionarios, setResponsablesPermisionarios] = React.useState([]);
    const [estadosCiviles, setEstadosCiviles] = React.useState([]);
    const [estadosPermisionarios, setEstadosPermisionarios] = React.useState([]);
    const [municipiosPermisionarios, setMunicipiosPermisionarios] = React.useState([]);
    const [generosPermisionarios, setGenerosPermisionarios] = React.useState([]);
    const [religiones, setReligiones] = React.useState([]);

    function getDataForm(){
        $.ajax({
            url: URL_API+'controlador/getDataAltaPermisionario.controlador.php',
            success: function(response){
                let _respuesta = JSON.parse(response)
                if(!(_respuesta.response === 'error')){
                    setTiposPermisionarios(_respuesta.tipos_permisionarios)
                    setResponsablesPermisionarios((!(_respuesta.responsables) ? [] : _respuesta.responsables))
                    setEstadosCiviles(_respuesta.estados_civiles)
                    setEstadosPermisionarios(_respuesta.estados)
                    setGenerosPermisionarios(_respuesta.generos)
                    setReligiones(_respuesta.creencia_religiosa)
                    setDataFormulario()
                    $('.selectpicker').selectpicker('refresh')
                }
            }

        })
    }

    function getMunicipios(estado){
        $.ajax({
            data:{estado:estado},
            url: URL_API+'controlador/getMunicipios.controlador.php',
            type:"POST",
            success: function(response){
                let _respuesta = JSON.parse(response)
                if(!(_respuesta.response === 'error')){
                    setMunicipiosPermisionarios(_respuesta.municipios)
                    $("#id_municipio").attr("disabled",false)
                    document.getElementById("id_municipio").value = permisionario.id_municipio;
                    $('.selectpicker').selectpicker('refresh')
                }
            }
        })
    }

    function onChangePermisionarioEstado(value){
        getMunicipios(value)
    }

    function onChangeEstado(value){
        document.getElementById("id_estado").value = value;
        getMunicipios(value)
    }

    function setDataFormulario(){
        document.getElementById('nombre').value = permisionario.nombre;
        document.getElementById('apellido_paterno').value = permisionario.apellido_paterno;
        document.getElementById('apellido_materno').value = permisionario.apellido_materno;
        document.getElementById('fecha_nacimiento').value = permisionario.fecha_nacimiento;
        document.getElementById('id_genero').value = permisionario.id_genero;
        document.getElementById('celular').value = permisionario.celular;
        document.getElementById('telefono').value = permisionario.telefono;
        document.getElementById('correo').value = permisionario.correo;
        document.getElementById('curp').value = permisionario.curp;

        document.getElementById('calle').value = permisionario.calle;
        document.getElementById('numero_exterior').value = permisionario.numero_exterior;
        document.getElementById('numero_interior').value = permisionario.numero_interior;
        document.getElementById('referencias').value = permisionario.referencias;
        document.getElementById('colonia').value = permisionario.colonia;
        onChangeEstado(permisionario.id_estado)
        document.getElementById('codigo_postal').value = permisionario.codigo_postal;
        
        document.getElementById('id_tipo_permisionario').value = permisionario.id_tipo_permisionario;
        document.getElementById('rfc').value = permisionario.rfc;
        document.getElementById('numero_seguridad_social').value = permisionario.numero_seguridad_social;
        document.getElementById('id_responsable_permisionario').value = permisionario.id_responsable_permisionario;
        document.getElementById('fecha_ingreso').value = permisionario.fecha_ingreso;
        document.getElementById('id_estado_civil').value = permisionario.id_estado_civil;
        document.getElementById('id_creencia_religiosa').value = permisionario.id_creencia_religiosa;
        document.getElementById('esposa').value = permisionario.esposa;
        document.getElementById('contacto_emergencia').value = permisionario.contacto_emergencia;
        document.getElementById('comentarios').value = permisionario.comentarios;
        
        document.getElementById('tiene_alergias').value = permisionario.tiene_alergias;
        document.getElementById('tiene_enfermedad_cronica').value = permisionario.tiene_enfermedad_cronica;
        document.getElementById('tiene_familiar_discapacidad').value = permisionario.tiene_familiar_discapacidad;
        document.getElementById('tiene_hijos').value = permisionario.tiene_hijos;

        $('.selectpicker').selectpicker('refresh');

        if(permisosUsuario[0].editar === "0"){
            $("form :input, :input[form]").prop("readonly", true);
        }
    }

    React.useEffect(() => {
        $('.selectpicker').selectpicker()
        getDataForm();
    }, []);

    return(
        <form className="formulario" onSubmit={ (e) => onSubmit(e) }>

            {/* ****************************** */}
            {/* BLOQUE DE LOS DATOS PERSONALES */}
            {/* ****************************** */}
        
            <div className="datosPersonales mb-5">
                <h3>
                    Datos personales
                </h3>

                <div className="datosPersonales__formulario row">
                    <div className="col-4">
                        <label htmlFor="nombre">
                            Nombre(s):
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="nombre"
                            name="nombre"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="apellido_paterno">
                            Apellido paterno:
                        </label>
                        <input 
                            type="text" 
                            className="input" 
                            id="apellido_paterno"
                            name="apellido_paterno"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="apellido_materno">
                            Apellido materno:
                        </label>
                        <input 
                            type="text"
                            className="input" 
                            id="apellido_materno"
                            name="apellido_materno"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="fecha_nacimiento">
                            Fecha nacimiento:
                        </label>
                        <input 
                            type="date"
                            className="input"
                            id="fecha_nacimiento"
                            name="fecha_nacimiento"
                            required
                            />
                    </div>
                    <div className="col-4">
                        <label htmlFor="id_genero">
                            Género:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_genero"
                            name="id_genero"
                            required
                        >
                            {
                                generosPermisionarios && 
                                generosPermisionarios.map( genero => (
                                    <option key={ genero.id_genero } value={ genero.id_genero }>
                                        { genero.nombre }
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="celular">
                            Número de celular:
                        </label>
                        <input 
                            type="tel" 
                            placeholder="#########" 
                            className="input"
                            onKeyPress={ validateNoNumbers }
                            maxLength="15"
                            id="celular"
                            name="celular"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="telefono">
                            Número local:
                        </label>
                        <input 
                            type="tel" 
                            className="input" 
                            onKeyPress={ validateNoNumbers }
                            maxLength="15"
                            id="telefono"
                            name="telefono"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="correo">
                            Correo electrónico:
                        </label>
                        <input 
                            type="email" 
                            className="input"
                            id="correo"
                            name="correo"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="curp">
                            CURP:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="curp"
                            maxLength="20"
                            name="curp"
                            required
                        />
                    </div>
                </div>

            </div>
            
            
            {/* ********************************* */}
            {/* BLOQUE DE LOS DATOS DEL DOMICILIO */}
            {/* ********************************* */}
        
            <div className="datosDomicilio my-5">
                <h3>
                    Datos de domicilio
                </h3>

                <div className="datosDomicilio__formulario row">
                    <div className="col-4">
                        <label htmlFor="calle">
                            Calle:
                        </label>
                        <input 
                            type="text"
                            className="input"
                            id="calle"
                            name="calle"
                            required
                        />
                    </div>
                    <div className="col-2">
                        <label htmlFor="numero_exterior">
                            No. exterior:
                        </label>
                        <input 
                            type="num" 
                            placeholder="###" 
                            className="input" 
                            onKeyPress={ validateNoNumbers }
                            id="numero_exterior"
                            name="numero_exterior"
                            required
                        />
                    </div>
                    <div className="col-2">
                        <label htmlFor="numero_interior">
                            No. interior:
                        </label>
                        <input 
                            type="num" 
                            placeholder="opcional" 
                            className="input" 
                            onKeyPress={ validateNoNumbers }
                            id="numero_interior"
                            name="numero_interior"
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="referencias">
                            Referencias:
                        </label>
                        <input 
                            type="text"
                            className="input"
                            id="referencias"
                            name="referencias"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="colonia">
                            Colonia:
                        </label>
                        <input 
                            type="text"
                            className="input"
                            id="colonia"
                            name="colonia"
                            required
                        />
                    </div>
                    <div className="col-2">
                        <label htmlFor="id_estado">
                            Estado:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_estado"
                            name="id_estado"
                            required
                            data-live-search="true"
                            onChange = { (e) => onChangePermisionarioEstado(e.target.value) }
                        >   
                            <option value="">Elige un estado</option>
                            {
                                estadosPermisionarios.map( (estado) => (
                                    <option key={ estado.id_estado } value={ estado.id_estado } >
                                        { estado.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>
                    <div className="col-2">
                        <label htmlFor="id_municipio">
                            Municipio:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_municipio"
                            name="id_municipio"
                            required
                            data-live-search="true"
                            disabled
                        >
                            <option value="">
                                Elige un municipio
                            </option>
                            {
                                municipiosPermisionarios.map( (municipio) => (
                                    <option key={ municipio.id_municipio } value={ municipio.id_municipio } >
                                        { municipio.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="codigo_postal">
                            Código postal:
                        </label>
                        <input 
                            type="num" 
                            placeholder="#####" 
                            className="input"
                            onKeyPress={ validateNoNumbers }
                            maxLength="10"
                            id="codigo_postal"
                            name="codigo_postal"
                            required
                        />
                    </div>
                </div>

            </div>
            
            
            {/* ************************************* */}
            {/* BLOQUE DE LOS DATOS DE IDENTIFICACIÓN */}
            {/* ************************************* */}
        
            <div className="datosIdentificacion my-5">
                <h3>
                    Datos de identificación
                </h3>

                <div className="datosIdentificacion__formulario row">
                    <div className="col-4">
                        <label htmlFor="id_tipo_permisionario">
                            Tipo permisionario:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_tipo_permisionario"
                            name="id_tipo_permisionario"
                            required
                        >
                            <option value="">
                                ¿Qué tipo de permisionario es?
                            </option>
                            {
                                tiposPermisionarios &&
                                tiposPermisionarios.map( (tipo, index) => (
                                    <option key={ tipo.id_tipo_permisionario } value={ tipo.id_tipo_permisionario }>
                                        { tipo.nombre }
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="rfc">
                            RFC:
                        </label>
                        <input 
                            type="text"
                            placeholder="XXXX#####XXX" 
                            className="input"
                            id="rfc"
                            name="rfc"
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="numero_seguridad_social">
                            # Seg. social:
                        </label>
                        <input 
                            type="num" 
                            placeholder="Opcional" 
                            className="input" 
                            onKeyPress={ validateNoNumbers }
                            id="numero_seguridad_social"
                            name="numero_seguridad_social"
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="id_responsable_permisionario">
                            Responsable:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_responsable_permisionario"
                            name="id_responsable_permisionario"
                            data-live-search="true"
                        >
                            <option value="">
                                Opcional
                            </option>
                            {
                                responsablesPermisionarios.map( (responsable, index) => (
                                    <option key={ responsable.id_permisionario } value={ responsable.id_permisionario }>
                                        { responsable.nombre+' '+responsable.apellido_paterno+' '+responsable.apellido_materno }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="fecha_ingreso">
                            Fecha ingreso:
                        </label>
                        <input 
                            type="date"
                            className="input" 
                            id="fecha_ingreso"
                            name="fecha_ingreso"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="id_estado_civil">
                            Estado civil:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_estado_civil"  
                            name="id_estado_civil"
                            required
                        >
                            {
                                estadosCiviles &&
                                estadosCiviles.map( (estadoCivil) => (
                                    <option key={ estadoCivil.id_estado_civil } value={ estadoCivil.id_estado_civil }>
                                        { estadoCivil.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>

                    <div className="col-4">
                        <label htmlFor="id_creencia_religiosa">
                            Religión:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_creencia_religiosa"
                            value=""
                            name='id_creencia_religiosa'
                        >
                            <option value="">
                                Opcional
                            </option>
                            {
                                religiones &&
                                religiones.map( (religion) => (
                                    <option key={ religion.id_creencia_religiosa } value={ religion.id_creencia_religiosa }>
                                        { religion.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>

                    <div className="col-4">
                        <label htmlFor="esposa">
                            Sí tiene esposa, escríbelo aquí:
                        </label>
                        <input 
                            type="text"
                            placeholder="Opcional" 
                            className="input"
                            id="esposa"
                            name="esposa"
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="contacto_emergencia">
                            Contacto de emergencia:
                        </label>
                        <input 
                            type="num"
                            onKeyPress={ validateNoNumbers }
                            placeholder="Opcional" 
                            className="input"
                            id="contacto_emergencia"
                            name="contacto_emergencia"
                        />
                    </div>
                    <div className="col-6">
                            <label htmlFor="tiene_alergias">
                                ¿Tiene alergias?, escríbelo aquí:
                            </label>
                            <input 
                                type="text"
                                placeholder="Opcional" 
                                className="input"
                                id="tiene_alergias"
                                name="tiene_alergias"
                            />
                        </div>
                        <div className="col-6">
                            <label htmlFor="tiene_enfermedad_cronica">
                                ¿Tiene alguna enfermedad crónica?, escríbelo aquí:
                            </label>
                            <input 
                                type="text"
                                placeholder="Opcional" 
                                className="input"
                                id="tiene_enfermedad_cronica"
                                name="tiene_enfermedad_cronica"
                            />
                        </div>
                        <div className="col-6">
                            <label htmlFor="tiene_familiar_discapacidad">
                                ¿Tiene familiar con discapacidad física o motriz?, escríbelo aquí:
                            </label>
                            <input 
                                type="text"
                                placeholder="Opcional" 
                                className="input"
                                id="tiene_familiar_discapacidad"
                                name="tiene_familiar_discapacidad"
                            />
                        </div>
                        <div className="col-6">
                            <label htmlFor="tiene_hijos">
                                ¿Tiene hijos?, escribe cuántos:
                            </label>
                            <input 
                                type="num"
                                placeholder="Opcional" 
                                className="input"
                                onKeyPress={ validateNoNumbers }
                                maxLength={2}
                                id="tiene_hijos"
                                name="tiene_hijos"
                            />
                        </div>
                    <div className="col-12">
                        <label htmlFor="comentarios">
                            Anotaciones:
                        </label>
                        <textarea 
                            rows={5}
                            cols={5}
                            type="text"
                            placeholder="(Opcional) Aquí puedes anotar cualquier detalle o comentario extra sobre el permisionario" 
                            className="input textarea"
                            id="comentarios"
                            name="comentarios"
                        />
                    </div>
                </div>

            </div>

            {/* ******************************* */}
            {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
            {/* ******************************* */}

            {
                permisosUsuario[0].editar !== "0" &&
                <React.Fragment>
                    <hr></hr>
                    <div className="d-flex justify-content-end">
                        <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                            <Icon icon="fa-solid fa-pen-to-square mr-3" />
                            Actualizar información del permisionario
                        </button>
                    </div>
                </React.Fragment>
            }


        </form>
    )
}