import React from "react";
// import getMarcasVehiculos from "../../../services/catalogos/marca_vehiculo/getMarcasVehiculos";
// import $ from 'jquery';

export default function ModalAddSubmarcaVehiculo(props){

    // const [marcasVehiculo, setMarcasVehiculo] = React.useState([]);

    // const getMarcasVehiculosService = ()=>{
    //     getMarcasVehiculos({values:{}})
    //         .then(res=>{
    //             console.log(res)
    //             let _res = JSON.parse(res)
    //             if(_res.response === 'success'){
    //                 console.log(_res.data)
    //                 setMarcasVehiculo(_res.data)
    //                 $('.selectpicker').selectpicker('refresh');
    //             }
    //         })
    // }

    // React.useEffect(getMarcasVehiculosService, []);

    return(
        <div className="modal" id="modalAddSubmarcaVehiculo" tabIndex="-1" role="dialog" aria-labelledby="modalAddSubmarcaVehiculoLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={props.onSubmit} id='formAddSubmarcaVehiculo' className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddSubmarcaVehiculoLabel">Agrega una nueva submarca</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="nombre">
                                    Nombre:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    id="nombre"
                                    name="nombre"
                                    required
                                />
                            </div>
                            <div className="col-12">
                                <label htmlFor="id_marca_vehiculo">
                                    Marca:
                                </label>
                                <select 
                                    className="input selectpicker"
                                    id="id_marca_vehiculo"
                                    name="id_marca_vehiculo"
                                    data-live-search="true"
                                    required
                                >  
                                    <option value="">Elige una marca</option>
                                    {
                                        props.marcasVehiculo && 
                                        props.marcasVehiculo.map( metodo_pago => (
                                            <option key={ metodo_pago.id_marca_vehiculo } value={ metodo_pago.id_marca_vehiculo }>
                                                { metodo_pago.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primario">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}