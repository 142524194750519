import $ from 'jquery';

import config from './../config';
const URL_API = config.urlApi+'controlador/changePassword.controlador.php';

export default function changePasswordService({values}){
    const user = JSON.parse(localStorage.getItem("user"))
    const id = user.id_usuario
    values = {...values, id: id}

    return $.ajax({
        url: URL_API,
        type: 'POST',
        data: values
    })
}