import React from 'react';
import "./FormularioUsuario.css"
import $ from 'jquery';
import { validateNoNumbers } from './../../validators/validateNoNumbers';
import getPermisosSistemaService from "./../../services/getPermisosSistemaService.js";
import getTiposUsuarioService from "./../../services/getTiposUsuarioService.js";
import Swal from 'sweetalert2'
import Icon from '../../components/Icon';

export default function FormularioUsuario({ usuario,  permisosUsuario, onSubmit }) {

    const[tiposUsuarios, setTiposUsuarios] = React.useState([]);
    const[permisosSistema, setPermisosSistema] = React.useState([]);

    React.useEffect(() => {
        $('.selectpicker').selectpicker()
        getTiposUsuario();
        getPermisosSistema();
    }, []);

    function getPermisosSistema(){
        getPermisosSistemaService().then(response => {
            let _respuesta = JSON.parse(response);
            // console.log(_respuesta)
            if(_respuesta.response === "error"){
                Swal.fire(
                    'Error al cargar permisos',
                    'No existe ningún permiso o hubo un error en la conexión, inténtalo de nuevo recargando la página.',
                    'error'
                )
            } else {
                setPermisosSistema(_respuesta.permisos);
                $('.selectpicker').selectpicker('refresh')
                if(usuario !== undefined){
                    setDataFormulario();
                }
            }

        }).catch((error) => {
            console.log(error)
            console.log("ha petado")
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    function getTiposUsuario(){
        getTiposUsuarioService().then(response => {
            let _respuesta = JSON.parse(response);
            // console.log(_respuesta)
            if(_respuesta.response === "error"){
                Swal.fire(
                    'Error al cargar tipos de usuarios',
                    'No existe ningún tipo de usuario o hubo un error en la conexión, inténtalo de nuevo recargando la página.',
                    'error'
                )
            } else {
                setTiposUsuarios(_respuesta.tipos_usuarios);
                $('.selectpicker').selectpicker('refresh')
            }

        }).catch((error) => {
            console.log(error)
            console.log("ha petado")
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    const setDataFormulario = () =>{
        console.log({usuario})
        document.getElementById("celular").value = usuario.celular;
        document.getElementById("correo").value = usuario.correo;
        document.getElementById("nombre_usuario").value = usuario.nombre_usuario;
        document.getElementById("id_tipo_usuario").value = usuario.id_tipo_usuario;
        if(usuario.permisos){
            usuario.permisos.forEach(permiso => {
                $('#permisosSistema option[value="' + permiso.id_permiso +'_leer"]').prop('selected', (permiso.leer === "1"));
                $('#permisosSistema option[value="' + permiso.id_permiso +'_escribir"]').prop('selected', (permiso.escribir === "1"));
                $('#permisosSistema option[value="' + permiso.id_permiso +'_editar"]').prop('selected', (permiso.editar === "1"));
                $('#permisosSistema option[value="' + permiso.id_permiso +'_eliminar"]').prop('selected', (permiso.eliminar === "1"));
            })
        }

        $('.selectpicker').selectpicker('refresh')

        if(permisosUsuario[0].editar === "0"){
            $("form :input, :input[form]").prop("readonly", true);
        }

    }

    return(
        <form id="formularioUsuario" className="formulario" onSubmit={ (e) => onSubmit(e) }>

            {/* ****************************** */}
            {/* BLOQUE DE LOS DATOS PERSONALES */}
            {/* ****************************** */}

            <div className="datosUsuario__formulario row">
                <div className="col-4">
                    <label htmlFor="correo">
                        Correo:
                    </label>
                    <input 
                        type="email" 
                        className="input"
                        id="correo"
                        name="correo"
                        required
                    />
                </div>
                <div className="col-4">
                    <label htmlFor="celular">
                        Número celular:
                    </label>
                    <input 
                        type="text"
                        className="input" 
                        id="celular"
                        name="celular"
                        maxLength="15"
                        onKeyPress={ validateNoNumbers }
                        placeholder="Opcional"
                    />
                </div>
                <div className="col-4">
                    <label htmlFor="nombre_usuario">
                        Nombre de usuario:
                    </label>
                    <input 
                        type="text"
                        className="input" 
                        id="nombre_usuario"
                        name="nombre_usuario"
                        placeholder="Opcional"
                    />
                </div>
                <div className="col-6">
                    <label htmlFor="id_tipo_usuario">
                        Tipo de usuario:
                    </label>
                    <select 
                        className="input selectpicker"
                        id="id_tipo_usuario"
                        name="id_tipo_usuario"
                        required
                    >
                        {
                            tiposUsuarios &&
                            tiposUsuarios.map( (tipoUsuario) => (
                                <option key={ tipoUsuario.id_tipo_usuario } value={ tipoUsuario.id_tipo_usuario }>{ tipoUsuario.nombre }</option>
                            ))
                        }
                    </select>
                </div>
                <div className="col-6">
                    <label htmlFor="permisosSistema">
                        Permisos del sistema:
                    </label>
                    <select 
                        className="input selectpicker"
                        id="permisosSistema"
                        multiple
                        name="permisosSistema"
                        required
                    >
                        {
                            permisosSistema && 
                            permisosSistema.map( (permiso) => (
                                <optgroup key={ permiso.id_permiso } label={ permiso.nombre_modulo }>
                                    <option value={ `${permiso.id_permiso}_leer` }>Leer</option>
                                    <option value={ `${permiso.id_permiso}_escribir` }>Escribir</option>
                                    <option value={ `${permiso.id_permiso}_editar` }>Editar</option>
                                    <option value={ `${permiso.id_permiso}_eliminar` }>Eliminar</option>
                                </optgroup>
                            ))
                        }
                    </select>
                </div>

                {
                    (usuario === undefined) &&
                        <React.Fragment>
                            <div className="col-11">
                                <label htmlFor="password">
                                    Contraseña:
                                </label>
                                <input 
                                    type="password"
                                    className="input" 
                                    id="password"
                                    name="password"
                                    required
                                />
                            </div>
                            <div className="col-1 align-items-center d-flex">
                                <button
                                    onClick={() => {
                                        if($('#password').attr("type") === "text"){
                                            $('#password').attr('type', 'password');
                                            $('.iconTogglePassword').removeClass( "fa-eye-slash" );
                                            $('.iconTogglePassword').addClass( "fa-eye" );
                                        }else if($('#password').attr("type") === "password"){
                                            $('#password').attr('type', 'text');
                                            $('.iconTogglePassword').addClass( "fa-eye-slash" );
                                            $('.iconTogglePassword').removeClass( "fa-eye" );
                                        }
                                    }}
                                    className="btn btn-outline-secondary btn-lg" 
                                    type="button" 
                                    id="button-addon1">
                                        <Icon icon="fa-solid fa-eye iconTogglePassword" />
                                </button>
                            </div>
                        </React.Fragment>
                }
            </div>
            
            {/* ******************************* */}
            {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
            {/* ******************************* */}

            {
                (permisosUsuario[0].editar !== "0" || permisosUsuario[0].escribir !== "0") &&
                <React.Fragment>
                    <hr></hr>
                    <div className="d-flex justify-content-end">
                        
                            {
                                (usuario !== undefined) && permisosUsuario[0].editar !== "0" &&

                                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                                        <Icon icon="fa-solid fa-pen-to-square mr-3" />
                                        Actualizar información del usuario
                                    </button>
                            }                             
                            { 

                                (usuario === undefined) && permisosUsuario[0].escribir !== "0" &&
                                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                                        <Icon icon="fa-solid fa-plus" />
                                        <Icon icon="fa-solid fa-user mr-3" />
                                        Agregar usuario
                                    </button>

                            }
                            
                        
                    </div>
                </React.Fragment>
            }


        </form>
    )
}