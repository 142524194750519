import React from "react";
import{ validateNoNumbers } from './../../../validators/validateNoNumbers'

export default function ModalAddReglaProrrateo(props){

    return(
        <div className="modal" id="modalAddReglaProrrateo" tabIndex="-1" role="dialog" aria-labelledby="modalAddReglaProrrateoLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={props.onSubmit} id='formAddReglaProrrateo' className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddReglaProrrateoLabel">Agrega una nueva regla para la prorrata</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="fecha_inicio">
                                    Fecha inicio:
                                </label>
                                <input 
                                    type="date" 
                                    className="input"
                                    id="fecha_inicio"
                                    name="fecha_inicio"
                                    placeholder="dd/mm/aaaa"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="minimo_uma">
                                    Mīnimo a cubrir en UMAs:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    id="minimo_uma"
                                    name="minimo_uma"
                                    onKeyPress={ validateNoNumbers }
                                    placeholder="50"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="maximo_uma">
                                    Máximo a cubrir en UMAs:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    id="maximo_uma"
                                    name="maximo_uma"
                                    onKeyPress={ validateNoNumbers }
                                    placeholder="2000"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primario">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}