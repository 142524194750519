import React from 'react';
import { useLocation } from 'react-router-dom';
import Icon from '../../components/Icon';
import $ from 'jquery';
import FormularioPermisionario from '../../components/FormularioPermisionario';
import FormularioDocumentosPermisionario from '../../components/FormularioDocumentosPermisionario';
import Swal from 'sweetalert2'
// import changePasswordService from './../../services/changePasswordService';
// import changeEstatusUsuarioService from './../../services/changeEstatusUsuarioService';
import getPermisionarioService from "./../../services/getPermisionarioService.js";
import changeInformacionPermisionarioService from './../../services/changeInformacionPermisionarioService';
import changeEstatusPermisionarioService from './../../services/changeEstatusPermisionarioService';

import ModalAsignacion from '../../components/ModalAsignacion';
import Table from "./../../components/Table";
import TableHead from "./../../components/Table/TableHead";
import TableBodyHistorial from "../../components/Table/TableBodyHistorial";

import getConcesionesService from "./../../services/getConcesionesService.js";
import getConcesionesByPermisionarioService from "./../../services/getConcesionesByPermisionarioService.js";
import addAsignacionPermisionarioByConcesionService from "./../../services/addAsignacionPermisionarioByConcesionService.js";

import getVehiculosActivosService from "./../../services/getVehiculosActivosService.js";
import getVehiculosByPermisionarioService from "./../../services/getVehiculosByPermisionarioService.js";
import addAsignacionVehiculoByPermisionarioService from "./../../services/addAsignacionVehiculoByPermisionarioService.js";

import getEconomicosActivosService from "./../../services/getEconomicosActivosService.js";
import getEconomicosByPermisionarioService from "./../../services/getEconomicosByPermisionarioService.js";
import addAsignacionEconomicoByPermisionarioService from "../../services/addAsignacionEconomicoByPermisionarioService.js";

import getOperadoresActivosService from "./../../services/getOperadoresActivosService.js";
import getOperadoresByPermisionarioService from "./../../services/getOperadoresByPermisionarioService.js";
import addAsignacionOperadorByPermisionarioService from "../../services/addAsignacionOperadorByPermisionarioService.js";
import usePermisionario from '../../hooks/usePermisionario';

export default function ViewPermisionario({permisosUsuario}) {

    const location = useLocation();
    const id_permisionario = location.pathname.split('/')[2];

    const [permisionario, setPermisionario] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    const { historialBajasAltas, getHistorialBajasAltasService } = usePermisionario();
    const rowsTableBajasAltas = [
        { id: 1, name: "Cambio" },
        { id: 2, name: "Hecho por" },
        { id: 3, name: "Fecha" }
    ];

    function getPermisionario(){
        setLoading(true)
        setPermisionario({})
        let data = {
            id_permisionario: id_permisionario
        }
        getPermisionarioService({values:data})
            .then(response => {
                console.log(response)
                let _response = JSON.parse(response);
                console.log(_response)
                if(_response.response === 'success'){
                    setPermisionario(_response.permisionario);
                    setLoading(false)
                }
            })
            .catch(error => {
                console.log(error);
                console.log("ha petado")
            })
    }

    function onSubmitUpdatePermisionario(e){
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_permisionario', permisionario.id_permisionario);
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        changeInformacionPermisionarioService({values:objectValues})
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: 'Exito',
                        text: 'Actualizado correctamente',
                        icon: 'success',
                        timer: 1200,
                        showConfirmButton: false
                    })
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No actualizaste ningún dato',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => {
                console.log(error)
                Swal.fire({
                    title: 'Error en el servidor',
                    text: 'Inténtalo de nuevo más tarde o contacta al desarrollador',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
    }

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitar al permisionario?' : 'Quieres deshabilitar al permisionario?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_permisionario', id_permisionario)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                console.log({objectValues})
                changeEstatusPermisionarioService({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getAllDataPermisionario()
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    // FUNCIONES PARA EL HISTORIAL DE CONCESIONES

    const [concesiones, setConcesiones] = React.useState([]);
    const [concesionesByPermisionario, setConcesionesByPermisionario] = React.useState([]);

    const rowsTableConcesiones = [
        { id: 1, name: "Concesión" },
        { id: 2, name: "Fecha asignación" },
        { id: 3, name: "Estatus" }
    ];

    const getConcesiones = () =>{
        getConcesionesService()
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response)
                // console.log(_response)
                if(_response.response === 'error'){}else{
                    setConcesiones(_response.data)
                }
            })
            .catch(error => {
                console.log("ha petado getConcesiones() de ViewConcesion.js: ", error)
            })
    }

    const getConcesionesByPermisionario = () =>{
        setLoading(true)
        setConcesionesByPermisionario([])
        let data = {
            id_permisionario: id_permisionario
        }
        getConcesionesByPermisionarioService({values:data})
            .then(response => {
                let _response = JSON.parse(response)
                // console.log(_response)
                if(_response.response === 'error'){
                } else {
                    setConcesionesByPermisionario(_response)
                }
                setLoading(false);
            })
            .catch(error => {
                console.log("ha petado getConcesionesByPermisionario() de ViewPermisionario.js: ", error)
            })
    }

    const onSubmitAsignacionConcesion = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_permisionario', id_permisionario)
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        Swal.fire({
            title: "¿Seguro que deseas asignarle la concesión?",
            text: "De ahora en adelante, este permisionario será el dueño de esta concesion.",
            showCancelButton: true,
            confirmButtonText: "Asignar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                addAsignacionPermisionarioByConcesionService({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getConcesionesByPermisionario();
                        $("#modalAsignacionConcesion").modal('hide');
                        Swal.fire({
                            title: 'Exito',
                            text: 'Asignado correctamente',
                            icon: 'success',
                            timer: 1200,
                            showConfirmButton: false
                        })
                        // window.location.reload();
                    }
                    
                })
            }
        })
    }

    // ///////////////////////////////////////////////
    // FUNCIONES PARA EL HISTORIAL DE VEHICULOS

    const [vehiculos, setVehiculos] = React.useState([]);
    const [vehiculosByPermisionario, setVehiculosByPermisionario] = React.useState([]);

    const rowsTableVehiculos = [
        { id: 1, name: "Vehículo" },
        { id: 2, name: "Fecha asignación" },
        { id: 3, name: "Estatus" }
    ];

    const getVehiculosActivos = () =>{
        getVehiculosActivosService()
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response)
                // console.log(_response)
                if(_response.response === 'error'){}else{
                    setVehiculos(_response)
                }
            })
            .catch(error => {
                console.log("ha petado getVehiculosActivos() de ViewPermisionario.js: ", error)
            })
    }

    const getVehiculosByPermisionario = () =>{
        setLoading(true)
        setVehiculosByPermisionario([])
        let data = {
            id_permisionario: id_permisionario
        }
        getVehiculosByPermisionarioService({values:data})
            .then(response => {
                let _response = JSON.parse(response)
                // console.log(_response)
                if(_response.response === 'error'){
                } else {
                    setVehiculosByPermisionario(_response)
                }
                setLoading(false);
            })
            .catch(error => {
                console.log("ha petado getVehiculosByPermisionario() de ViewConcesion.js: ", error)
            })
    }

    const onSubmitAsignacionVehiculo = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_permisionario', id_permisionario)
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        Swal.fire({
            title: "¿Seguro que deseas asignarle el vehículo?",
            text: "De ahora en adelante, este permisionario será el dueño del vehículo.",
            showCancelButton: true,
            confirmButtonText: "Asignar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                addAsignacionVehiculoByPermisionarioService({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getVehiculosByPermisionario();
                        $("#modalAsignacionVehiculo").modal('hide');
                        Swal.fire({
                            title: 'Exito',
                            text: 'Asignado correctamente',
                            icon: 'success',
                            timer: 1200,
                            showConfirmButton: false
                        })
                    }
                    
                })
            }
        })
    }

    // ///////////////////////////////////////////////

    // FUNCIONES PARA EL HISTORIAL DE ECONOMICOS

    const [economicos, setEconomicos] = React.useState([]);
    const [economicosByPermisionario, setEconomicosByPermisionario] = React.useState([]);

    const rowsTableEconomicos = [
        { id: 1, name: "Económico" },
        { id: 2, name: "Fecha asignación" },
        { id: 3, name: "Estatus" }
    ];

    const getEconomicosActivos = () =>{
        getEconomicosActivosService()
            .then(response => {
                console.log(response)
                let _response = JSON.parse(response)
                // console.log(_response)
                if(_response.response === 'error'){}else{
                    setEconomicos(_response.data)
                }
            })
            .catch(error => {
                console.log("ha petado getEconomicos() de ViewPermisionario.js: ", error)
            })
    }

    const getEconomicosByPermisionario = () =>{
        setLoading(true)
        setEconomicosByPermisionario([])
        let data = {
            id_permisionario: id_permisionario
        }
        getEconomicosByPermisionarioService({values:data})
            .then(response => {
                let _response = JSON.parse(response)
                // console.log(_response)
                if(_response.response === 'error'){
                } else {
                    setEconomicosByPermisionario(_response)
                }
                setLoading(false);
            })
            .catch(error => {
                console.log("ha petado getEconomicosByPermisionario() de ViewPermisionario.js: ", error)
            })
    }

    const onSubmitAsignacionEconomico = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_permisionario', id_permisionario)
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        Swal.fire({
            title: "¿Seguro que deseas asignarle el económico?",
            text: "De ahora en adelante, este permisionario será el dueño del económico.",
            showCancelButton: true,
            confirmButtonText: "Asignar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                addAsignacionEconomicoByPermisionarioService({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getEconomicosByPermisionario();
                        $("#modalAsignacionEconomico").modal('hide');
                        Swal.fire({
                            title: 'Exito',
                            text: 'Asignado correctamente',
                            icon: 'success',
                            timer: 1200,
                            showConfirmButton: false
                        })
                    }
                    
                })
            }
        })
    }

    // ///////////////////////////////////////////////

    // FUNCIONES PARA EL HISTORIAL DE OPERADORES

    const [operadores, setOperadores] = React.useState([]);
    const [operadoresByPermisionario, setOperadoresByPermisionario] = React.useState([]);

    const rowsTableOperadores = [
        { id: 1, name: "Trabajador" },
        { id: 2, name: "Fecha asignación" },
        { id: 3, name: "Estatus" }
    ];

    const getOperadoresActivos = () =>{
        getOperadoresActivosService()
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response)
                console.log(_response)
                if(_response.response === 'error'){}else{
                    
                    setOperadores(_response)
                }
            })
            .catch(error => {
                console.log("ha petado getOperadores() de ViewPermisionario.js: ", error)
            })
    }

    const getOperadoresByPermisionario = () =>{
        setLoading(true)
        setOperadoresByPermisionario([])
        let data = {
            id_permisionario: id_permisionario
        }
        getOperadoresByPermisionarioService({values:data})
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response)
                // console.log(_response)
                if(_response.response === 'error'){
                } else {
                    setOperadoresByPermisionario(_response)
                }
                setLoading(false);
            })
            .catch(error => {
                console.log("ha petado getOperadoresByPermisionario() de ViewPermisionario.js: ", error)
            })
    }

    const onSubmitAsignacionOperador = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_permisionario', id_permisionario)
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        Swal.fire({
            title: "¿Seguro que deseas asignarle el operador?",
            text: "De ahora en adelante, este permisionario será patrón del operador y podrá operar sus vehículos.",
            showCancelButton: true,
            confirmButtonText: "Asignar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                addAsignacionOperadorByPermisionarioService({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        $("#modalAsignacionOperador").modal('hide');
                        getOperadoresByPermisionario();
                        Swal.fire({
                            title: 'Exito',
                            text: 'Asignado correctamente',
                            icon: 'success',
                            timer: 1200,
                            showConfirmButton: false
                        })
                    }
                    
                })
            }
        })
    }

    // ///////////////////////////////////////////////

    const getAllDataPermisionario = () => {
        getPermisionario()
    
        getConcesiones()
        getConcesionesByPermisionario()
    
        getVehiculosActivos()
        getVehiculosByPermisionario()
    
        getEconomicosActivos()
        getEconomicosByPermisionario()
    
        getOperadoresActivos()
        getOperadoresByPermisionario()

        getHistorialBajasAltasService({id_permisionario: id_permisionario})
    }

    React.useEffect(getAllDataPermisionario , []);

    if(!loading){
        return(
            <section>
                <input
                    action="action"
                    onClick={() => {window.history.go(-1); return false;}}
                    type="submit"
                    value="Regresar"
                    className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
                />
    
                <h1 className="my-4">Información del permisionario:</h1>
    
                <hr></hr>
    
                <div className="row">
                    <div className="col-3 text-center">

                        <img className='img-fluid' id="fotoPerfilTrabajador" alt="Foto de perfil permisionario" src={permisionario.urlImageProfile} />
    
                        <h4 className="my-2">
                            {
                                permisionario.key ?
                                `Clave: ${permisionario.key}` :
                                `Sin clave asignada`
                            }
                        </h4>
    
                        <div className="d-flex flex-column align-items-center">
                            <span className={`flag ${ permisionario.activo == 1 ? '' : 'flag-inactive' }`}>
                                { permisionario.activo == 1 ? 'Activo' : 'Inactivo' }
                            </span>
                            {
                                historialBajasAltas.length > 0 &&
                                    <p className="mt-2">
                                        <small>
                                            Fecha de baja: <br/>{historialBajasAltas[0].fecha_alta}
                                        </small>
                                    </p>
                            }

                            <p className="mt-2">
                                <small>
                                    Fecha de ingreso: {permisionario.date}
                                </small>
                            </p>
                            <p className="mt-2">
                                <small>
                                    Alta en sistema: {permisionario.fecha_alta}
                                </small>
                            </p>
                        </div>

    
                        <hr></hr>
    
                        <div className="my-2 text-left nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                <Icon icon="fa-solid fa-user mr-2" />
                                Permisionario
                            </a>
                            <a className="nav-link" id="v-pills-documentos-tab" data-toggle="pill" href="#v-pills-documentos" role="tab" aria-controls="v-pills-documentos" aria-selected="false">
                                <Icon icon="fa-solid fa-file-circle-check mr-2" />
                                Documentos
                            </a>
                            <a className="nav-link" id="v-pills-concesiones-tab" data-toggle="pill" href="#v-pills-concesiones" role="tab" aria-controls="v-pills-concesiones" aria-selected="true">
                                <Icon icon="fa-solid fa-file-invoice mr-2" />
                                Concesiones
                            </a>
                            <a className="nav-link" id="v-pills-unidades-tab" data-toggle="pill" href="#v-pills-unidades" role="tab" aria-controls="v-pills-unidades" aria-selected="true">
                                <Icon icon="fa-solid fa-truck-moving mr-2" />
                                Unidades
                            </a>
                            <a className="nav-link" id="v-pills-economicos-tab" data-toggle="pill" href="#v-pills-economicos" role="tab" aria-controls="v-pills-economicos" aria-selected="true">
                                <Icon icon="fa-solid fa-route mr-2" />
                                Económicos
                            </a>
                            <a className="nav-link" id="v-pills-clave-tab" data-toggle="pill" href="#v-pills-clave" role="tab" aria-controls="v-pills-clave" aria-selected="true">
                                <Icon icon="fa-solid fa-id-card mr-2" />
                                Operadores
                            </a>
                            <a className="nav-link" id="v-pills-historial-bajas-tab" data-toggle="pill" href="#v-pills-historial-bajas" role="tab" aria-controls="v-pills-historial-bajas" aria-selected="true">
                                <Icon icon="fa-solid fa-exchange-alt mr-2" />
                                Historial bajas/altas
                            </a>
                            {
                                permisosUsuario.permisionario[0].editar == 1 &&
                                    (permisionario.activo == 1 ?
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(false)}>
                                        <Icon icon="fa-solid fa-user-slash mr-2" />
                                        Deshabilitar permisionario
                                    </a>
                                    :
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(true)}>
                                        <Icon icon="fa-solid fa-user-plus mr-2" />
                                        Habilitar permisionario
                                    </a>)
                            }
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">

                                {
                                    Object.keys(permisionario).length !== 0 && 
                                        <FormularioPermisionario 
                                            permisionario={permisionario}
                                            permisosUsuario={permisosUsuario.permisionario}
                                            onSubmit={onSubmitUpdatePermisionario}
                                        />
    
                                }
                            </div>

                            <div className="tab-pane fade" id="v-pills-documentos" role="tabpanel" aria-labelledby="v-pills-documentos-tab">

                                {
                                    Object.keys(permisionario).length !== 0 && 
                                        <FormularioDocumentosPermisionario 
                                            permisionario={permisionario}
                                            permisosUsuario={permisosUsuario.permisionario}
                                        />
    
                                }
                            </div>

                            <div className="tab-pane fade" id="v-pills-concesiones" role="tabpanel" aria-labelledby="v-pills-concesiones-tab">

                                <div className="d-flex justify-content-between align-items-center">
                                    <h3>
                                        Concesiones que ha tenido el permisionario:
                                    </h3>

                                    {
                                        permisosUsuario.permisionario[0].escribir == 1 &&
                                        (
                                        <React.Fragment>
                                            <button className="btnPrimario btnPrimario-sm" data-toggle="modal" data-target="#modalAsignacionConcesion">
                                                <Icon icon="fas fa-circle-plus mr-2" />
                                                Asignar concesión
                                            </button>
                                            <ModalAsignacion
                                                titulo={`Asignar una concesión al permisionario: ${permisionario.nombre}`}
                                                labelSelect="Seleccione una concesión:"
                                                dataSelect={concesiones}
                                                // dataFiltered={concesionesByPermisionario}
                                                idSelect="id_concesion"
                                                idModal="modalAsignacionConcesion"
                                                onSubmit={onSubmitAsignacionConcesion}
                                            />
                                        </React.Fragment>
                                        )
                                    }
                                </div>

                                {
                                    concesionesByPermisionario.length == 0 &&
                                    <p className="text-muted">
                                        No existen concesiones asignadas a este permisionario.
                                    </p>
                                }

                                {
                                    concesionesByPermisionario.length >= 1 && 
                                    <React.Fragment>
                                        <Table>
                                            <TableHead rows={rowsTableConcesiones} />

                                            <TableBodyHistorial elements={concesionesByPermisionario} type="concesiones" permisosUsuario={permisosUsuario.permisionario}  />
                                        </Table>
                                    </React.Fragment>
                                }
                            </div>

                            <div className="tab-pane fade" id="v-pills-unidades" role="tabpanel" aria-labelledby="v-pills-unidades-tab">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h3>
                                        Unidades que ha tenido el permisionario:
                                    </h3>

                                    {
                                        permisosUsuario.permisionario[0].escribir == 1 &&
                                        (
                                        <React.Fragment>
                                            <button className="btnPrimario btnPrimario-sm" data-toggle="modal" data-target="#modalAsignacionVehiculo">
                                                <Icon icon="fas fa-circle-plus mr-2" />
                                                Asignar vehículo
                                            </button>
                                            <ModalAsignacion
                                                titulo={`Asignar un vehículo al permisionario: ${permisionario.nombre}`}
                                                labelSelect="Seleccione un vehículo:"
                                                dataSelect={vehiculos}
                                                dataFiltered={vehiculosByPermisionario}
                                                idSelect="id_vehiculo"
                                                idModal="modalAsignacionVehiculo"
                                                onSubmit={onSubmitAsignacionVehiculo}
                                            />
                                        </React.Fragment>
                                        )
                                    }
                                </div>

                                {
                                    vehiculosByPermisionario.length == 0 &&
                                    <p className="text-muted">
                                        No existen vehículos vinculados a este permisionario
                                    </p>
                                }

                                {
                                    vehiculosByPermisionario.length >= 1 && 
                                    <React.Fragment>
                                        <Table>
                                            <TableHead rows={rowsTableVehiculos} />

                                            <TableBodyHistorial elements={vehiculosByPermisionario} type="vehiculos" permisosUsuario={permisosUsuario.permisionario}  />
                                        </Table>
                                    </React.Fragment>
                                }
                            </div>

                            <div className="tab-pane fade" id="v-pills-economicos" role="tabpanel" aria-labelledby="v-pills-economicos-tab">

                                <div className="d-flex justify-content-between align-items-center">
                                    <h3>
                                        Económicos que ha tenido el permisionario:
                                    </h3>

                                    {
                                        permisosUsuario.permisionario[0].escribir == 1 &&
                                        (
                                        <React.Fragment>
                                            <button className="btnPrimario btnPrimario-sm" data-toggle="modal" data-target="#modalAsignacionEconomico">
                                                <Icon icon="fas fa-circle-plus mr-2" />
                                                Asignar económico
                                            </button>
                                            <ModalAsignacion
                                                titulo={`Asignar un económico al permisionario: ${permisionario.nombre}`}
                                                labelSelect="Seleccione un económico:"
                                                dataSelect={economicos}
                                                // dataFiltered={economicosByPermisionario}
                                                idSelect="id_economico"
                                                idModal="modalAsignacionEconomico"
                                                onSubmit={onSubmitAsignacionEconomico}
                                            />
                                        </React.Fragment>
                                        )
                                    }
                                </div>

                                {
                                    economicosByPermisionario.length == 0 &&
                                    <p className="text-muted">
                                        No existen económicos vinculados a este permisionario
                                    </p>
                                }

                                {
                                    economicosByPermisionario.length >= 1 && 
                                    <React.Fragment>
                                        <Table>
                                            <TableHead rows={rowsTableEconomicos} />

                                            <TableBodyHistorial elements={economicosByPermisionario} type="economicos" permisosUsuario={permisosUsuario.permisionario}  />
                                        </Table>
                                    </React.Fragment>
                                }
                            </div>

                            <div className="tab-pane fade" id="v-pills-clave" role="tabpanel" aria-labelledby="v-pills-clave-tab">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h3>
                                        Operadores del permisionario:
                                    </h3>

                                    {
                                        permisosUsuario.permisionario[0].escribir == 1 &&
                                        (
                                        <React.Fragment>
                                            <button className="btnPrimario btnPrimario-sm" data-toggle="modal" data-target="#modalAsignacionOperador">
                                                <Icon icon="fas fa-circle-plus mr-2" />
                                                Asignar operador
                                            </button>
                                            <ModalAsignacion
                                                titulo={`Asignar un operador al permisionario: ${permisionario.nombre}`}
                                                labelSelect="Seleccione un trabajador:"
                                                dataSelect={operadores}
                                                dataFiltered={operadoresByPermisionario}
                                                idSelect="id_trabajador"
                                                idModal="modalAsignacionOperador"
                                                onSubmit={onSubmitAsignacionOperador}
                                            />
                                        </React.Fragment>
                                        )
                                    }
                                </div>

                                {
                                    operadoresByPermisionario.length == 0 &&
                                    <p className="text-muted">
                                        No existen operadores vinculados a este permisionario
                                    </p>
                                }

                                {
                                    operadoresByPermisionario.length >= 1 && 
                                    <React.Fragment>
                                        <Table>
                                            <TableHead rows={rowsTableOperadores} />

                                            <TableBodyHistorial elements={operadoresByPermisionario} type="trabajadores" permisosUsuario={permisosUsuario.permisionario}  />
                                        </Table>
                                    </React.Fragment>
                                }
                            </div>
                            
                            <div className="tab-pane fade" id="v-pills-historial-bajas" role="tabpanel" aria-labelledby="v-pills-historial-bajas-tab">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h3>
                                        Historial de bajas y altas
                                    </h3>
                                </div>

                                {
                                    historialBajasAltas.length === 0 &&
                                    <p className="text-muted">
                                        No existen cambios de estatus en este permisionario
                                    </p>
                                }

                                {
                                    historialBajasAltas.length >= 1 && 
                                    <React.Fragment>
                                        <Table>
                                            <TableHead rows={rowsTableBajasAltas} />

                                            <TableBodyHistorial 
                                                elements={historialBajasAltas} 
                                                type="historial_bajas_altas_permisionario" 
                                                permisosUsuario={permisosUsuario.permisionario}  
                                            />
                                        </Table>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

    } else {
        return(
            <div className="text-center mt-5">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }

}