import React from "react";
import AddButton from "../../components/AddButton";
import AlertWarningSearch from "../../components/AlertWarningSearch";
import LoadingSearchIcon from "../../components/LoadingSearchIcon";
import TableBody from "../../components/TableCatalogo/TableBody";
import TableHead from "../../components/TableCatalogo/TableHead";
import TableCatalogo from "../../components/TableCatalogo";
import getCreenciasReligiosas from "../../services/catalogos/creencia_religiosa/getCreenciasReligiosas";
import addCreenciaReligiosa from "../../services/catalogos/creencia_religiosa/addCreenciaReligiosa";
import $ from 'jquery';
import Swal from "sweetalert2";
import ModalAddCreenciaReligiosa from "../../components/Catalogos/ModalAddCreenciaReligiosa";

export default function Religiones(props){
    const [elements, setElements] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    const rows = [
        { id: 1, name: "Nombre" },
        { id: 2, name: "Descripción" },
        { id: 3, name: "Estatus" }
    ];

    function getCreenciasReligiosasService(){
        setLoading(true)
        getCreenciasReligiosas().then(res => {
            let _res = JSON.parse(res);
            console.log(_res)
            if(!(_res.response === 'error')){
                setElements(_res.data);
            } else {
                setElements([])
            }
            setLoading(false);
        })
    }

    const onSubmit = (e)=>{
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        addCreenciaReligiosa({values:objectValues}).then(res=>{
            console.log({res})
            let _res = JSON.parse(res)
            if(_res.response === 'success'){
                Swal.fire({
                    title:'Agregado correctamente',
                    timer:1200,
                    showConfirmButton:false,
                    icon:'success'
                })
                $("#modalAddCreenciaReligiosa").modal("hide")
                $("#formAddCreenciaReligiosa")[0].reset();
                getCreenciasReligiosasService()
            }
        })
    }

    React.useEffect(getCreenciasReligiosasService, []);

    return(
        <section>

            <ModalAddCreenciaReligiosa
                onSubmit={onSubmit}
            />

            <div className="d-flex justify-content-between">

                <h1>Religiones</h1>

                {
                    props.permisos[0].escribir === '1' &&
                        <AddButton text="Nueva religión" onClick={ async () => {
                            $('#modalAddCreenciaReligiosa').modal('show')
                        } } />
                }

            </div>

            <hr className="my-4" />
            
            <LoadingSearchIcon show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <TableCatalogo>
                        <TableHead rows={rows} />

                        <TableBody 
                            elements={elements} 
                            typeElement="creencias_religiosas" 
                            permisosUsuario={props.permisos} 
                            getCreenciasReligiosasService={getCreenciasReligiosasService}  
                        />
                    </TableCatalogo>
            }

            {
                ( Object.keys(elements).length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }


        </section>
    )
}