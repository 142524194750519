import React from "react";
import './ModalImprimirSiniestro.css';

export default function ModalImprimirSiniestro(props){

    const { onSubmit, siniestro, selectedImages, setSelectedImages } = props
    
    const handleImageSelection = (event, imageUrl) => {

        const checkbox = event.target.previousSibling;
        checkbox.checked = !checkbox.checked;
      
        if (checkbox.checked) {
          setSelectedImages([...selectedImages, imageUrl]);
        } else {
          setSelectedImages(selectedImages.filter((img) => img !== imageUrl));
        }
    };

      
    return(
        <div className="modal" id="modalImprimirSiniestro" tabIndex="-1" role="dialog" aria-labelledby="modalImprimirSiniestroLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={onSubmit} id='formAddLesionados' className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalImprimirSiniestroLabel">Generar nuevo PDF</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>
                            Elige las fotos que aparecerán en el reporte:
                        </p>
                        <small className="text-muted">
                            Si no quieres que aparezcan, no eligas nada.
                        </small>
                        <hr className="my-3"/>
                        <div className="row">
                        {
                            siniestro.fotos_siniestro !== null && siniestro.fotos_siniestro.length > 0 &&
                                siniestro.fotos_siniestro.map((element) => (
                                    <div key={element.url_foto} className="col-md-4 mb-3">
                                        <div className="image-container">
                                            <input
                                                type="checkbox"
                                                id={`checkbox-${element.url_foto}`}
                                                className="image-checkbox"
                                                onChange={(event) => handleImageSelection(event, element.url_foto)}
                                                style={{ display: "none" }}
                                            />
                                            <img
                                                className={`imgOperador px-2 py-2 ${
                                                    selectedImages.includes(element.url_foto) ? "image-selected" : ""
                                                }`}
                                                src={element.url_foto}
                                                alt="Siniestro"
                                                onClick={(event) => handleImageSelection(event, element.url_foto)}
                                            />
                                        </div>
                                    </div>
                        ))
                        }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primario" id="btnSubmitModalAddLesionado">Imprimir</button>
                    </div>
                </form>
            </div>
        </div>
    )
}