import React from 'react';
import "./FormularioDocumentosFianzaTrabajador.css"
import Icon from '../../components/Icon';
import $ from 'jquery';
import changeDocumentosFianzaTrabajadorService from './../../services/changeDocumentosFianzaTrabajadorService';
import Swal from 'sweetalert2'
import getCantidadFianzaActive from '../../services/catalogos/cantidad_fianza/getCantidadFianzaActive';

export default function FormularioDocumentosFianzaTrabajador({ trabajador, permisosUsuario }){
    
    let{
        url_pdf_ticket_fianza,
        id_trabajador,
        id_cantidad_fianza,
        pago_fianza_permisionario,
        rutaServidor
    } = trabajador;

    const [cantidadFianzas, setCantidadFianzas] = React.useState([]);

    function getCantidadFianzaActiveService(){
        getCantidadFianzaActive().then(response => {
            let _respuesta = JSON.parse(response);
            // console.log(_respuesta)
            if(_respuesta.response === "error"){
                Swal.fire(
                    'Error al cargar las fianzas',
                    'No existe ninguna cantidad de fianza en el catálogo.',
                    'error'
                )
            } else {
                setCantidadFianzas(_respuesta.data);
                $('.selectpicker').selectpicker('refresh')
            }

        }).catch((error) => {
            console.log(error)
            console.log("ha petado")
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    function setDataFormulario(){

        document.getElementById("id_cantidad_fianza").value = id_cantidad_fianza;
        document.getElementById("pago_fianza_permisionario").value = pago_fianza_permisionario;

        if(permisosUsuario[0].editar === "0"){
            $("form :input, :input[form]").prop("readonly", true);
            $("form :select, :select[form]").prop("disabled", true);
        }
    }

    function onSubmit(e){
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_trabajador', id_trabajador)
        changeDocumentosFianzaTrabajadorService({values:values})
            .then(response => {
                console.log(response);
                let _response = JSON.parse(response);
                // console.log(_response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: '¡Listo!',
                        text: 'Se ha actualizado la fianza',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No haz actualizado nada',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => console.log(error))
    }

    function onChangeFileInput(e, nombreInput){
        const file = e.target.files[0];
        document.getElementById(nombreInput+'Preview').src = URL.createObjectURL(file);
        $('#'+nombreInput+'Preview').removeClass("hidden")
    }

    React.useEffect(() => {
        getCantidadFianzaActiveService();
        setDataFormulario();
    }, []);

    return(

        <form className="formulario" onSubmit={ onSubmit }>

            <div className="perfil__fotoPerfil justify-content-center flex-column">
                <input type="file" name="url_pdf_ticket_fianza" id="url_pdf_ticket_fianza" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "url_pdf_ticket_fianza") } />
                <div>
                    <label htmlFor="url_pdf_ticket_fianza" className="perfil__cambiarFoto text-center">
                        <h4>
                            PDF Ticket de fianza
                        </h4>
                        <p>
                            Da clic aquí para cargar un nuevo pdf del ticket de fianza
                        </p>
                    </label>
                </div>
                <embed 
                    id="url_pdf_ticket_fianzaPreview" 
                    type="application/pdf" 
                    src={`${rutaServidor}${url_pdf_ticket_fianza}`}
                    className={`previewPDF ${url_pdf_ticket_fianza ? "" : "hidden"}`}
                />
            </div>

            <div className='row'>

                <div className='col-3'>
                    <label htmlFor="id_cantidad_fianza">
                        Cantidad fianza:
                    </label>
                    <select 
                        className="input selectpicker"
                        id="id_cantidad_fianza"  
                        name="id_cantidad_fianza"
                        required
                    >
                        {
                            cantidadFianzas &&
                            cantidadFianzas.map( (cantidad_fianza) => (
                                <option key={ cantidad_fianza.id_cantidad_fianza } value={ cantidad_fianza.id_cantidad_fianza }>
                                    $ { cantidad_fianza.cantidad }
                                </option>
                            ) )
                        }
                    </select>
                </div>
                
                <div className='col-4'>
                    <label htmlFor="pago_fianza_permisionario">
                        La fianza la pagó el permisionario?
                    </label>
                    <br />

                    <select 
                        className="input selectpicker"
                        id="pago_fianza_permisionario"  
                        name="pago_fianza_permisionario"
                        required
                    >
                        <option key={1} value={1}>
                            Si
                        </option>
                        <option key={0} value={0}>
                            No
                        </option>
                    </select>
                </div>
            </div>

            {/* <label htmlFor="fianza">
                Cantidad fianza:
            </label>
            <input 
                type="text"
                placeholder="$$$" 
                className="input"
                id="fianza"
                name="fianza"
                required
            /> */}

            {/* ******************************* */}
            {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
            {/* ******************************* */}

            {
            (permisosUsuario[0].editar !== "0") &&
            <React.Fragment>
                <hr></hr>
                <div className="d-flex justify-content-end">
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus" />
                        <Icon icon="fa-solid fa-file mr-3" />
                        Actualizar documento del trabajador
                    </button>
                </div>
            </React.Fragment>
            }


        </form>
    );
}