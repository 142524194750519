import React from 'react';

export default function TableElementMovimientoSaldoAreaEmpresa({ element, permisosUsuario }) {

    let { 
        cantidad, 
        saldo, 
        descripcion, 
        concepto, 
        id_banco_digital, 
        fecha_alta, 
        // fecha_movimiento,
        lesionado,
        vehiculo_afectado,
        bien_afectado
    } = element;

    return (
        <tr key={id_banco_digital}>
            <th className='table-minwidth'>
                <div className="table__name">
                    {/* <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <button onClick={handleViewMovimientos} className="dropdown-item" >Ver detalles de movimientos</button>
                            <Link to={`/saldos-siniestro/${id_banco_digital}`} className="dropdown-item bg-color-naranja">Generar PDF</Link>
                        </div>
                    </div> */}

                    <div className="table__nameDescription">
                        <p>{fecha_alta}</p>
                    </div>
                </div>
            </th>
            
            <td>
                { concepto ? concepto : '-' }
                {
                    descripcion && 
                    <React.Fragment>
                        <br/>
                        <small className=''>
                            Descripción: { descripcion ? descripcion : '-' }
                        </small>
                        {
                            (lesionado || vehiculo_afectado || bien_afectado) &&
                                <React.Fragment>
                                    <br/>
                                    <small className='text-muted'>
                                        {
                                            lesionado && `Lesionado: ${lesionado.nombre_completo}`
                                        }
                                        {
                                            vehiculo_afectado && `Vehiculo afectado: ${vehiculo_afectado.nombre_conductor}, Modelo: ${vehiculo_afectado.modelo}, Placas: ${vehiculo_afectado.placas}, ${vehiculo_afectado.nombre_marca_vehiculo ? vehiculo_afectado.nombre_marca_vehiculo : 'Sin marca'},${vehiculo_afectado.nombre_submarca_vehiculo ? vehiculo_afectado.nombre_submarca_vehiculo : 'Sin submarca'}`
                                        }
                                        {
                                            bien_afectado && `Bien afectado: ${bien_afectado.nombre_tipo_de_bien}, ${bien_afectado.descripcion}`
                                        }
                                    </small>
                                </React.Fragment>
                        }
                    </React.Fragment>
                }
            </td>

            <td className="font-size-lg color-rojo">
                {
                    parseFloat(cantidad) < 0 &&
                        parseFloat(cantidad).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                }
            </td>

            <td className="font-size-lg color-verde">
                {
                    parseFloat(cantidad) >= 0 &&
                        parseFloat(cantidad).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                }
            </td>

            <td className={`font-size-lg ${saldo >= 0 ? 'color-verde' : 'color-rojo'}`}>
                { parseFloat(saldo).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
            </td>
        </tr>
    )
    
}