import React from "react";
import deleteLesionadoSiniestro from "../../services/siniestro/deleteLesionadoSiniestro";
import Swal from "sweetalert2";

export default function TableElementLesionado({ index, element, elements, setElements, permisosUsuario, idEstadoSiniestro }) {

    let { id_lesionado_siniestro, nombre_completo, nombre_genero, descripcion } = element;

    const onClickDelete = () => {
        // Eliminamos el elemento del array principal y lo actualizamos
        // if( id_lesionado_siniestro !== undefined && id_lesionado_siniestro !== null ){
        //     deleteLesionadoSiniestroService()
        // }
        
        let newElements = elements.filter((e, i)=>{
            return ( i !== index )
        })
        setElements(newElements)
    }

    const deleteLesionadoSiniestroService = ()=>{
        // Eliminamos el lesionado de la tabla lesionados_siniestro con el id_lesionado_siniestro
        let data = { id_lesionado_siniestro }

        deleteLesionadoSiniestro({values:data})
            .then(res=>{
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    onClickDelete()
                    return true
                }

                if (_res.response === "error") {
                    Swal.fire({
                        title:'No se puede eliminar',
                        text:"Existen registros vinculados a este dato (podrían ser egresos del siniestro), no se puede eliminar hasta que elimines todos los registros vinculados a él",
                        showConfirmButton:true,
                        icon:'info'
                    })
                }

                return false;
            })
            .catch(err => ()=>{
                console.error(err)
                return false
            })
    }

    return (
        <tr>
            <th>
                <div className="table__name">
                    {
                    // PRIMERO VALIDAMOS QUE EL SINIESTRO SIGA ABIERTO, PARA PODER HACER CUALQUIER COSA
                    idEstadoSiniestro === '1' &&
                    (id_lesionado_siniestro !== undefined && id_lesionado_siniestro !== null ) &&
                    
                        permisosUsuario[0].eliminar === '1' &&
                        <div className="dropdown">
                            <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item bg-danger text-white" onClick={() => deleteLesionadoSiniestroService()}>
                                    Eliminar
                                </a>
                            </div>
                        </div>
                    }
                    {
                    (id_lesionado_siniestro === undefined || id_lesionado_siniestro === null ) && 
                        <div className="dropdown">
                            <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item bg-danger text-white" onClick={() => onClickDelete()}>
                                    Eliminar
                                </a>
                            </div>
                        </div>
                    }
                    <p>
                        {nombre_completo}
                        <br/>
                        <small className={"text-muted"}>
                            Género: { nombre_genero }
                        </small>
                    </p>
                </div>
            </th>

            <td>
                <div className="table__fechaAlta">
                    <p>
                        { descripcion ? descripcion : "Sin descripción" }
                    </p>
                </div>
            </td>
        </tr>
    )
    
}