import React from "react";
import AddButton from "../../components/AddButton";
import AlertWarningSearch from "../../components/AlertWarningSearch";
import LoadingSearchIcon from "../../components/LoadingSearchIcon";
import TableBody from "../../components/TableCatalogo/TableBody";
import TableHead from "../../components/TableCatalogo/TableHead";
import TableCatalogo from "../../components/TableCatalogo";
import getSubmarcasVehiculos from "../../services/catalogos/submarca_vehiculo/getSubmarcasVehiculos";
import addSubmarcaVehiculo from "../../services/catalogos/submarca_vehiculo/addSubmarcaVehiculo";
import getSubmarcasVehiculosByMarca from "../../services/catalogos/submarca_vehiculo/getSubmarcasVehiculosByMarca";
import $ from 'jquery';
import Swal from "sweetalert2";
import ModalAddSubmarcaVehiculo from "../../components/Catalogos/ModalAddSubmarcaVehiculo";
import getMarcasVehiculos from "../../services/catalogos/marca_vehiculo/getMarcasVehiculos";

export default function SubmarcasVehiculos(props){
    const [elements, setElements] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [marcasVehiculo, setMarcasVehiculo] = React.useState([]);

    const rows = [
        { id: 1, name: "Nombre submarca" },
        { id: 2, name: "Nombre marca" },
        { id: 3, name: "Estatus" }
    ];

    function getSubmarcasVehiculosService(){
        setLoading(true)
        getSubmarcasVehiculos().then(res => {
            let _res = JSON.parse(res);
            console.log(_res)
            if(!(_res.response === 'error')){
                setElements(_res.data);
            } else {
                setElements([])
            }
            setLoading(false);
        })
    }

    const onSubmit = (e)=>{
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        addSubmarcaVehiculo({values:objectValues}).then(res=>{
            console.log({res})
            let _res = JSON.parse(res)
            if(_res.response === 'success'){
                Swal.fire({
                    title:'Agregado correctamente',
                    timer:1200,
                    showConfirmButton:false,
                    icon:'success'
                })
                $("#modalAddSubmarcaVehiculo").modal("hide")
                $("#formAddSubmarcaVehiculo")[0].reset();
                getSubmarcasVehiculosService()
            }
        })
    }

    const getMarcasVehiculosService = ()=>{
        getMarcasVehiculos({values:{}})
            .then(res=>{
                console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    console.log(_res.data)
                    setMarcasVehiculo(_res.data)
                    $('.selectpicker').selectpicker('refresh');
                }
            })
    }

    const handleMarcaVehiculo = (value)=>{
        if(value === ''){
            getSubmarcasVehiculosService()
        } else {
            let values = new FormData()
            values.append('id_marca_vehiculo', value)
            let objectValues = Object.fromEntries(values);
            console.log({objectValues})
            getSubmarcasVehiculosByMarca({values:objectValues}).then(res => {
                let _res = JSON.parse(res);
                console.log(_res)
                if(!(_res.response === 'error')){
                    setElements(_res.data);
                } else {
                    setElements([])
                }
                setLoading(false);
            })
        }
    }

    React.useEffect(getMarcasVehiculosService, []);

    React.useEffect(getSubmarcasVehiculosService, []);

    return(
        <section>

            <ModalAddSubmarcaVehiculo
                marcasVehiculo={marcasVehiculo}
                onSubmit={onSubmit}
            />

            <div className="d-flex justify-content-between">

                <h1>Submarcas de vehículos</h1>

                {
                    props.permisos[0].escribir === '1' &&
                        <AddButton text="Nueva submarca vehículo" onClick={ async () => {
                            $('#modalAddSubmarcaVehiculo').modal('show')
                        } } />
                }

            </div>

            <div className="row my-3">
                <div className="col-4">
                    <label htmlFor="marca_vehiculo">
                        Marca de vehículo:
                    </label>
                    <select 
                        className="input selectpicker"
                        id="marca_vehiculo"
                        name="marca_vehiculo"
                        onChange={ (e) => handleMarcaVehiculo(e.target.value) }
                        data-live-search="true"
                        required
                    >
                        <option value="">
                            Elige una marca
                        </option>
                        {
                            marcasVehiculo &&
                            marcasVehiculo.map( (marca_vehiculo) => (
                                <option key={ marca_vehiculo.id_marca_vehiculo } value={ marca_vehiculo.id_marca_vehiculo }>
                                    { marca_vehiculo.nombre }
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div>

            <hr className="my-4" />
            
            <LoadingSearchIcon show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <TableCatalogo>
                        <TableHead rows={rows} />

                        <TableBody 
                            elements={elements} 
                            typeElement="submarcas_vehiculos" 
                            permisosUsuario={props.permisos} 
                            getSubmarcasVehiculosService={getSubmarcasVehiculosService}  
                        />
                    </TableCatalogo>
            }

            {
                ( Object.keys(elements).length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }


        </section>
    )
}