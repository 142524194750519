import React from 'react';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
// import changeEstatusProyectoService from '../../services/changeEstatusProyectoService';

export default function TableElementProyecto({ element, permisosUsuario }) {

    let { clave_proyecto, nombre, fecha_inicio, monto_recaudado, total_inversionistas, activo } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(activo);

    const onClickDisable = (activo) => {
        let msg = (activo) ? '¿Seguro que deseas habilitar el proyecto?' : '¿Seguro que deseas deshabilitar el proyecto?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('clave_proyecto', clave_proyecto)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                // changeEstatusProyectoService({values:objectValues}).then(response => {
                //     // console.log(response)
                //     let _respuesta = JSON.parse(response);
                //     if (_respuesta.response === "success") {
                //         setEstadoEstatus(activo)
                //         Swal.fire(
                //             'Estatus actualizado',
                //             '',
                //             'success'
                //         )
                //     }
                    
                // })
            }
        })
    }

    return (
        <tr key={clave_proyecto}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={`/proyectos/${clave_proyecto}`} className="dropdown-item" >Ver</Link>
                            {
                                permisosUsuario[0].editar == 1 &&
                                (estadoEstatus == 1 ?
                                <a className="dropdown-item bg-danger text-white" onClick={() => onClickDisable(false)}>Deshabilitar</a>
                                :
                                <a className="dropdown-item bg-success text-white" onClick={() => onClickDisable(true)}>Habilitar</a>)
                            }
                        </div>
                    </div>
                    <div className="table__nameDescription">
                        <p>{clave_proyecto}</p>
                        <small>{`Nombre del proyecto: ${nombre}`}</small>
                        {/* <small>{ numero_serie ? `Unidad asignada: ${numero_serie}` : 'Sin unidad asignada' }</small> */}
                    </div>
                </div>
            </th>

            <td>
                <p className="table_montoRecaudado">
                    { monto_recaudado }
                </p>
            </td>

            <td>
                <p className="table_totalInversionistas">
                    { total_inversionistas }
                </p>
            </td>

            <td>
                <p className="table__fechaInicioOperaciones">
                    { fecha_inicio ? fecha_inicio : '-' }
                </p>
            </td>

            <td>
                <div className="table__estatus">
                   <span className={ estadoEstatus == 1 ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus == 1 ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}