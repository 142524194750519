import React from 'react'
import $ from 'jquery'
import getAreasEmpresaActivos from '../../services/catalogos/area_empresa/getAreasEmpresaActivos';
import LoadingSearchIcon from '../../components/LoadingSearchIcon';
import Table from '../../components/Table';
import TableHead from '../../components/Table/TableHead';
import TableBody from '../../components/Table/TableBody';
import AlertWarningSearch from '../../components/AlertWarningSearch';
import getDetallesSaldoAreaEmpresa from '../../services/banco_digital/getDetallesSaldoAreaEmpresa';
import PDFGeneratorEstadoCuentaArea from '../../components/PDFGeneratorEstadoCuentaArea';

export default function SaldosDepartamentos({permisosUsuario}){

    const [areaSelected, setAreaSelected] = React.useState("")
    const [areas, setAreas] = React.useState([]);
    const [elements, setElements] = React.useState([]);
    const [dataGeneralCuenta, setDataGeneralCuenta] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    const rows = [
        { id: 1, name: "Fecha" },
        { id: 2, name: "Concepto" },
        { id: 3, name: "Cargo" },
        { id: 4, name: "Abono" },
        { id: 5, name: "Saldo" }
    ];

    // Manejo de fechas
    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay()-1)));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
    const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])

    function onSubmitFechas(e){
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        setFechaInicio(objectValues.fecha_inicial)
        setFechaFinal(objectValues.fecha_final)
    }

    const getAreasEmpresaService = ()=>{
        setLoading(true)
        // let data = {
        //     fecha_inicio: fechaInicio,
        //     fecha_final: fechaFinal,
        //     id_area_empresa: areaSelected
        // }
        // Validamos si tiene permisos para ver todas las areas
        if(permisosUsuario.saldosDepartamentos[0].leer === '1'){
            getAreasEmpresaActivos()
                .then(res=>{
                    // console.log(res)
                    let _res = JSON.parse(res)
                    if(_res.response === 'success'){
                        console.log(_res.data)
                        setAreas(_res.data)
                        setLoading(false)
                        $('.selectpicker').selectpicker('refresh');
                    }
                })
            return;
        }

        
        if(permisosUsuario.saldosSiniestros[0].leer === '1'){
            setTimeout(getAreaByPermisoUsuario,200)
        }
    }

    const getAreaByPermisoUsuario = ()=>{
        let data = [{
            id_area_empresa: 3,
            nombre: "Siniestros"
        }]
        console.log(data)
        setAreas(data)
        setLoading(false)
        $('.selectpicker').selectpicker('refresh');
    }
    
    const getDetallesSaldoAreaEmpresaService = ()=>{
        setLoading(true)
        setElements([])
        setDataGeneralCuenta([])
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            id_area_empresa: areaSelected
        }
        console.log(data)
        getDetallesSaldoAreaEmpresa({values:data})
            .then(res=>{
                console.log(res)
                let _res = JSON.parse(res)
                console.log(_res)
                if(_res.response === 'success'){
                    // console.log(_res.data)
                    calculateSaldoOfMovimientos(_res.data,_res.data_cuenta)
                    setDataGeneralCuenta(_res.data_cuenta)
                }
                setLoading(false)
            })
    }

    const calculateSaldoOfMovimientos = (elements, dataCuenta)=>{
        let saldo_inicial = parseFloat(dataCuenta.saldo_inicial);
        let saldo_inicial_establecido = dataCuenta.saldo_inicial_establecido;
        let saldo = saldo_inicial;

        let movimientosConSaldo = elements.slice().reverse().map(movimiento => {
            // Verifica si es un ingreso o un egreso y suma/resta el cantidad al saldo
            if (movimiento.id_movimiento_ingreso) {
                saldo += parseFloat(movimiento.cantidad);
            } else if (movimiento.id_movimiento_egreso) {
                saldo += parseFloat(movimiento.cantidad);
            }

            // Crea una copia del objeto 'movimiento' y añade el campo 'saldo' con el valor actualizado
            return {
                ...movimiento,
                saldo: saldo
            };
        }).reverse();

        if(saldo_inicial_establecido === '1'){
            // Añadir el saldo inicial al final del array movimientosConSaldo
            movimientosConSaldo.push({
                // cantidad: 0,
                // concepto: "Saldo inicial establecido manualmente",
                // id_movimiento_ingreso: 0,
                // fecha_alta: 'xddd',
                saldo: saldo_inicial,
            });
            
            // // Actualizar el saldo de cada elemento teniendo en cuenta el saldo_inicial
            // movimientosConSaldo = movimientosConSaldo.map(movimiento => {
            //     if (movimiento.hasOwnProperty('saldo')) {
            //         return {
            //             ...movimiento,
            //             saldo: movimiento.saldo + saldo_inicial
            //         };
            //     }
            //     return movimiento;
            // });
        }

        // Debemos de agregar el saldo inicial al array para que lo muestre como primer movimiento
        setElements(movimientosConSaldo);
    }

    const handleEditBalanceInicial = () =>{

    }

    React.useEffect(() => {
        getAreasEmpresaService()
        $('.selectpicker').selectpicker();
    },[]);

    React.useEffect(getDetallesSaldoAreaEmpresaService, [fechaInicio, fechaFinal, areaSelected])
    
    React.useEffect(()=>{
        $('[data-toggle="tooltip"]').tooltip()
    },[])

    return(
        <section>

            <div className="d-flex justify-content-between">
                <h1>
                    Saldos y movimientos de las áreas
                </h1>
            </div>

            <p className='mb-3'>
                Elige un área para ver el saldo y movimientos.
            </p>

            <div className='d-flex justify-content-between align-items-center'>
                {
                    
                }
                <div className='d-flex'>
                    <div className='mr-3'>
                        {
                            areas !== null && areas.length > 0 &&
                                <select 
                                    className="input selectpicker m-0 mr-3"
                                    name="id_area_empresa"
                                    data-live-search="true"
                                    onChange={(e) => setAreaSelected(e.target.value)}
                                > 
                                    <option value=''>
                                        Selecciona un área
                                    </option>
                                    {
                                        areas.map( area => (
                                            <option key={ area.id_area_empresa } value={ area.id_area_empresa }>
                                                { area.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                        }
                    </div>

                    {
                        !loading && Object.keys(dataGeneralCuenta).length !== 0 &&
                            <div className='d-flex align-items-center justify-content-end'>
                                <h3 className='m-0'>
                                    Saldo:
                                </h3>
                                <h2 className={`m-0 mx-2 ${dataGeneralCuenta.saldo_total > 0 && 'color-verde'} ${dataGeneralCuenta.saldo_total < 0 && 'color-rojo'} ${dataGeneralCuenta.saldo_total === 0 && 'text-black'}`}>
                                    { parseFloat(dataGeneralCuenta.saldo_total).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                                </h2>
                                {
                                    dataGeneralCuenta.saldo_inicial_establecido === '0' && 
                                        <div className=''>
                                            <button 
                                                className='btn btn-light font-size-lg color-azul' 
                                                onClick={handleEditBalanceInicial}
                                                data-toggle="tooltip" 
                                                data-placement="top" 
                                                title="Saldo inicial"
                                            >
                                                <i className="fa-solid fa-pen-to-square"></i>
                                            </button>
                                        </div>
                                }
                            </div>
                    }

                </div>


                {/* <form onSubmit={onSubmitFechas} className={`d-flex justify-content-between align-items-center`}>
                    <input id="fecha_inicial" name="fecha_inicial" className="input noMargin" type="date" required />
                    <p className="mx-2">y</p>
                    <input id="fecha_final" name="fecha_final" className="input noMargin" type="date" required />
                    <button className='btn btn-gris'>Ver</button>
                </form> */}
            </div>

            <hr />
            
            {
                areaSelected !== "" &&
                    <div className='d-flex justify-content-between align-items-center my-4'>
                        <div>
                            <h2 className='m-0'>Movimientos:</h2>
                            {
                                !loading && Object.keys(dataGeneralCuenta).length !== 0 && elements.length > 0 &&
                                    <PDFGeneratorEstadoCuentaArea 
                                        dataGeneralCuenta={dataGeneralCuenta}
                                        gastos={elements}
                                    />
                            }
                        </div>
                        <div>
                            <form onSubmit={onSubmitFechas} className={`d-flex justify-content-between align-items-center`}>
                                <input id="fecha_inicial" name="fecha_inicial" className="input noMargin" type="date" defaultValue={fechaInicio} required />
                                <p className="mx-2">y</p>
                                <input id="fecha_final" name="fecha_final" className="input noMargin" type="date" defaultValue={fechaFinal} required />
                                <button className='btn btn-gris'>Ver</button>
                            </form>
                        </div>
                    </div>
            }


            {/* <div className='d-flex mb-4'>
                <form onSubmit={onSubmitFechas} className={`d-flex justify-content-between align-items-center`}>
                    <input id="fecha_inicial" name="fecha_inicial" className="input noMargin" type="date" defaultValue={fechaInicio} required />
                    <p className="mx-2">y</p>
                    <input id="fecha_final" name="fecha_final" className="input noMargin" type="date" defaultValue={fechaFinal} required />
                    <button className='btn btn-gris'>Ver</button>
                </form>
            </div> */}

            <LoadingSearchIcon show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) && 

                    <Table>
                        <TableHead rows={rows} />

                        <TableBody 
                            elements={elements} 
                            typeElement="movimiento_saldo_area_empresa" 
                            permisosUsuario={permisosUsuario} 
                            // setElementToEdit={setMaterialSelected}
                        />
                    </Table>
            }

            {
                ( Object.keys(elements).length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }



        </section>
    )    
}