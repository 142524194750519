import React from 'react'
import AlertWarningSearch from '../../components/AlertWarningSearch';
import FilterButton from '../../components/FilterButton';
import Icon from '../../components/Icon';
import InputSearch from '../../components/InputSearch';
import LoadingSearchIcon from '../../components/LoadingSearchIcon';
import Table from '../../components/Table';
import TableBody from '../../components/Table/TableBody';
import TableHead from '../../components/Table/TableHead';
import getGastosPorEntregar from '../../services/previo_movimiento_egresos/getGastosPorEntregar';
import searchGastosPorEntregar from '../../services/previo_movimiento_egresos/searchGastosPorEntregar';
import './EntregaRecurso.css'

export default function EntregaRecurso(props){
    let { permisosUsuario } = props;

    const [elements, setElements] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filters, setFilters] = React.useState([])
    const [orderBy, setOrderBy] = React.useState({})
    const [terminoBusqueda, setTerminoBusqueda] = React.useState("")
    const [showDeleteFilters, setShowDeleteFilters] = React.useState(false)
    const [loadingFilters, setLoadingFilters] = React.useState(false)

    const rows = [
        { id: 1, name: "Entregar a" },
        { id: 2, name: "Fecha de entrega" },
        { id: 3, name: "Cantidad" },
        { id: 4, name: "" }
    ];

    const getGastosPorEntregarService = () => {
        setLoading(true)
        setElements([])
        
        if(filters.length !== 0 || Object.keys(orderBy).length !== 0 || terminoBusqueda !== ""){
            search()
        } else {
            getGastosPorEntregar()
                .then(res=>{
                    console.log(res)
                    let _res = JSON.parse(res)
                    // console.log(_res)
                    if(_res.response === 'success'){
                        setTimeout(()=>setElements(_res.data),300)
                    }
                    setLoading(false)
                })
        }
    }

    const search = () =>{
        setLoading(true)
        let data = {}

        if(terminoBusqueda !== "")
            data.q = terminoBusqueda
        if(filters.length !== 0)
            data.filtros = filters
        if(Object.keys(orderBy).length !== 0)
            data.ordenar = orderBy

        console.log(filters);
        searchGastosPorEntregar({values:data})
            .then((response) =>{
                console.log(response)
                let _response = JSON.parse(response)
                if(_response.response === "success"){
                    setElements(_response.data)
                } else {
                    setElements([])
                }
                setLoading(false)
            })
            .catch((error)=>{
                console.log(error)
            })
    }

    const onSubmitFilter = (tipoFiltro, e) => {
        e.preventDefault()
        setShowDeleteFilters(true)
        let values = new FormData(e.target)
        let object = Object.fromEntries(values)

        let nuevosFiltros = [...filters]

        let newFiltros = nuevosFiltros.filter((value) =>{
            return value.campo !== tipoFiltro
        })

        newFiltros.push({ campo: tipoFiltro, data: object })
        setFilters(newFiltros)
    }

    const onClickDeleteFilters = () => {
        setFilters([])
        setOrderBy({})
        setShowDeleteFilters(false)
        // Ocultamos y mostramos de nuevo los filtros para limpiar los valores seleccionados
        setLoadingFilters(true)
        setTimeout(()=>{
            setLoadingFilters(false)
        }, 50)
    }

    // const onSubmitSearch = (e) =>{
    //     e.preventDefault()
    //     let values = new FormData(e.target)
    //     let objectValues = Object.fromEntries(values)
    //     let terminoBusqueda = objectValues.q
    //     setTerminoBusqueda(terminoBusqueda)
    // }

    const onClickOrderBy = (campo, orden) => {
        let data = {
            campo: campo,
            orden: orden
        }
        setOrderBy(data)
        setShowDeleteFilters(true)
    }

    React.useEffect(() => {
        getGastosPorEntregarService()
    },[filters, terminoBusqueda, orderBy]);

    return(
        <section>

            <h1>
                Entrega de recurso
            </h1>

            <p>
                Entrega el recurso a la persona indicada y luego márcalo como <span className='font-weight-bold'>entregado</span>
            </p>

            <div className="d-flex justify-content-between mt-3">
                <div>
                    {
                        !loadingFilters &&
                            <>
                                <FilterButton text="Entregado" type="entregado" onSubmit={onSubmitFilter} />
                                <FilterButton text="Fecha alta" type="fecha_alta" onSubmit={onSubmitFilter} />
                            </>
                    }
                </div>

                <div className="d-flex align-items-center">

                    <button className={`btn btn-sm btn-light text-muted ${(!showDeleteFilters) && 'hidden'}`} onClick={()=>onClickDeleteFilters()}>
                        <Icon icon='fa-solid fa-x' /> Eliminar filtros
                    </button>

                    <div className="dropdown mx-2">
                        <button className="btn btn-sm btn-light dropdown-toggle text-muted" data-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-arrow-down-short-wide mr-2"></i>
                                Ordenar
                        </button>

                        <div className="dropdown-menu">
                            {/* <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('nombre','ASC')}>Nombre de A-Z</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('nombre','DESC')}>Nombre de Z-A</button> */}
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('cantidad','ASC')}>Cantidad de A-Z</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('cantidad','DESC')}>Cantidad de Z-A</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_alta','ASC')}>Fecha alta de A-Z</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_alta','DESC')}>Fecha alta de Z-A</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_entrega','ASC')}>Fecha entrega de A-Z</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_entrega','DESC')}>Fecha entrega de Z-A</button>
                        </div>
                    </div>
                    
                </div>
            </div>

            <hr className="my-3" />

            <LoadingSearchIcon show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rows} />

                        <TableBody 
                            elements={elements} 
                            typeElement="entrega_recurso_aprobado_contralor" 
                            permisosUsuario={permisosUsuario}
                            updateElements={()=>{
                                getGastosPorEntregarService()
                            }}
                        />
                    </Table>
            }

            {
                ( Object.keys(elements).length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }

        </section>
    )
}