import React from 'react';

import { handleErrorsInputFile } from './../../validators/handleErrorsInputFile';
import { validateNoNumbers } from './../../validators/validateNoNumbers';
import Icon from '../../components/Icon';
import { useHistory } from "react-router-dom"

import $ from 'jquery';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min.js';
import Swal from 'sweetalert2'
import config from '../../config';
import getMarcasVehiculos from '../../services/catalogos/marca_vehiculo/getMarcasVehiculos';
import getSubmarcasVehiculosByMarca from '../../services/catalogos/submarca_vehiculo/getSubmarcasVehiculosByMarca';
import getTiposCombustible from '../../services/catalogos/tipo_combustible/getTiposCombustible';
import getTiposVehiculos from '../../services/catalogos/tipo_vehiculo/getTiposVehiculos';
const URL_API = config.urlApi;

export default function AddVehiculo(){

    const history = useHistory()

    $(document).ready(function(){
        $('.selectpicker').selectpicker();
    });

    function onChangeFileInput(e, nombreInput){
        const file = e.target.files[0];
        document.getElementById(nombreInput+'Preview').src = URL.createObjectURL(file);
        $('#'+nombreInput+'Preview').removeClass("hidden")
    }

    const [fechaEmisionFacturaOrigen, setFechaEmisionFacturaOrigen] = React.useState('');
    const [anotacionesFacturaOrigen, setAnotacionesFacturaOrigen] = React.useState('');
    
    const [marcasVehiculo, setMarcasVehiculo] = React.useState([]);
    const [submarcasVehiculo, setSubmarcasVehiculo] = React.useState([]);
    const [tiposVehiculos, setTiposVehiculos] = React.useState([]);
    const [tiposCombustibles, setTiposCombustibles] = React.useState([]);

    const getMarcasVehiculosService = ()=>{
        getMarcasVehiculos().then(res=>{
            let _res = JSON.parse(res);
            console.log(_res)
            if(!(_res.response === 'error')){
                setMarcasVehiculo(_res.data);
            } else {
                setMarcasVehiculo([])
            }
            $('.selectpicker').selectpicker('refresh')
        })
    }

    const getSubmarcasVehiculosService = (id_marca_vehiculo)=>{
        let form = new FormData()
        form.append('id_marca_vehiculo',id_marca_vehiculo)
        let objectValues = Object.fromEntries(form);
        getSubmarcasVehiculosByMarca({values:objectValues}).then(res=>{
            let _res = JSON.parse(res);
            console.log(_res)
            if(!(_res.response === 'error')){
                $("#id_submarca_vehiculo").attr("disabled",false)
                setSubmarcasVehiculo(_res.data);
            } else {
                $("#id_submarca_vehiculo").attr("disabled",true)
                setSubmarcasVehiculo([])
            }
            $('.selectpicker').selectpicker('refresh')
        })
    }
    
    const onChangeMarca = (value)=>{
        setMarca(value)
        getSubmarcasVehiculosService(value)
    }

    const getTiposCombustibleService = ()=>{
        getTiposCombustible().then(res => {
            let _res = JSON.parse(res);
            console.log(_res)
            if(!(_res.response === 'error')){
                setTiposCombustibles(_res.data);
            } else {
                setTiposCombustibles([])
            }
            $('.selectpicker').selectpicker('refresh')
        })
    }

    const getTiposVehiculosService = ()=>{
        getTiposVehiculos().then(res => {
            let _res = JSON.parse(res);
            console.log(_res)
            if(!(_res.response === 'error')){
                setTiposVehiculos(_res.data);
            } else {
                setTiposVehiculos([])
            }
            $('.selectpicker').selectpicker('refresh')
        })
    }

    React.useEffect(getMarcasVehiculosService,[])
    React.useEffect(getTiposCombustibleService,[])
    React.useEffect(getTiposVehiculosService,[])

    const [marca, setMarca] = React.useState('');
    const [submarca, setSubmarca] = React.useState('');
    const [modeloUnidad, setModeloUnidad] = React.useState('');
    const [tipoVehiculo, setTipoVehiculo] = React.useState('');
    const [tipoCombustible, setTipoCombustible] = React.useState('');
    const [numeroSerie, setNumeroSerie] = React.useState('');
    const [numeroMotor, setNumeroMotor] = React.useState('');
    const [numeroPasajeros, setNumeroPasajeros] = React.useState('');
    const [vigenciaSeguro, setVigenciaSeguro] = React.useState('');
    const [numeroPolizaSeguro, setNumeroPolizaSeguro] = React.useState('');
    const [placas, setPlacas] = React.useState('');
    const [numeroTarjetaCirculacion, setNumeroTarjetaCirculacion] = React.useState('');
    const [fechaOperacion, setFechaOperacion] = React.useState('');
    const [colorEngomado, setColorEngomado] = React.useState('RO');
    const [colorUnidad, setColorUnidad] = React.useState('blanco');
    const [anotacionesUnidad, setAnotacionesUnidad] = React.useState('');
    const [anotacionesDocumentosUnidad, setAnotacionesDocumentosUnidad] = React.useState('');

    function onSubmitVehiculo(e){
        e.preventDefault();

        let formData = new FormData();
        formData.append('fechaEmisionFacturaOrigen',fechaEmisionFacturaOrigen);
        formData.append('anotacionesFacturaOrigen',anotacionesFacturaOrigen);
        formData.append('marca',marca);
        formData.append('submarca',submarca);
        formData.append('modeloUnidad',modeloUnidad);
        formData.append('tipoVehiculo',tipoVehiculo);
        formData.append('tipoCombustible',tipoCombustible);
        formData.append('numeroSerie',numeroSerie);
        formData.append('numeroMotor',numeroMotor);
        formData.append('numeroPasajeros',numeroPasajeros);
        formData.append('vigenciaSeguro',vigenciaSeguro);
        formData.append('numeroPolizaSeguro',numeroPolizaSeguro);
        formData.append('placas',placas);
        formData.append('numeroTarjetaCirculacion',numeroTarjetaCirculacion);
        formData.append('fechaOperacion',fechaOperacion);
        formData.append('colorEngomado',colorEngomado);
        formData.append('colorUnidad',colorUnidad);
        formData.append('anotacionesUnidad',anotacionesUnidad);
        formData.append('anotacionesDocumentosUnidad',anotacionesDocumentosUnidad);
        
        let facturaUnidad = document.getElementById("facturaUnidad").files;
        let polizaSeguro = document.getElementById("polizaSeguro").files;
        let tarjetaCirculacionPDF = document.getElementById("tarjetaCirculacionPDF").files;
        let tablaAmortizacion = document.getElementById("tablaAmortizacion").files;
        let documentoBajaPlacas = document.getElementById("documentoBajaPlacas").files;
        let documentoVoBo = document.getElementById("documentoVoBo").files;

        if( handleErrorsInputFile(facturaUnidad, "Factura origen de la unidad") ){
            return;
        }

        formData.append('facturaUnidad', facturaUnidad.item(0));
        formData.append('polizaSeguro', polizaSeguro.item(0));
        formData.append('tarjetaCirculacionPDF', tarjetaCirculacionPDF.item(0));
        formData.append('tablaAmortizacion', tablaAmortizacion.item(0));
        formData.append('documentoBajaPlacas', documentoBajaPlacas.item(0));
        formData.append('documentoVoBo', documentoVoBo.item(0));
        
        const user = JSON.parse(localStorage.getItem("user"))
        const id = user.id_usuario
        formData.append('id',id);

        // Convert formdata to object
        let objectValues = Object.fromEntries(formData);
        console.log({objectValues})

        $.ajax({
            xhr: function() {

                var xhr = new window.XMLHttpRequest();

                xhr.upload.addEventListener("progress", function(evt) {
                    if (evt.lengthComputable) {
                        Swal.fire({
                            title: 'Subiendo vehículo...',
                            html: '<b></b>% de 100%',
                            timerProgressBar: true,
                            showConfirmButton: false,
                            didOpen: () => {
                                Swal.showLoading()
                                const b = Swal.getHtmlContainer().querySelector('b')
                                var percentComplete = evt.loaded / evt.total;
                                percentComplete = parseInt(percentComplete * 100);
                                // b.textContent = `${percentComplete}%`
                                // console.log(percentComplete)
                        
                                if (percentComplete === 100) {
                                    // Swal.close()
                                    Swal.hideLoading();
                                    Swal.fire({
                                        title:"Vehículo agregado",
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 1500
                                    }).then(function(){
                                        history.push('/vehiculos');
                                    })
                                }
                            },
                        })
                    }
                }, false);
            
                return xhr;
            },
            url:URL_API+'controlador/vehiculo.controlador.php',
            data: formData,
            type: "POST",
            contentType: false,
            processData: false,
            // success:function(respuesta){
            //     let _respuesta = JSON.parse(respuesta);
            //     if(_respuesta.response === 'success'){
            //         Swal.fire(
            //             'Vehículo agregado',
            //             '',
            //             'success'
            //         )
            //     } else {
            //         Swal.fire(
            //             'Ocurrió un error',
            //             'Vuelve a intentarlo',
            //             'error'
            //         )
            //     }
            // }
        })
    }

    return (
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
            />

            <h2 className="mt-5">Agregar una unidad</h2>

            <p>
                Para agregar una unidad es necesario completar el siguiente formulario:
            </p>

            <div className="perfil__fotoPerfil justify-content-center flex-column">
                <input type="file" id="facturaUnidad" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "facturaUnidad") } />
                <div>
                    <label htmlFor="facturaUnidad" className="perfil__cambiarFoto text-center">
                        <h4>
                            Factura origen de la unidad
                        </h4>
                        <p>
                            Da clic aquí para cargar el pdf de la factura origen
                        </p>
                    </label>
                </div>
                <embed id="facturaUnidadPreview" className="previewPDF hidden" type="application/pdf" />
            </div>

            <form className="formulario" onSubmit={ onSubmitVehiculo }>

                {/* **************************************** */}
                {/* BLOQUE DE LOS DATOS DE LA FACTURA ORIGEN */}
                {/* **************************************** */}

                <div className="datosFacturaOrigen__formulario row">
                    <div className="col-4">
                        <label htmlFor="fechaEmisionFacturaOrigen">
                            Fecha emisión factura:
                        </label>
                        <input 
                            type="date" 
                            className="input"
                            id="fechaEmisionFacturaOrigen"
                            name="fechaEmisionFacturaOrigen"
                            value={ fechaEmisionFacturaOrigen }
                            onChange={ (e) => setFechaEmisionFacturaOrigen(e.target.value) }
                            required
                        />
                    </div>
                    <div className="col-8">
                        <label htmlFor="anotacionesFacturaOrigen">
                            Anotaciones factura:
                        </label>
                        <input 
                            type="text" 
                            className="input" 
                            id="anotacionesFacturaOrigen"
                            name="anotacionesFacturaOrigen"
                            value={ anotacionesFacturaOrigen }
                            onChange={ (e) => setAnotacionesFacturaOrigen(e.target.value) }
                        />
                    </div>
                </div>

                <hr></hr>
            
                {/* ******************************** */}
                {/* BLOQUE DE LOS DATOS DE LA UNIDAD */}
                {/* ******************************** */}
            
                <div className="datosPersonales__formulario row">
                    <div className="col-4">
                        <label htmlFor="id_marca_vehiculo">
                            Marca:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_marca_vehiculo"
                            name="id_marca_vehiculo"
                            onChange = { (e) => onChangeMarca(e.target.value) }
                            required
                            data-live-search="true"
                            >   
                            <option value="">Elige una marca</option>
                            {
                                marcasVehiculo.map( (marca) => (
                                    <option key={ marca.id_marca_vehiculo } value={ marca.id_marca_vehiculo } >
                                        { marca.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="id_submarca_vehiculo">
                            Submarca:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_submarca_vehiculo"
                            name="id_submarca_vehiculo"
                            onChange = { (e) => setSubmarca(e.target.value) }
                            required
                            data-live-search="true"
                            disabled
                        >   
                            <option value="">Elige una submarca</option>
                            {
                                submarcasVehiculo.map( (submarca) => (
                                    <option key={ submarca.id_submarca_vehiculo } value={ submarca.id_submarca_vehiculo } >
                                        { submarca.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="modeloUnidad">
                            Modelo:
                        </label>
                        <input 
                            type="text"
                            className="input" 
                            onKeyPress={ validateNoNumbers }
                            id="modeloUnidad"
                            name="modeloUnidad"
                            value={ modeloUnidad }
                            onChange={ (e) => setModeloUnidad(e.target.value) }
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="id_tipo_vehiculo">
                            Tipo de vehículo:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_tipo_vehiculo"
                            name="id_tipo_vehiculo"
                            onChange = { (e) => setTipoVehiculo(e.target.value) }
                            required
                            data-live-search="true"
                        >   
                            <option value="">Elige tipo de vehículo</option>
                            {
                                tiposVehiculos.map( (tipo_vehiculo) => (
                                    <option key={ tipo_vehiculo.id_tipo_vehiculo } value={ tipo_vehiculo.id_tipo_vehiculo } >
                                        { tipo_vehiculo.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="id_tipo_combustible">
                            Tipo de combustible:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_tipo_combustible"
                            name="id_tipo_combustible"
                            onChange = { (e) => setTipoCombustible(e.target.value) }
                            required
                            data-live-search="true"
                        >   
                            <option value="">Elige un tipo de combustible</option>
                            {
                                tiposCombustibles.map( (tipo_combustible) => (
                                    <option key={ tipo_combustible.id_tipo_combustible } value={ tipo_combustible.id_tipo_combustible } >
                                        { tipo_combustible.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="numeroSerie">
                            Número de serie:
                        </label>
                        <input 
                            type="text"
                            className="input"
                            id="numeroSerie"
                            name="numeroSerie"
                            value={ numeroSerie }
                            onChange={ (e) => setNumeroSerie(e.target.value) }
                            required
                            />
                    </div>
                    <div className="col-4">
                        <label htmlFor="numeroMotor">
                            Número de motor:
                        </label>
                        <input 
                            type="text"
                            className="input"
                            id="numeroMotor"
                            name="numeroMotor"
                            value={ numeroMotor }
                            onChange={ (e) => setNumeroMotor(e.target.value) }
                            required
                            />
                    </div>
                    <div className="col-4">
                        <label htmlFor="numeroPasajeros">
                            Número de pasajeros:
                        </label>
                        <input 
                            type="num"
                            onKeyPress={ validateNoNumbers }
                            className="input"
                            id="numeroPasajeros"
                            name="numeroPasajeros"
                            value={ numeroPasajeros }
                            onChange={ (e) => setNumeroPasajeros(e.target.value) }
                            required
                            />
                    </div>
                    <div className="col-4">
                        <label htmlFor="placas">
                            Placas:
                        </label>
                        <input 
                            type="text" 
                            className="input" 
                            id="placas"
                            name="placas"
                            value={ placas }
                            onChange={ (e) => setPlacas(e.target.value) }
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="vigenciaSeguro">
                            Vigencia del seguro:
                        </label>
                        <input 
                            type="date" 
                            className="input"
                            id="vigenciaSeguro"
                            name="vigenciaSeguro"
                            value={ vigenciaSeguro }
                            onChange={ (e) => setVigenciaSeguro(e.target.value) }
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="numero_poliza_seguro">
                            Número de póliza del seguro:
                        </label>
                        <input 
                            type="text"
                            className="input"
                            id="numero_poliza_seguro"
                            name="numero_poliza_seguro"
                            value={ numeroPolizaSeguro }
                            onChange={ (e) => setNumeroPolizaSeguro(e.target.value) }
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="numeroTarjetaCirculacion">
                            Número de tarjeta de circulación:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="numeroTarjetaCirculacion"
                            name="numeroTarjetaCirculacion"
                            value={ numeroTarjetaCirculacion }
                            onChange={ (e) => setNumeroTarjetaCirculacion(e.target.value) }
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="fechaOperacion">
                            Fecha cuando comienza a operar la unidad:
                        </label>
                        <input 
                            type="date" 
                            className="input"
                            id="fechaOperacion"
                            name="fechaOperacion"
                            value={ fechaOperacion }
                            onChange={ (e) => setFechaOperacion(e.target.value) }
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="colorEngomado">
                            Color de engomado:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="colorEngomado"
                            name="colorEngomado"
                            value={ colorEngomado }
                            onChange={ (e) => setColorEngomado(e.target.value) }
                            required
                        >
                            <option value="A" defaultValue>
                                Amarillo
                            </option>
                            <option value="R">
                                Rosa
                            </option>
                            <option value="RO">
                                Rojo
                            </option>
                            <option value="V">
                                Verde
                            </option>
                            <option value="AZ">
                                Azul
                            </option>
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="colorUnidad">
                            Color de la unidad:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="colorUnidad"
                            name="colorUnidad"
                            value={ colorUnidad }
                            onChange={ (e) => setColorUnidad(e.target.value) }
                            required
                        />
                    </div>
                    <div className="col-12">
                            <label htmlFor="anotacionesUnidad">
                                Anotaciones:
                            </label>
                            <textarea 
                                rows={5}
                                cols={5}
                                type="text"
                                placeholder="(Opcional) Aquí puedes anotar cualquier detalle o comentario extra sobre la unidad" 
                                className="input textarea"
                                id="anotacionesUnidad"
                                name="anotacionesUnidad"
                                value={ anotacionesUnidad }
                                onChange={ (e) => setAnotacionesUnidad(e.target.value) }
                            />
                        </div>
                </div>
               
               
                

                {/* ********************************* */}
                {/* BLOQUE DE LA SUBIDA DE DOCUMENTOS */}
                {/* ********************************* */}

                <div className="subidaDocumentos mt-5">
                    <h3>
                        Documentos de la unidad
                    </h3>
                    
                    <p>
                        Tienes que subir cada documento por separado, da clic en el botón del pdf que quieres subir y selecciona el archivo.
                    </p>

                    <div className="subidaDocumentos__formulario row mt-3">
                        <div className="col-4">
                            <input type="file" id="polizaSeguro" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "polizaSeguro") } />
                            <label htmlFor="polizaSeguro">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Subir póliza de seguro
                                </a>
                            </label>
                            <embed
                                id="polizaSeguroPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>
                        
                        <div className="col-4">
                            <input type="file" id="tarjetaCirculacionPDF" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "tarjetaCirculacionPDF") } />
                            <label htmlFor="tarjetaCirculacionPDF">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Subir tarjeta de circulación
                                </a>
                            </label>
                            <embed
                                id="tarjetaCirculacionPDFPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="tablaAmortizacion" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "tablaAmortizacion") } />
                            <label htmlFor="tablaAmortizacion">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Subir tabla de amortización
                                </a>
                            </label>
                            <embed
                                id="tablaAmortizacionPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="documentoBajaPlacas" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "documentoBajaPlacas") } />
                            <label htmlFor="documentoBajaPlacas">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Documento de baja de placas
                                </a>
                            </label>
                            <embed
                                id="documentoBajaPlacasPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-8">
                            <input type="file" id="documentoVoBo" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "documentoVoBo") } />
                            <label htmlFor="documentoVoBo">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Documento de VoBo Secretaría de movilidad
                                </a>
                            </label>
                            <embed
                                id="documentoVoBoPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-12">
                            <label htmlFor="anotacionesDocumentosUnidad">
                                Anotaciones:
                            </label>
                            <textarea 
                                rows={5}
                                cols={5}
                                type="text"
                                placeholder="(Opcional) Aquí puedes anotar cualquier detalle o comentario extra sobre los documentos del vehículo" 
                                className="input textarea"
                                id="anotacionesDocumentosUnidad"
                                name="anotacionesDocumentosUnidad"
                                value={ anotacionesDocumentosUnidad }
                                onChange={ (e) => setAnotacionesDocumentosUnidad(e.target.value) }
                            />
                        </div>

                    </div>

                </div>

                {/* ******************************* */}
                {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
                {/* ******************************* */}

                <hr></hr>
                <div className="d-flex justify-content-end">
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus" />
                        <Icon icon="fa-solid fa-truck-moving mr-3" />
                        Agregar unidad
                    </button>
                </div>


            </form>


        </section>
    );
}