import React from 'react';
import Swal from 'sweetalert2'
import updateEstatusPrefijoEconomico from './../../services/catalogos/prefijos_economico/updateEstatusPrefijoEconomico';
import deletePrefijoEconomico from './../../services/catalogos/prefijos_economico/deletePrefijoEconomico';

export default function TableElementPrefijosEconomicos({ element, permisosUsuario, getPrefijosEconomicosService }) {

    let { id_prefijo_economico, clave, modalidad, activo } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(activo);

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitarlo?' : 'Quieres deshabilitarlo?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_prefijo_economico', id_prefijo_economico)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                console.log({objectValues})
                updateEstatusPrefijoEconomico({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo)
                        Swal.fire({
                            title:'Actualizado correctamente',
                            timer:1200,
                            showConfirmButton:false,
                            icon:'success'
                        })
                    }
                    
                })
            }
        })
    }
    
    const onClickDelete = () => {
        Swal.fire({
            title: 'Seguro que quieres eliminarlo?',
            showCancelButton: true,
            confirmButtonText: 'Si, seguro',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_prefijo_economico', id_prefijo_economico)
                let objectValues = Object.fromEntries(values);
                console.log({objectValues})
                deletePrefijoEconomico({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getPrefijosEconomicosService()
                        Swal.fire({
                            title:'Eliminado',
                            timer:1000,
                            showConfirmButton:false,
                            icon:'success'
                        })
                    }
                    
                    if (_respuesta.response === "error") {
                        Swal.fire({
                            title:'No se puede eliminar',
                            text:"Existen registros vinculados a este dato, no se puede eliminar hasta que elimines todos los registros vinculados a él",
                            showConfirmButton:true,
                            icon:'info'
                        })
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_prefijo_economico}>
            <th>
                <div className="table__name">
                    {
                        (permisosUsuario[0].editar === '1' || permisosUsuario[0].eliminar === '1') &&

                        <div className="dropdown">
                            <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                {/* {
                                    permisosUsuario[0].editar === '1' &&
                                    <a className="dropdown-item text-black" onClick={() => onClickEstatus(false)}>
                                        Editar
                                    </a>
                                } */}
                                {
                                    permisosUsuario[0].editar === '1' &&
                                        (estadoEstatus == 1 ?
                                        <a className="dropdown-item bg-warning text-black" onClick={() => onClickEstatus(false)}>
                                            Deshabilitar
                                        </a>
                                        :
                                        <a className="dropdown-item bg-success text-white" onClick={() => onClickEstatus(true)}>
                                            Habilitar
                                        </a>)
                                }
                                {
                                    permisosUsuario[0].eliminar === '1' &&
                                        <a className="dropdown-item bg-danger text-white" onClick={() => onClickDelete()}>
                                            Eliminar
                                        </a>
                                }
                            </div>
                        </div>
                    }
                    <div className="table__nameDescription">
                        <p>{clave}</p>
                        <small>ID: {id_prefijo_economico}</small>
                    </div>
                </div>
            </th>

            <td>
                <div className="table__fechaAlta">
                    <p>{modalidad}</p>
                    {/* <small>{hour}</small> */}
                </div>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ estadoEstatus == 1 ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus == 1 ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}