import React from "react";
import './CardCorteSemanal.css'

export default function CardCorteSemanal(props){

    let dollarUSLocale = Intl.NumberFormat('en-US');

    return(
        <div className='d-flex justify-content-evenly globalCardsCorteSemanal'>
            <div className='cardCorteSemanal cardCorteSemanal--no-border-left'>
                <small className='font-weight-bold'>Gastos de empresa:</small>
                <h2>$&nbsp;
                {
                    props.total_empresa !== undefined ?
                        dollarUSLocale.format(props.total_empresa) : 0
                }
                </h2>
                {
                    (props.porcentaje_aportado_empresa !== null && props.porcentaje_aportado_empresa !== undefined) && 
                        <small>Aportado: {props.porcentaje_aportado_empresa} %</small>
                }
                {
                    (props.porcentaje_obligado_empresa !== null && props.porcentaje_obligado_empresa !== undefined) && 
                        <>
                            <br />
                            <small>Obligado: {props.porcentaje_obligado_empresa} %</small>
                        </>
                }

            </div>
            <div className='cardCorteSemanal'>
                <small className='color-gris font-weight-bold'>Gastos de permisionario:</small>
                <h2>$&nbsp;
                {
                    props.total_permisionario !== undefined ?
                        dollarUSLocale.format(props.total_permisionario) : 0
                }
                </h2>
                {
                    (props.porcentaje_aportado_permisionario !== null && props.porcentaje_aportado_permisionario !== undefined) && 
                        <small>Aportado: {props.porcentaje_aportado_permisionario} %</small>
                }
                {
                    (props.porcentaje_obligado_permisionario !== null && props.porcentaje_obligado_permisionario !== undefined) && 
                        <>
                            <br />
                            <small>Obligado: {props.porcentaje_obligado_permisionario} %</small>
                        </>
                }
            </div>
            <div className='cardCorteSemanal'>
                <small className='color-gris font-weight-bold'>Gastos de operador:</small>
                <h2>$&nbsp;
                {
                    props.total_operador !== undefined ?
                        dollarUSLocale.format(props.total_operador) : 0
                }
                </h2>
                {
                    (props.porcentaje_aportado_operador !== null && props.porcentaje_aportado_operador !== undefined) && 
                        <small>Aportado: {props.porcentaje_aportado_operador} %</small>
                }
                {
                    (props.porcentaje_obligado_operador !== null && props.porcentaje_obligado_operador !== undefined) && 
                        <>
                            <br />
                            <small>Obligado: {props.porcentaje_obligado_operador} %</small>
                        </>
                }

            </div>
            <div className='cardCorteSemanal'>
                <small className='color-verde font-weight-bold'>Total:</small>
                <h2 className="color-verde">$&nbsp;
                {
                    props.total_siniestro !== undefined ?
                        dollarUSLocale.format(props.total_siniestro) : 0
                }
                </h2>
                {
                    (props.total_aportaciones !== undefined && props.total_aportaciones !== null && props.total_aportaciones !== 0) &&
                        <small>Aportaciones:&nbsp;$
                            { dollarUSLocale.format(props.total_aportaciones) }
                        </small>
                }
            </div>
        </div>
    )
}