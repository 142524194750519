import React from 'react'
import Swal from 'sweetalert2'
import addGastoContralorSiniestro from '../../services/gasto_contralor/addGastoContralorSiniestro'
import approveGastoSiniestro from '../../services/siniestro/approveGastoSiniestro'
import deleteGastoSiniestro from '../../services/siniestro/deleteGastoSiniestro'
import Icon from '../Icon'

export default function TableElementGastoSiniestro({
  index, element, elements, setElements, permisosUsuario, updateElements, idEstadoSiniestro, prorratasSiniestro
}) {
  const IdAreaSiniestros = '3' // Este es el tipo de trabajador (Director de siniestros) que puede cerrar un siniestro (esto segun la tabla "tipo_trabajador" de mysql)
  const { director_area_empresa_trabajador_seleccionado } = JSON.parse(localStorage.getItem('user'))

  const {
    id_gasto_siniestro, 
    cantidad, 
    nombre_tipo_gasto_siniestro, 
    nombre_abonante_siniestro, 
    nombre_metodo_pago, 
    descripcion, 
    correo_usuario_alta, 
    es_aportacion, 
    aprobado, 
    motivo_rechazo, 
    fecha_alta, 
    fecha,
    gasto_contralor, 
    id_lesionado_siniestro, 
    id_vehiculo_afectado_siniestro, 
    id_bien_afectado_siniestro, 
    lesionado, 
    vehiculo_afectado, 
    bien_afectado, 
    movimiento_egreso, 
    previo_movimiento_egreso
  } = element

  const [esAprobado, setEsAprobado] = React.useState(aprobado)

  const onClickDelete = () => {
    setElements([])
    const newElements = elements.filter((e, i) => (i !== index))
    setElements(newElements)
  }

  const deleteGastoSiniestroService = () => {
    // Eliminamos el lesionado de la tabla lesionados_siniestro con el id_gasto_siniestro
    const data = { id_gasto_siniestro }

    deleteGastoSiniestro({ values: data })
      .then((res) => {
        const _res = JSON.parse(res)
        if (_res.response === 'success') {
          onClickDelete()
          updateElements()
          return true
        }

        if (_res.response === "error") {
            Swal.fire({
                title:'No se puede eliminar',
                text:"Existen registros vinculados a este gasto, no se puede eliminar hasta que elimines todos los registros vinculados a él",
                showConfirmButton:true,
                icon:'info'
            })
        }

        return false
      })
      .catch((err) => () => {
        console.error(err)
        return false
      })
  }

  const approveGastoSiniestroService = async (approved) => {
    let motivo_rechazo;
    if(approved === '0'){
      const { value: motivo } = await Swal.fire({
        title: 'Porqué no lo apruebas?',
        input: 'text',
        inputPlaceholder: 'Escríbelo aquí',
        confirmButtonText: 'Rechazar'
      })
      
      if (motivo) {
        motivo_rechazo = motivo;
      }
    }

    const data = {
      approved,
      id_gasto_siniestro,
      motivo_rechazo: motivo_rechazo
    }
    approveGastoSiniestro({ values: data })
      .then((res) => {
        console.log(res)
        const _res = JSON.parse(res)
        if (_res.response === 'success') {
          setEsAprobado(approved)
          updateElements()
        } else {
          Swal.fire({
            icon:'error',
            title:'Ocurrió un error en el servidor al aprobar el gasto',
            text:'Repórtalo al desarrollador'
          })
        }
      })
  }

  const solicitarRecursoSiniestroService = ()=>{
    const data = {
      id_gasto_siniestro,
    }

    addGastoContralorSiniestro({values:data})
      .then((res) => {
        console.log(res)
        const _res = JSON.parse(res)
        if (_res.response === 'success') {
          updateElements()
        } else {
          Swal.fire({
            icon:'error',
            title:'Ocurrió un error en el servidor al aprobar el gasto',
            text:'Repórtalo al desarrollador'
          })
        }
      })
      .catch((error) =>{
        console.log(error)
        Swal.fire({
          icon:'error',
          title:'Ocurrió una excepción',
          text:error
        })
      })
  }

  const renderAprobar = () => {
    if( director_area_empresa_trabajador_seleccionado !== undefined && 
        director_area_empresa_trabajador_seleccionado.some(value => value.id_area_empresa === IdAreaSiniestros) ){
      
      // Si es director de siniestros, puede aprobar o no un gasto
      if (es_aportacion !== '1' && (esAprobado === null || esAprobado === undefined)) {
        return (
          <React.Fragment>
            <a href className="dropdown-item" onClick={() => approveGastoSiniestroService('1')}>
              Aprobar gasto
            </a>
            <a href className="dropdown-item" onClick={() => approveGastoSiniestroService('0')}>
              No aprobar gasto
            </a>
          </React.Fragment>
        )
      }
        
      if (es_aportacion !== '1' && esAprobado === '1' && gasto_contralor === undefined) {
        return (
          <React.Fragment>
            <a href className="dropdown-item" onClick={() => solicitarRecursoSiniestroService()}>
              Solicitar recurso
            </a>
          </React.Fragment>
        )
      }
    }
  }

  const setBackgroundColorGasto = () =>{
    if((esAprobado === null || esAprobado === undefined) && (es_aportacion !== '1')) {
      return 'bg-color-amarillo'
    }
    
    if(esAprobado === '1' && gasto_contralor === undefined && (es_aportacion !== '1')){
      return 'bg-color-amarillo'
    }

    if(gasto_contralor !== undefined){
      if(gasto_contralor.aprobado === null){
        return 'bg-color-amarillo'
      }
      if(gasto_contralor.aprobado === '1'){
        // return 'bg-color-verde'
      }
      if(gasto_contralor.aprobado === '0'){
        // return 'bg-color-rojo'
      }
    }
  }

  // Solo se pueden aprobar/desaprobar gastos que no sean aportación.

  return (
    <tr className={setBackgroundColorGasto()}>
      <th>
        <div className="table__name">

          {
            // PRIMERO VALIDAMOS QUE EL SINIESTRO SIGA ABIERTO, PARA PODER HACER CUALQUIER COSA
            idEstadoSiniestro === '1' &&

            // Verificamos si existe ya una prorrata, y sí existe, tomar la fecha de la última prorrata hecha y verificar sí
            // la fecha de este gasto es mayor a la fecha de cierre del último siniestro.
            (prorratasSiniestro === null || (prorratasSiniestro !== null && (new Date(fecha_alta) > new Date(prorratasSiniestro[0].fecha_alta)))) &&

            // Luego verificamos si el usuario tiene permisos de eliminar o es director de siniestros, Y si el gasto contralor aún no 
            // ha sido solicitado, y sī ya se solicitó, verificar sí está aprobado o no ese gasto
            (id_gasto_siniestro !== undefined && id_gasto_siniestro !== null)
              && (((director_area_empresa_trabajador_seleccionado !== undefined && director_area_empresa_trabajador_seleccionado.some(value => value.id_area_empresa === IdAreaSiniestros)) || permisosUsuario[0].eliminar === '1') && (gasto_contralor === undefined || gasto_contralor.aprobado === null))
                && (
                  <div className="dropdown">
                    <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      {
                        permisosUsuario[0].eliminar === '1'
                                && (
                                  <a href className="dropdown-item bg-danger text-white" onClick={() => deleteGastoSiniestroService()}>
                                  Eliminar
                                  </a>
                                )
                      }
                      {
                        renderAprobar()
                      }
                    </div>
                  </div>
                )
          }
          {
            (id_gasto_siniestro === undefined || id_gasto_siniestro === null)
              && (
                <div className="dropdown">
                  <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a href className="dropdown-item bg-danger text-white" onClick={() => onClickDelete()}>
                    Eliminar
                    </a>
                  </div>
                </div>
              )
          }
          <p>
            <small className="text-muted">Fecha del gasto: {fecha}</small>
            <br />
            {nombre_tipo_gasto_siniestro}
            <br />
            <small className="text-muted">
              Método pago:
              {' '}
              { nombre_metodo_pago }
            </small>
            <br />
            <small className="text-muted">
              Dio de alta:
              {' '}
              { correo_usuario_alta }
            </small>
            <br />
            <small className="text-muted">Fecha alta sistema: {fecha_alta}</small>
          </p>
        </div>
      </th>

      <td>
        <p>
          { parseFloat(cantidad).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
        </p>
        {
          es_aportacion === '1'
            && (
              <div className="table__estatus mt-2">
                <span className="table__estatus-warning d-flex align-items-center">
                  <Icon icon="fa-solid fa-hand-holding-dollar mr-2" />
                Aportación
                </span>
              </div>
            )
        }
        {
          gasto_contralor !== undefined &&
            gasto_contralor.aprobado === null
              && (
                <button 
                  onClick={()=>{
                    Swal.fire({
                      icon: 'info',
                      title:'En espera de aprobación del contralor',
                      text:`Recurso solicitado el: ${gasto_contralor.fecha_alta}.`
                    })
                  }}
                  className="btn p-0 table__estatus mt-2"
                >
                  <span className="table__estatus-gray d-flex align-items-center">
                    <Icon icon="fa-solid fa-comments-dollar mr-2" />
                    Recurso solicitado
                  </span>
                </button>
              )
        }
        {
          gasto_contralor !== undefined &&
            (gasto_contralor.aprobado === '1' && movimiento_egreso === undefined)
              && (
                <button 
                  onClick={()=>{
                    Swal.fire({
                      icon: 'info',
                      title:'Recurso aprobado por el contralor',
                      text:`Aprobado el: ${gasto_contralor.fecha_revisado}. Está pendiente de retirarse en ventanilla desde el día ${previo_movimiento_egreso.fecha_entrega}`
                    })
                  }}
                  className="btn p-0 table__estatus mt-2"
                >
                  <span className="table__estatus-active d-flex align-items-center">
                    <Icon icon="fa-solid fa-circle-check mr-2" />
                    Recurso aprobado
                  </span>
                </button>
              )
        }
        {
          gasto_contralor !== undefined &&
            (gasto_contralor.aprobado === '1' && movimiento_egreso !== undefined)
              && (
                <button 
                  onClick={()=>{
                    Swal.fire({
                      icon: 'info',
                      title:'Recurso retirado',
                      text:`Ha sido retirado por: ${movimiento_egreso.nombre_quien_retiro} el ${movimiento_egreso.fecha_alta}. ${movimiento_egreso.descripcion ? 'Descripcion: '+movimiento_egreso.descripcion : ''}`
                    })
                  }}
                  className="btn p-0 table__estatus mt-2"
                >
                  <span className="table__estatus-active d-flex align-items-center">
                    <Icon icon="fa-solid fa-circle-check mr-2" />
                    Recurso retirado
                  </span>
                </button>
              )
        }
        {
          gasto_contralor !== undefined &&
            gasto_contralor.aprobado === '0'
              && (
                <button 
                  onClick={()=>{
                    Swal.fire({
                      icon: 'info',
                      title:'Recurso rechazado por el contralor',
                      text:`Motivo: ${gasto_contralor.motivo_rechazo}. Rechazado el: ${gasto_contralor.fecha_revisado}.`
                    })
                  }}
                  className="btn p-0 table__estatus mt-2"
                >
                  <span className="table__estatus-inactive d-flex align-items-center">
                    <Icon icon="fa-solid fa-circle-xmark mr-2" />
                    Recurso rechazado
                  </span>
                </button>
              )
        }
      </td>

      <td>
        <p>
          { nombre_abonante_siniestro }
        </p>
        {
          (esAprobado !== null)
            && esAprobado === '1'
                && (
                  <div
                    className="table__estatus mt-2"
                  >
                    <span className="table__estatus-active">
                    Aprobado
                    </span>
                  </div>
                )
        }

        {
          (esAprobado !== null)
            && esAprobado === '0'
                && (
                  <button 
                    onClick={()=>{
                      Swal.fire({
                        icon: 'info',
                        title:'Recurso rechazado por el director',
                        text:`Motivo: ${motivo_rechazo}`
                      })
                    }}
                    className="table__estatus mt-2 btn p-0"
                  >
                    <span className="table__estatus-inactive">
                    No aprobado
                    </span>
                  </button>
                )
        }
      </td>

      <td>
        {
          (lesionado !== undefined && id_lesionado_siniestro !== null) &&
            <>
            <small>
              {`Lesionado seleccionado: ${lesionado.nombre_completo}`}
            </small>
            <br/>
            </>
        }
        {
          (vehiculo_afectado !== undefined && id_vehiculo_afectado_siniestro !== null) &&
            <>
            <small>
              {`Vehiculo seleccionado: ${vehiculo_afectado.nombre_conductor}, Modelo: ${vehiculo_afectado.modelo}, Placas: ${vehiculo_afectado.placas}, ${vehiculo_afectado.nombre_marca_vehiculo ? vehiculo_afectado.nombre_marca_vehiculo : 'Sin marca'},${vehiculo_afectado.nombre_submarca_vehiculo ? vehiculo_afectado.nombre_submarca_vehiculo : 'Sin submarca'}`}
            </small>
            <br/>
            </>
        }
        {
          (bien_afectado !== undefined && id_bien_afectado_siniestro !== null) &&
            <>
            <small>
              {`Bien afectado: ${bien_afectado.nombre_tipo_de_bien}, ${bien_afectado.descripcion}`}
            </small>
            <br/>
            </>
        }
        <p>
          { descripcion || '-' }
        </p>
      </td>
    </tr>
  )
}
