import React from "react";
import $ from 'jquery'
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import getTiposGastoSiniestroActive from "../../services/catalogos/tipo_gasto_siniestro/getTiposGastoSiniestroActive";
import getMetodoPagoActive from "../../services/catalogos/metodo_pago/getMetodosPagoActive";
import getAbonantesSiniestro from "../../services/catalogos/abonante_siniestro/getAbonantesSiniestro";

export default function ModalAddGastoSiniestro(props){

    let { lesionados, vehiculosAfectados, bienesAfectados } = props

    const [tipoGasto, setTipoGasto] = React.useState([]);
    const [metodosPago, setMetodosPago] = React.useState([]);
    const [abonantes, setAbonantes] = React.useState([]);

    const [tipoGastoSelected, setTipoGastoSelected] = React.useState(null);

    const getTiposGastosSiniestroService = ()=>{
        getTiposGastoSiniestroActive().then(res=>{
            let _res = JSON.parse(res);
            if(!(_res.response === 'error')){
                setTipoGasto(_res.data);
            } else {
                setTipoGasto([])
            }
            $('.selectpicker').selectpicker('refresh')
        })
    }

    const getMetodosPagoService = ()=>{
        getMetodoPagoActive().then(res=>{
            let _res = JSON.parse(res);
            if(!(_res.response === 'error')){
                setMetodosPago(_res.data);
            } else {
                setMetodosPago([])
            }
            $('.selectpicker').selectpicker('refresh')
        })
    }

    const getAbonantesSiniestroService = ()=>{
        getAbonantesSiniestro().then(res=>{
            let _res = JSON.parse(res);
            if(!(_res.response === 'error')){
                setAbonantes(_res.data);
            } else {
                setAbonantes([])
            }
            $('.selectpicker').selectpicker('refresh')
        })
    }

    // const getSubmarcasVehiculosService = (id_marca_vehiculo)=>{
    //     let form = new FormData()
    //     form.append('id_marca_vehiculo',id_marca_vehiculo)
    //     let objectValues = Object.fromEntries(form);
    //     getSubmarcasVehiculosByMarca({values:objectValues}).then(res=>{
    //         let _res = JSON.parse(res);
    //         if(!(_res.response === 'error')){
    //             $("#id_submarca_vehiculo").attr("disabled",false)
    //             setSubmarcasVehiculo(_res.data);
    //         } else {
    //             $("#id_submarca_vehiculo").attr("disabled",true)
    //             setSubmarcasVehiculo([])
    //         }
    //         $('.selectpicker').selectpicker('refresh')
    //     })
    // }
    
    // const onChangeMarca = (value)=>{
    //     getSubmarcasVehiculosService(value)
    // }

    React.useEffect(()=>{
        $(".selectpicker").selectpicker('refresh');
    },[tipoGastoSelected])
    
    React.useEffect(getTiposGastosSiniestroService,[])
    React.useEffect(getMetodosPagoService,[])
    React.useEffect(getAbonantesSiniestroService,[])

    return(
        <div className="modal" id="modalAddGastoSiniestro" tabIndex="-1" role="dialog" aria-labelledby="modalAddGastoSiniestroLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={props.onSubmit} id='formAddGastoSiniestro' className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddGastoSiniestroLabel">Agrega un gasto</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="cantidad">
                                    Importe:
                                </label>
                                <input 
                                    type="tel" 
                                    className="input"
                                    id="cantidad"
                                    name="cantidad"
                                    placeholder="$"
                                    onKeyPress={ validateNoNumbers }
                                    maxLength={9}
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="id_metodo_pago">
                                    Método de pago:
                                </label>
                                <select 
                                    className="input selectpicker"
                                    id="id_metodo_pago"
                                    name="id_metodo_pago"
                                    data-live-search="true"
                                    required
                                >   
                                    <option value="">Elige un método de pago</option>
                                    {
                                        metodosPago.map( (metodo_pago) => (
                                            <option key={ metodo_pago.id_metodo_pago } value={ metodo_pago.id_metodo_pago } >
                                                { metodo_pago.nombre }
                                            </option>
                                        ) )
                                    }
                                </select>
                            </div>
                            <div className="col-6">
                                <label htmlFor="id_abonante_siniestro">
                                    Aplicado por:
                                </label>
                                <select 
                                    className="input selectpicker"
                                    id="id_abonante_siniestro"
                                    name="id_abonante_siniestro"
                                    data-live-search="true"
                                    required
                                >   
                                    <option value="">Elige ...</option>
                                    {
                                        abonantes.map( (abonante) => (
                                            <option key={ abonante.id_abonante_siniestro } value={ abonante.id_abonante_siniestro } >
                                                { abonante.nombre }
                                            </option>
                                        ) )
                                    }
                                </select>
                            </div>
                            <div className="col-6">
                                <label htmlFor="fecha">
                                    Fecha del gasto:
                                </label>
                                <input 
                                    type="date" 
                                    className="input"
                                    id="fecha"
                                    name="fecha"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="id_tipo_gasto_siniestro">
                                    Tipo de gasto:
                                </label>
                                <select 
                                    className="input selectpicker"
                                    id="id_tipo_gasto_siniestro"
                                    name="id_tipo_gasto_siniestro"
                                    data-live-search="true"
                                    onChange={(e) => {
                                        setTipoGastoSelected(e.target.value)
                                    }}
                                    required
                                    >   
                                    <option value="">Elige un tipo de gasto</option>
                                    {
                                        tipoGasto.map( (tipo_gasto) => (
                                            <option key={ tipo_gasto.id_tipo_gasto_siniestro } value={ tipo_gasto.id_tipo_gasto_siniestro } >
                                                { tipo_gasto.nombre }
                                            </option>
                                        ) )
                                    }
                                </select>
                            </div>

                            {
                                // Si el tipo de gasto seleccionado es 'LIQUIDACION DE DAÑOS MATERIALES id:4'
                                (tipoGastoSelected === '4') && 
                                    <div className="col-6">
                                        <label htmlFor="id_bien_afectado_siniestro">
                                            Daño a bien del siniestro:
                                        </label>
                                        <select 
                                            className="input selectpicker"
                                            id="id_bien_afectado_siniestro"
                                            name="id_bien_afectado_siniestro"
                                            data-live-search="true"
                                            required
                                            >   
                                            <option value="">Elige un bien afectado</option>
                                            {
                                                bienesAfectados.map( (bien_afectado) => (
                                                    <option key={ bien_afectado.id_bien_afectado_siniestro } value={ bien_afectado.id_bien_afectado_siniestro } >
                                                        { `${bien_afectado.nombre_tipo_de_bien}, ${bien_afectado.descripcion}` }
                                                    </option>
                                                ) )
                                            }
                                        </select>
                                    </div>
                            }

                            {
                                // Si el tipo de gasto seleccionado es 'LIQUIDACIÓN DE LESIONADOS id:5'
                                (tipoGastoSelected === '5') && 
                                    <div className="col-6">
                                        <label htmlFor="id_lesionado_siniestro">
                                            Lesionados del siniestro:
                                        </label>
                                        <select 
                                            className="input selectpicker"
                                            id="id_lesionado_siniestro"
                                            name="id_lesionado_siniestro"
                                            data-live-search="true"
                                            required
                                            >   
                                            <option value="">Elige un lesionado</option>
                                            {
                                                lesionados.map( (lesionado) => (
                                                    <option key={ lesionado.id_lesionado_siniestro } value={ lesionado.id_lesionado_siniestro } >
                                                        { lesionado.nombre_completo }
                                                    </option>
                                                ) )
                                            }
                                        </select>
                                    </div>
                            }

                            {
                                // Si el tipo de gasto seleccionado es 'LIQUIDACION DE DAÑOS A VEHICULOS id:6'
                                (tipoGastoSelected === '6') && 
                                    <div className="col-6">
                                        <label htmlFor="id_vehiculo_afectado_siniestro">
                                            Daño a vehículo:
                                        </label>
                                        <select 
                                            className="input selectpicker"
                                            id="id_vehiculo_afectado_siniestro"
                                            name="id_vehiculo_afectado_siniestro"
                                            data-live-search="true"
                                            required
                                            >   
                                            <option value="">Elige un vehículo afectado</option>
                                            {
                                                vehiculosAfectados.map( (vehiculo_afectado) => (
                                                    <option key={ vehiculo_afectado.id_vehiculo_afectado_siniestro } value={ vehiculo_afectado.id_vehiculo_afectado_siniestro } >
                                                        { `${vehiculo_afectado.nombre_conductor } | 
                                                            Placas: ${vehiculo_afectado.placas}${vehiculo_afectado.nombre_marca ? ', '+ vehiculo_afectado.nombre_marca : ''}${vehiculo_afectado.nombre_submarca ? ', '+ vehiculo_afectado.nombre_submarca : ''} | 
                                                            Modelo: ${vehiculo_afectado.modelo}`   }
                                                    </option>
                                                ) )
                                            }
                                        </select>
                                    </div>
                            }
                            {/* <div className='col-6'>
                                <label>
                                    Es una aportación?
                                </label>
                                
                                <br />

                                <div className="form-check form-check-inline">
                                    <input 
                                        type="radio"
                                        name="es_aportacion"
                                        value={1}
                                        id={'operador-registrado'}
                                        // onChange={(e) => {
                                        //     setOperadorRegistrado(e.target.value)
                                        //     $("#id_operador").prop("required",true)
                                        //     $("#nombre_operador_no_registrado").prop("required",false)
                                        //     $("#apellido_paterno_operador_no_registrado").prop("required",false)
                                        //     $("#apellido_materno_operador_no_registrado").prop("required",false)
                                        //     document.getElementById('nombre_operador_no_registrado').value = ''
                                        //     document.getElementById('apellido_paterno_operador_no_registrado').value = ''
                                        //     document.getElementById('apellido_materno_operador_no_registrado').value = ''
                                        // }}
                                        required
                                    />
                                    <label className="form-check-label ml-1" htmlFor="operador-registrado">
                                        Si
                                    </label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input 
                                        type="radio"
                                        name="es_aportacion"
                                        value={0}
                                        id={'operador-no-registrado'}
                                        // onChange={(e) => { 
                                        //     setOperadorRegistrado(e.target.value)
                                        //     $("#id_operador").prop("required",false)
                                        //     $("#nombre_operador_no_registrado").prop("required",true)
                                        //     $("#apellido_paterno_operador_no_registrado").prop("required",true)
                                        //     $("#apellido_materno_operador_no_registrado").prop("required",true)
                                        //     document.getElementById('id_operador').value = ''
                                        //     $("#id_operador").selectpicker("refresh")
                                        // }}
                                        required
                                    />
                                    <label className="form-check-label ml-1" htmlFor="operador-no-registrado">
                                        No
                                    </label>
                                </div>

                                <br />

                                <small className="text-muted">
                                    Una aportación no se contemplará en los gastos globales del siniestro.
                                </small>

                            </div> */}
                            <div className="col-12">
                                <hr/>
                                <label htmlFor="descripcion">
                                    Descripción:
                                </label>
                                <textarea 
                                    type="text" 
                                    className="input"
                                    id="descripcion"
                                    name="descripcion"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primario" id="btnSubmitModalAddGastoSiniestro">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}