import React from 'react';
import "./FormularioDocumentosDerrotero.css"
import Icon from '../../components/Icon';
import $ from 'jquery';
import changeDocumentosDerroteroService from './../../services/changeDocumentosDerroteroService';
import Swal from 'sweetalert2'

export default function FormularioDocumentosDerrotero({ derrotero, permisosUsuario }){
    
    let{
        documento_pdf,
        id_derrotero,
    } = derrotero;

    function setDataFormulario(){

        if(permisosUsuario[0].editar === "0"){
            $("form :input, :input[form]").prop("readonly", true);
        }
    }

    function onSubmit(e){
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_derrotero', id_derrotero)
        changeDocumentosDerroteroService({values:values})
            .then(response => {
                console.log(response);
                let _response = JSON.parse(response);
                // console.log(_response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: '¡Listo!',
                        text: 'Se ha actualizado la factura',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No haz actualizado nada',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => console.log(error))
    }

    function onChangeFileInput(e, nombreInput){
        const file = e.target.files[0];
        document.getElementById(nombreInput+'Preview').src = URL.createObjectURL(file);
        $('#'+nombreInput+'Preview').removeClass("hidden")
    }

    React.useEffect(() => {
        setDataFormulario();
    }, []);

    return(

        <form className="formulario" onSubmit={ onSubmit }>

            <div className="perfil__fotoPerfil justify-content-center flex-column">
                <input type="file" name="documentoPDF" id="documentoPDF" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "documentoPDF") } />
                <div>
                    <label htmlFor="documentoPDF" className="perfil__cambiarFoto text-center">
                        <h4>
                            Documento PDF
                        </h4>
                        <p>
                            Da clic aquí para cargar un nuevo pdf del derrotero
                        </p>
                    </label>
                </div>
                <embed 
                    id="documentoPDFPreview" 
                    type="application/pdf" 
                    src={`${documento_pdf}`}
                    className={`previewPDF ${documento_pdf ? "" : "hidden"}`}
                />
            </div>

            {/* ******************************* */}
            {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
            {/* ******************************* */}

            {
            (permisosUsuario[0].editar !== "0") &&
            <React.Fragment>
                <hr></hr>
                <div className="d-flex justify-content-end">
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus" />
                        <Icon icon="fa-solid fa-file mr-3" />
                        Actualizar documento del derrotero
                    </button>
                </div>
            </React.Fragment>
            }


        </form>
    );
}