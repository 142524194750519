import React from 'react';
import Icon from "./../../components/Icon";
import Swal from 'sweetalert2'
import $ from 'jquery';
import approveGastoContralorSiniestro from '../../services/gasto_contralor/approveGastoContralorSiniestro';
import approveEntregaDeRecurso from '../../services/previo_movimiento_egresos/approveEntregaDeRecurso';

export default function TableElementEntregaRecursoAprobadoContalor({ element, permisosUsuario, updateElements }) {

    const { 
        id_previo_movimiento_egreso, 
        nombre_solicitante,
        entregado,
        fecha_entrega,
        fecha_entregado,
        fecha_aprobado_contralor,
        id_gasto_siniestro, 
        fecha_alta,
        cantidad
    } = element;
    
    React.useEffect(()=>{
        $('[data-toggle="tooltip"]').tooltip()
    },[])

    const onClickMarcarRecursoEntregado = async (aprobado)=>{
        if(aprobado){
            Swal.fire({
                title: 'Seguro que haz entregado el recurso?',
                icon: 'question',
                confirmButtonText: 'Si, seguro',
                cancelButtonText: 'Cancelar',
                showCancelButton: true
            }).then(value =>{
                if(value.isConfirmed){
                    let data = {
                        id_previo_movimiento_egreso: id_previo_movimiento_egreso,
                    }
            
                    approveEntregaDeRecurso({values:data})
                        .then(res =>{
                            console.log(res)
                            let _res = JSON.parse(res)
                            if(_res.response === 'success'){
                                let title = 'Gasto aprobado'
                                Swal.fire({
                                    title: title,
                                    icon: 'success',
                                    // text: '',
                                    showConfirmButton: false,
                                    timer: 1200,
                                })
                                updateElements()
                            }
                        })
                        .catch(err =>{
                            console.error(err)
                            Swal.fire({
                                text: 'Ocurrió un error, repórtalo con el desarrollador.',
                                icon: 'error',
                                showConfirmButton: false,
                                timer:1500
                            })
                        })
                }
            })
        } 
    }


    return (
        <tr key={id_previo_movimiento_egreso}>
            <th>
                <div className="table__name">

                    <div className="table__nameDescription">
                        <p>{ `${nombre_solicitante}` }</p>
                    </div>
                </div>
            </th>

            <td>
                <div className="table__derrotero">
                    {
                        fecha_entregado === null && <p>{ fecha_entrega }</p>
                    }
                    {
                        fecha_entregado !== null && <p>{ fecha_entregado }</p>
                    }
                    <small>
                        Se solicitó el: { `${fecha_alta}` }
                    </small>
                </div>
            </td>

            <td>
                <div className="table__derrotero">
                    <p>{`${parseFloat(cantidad).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}</p>
                </div>
            </td>

            <td>

                {
                (entregado === '0') && (permisosUsuario[0].editar === '1') && (permisosUsuario[0].escribir === '1') &&
                <>
                    <button 
                        className='btn btn-success btn-sm mr-2' 
                        data-toggle="tooltip" 
                        data-placement="top" 
                        title="Entregado"
                        onClick={() => onClickMarcarRecursoEntregado(true)}
                    >
                        <Icon icon={'fa-solid fa-check'} />
                    </button>
                </>
                }
            </td>
        </tr>
    )
    
}