import React from 'react';

import Icon from '../../components/Icon';
import { useHistory } from "react-router-dom"
import $ from 'jquery';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min.js';
import Swal from 'sweetalert2'
import config from '../../config';
const URL_API = config.urlApi;

export default function AddConcesion(){

    const history = useHistory()
    
    React.useEffect(() => {
        $('.selectpicker').selectpicker();
        getTiposConcesiones();
    }, []);

    function onChangeFileInput(e, nombreInput){
        const file = e.target.files[0];
        document.getElementById(nombreInput+'Preview').src = URL.createObjectURL(file);
        $('#'+nombreInput+'Preview').removeClass("hidden")
    }

    function onSubmitConcesion(e){
        e.preventDefault();

        const formData = new FormData();
        formData.append('claveConcesion',claveConcesion);
        formData.append('vigencia',vigencia);
        formData.append('fechaAlta',fechaAlta);
        formData.append('tipoConcesion',tipoConcesion);
        formData.append('documentoConcesion', document.getElementById("documentoConcesion").files.item(0))
        const user = JSON.parse(localStorage.getItem("user"))
        const id = user.id_usuario
        formData.append('id',id);

        $.ajax({
            xhr: function() {

                var xhr = new window.XMLHttpRequest();

                xhr.upload.addEventListener("progress", function(evt) {
                    if (evt.lengthComputable) {
                        Swal.fire({
                            title: 'Subiendo concesión...',
                            html: '<b className="counter"></b>% de 100%',
                            timerProgressBar: true,
                            showConfirmButton: false,
                            didOpen: () => {
                                Swal.showLoading()
                                const b = Swal.getHtmlContainer().querySelector('b')
                                var percentComplete = evt.loaded / evt.total;
                                percentComplete = parseInt(percentComplete * 100);
                                b.textContent = `${percentComplete}%`
                                console.log(percentComplete)
                        
                                if (percentComplete === 100) {
                                    // Swal.close()
                                    Swal.hideLoading();
                                    Swal.fire({
                                        title:"Concesión agregada",
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 1500
                                    }).then(function(){
                                        history.push('/concesiones');
                                    })
                                }
                            },
                        })
                    }
                }, false);
            
                return xhr;
            },
            url:URL_API+'controlador/concesion.controlador.php',
            data: formData,
            type: "POST",
            contentType: false,
            processData: false,
            // success:function(respuesta){
            //     // console.log(respuesta);
            //     let _respuesta = JSON.parse(respuesta);
            //     if(_respuesta.response === 'success'){
            //         Swal.fire(
            //             'Concesión agregada',
            //             '',
            //             'success'
            //         )
            //     } else {
            //         Swal.fire(
            //             'Ocurrió un error',
            //             'Vuelve a intentarlo',
            //             'error'
            //         )
            //     }
            // }
        })
    }

    function getTiposConcesiones(){
        $.ajax({
            url:URL_API+'controlador/getTiposConcesiones.controlador.php',
            success:function(respuesta){
                let _respuesta = JSON.parse(respuesta);
                setTiposConcesiones(_respuesta)
                $('.selectpicker').selectpicker('refresh');
            }
        })
    }

    const[tiposConcesiones, setTiposConcesiones] = React.useState([]);

    const [claveConcesion, setClaveConcesion] = React.useState('');
    const [vigencia, setVigencia] = React.useState('');
    const [fechaAlta , setFechaAlta] = React.useState('');
    const [tipoConcesion, setTipoConcesion] = React.useState('1');

    return (
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
            />

            <h2 className="mt-5">Agregar una concesión</h2>

            <p>
                Para agregar una concesión, tienes que completar el siguiente formulario:
            </p>

            <small>
                Sí la concesión <span className="font-weight-bold">tiene prórrogas</span>, se agregan después de darla de alta.
            </small>
            <br></br>
            <small>
                La concesión estará  <span className="font-weight-bold">desactivada hasta que se asigne a un permisionario.</span>.
            </small>

            <div className="perfil__fotoPerfil justify-content-center flex-column">
                <input type="file" id="documentoConcesion" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "documentoConcesion") } />
                <div>
                    <label htmlFor="documentoConcesion" className="perfil__cambiarFoto text-center">
                        <h4>
                            Documento de la concesión
                        </h4>
                        <p>
                            Da clic aquí para cargar el pdf de la concesión
                        </p>
                    </label>
                </div>
                <embed id="documentoConcesionPreview" className="previewPDF hidden" type="application/pdf" />
            </div>

            <form className="formulario" onSubmit={ onSubmitConcesion }>

                {/* ****************************** */}
                {/* BLOQUE DE LOS DATOS PERSONALES */}
                {/* ****************************** */}

                <div className="datosPersonales__formulario row">
                    <div className="col-4">
                        <label htmlFor="claveConcesion">
                            Clave:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="claveConcesion"
                            name="claveConcesion"
                            value={ claveConcesion }
                            onChange={ (e) => setClaveConcesion(e.target.value) }
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="vigencia">
                            Vigencia:
                        </label>
                        <input 
                            type="date"
                            className="input" 
                            id="vigencia"
                            name="vigencia"
                            value={ vigencia }
                            onChange={ (e) => setVigencia(e.target.value) }
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="fechaAlta">
                            Fecha de otorgamiento:
                        </label>
                        <input 
                            type="date"
                            className="input" 
                            id="fechaAlta"
                            name="fechaAlta"
                            value={ fechaAlta }
                            onChange={ (e) => setFechaAlta(e.target.value) }
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="tipoConcesion">
                            Tipo de concesión:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="tipoConcesion"
                            name="tipoConcesion"
                            value={ tipoConcesion }
                            onChange={ (e) => setTipoConcesion(e.target.value) }
                            required
                        >
                            {
                                tiposConcesiones &&
                                tiposConcesiones.map( (tipoConcesion) => (
                                    <option key={ tipoConcesion.id_tipo_concesion } value={ tipoConcesion.id_tipo_concesion }>{ tipoConcesion.nombre }</option>
                                ))
                            }
                        </select>
                    </div>
                    {/* <div className="col-8">
                        <label htmlFor="concesionPermisionario">
                            Asignar concesión a un permisionario:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="concesionPermisionario"
                            data-live-search="true"
                            required
                        >
                            <option value="F" defaultValue>
                                P0000001 - Gustavo Reyes
                            </option>
                            <option value="M">
                                P0000002 - Ricardo Acuña
                            </option>
                        </select>

                    </div> */}
                </div>
               
                {/* ******************************* */}
                {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
                {/* ******************************* */}

                <hr></hr>
                <div className="d-flex justify-content-end">
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus" />
                        <Icon icon="fa-solid fa-file-invoice mr-3" />
                        Agregar concesión
                    </button>
                </div>


            </form>


        </section>
    );
}