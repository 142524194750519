import React from "react";
import AddButton from "../../components/AddButton";
import AlertWarningSearch from "../../components/AlertWarningSearch";
import LoadingSearchIcon from "../../components/LoadingSearchIcon";
import TableBody from "../../components/TableCatalogo/TableBody";
import TableHead from "../../components/TableCatalogo/TableHead";
import TableCatalogo from "../../components/TableCatalogo";
import getTiposPermisionarios from "../../services/catalogos/tipo_permisionario/getTiposPermisionarios";
import addTipoPermisionario from "../../services/catalogos/tipo_permisionario/addTipoPermisionario";
import $ from 'jquery';
import Swal from "sweetalert2";
import ModalAddTipoPermisionario from "../../components/Catalogos/ModalAddTipoPermisionario";

export default function TiposPermisionario(props){
    const [elements, setElements] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    const rows = [
        { id: 1, name: "Nombre" },
        { id: 2, name: "Estatus" }
    ];

    function getTiposPermisionariosService(){
        setLoading(true)
        getTiposPermisionarios().then(res => {
            let _res = JSON.parse(res);
            console.log(_res)
            if(!(_res.response === 'error')){
                setElements(_res.data);
            } else {
                setElements([])
            }
            setLoading(false);
        })
    }

    const onSubmit = (e)=>{
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        addTipoPermisionario({values:objectValues}).then(res=>{
            console.log({res})
            let _res = JSON.parse(res)
            if(_res.response === 'success'){
                Swal.fire({
                    title:'Agregado correctamente',
                    timer:1200,
                    showConfirmButton:false,
                    icon:'success'
                })
                $("#modalAddTipoPermisionario").modal("hide")
                $("#formAddTipoPermisionario")[0].reset();
                getTiposPermisionariosService()
            }
        })
    }

    React.useEffect(getTiposPermisionariosService, []);

    return(
        <section>

            <ModalAddTipoPermisionario
                onSubmit={onSubmit}
            />

            <div className="d-flex justify-content-between">

                <h1>Tipos de permisionario</h1>

                {
                    props.permisos[0].escribir === '1' &&
                        <AddButton text="Nuevo tipo permisionario" onClick={ async () => {
                            $('#modalAddTipoPermisionario').modal('show')
                        } } />
                }

            </div>

            <hr className="my-4" />
            
            <LoadingSearchIcon show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <TableCatalogo>
                        <TableHead rows={rows} />

                        <TableBody 
                            elements={elements} 
                            typeElement="tipos_permisionarios" 
                            permisosUsuario={props.permisos} 
                            getTiposPermisionariosService={getTiposPermisionariosService}  
                        />
                    </TableCatalogo>
            }

            {
                ( Object.keys(elements).length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }


        </section>
    )
}