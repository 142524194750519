import React from 'react';
import "./Dashboard.css";

export default function Dashboard(){

    return(
        <div id="dashboard">
            <section>
                <h1 className="">Dashboard</h1>
            </section>
        </div>
    );
}