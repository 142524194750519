import React from 'react';
import { Link } from 'react-router-dom';
import Icon from "./../../components/Icon";
import Swal from 'sweetalert2'
import changeEstatusConcesionService from './../../services/changeEstatusConcesionService';

export default function TableElementConcesion({ element, permisosUsuario }) {

    let { id_concesion, key, validity, date, hour, status, vigencia } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(status);
    const [mensajeVigencia, setMensajeVigencia] = React.useState('');
    const [estatusVigencia, setEstatusVigencia] = React.useState('');

    const onClickEstatus = (activo) => {
        // let msg = (activo) ? 'Quieres habilitar la concesión?' : 'Quieres deshabilitar la concesión?';
        // let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        
        let msg = (activo) ? 'Para activar la concesión, debes asignarla a un permisionario' : 'Al desactivar la concesión, se desasignará de su permisionario actual';
        
        if(activo){
            Swal.fire({
                title: msg,
                icon: 'info'
            })
        } else {
            let txt = 'Desactivar';
            Swal.fire({
                title: msg,
                showCancelButton: true,
                confirmButtonText: txt,
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if(result.isConfirmed){
                    let values = new FormData()
                    values.append('id_concesion', id_concesion)
                    values.append('activo', activo)
                    let objectValues = Object.fromEntries(values);
                    console.log({objectValues})
                    changeEstatusConcesionService({values:objectValues}).then(response => {
                        console.log(response)
                        let _respuesta = JSON.parse(response);
                        if (_respuesta.response === "success") {
                            setEstadoEstatus(activo)
                            Swal.fire(
                                'Estatus actualizado',
                                '',
                                'success'
                            )
                        }
                        
                    })
                }
            })
        }
    }

    const getFlagVigencia = () =>{
        let fechaVigencia = new Date(vigencia).getTime()
        let fechaActual = new Date().getTime()
        let diferenciaEnDias = ((fechaVigencia - fechaActual)/(1000*60*60*24)).toFixed(0)
        if(diferenciaEnDias <= 0){
            console.log('vencida')
            setEstatusVigencia('table__estatus-inactive')
            setMensajeVigencia('Vencida')
            return;
        }
        
        if(diferenciaEnDias <= 30 && diferenciaEnDias >= 7){
            console.log('proxima a vencer')
            setMensajeVigencia('Próxima a vencer')
            setEstatusVigencia('table__estatus-warning')
            return;
        }
        
        if(diferenciaEnDias <= 7 && diferenciaEnDias >= 1){
            console.log('vence en '+diferenciaEnDias+' dias')
            if(diferenciaEnDias > 1)
                setMensajeVigencia('Vence en '+diferenciaEnDias+' días')
            else
                setMensajeVigencia('Vence en '+diferenciaEnDias+' día')
            
            setEstatusVigencia('table__estatus-warning')
            return;
        }

        setMensajeVigencia('Vigente')

    }

    React.useEffect(()=>{
        getFlagVigencia()
    },[])

    return (
        <tr key={id_concesion}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={`/concesiones/${id_concesion}`} className="dropdown-item" >Ver</Link>
                            {/* <a className="dropdown-item" href="#">Agregar prórroga</a>
                            <a className="dropdown-item" href="#">Asignar vehículo</a>
                            <a className="dropdown-item" href="#">Asignar económico</a> */}
                            {
                                permisosUsuario[0].editar === '1' &&
                                (estadoEstatus === '1' ?
                                <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatus(false)} href>Deshabilitar</a>
                                :
                                <a className="dropdown-item bg-success text-white" onClick={() => onClickEstatus(true)} href>Habilitar</a>)
                            }
                        </div>
                    </div>
                    <p>{key}</p>
                </div>
                <div className="table__estatus">
                    <span className={ estatusVigencia }>
                        {mensajeVigencia}
                    </span>
                </div>
            </th>

            <td>
                <div className="table__fechaAlta">
                    <p>{date}</p>
                </div>
            </td>

            <td>
                <p className="table__unidades">
                    {validity}
                </p>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ estadoEstatus == 1 ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus == 1 ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}