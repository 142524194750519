import React from 'react'
import Icon from '../Icon'
import {Link} from 'react-router-dom'
import "./SearchListResult.css"

export default function SearchListResult({data, tipo}){

    let { url, id, fecha_alta, title, first, second, third, activo } = data

    return (
        <Link to={`/${tipo}/${id}`}>
            <div className="w-100 d-flex justify-content-between align-items-center itemSearchListResult">
                {
                    (url !== undefined) && 
                    <div className="d-flex align-items-center">
                        <img src={url} alt="Result image" />
                        <div className="ml-3">
                            <p className="font-weight-bold">
                                {title}
                            </p>
                            <div className="d-flex justify-content-around">
                                <div className="mr-3">
                                    <small className="table__estatus">
                                        <span className={ activo == 1 ? '' : 'table__estatus-inactive' }>
                                            { activo == 1 ? 'Activo' : 'Inactivo' }
                                        </span>
                                    </small>
                                </div>
                                <small className="mr-3">{first}</small>
                                <small className="mx-3">{second}</small>
                            </div>
                        </div>
                    </div>
                }
                
                {
                    (url === undefined) && 
                    <div className="d-flex flex-column">
                        <p className="font-weight-bold">
                            {title}
                        </p>
                        <div className="d-flex justify-content-around">
                            <div className="mr-3">
                                <small className="table__estatus">
                                    <span className={ activo == 1 ? '' : 'table__estatus-inactive' }>
                                        { activo == 1 ? 'Activo' : 'Inactivo' }
                                    </span>
                                </small>
                            </div>
                            <small className="mr-3">{first}</small>
                            <small className="mx-3">{second}</small>
                        </div>
                    </div>
                }

                <Icon icon="fa-solid fa-circle-chevron-right" />
            </div>
        </Link>
    )
}