import React from 'react'
import LoadingSearchIcon from '../../components/LoadingSearchIcon';
import Table from '../../components/Table';
import TableHead from '../../components/Table/TableHead';
import TableBody from '../../components/Table/TableBody';
import getMovimientosCuentaByIdBancoDigital from '../../services/banco_digital/getMovimientosCuentaByIdBancoDigital';
import { useParams } from 'react-router-dom';
import AlertWarningSearch from '../../components/AlertWarningSearch';
import { BlobProvider } from "@react-pdf/renderer";
import PDFGeneratorEstadoCuentaSinietroEntreFechas from '../../components/PDFGeneratorEstadoCuentaSinietroEntreFechas';
import Swal from 'sweetalert2';

export default function ViewMovimientosSaldoSiniestro({permisosUsuario}){

    const { id } = useParams();

    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay() - 1)));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
    const [fechaInicio, setFechaInicio] = React.useState(firstday.toISOString().split('T')[0])
    const [fechaFinal, setFechaFinal] = React.useState(lastday.toISOString().split('T')[0])

    const [elements, setElements] = React.useState([]);
    const [user, setUser] = React.useState({})
    const [loading, setLoading] = React.useState(true);
    const [showPDF, setShowPDF] = React.useState(false)

    const rows = [
        { id: 1, name: "Fecha" },
        { id: 2, name: "Concepto" },
        { id: 3, name: "Cargo" },
        { id: 4, name: "Abono" },
        { id: 5, name: "Saldo" }
    ];

    function getMovimientosCuentaService(){
        setLoading(true)
        let data = {
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            id_banco_digital: id
        }
        getMovimientosCuentaByIdBancoDigital({values:data})
            .then(res =>{
                console.log(res)
                let _res = JSON.parse(res);
                console.log(_res)
                if(_res.response === 'success'){
                    calculateSaldoOfMovimientos(_res.data, parseFloat(_res.data_usuario.saldo_inicial))
                    setUser(_res.data_usuario)
                    // setElements(_res.data);
                }
                setLoading(false);
            })
    }

    const calculateSaldoOfMovimientos = (elements, saldoInicial)=>{
        // let saldoInicial = 0;
        let movimientosConSaldo = elements.slice().reverse().map(movimiento => {
            // Verifica si es un ingreso o un egreso y suma/resta el cantidad al saldo
            if (movimiento.id_movimiento_ingreso) {
                saldoInicial += parseFloat(movimiento.cantidad);
            } else if (movimiento.id_movimiento_egreso) {
                saldoInicial += parseFloat(movimiento.cantidad);
            }

            // Crea una copia del objeto 'movimiento' y añade el campo 'saldo' con el valor actualizado
            return {
                ...movimiento,
                saldo: saldoInicial
            };
        }).reverse();
        setElements(movimientosConSaldo);
    }

    function onSubmitFechas(e){
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        setFechaInicio(objectValues.fecha_inicial)
        setFechaFinal(objectValues.fecha_final)
    }

    const blob = () =>{
        return (
            <BlobProvider document={<PDFGeneratorEstadoCuentaSinietroEntreFechas gastos={elements} user={user}/>}>
                {({ blob, url, loading, error }) =>{
                    try{

                        if (loading) {
                            return (
                                <button className="btn btn-sm btn-light mt-2 d-flex align-items-center justify-content-center">
                                    <i className="fa-solid fa-spinner fa-spin mr-2"></i>
                                    Generando estado de cuenta...
                                </button>
                            )
                        } else if (error) {
                            return <div>Ocurrió un error: {error.message}</div>;
                        } else {
                            return (
                                <button 
                                    onClick={()=>{
                                        const pdfWindow = window.open();
                                        pdfWindow.location.href = url;
                                        setTimeout(()=>{
                                            setShowPDF(false);
                                        }, 200)
                                    }}
                                    className="btn btn-sm btn-light mt-2 d-flex align-items-center justify-content-center"
                                >
                                    <i className="fa-solid fa-download mr-2"></i>
                                    Descargar PDF
                                </button>
                            )
                        }
                    } catch(err){
                        Swal.fire({
                            icon:"warning",
                            title: "Hubo un error al generar el PDF",
                            text:'Vuelve a intentarlo'
                        }).then(()=>{
                            window.location.reload()
                        })
                    }
                }}
            </BlobProvider>
        )
    }

    React.useEffect(() => {
        getMovimientosCuentaService()
    },[fechaInicio, fechaFinal]);

    return(
        <section>

            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
            />

            {
                !loading &&
                    <React.Fragment>
                        <div className='d-flex align-items-center mt-4'>
                            <img src={user.foto_perfil} className="img-fluid rounded-circle" style={{width:"100px", height:"100px", objectFit: "cover"}} alt="foto_perfil"/>
                            <div className='ml-3'>
                                <h3 className=''>
                                    {user.nombre}
                                </h3>
                                <h3 className='text-muted d-flex'>
                                    Saldo: 
                                    <h3 className={`ml-2 ${user.saldo_total >= 0 ? 'color-verde' : 'color-rojo'}`}>
                                        {parseFloat(user.saldo_total).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                    </h3>
                                </h3>
                            </div>
                        </div>

                        <hr />

                        <div className='d-flex justify-content-between align-items-center my-4'>
                            <div>
                                <h2 className='m-0'>Movimientos de la cuenta</h2>
                                {
                                    !showPDF &&
                                        <button 
                                            className="btn btn-sm btn-light mt-2 d-flex align-items-center justify-content-center text-muted" 
                                            onClick={() => {
                                                if(elements.length > 0){
                                                    // Si existe al menos un gasto, generamos el pdf
                                                    setShowPDF(true);
                                                } else {
                                                    // Si no existe ningún gasto, no se podrá generar el pdf
                                                    Swal.fire({
                                                        icon:'warning',
                                                        title:"No hay gastos para imprimir"
                                                    })
                                                }
                                            }}
                                        >
                                            <i className="fa-solid fa-print mr-2"></i>
                                            Imprimir
                                        </button>
                                }
                                {
                                    showPDF && blob()
                                }
                            </div>
                            <div>
                                <form onSubmit={onSubmitFechas} className={`d-flex justify-content-between align-items-center`}>
                                    <input id="fecha_inicial" name="fecha_inicial" className="input noMargin" type="date" defaultValue={fechaInicio} required />
                                    <p className="mx-2">y</p>
                                    <input id="fecha_final" name="fecha_final" className="input noMargin" type="date" defaultValue={fechaFinal} required />
                                    <button className='btn btn-gris'>Ver</button>
                                </form>
                            </div>
                        </div>

                    </React.Fragment>
            }

            <LoadingSearchIcon show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rows} />

                        <TableBody 
                            elements={elements} 
                            typeElement="movimiento_saldo_siniestros" 
                            permisosUsuario={permisosUsuario}
                        />
                    </Table>
            }

            {
                ( Object.keys(elements).length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }

        </section>
    );
}