import React from 'react';
import Icon from '../../components/Icon';
import $ from 'jquery';
import "./FormularioConcesion.css"
import getTiposConcesionesService from './../../services/getTiposConcesionesService';

export default function FormularioConcesion({ concesion, permisosUsuario, onSubmit }){
    
    const[tiposConcesiones, setTiposConcesiones] = React.useState([]);

    function getTiposConcesiones(){
        getTiposConcesionesService()
            .then(response => {
                let _response = JSON.parse(response);
                setTiposConcesiones(_response)
                setDataFormulario()
            })
            .catch(error => {
                console.log(error)
            })
    }

    function onChangeFileInput(e, nombreInput){
        const file = e.target.files[0];
        document.getElementById(nombreInput+'Preview').src = URL.createObjectURL(file);
        $('#'+nombreInput+'Preview').removeClass("hidden")
    }
    
    function setDataFormulario(){
        document.getElementById('clave').value = concesion.clave;
        document.getElementById('vigencia').value = concesion.vigencia;
        document.getElementById('fecha_alta').value = concesion.fecha_alta;
        document.getElementById('id_tipo_concesion').value = concesion.id_tipo_concesion;
        $('.selectpicker').selectpicker('refresh');

        if(permisosUsuario[0].editar === "0"){
            $("form :input, :input[form]").prop("readonly", true);
        }
    }

    React.useEffect(() => {
        getTiposConcesiones();
    }, []);

    return(
        <form className="formulario" onSubmit={ onSubmit }>

            <div className="perfil__fotoPerfil justify-content-center flex-column">
                <input type="file" name="documentoConcesion" id="documentoConcesion" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "documentoConcesion") } />
                <div>
                    <label htmlFor="documentoConcesion" className="perfil__cambiarFoto text-center">
                        <h4>
                            Documento de la concesión
                        </h4>
                        <p>
                            Da clic aquí para cargar el pdf de la concesión
                        </p>
                    </label>
                </div>
                <embed 
                    id="documentoConcesionPreview" 
                    type="application/pdf" 
                    src={concesion.url_documento}
                    className={`previewPDF ${concesion.url_documento ? "" : "hidden"}`}
                />
            </div>

            {/* ****************************** */}
            {/* BLOQUE DE LOS DATOS PERSONALES */}
            {/* ****************************** */}

            <div className="datosPersonales__formulario row">
                <div className="col-4">
                    <label htmlFor="clave">
                        Clave:
                    </label>
                    <input 
                        type="text" 
                        className="input"
                        id="clave"
                        name="clave"
                        required
                    />
                </div>
                <div className="col-4">
                    <label htmlFor="vigencia">
                        Vigencia:
                    </label>
                    <input 
                        type="date"
                        className="input" 
                        id="vigencia"
                        name="vigencia"
                        required
                    />
                </div>
                <div className="col-4">
                    <label htmlFor="fecha_alta">
                        Fecha de otorgamiento:
                    </label>
                    <input 
                        type="date"
                        className="input" 
                        id="fecha_alta"
                        name="fecha_alta"
                        required
                    />
                </div>
                <div className="col-4">
                    <label htmlFor="id_tipo_concesion">
                        Tipo de concesión:
                    </label>
                    <select 
                        className="input selectpicker"
                        id="id_tipo_concesion"
                        name="id_tipo_concesion"
                        required
                    >
                        {
                            tiposConcesiones &&
                            tiposConcesiones.map( (tipoConcesion) => (
                                <option key={ tipoConcesion.id_tipo_concesion } value={ tipoConcesion.id_tipo_concesion }>{ tipoConcesion.nombre }</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            
            {/* ******************************* */}
            {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
            {/* ******************************* */}

            

            {
                (permisosUsuario[0].editar !== "0") &&
                <React.Fragment>
                    <hr></hr>
                    <div className="d-flex justify-content-end">
                        <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                            <Icon icon="fa-solid fa-pen-to-square mr-3" />
                            Actualizar información de la concesión
                        </button>
                    </div>
                </React.Fragment>
            }


        </form>
    );
}