import React from 'react'
import $ from "jquery"
export default function ButtonAgregarHorario({checadores, terminales, esTerminal, onSubmit, onClick}){

    const handleAgregarHorario = ()=>{
        $("#modalAgregarHorario").modal("show");
    }
    
    React.useEffect(()=>{
        $('.selectpicker').selectpicker('')
    },[])

    return(
        <>
            <div className="modal fade" id="modalAgregarHorario" tabIndex="-1" role="dialog" aria-labelledby={`modalAgregarHorario`} aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <form className="modal-content" onSubmit={(e) => onSubmit(e)}>
                        <div className="modal-header">
                            <h3 className="modal-title" id={`modalAgregarHorario`}>{`Agregar horario`}</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            {
                                esTerminal !== false &&
                                    <>
                                        <label htmlFor={`checador`}>
                                            Selecciona un checador:
                                        </label>
                                        <select 
                                            className="input selectpicker"
                                            id="checador"
                                            name="checador"
                                            // value={ tipoDeIdentificacion }
                                            // onChange={ (e) => handleChecador(e.target.value) }
                                            data-live-search="true"
                                            required
                                        >
                                            <option value="0">
                                                Elige un checador
                                            </option>
                                            {
                                                checadores &&
                                                checadores.map( (checador) => (
                                                    <option key={ checador.id_trabajador } value={ checador.id_trabajador }>
                                                        { checador.nombre }&nbsp;
                                                        { checador.apellido_paterno }&nbsp;
                                                        { checador.apellido_materno }&nbsp;
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </>
                            }
                            {
                                esTerminal === false &&
                                    <>
                                        <label htmlFor={`terminal`}>
                                            Selecciona una terminal:
                                        </label>
                                        <select 
                                            className="input selectpicker"
                                            id="terminal"
                                            name="terminal"
                                            // value={ tipoDeIdentificacion }
                                            // onChange={ (e) => handleTerminal(e) }
                                            data-live-search="true"
                                            required
                                        >
                                            <option value="0">
                                                Elige una terminal
                                            </option>
                                            {
                                                terminales &&
                                                terminales.map( (terminal) => (
                                                    <option key={ terminal.id_terminal } value={ terminal.id_terminal }>
                                                        { terminal.nombre }
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </>
                            }
                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="submit" className="btn btn-info">Agregar</button>
                        </div>
                    </form>
                </div>
            </div>

            <button className="btn btnAgregarHorario"
                onClick={()=>{
                    onClick()
                    handleAgregarHorario()
                }}>
                <i className="fa-solid fa-circle-plus"></i>
            </button>
        </>
    )
}