import React from "react";
import $ from 'jquery'
import { validateNoNumbers } from "../../validators/validateNoNumbers";
import getMarcasVehiculos from "../../services/catalogos/marca_vehiculo/getMarcasVehiculos";
import getSubmarcasVehiculosByMarca from "../../services/catalogos/submarca_vehiculo/getSubmarcasVehiculosByMarca";

export default function ModalAddVehiculoAfectado(props){

    const [marcasVehiculo, setMarcasVehiculo] = React.useState([]);
    const [submarcasVehiculo, setSubmarcasVehiculo] = React.useState([]);

    const getMarcasVehiculosService = ()=>{
        getMarcasVehiculos().then(res=>{
            let _res = JSON.parse(res);
            if(!(_res.response === 'error')){
                setMarcasVehiculo(_res.data);
            } else {
                setMarcasVehiculo([])
            }
            $('.selectpicker').selectpicker('refresh')
        })
    }

    const getSubmarcasVehiculosService = (id_marca_vehiculo)=>{
        let form = new FormData()
        form.append('id_marca_vehiculo',id_marca_vehiculo)
        let objectValues = Object.fromEntries(form);
        getSubmarcasVehiculosByMarca({values:objectValues}).then(res=>{
            let _res = JSON.parse(res);
            if(!(_res.response === 'error')){
                $("#id_submarca_vehiculo").attr("disabled",false)
                setSubmarcasVehiculo(_res.data);
            } else {
                $("#id_submarca_vehiculo").attr("disabled",true)
                setSubmarcasVehiculo([])
            }
            $('.selectpicker').selectpicker('refresh')
        })
    }
    
    const onChangeMarca = (value)=>{
        getSubmarcasVehiculosService(value)
    }

    React.useEffect(()=>{
        $(".selectpicker").selectpicker('refresh');
    },[])
    
    React.useEffect(getMarcasVehiculosService,[])

    return(
        <div className="modal" id="modalAddVehiculoAfectado" tabIndex="-1" role="dialog" aria-labelledby="modalAddVehiculoAfectadoLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={props.onSubmit} id='formAddVehiculoAfectado' className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddVehiculoAfectadoLabel">Agrega un vehículo afectado</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="nombre_conductor">
                                    Nombre completo del conductor:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    id="nombre_conductor"
                                    name="nombre_conductor"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="placas">
                                    Placas del vehículo:
                                </label>
                                <input 
                                    type="text" 
                                    className="input"
                                    id="placas"
                                    name="placas"
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="modelo">
                                    Modelo del vehículo:
                                </label>
                                <input 
                                    type="tel" 
                                    className="input"
                                    id="modelo"
                                    name="modelo"
                                    onKeyPress={ validateNoNumbers }
                                    maxLength={4}
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="id_marca_vehiculo">
                                    Marca:
                                </label>
                                <select 
                                    className="input selectpicker"
                                    id="id_marca_vehiculo"
                                    name="id_marca_vehiculo"
                                    onChange = { (e) => onChangeMarca(e.target.value) }
                                    data-live-search="true"
                                    >   
                                    <option value="">Elige una marca</option>
                                    {
                                        marcasVehiculo.map( (marca) => (
                                            <option key={ marca.id_marca_vehiculo } value={ marca.id_marca_vehiculo } >
                                                { marca.nombre }
                                            </option>
                                        ) )
                                    }
                                </select>
                            </div>
                            <div className="col-6">
                                <label htmlFor="id_submarca_vehiculo">
                                    Submarca:
                                </label>
                                <select 
                                    className="input selectpicker"
                                    id="id_submarca_vehiculo"
                                    name="id_submarca_vehiculo"
                                    data-live-search="true"
                                    disabled
                                >   
                                    <option value="">Elige una submarca</option>
                                    {
                                        submarcasVehiculo.map( (submarca) => (
                                            <option key={ submarca.id_submarca_vehiculo } value={ submarca.id_submarca_vehiculo } >
                                                { submarca.nombre }
                                            </option>
                                        ) )
                                    }
                                </select>
                            </div>
                            <div className="col-12">
                                <label htmlFor="descripcion">
                                    Descripción:
                                </label>
                                <textarea 
                                    type="text" 
                                    className="input"
                                    id="descripcion"
                                    name="descripcion"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primario" id="btnSubmitModalAddVehiculoAfectado">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}