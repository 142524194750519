import React from 'react'
import { useHistory, useParams } from 'react-router'
import resetPasswordByCode from '../../services/usuario/resetPasswordByCode';
import verifyCodeExist from '../../services/usuario/verifyCodeExist';
import logo from "./../../assets/images/logo.png";
import Swal from 'sweetalert2';
import LoadingSearchIcon from '../../components/LoadingSearchIcon';

export default function RecoverPassword(){

    let { id } = useParams()
    const history = useHistory()
    const [loading, setLoading] = React.useState(true);
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    const handleNoId = () => {
        if(id === undefined || id === null)
            history.push('/')

        verifyIdExist()
    }

    const verifyIdExist = async () => {
        let data = { id }
        let respuesta = await verifyCodeExist({values:data});
        let _res = JSON.parse(respuesta)
        if(_res.response !== 'exist')
            history.push('/')

        if(_res.response === 'exist')
            setLoading(false)
    }

    const handleSubmit = (event) => {
        setErrorMessage('');
        event.preventDefault();
        let values = new FormData(event.target)
        let object = Object.fromEntries(values)
        
        let password = object.password
        let confirmPassword = object.confirmPassword

        if(password.length === 0 || confirmPassword.length === 0){
            setErrorMessage('No dejes vacío ningún campo');
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage('Las contraseñas no coinciden');
            return;
        }

        let data = {
            password: password,
            id: id
        }

        resetPasswordByCode({values:data})
            .then(res => JSON.parse(res))
            .then(res => {
                if(res.response === 'success')
                    Swal.fire({
                        title: 'Contraseña actualizada',
                        showConfirmButton: false,
                        icon: 'success',
                        timer:1500
                    }).then(() =>{
                        history.push('/')
                    })

                if(res.response !== 'success')
                    Swal.fire({
                        title: 'No se actualizó la contraseña',
                        text: 'Ocurrió un error en el servidor, inténtalo de nuevo más tarde o repórtalo al desarrollador',
                        icon: 'error',
                        showConfirmButton: false,
                        timer:1500
                    })
            })
            .catch(err => Swal.fire({
                title: 'No se actualizó la contraseña',
                text: 'Ocurrió un error en el servidor, inténtalo de nuevo más tarde o repórtalo al desarrollador:' +err,
                icon: 'error',
                showConfirmButton: false,
                timer:1500
            }))
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    React.useEffect(handleNoId, [])

    return (
        loading ? <LoadingSearchIcon show={loading} /> :
        <>
            <header className="header__recover-password bg-light shadow px-2 py-2">
                <div className='container d-flex justify-content-center align-items-center'>
                    <img src={ logo } className='img-fluid h-100' alt='Logo AUSE' />

                    <h1 className='text-dark text-center ml-4 m-0'>
                        Restablece tu contraseña
                    </h1>
                </div>
            </header>

            <form onSubmit={handleSubmit} className='container d-flex align-items-center justify-content-center vh-100'>
                <div className=''>
                    <h2> 
                        Por favor, escribe una nueva contraseña y confírmala:
                    </h2>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Nueva contraseña</label>
                        <div className="input-group">
                            <input 
                                type={showPassword ? "text" : "password"} 
                                className="form-control" 
                                id="password" 
                                name="password"  
                                value={password} 
                                onChange={(event) => setPassword(event.target.value)} 
                            />
                            <button 
                                type="button" 
                                className="btn btn-outline-secondary" 
                                onClick={handleShowPassword}
                            >
                                <i className={`fa-solid ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} />
                            </button>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="confirmPassword" className="form-label">Confirmar nueva contraseña</label>
                        <div className="input-group">
                            <input 
                                type={showConfirmPassword ? "text" : "password"} 
                                className="form-control" 
                                id="confirmPassword" 
                                name="confirmPassword" 
                                value={confirmPassword} 
                                onChange={(event) => setConfirmPassword(event.target.value)} 
                            />
                            <button 
                                type="button" 
                                className="btn btn-outline-secondary" 
                                onClick={handleShowConfirmPassword}
                            >
                                <i className={`fa-solid ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`} />
                            </button>
                        </div>
                        {errorMessage && (
                            <small className="form-text text-danger">{errorMessage}</small>
                        )}
                    </div>
                    <hr />
                    <button type="submit" className="btn btn-primario text-white" >
                        Restablecer contraseña
                    </button>
                </div>
            </form>

        </>
    )
}