import Swal from 'sweetalert2'
import { Link } from "react-router-dom";
import deleteAsignacionDerroteroEconomico from './../../services/derrotero/deleteAsignacionDerroteroEconomico';
import updateEstatusAsignacionDerroteroEconomico from './../../services/derrotero/updateEstatusAsignacionDerroteroEconomico';
import React from "react";

export default function TableElementPreSiniestro({ element, permisosUsuario, getReloadElements }) {

    let { id_siniestro, fecha_alta, fecha_siniestro, nombre_permisionario, apellido_paterno_permisionario, apellido_materno_permisionario, clave_permisionario, numero_serie, placas, numero_economico, clave_prefijo_economico, modalidad_prefijo_economico, nombre_gestor, importe, nombre_operador, nombre_estado_siniestro, id_estado_siniestro, numero_poliza } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(id_estado_siniestro);

    let dollarUSLocale = Intl.NumberFormat('en-US');

    const onClickEstatusAcceso = (activo) => {
        let msg = (activo) ? 'Quieres habilitarlo?' : 'Quieres deshabilitarlo?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_siniestro', id_siniestro)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                // updateEstatusAsignacionDerroteroEconomico({values:objectValues}).then(response => {
                //     console.log(response)
                //     let _respuesta = JSON.parse(response);
                //     if (_respuesta.response === "success") {
                //         getReloadElements()
                //         setEstadoEstatus(activo)
                //         Swal.fire(
                //             'Estatus actualizado',
                //             '',
                //             'success'
                //         )
                //     }
                    
                // })
            }
        })
    } 

    const onClickDelete = () => {
        Swal.fire({
            title: 'Seguro que quieres eliminarlo?',
            showCancelButton: true,
            confirmButtonText: 'Si, seguro',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_siniestro', id_siniestro)
                let objectValues = Object.fromEntries(values);
                console.log({objectValues})
                // deleteAsignacionDerroteroEconomico({values:objectValues}).then(response => {
                //     console.log(response)
                //     let _respuesta = JSON.parse(response);
                //     if (_respuesta.response === "success") {
                //         getReloadElements()
                //         Swal.fire({
                //             title:'Eliminado',
                //             timer:1000,
                //             showConfirmButton:false,
                //             icon:'success'
                //         })
                //     }
                    
                //     if (_respuesta.response === "error") {
                //         Swal.fire({
                //             title:'No se puede eliminar',
                //             text:"Existen registros vinculados a este dato, no se puede eliminar hasta que elimines todos los registros vinculados a él",
                //             showConfirmButton:true,
                //             icon:'info'
                //         })
                //     }
                    
                // })
            }
        })
    }

    return (
        <tr key={id_siniestro}>
            <th className='table-minwidth'>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={`/siniestros/${id_siniestro}`} className="dropdown-item">Ver</Link>
                            {/* {
                            ( (permisosUsuario[0].editar == 1) || (permisosUsuario[0].eliminar == 1) ) &&
                                
                            } */}
                            {/* {
                                estadoEstatus == 1 ?
                                <a className="dropdown-item bg-warning text-black" onClick={() => onClickEstatusAcceso(false)}>Deshabilitar</a>
                                : null
                            }

                            {
                                permisosUsuario[0].eliminar == 1 &&
                                    <a className="dropdown-item bg-danger text-white" onClick={() => onClickDelete()}>
                                        Eliminar
                                    </a>
                            } */}
                        </div>
                    </div>
                    <div>
                        <p>
                            {`ID: ${id_siniestro}`}
                        </p>
                        <small>
                            {fecha_siniestro}
                            <br/>
                            Gestor: {nombre_gestor}
                        </small>
                    </div>
                </div>
            </th>

            <td>
                <div className="table__fechaAlta">
                    <p>
                        {nombre_permisionario} {apellido_paterno_permisionario} {apellido_materno_permisionario}
                        <br/>   
                        Clave: <span className='font-weight-bold'>{clave_permisionario}</span>
                    </p>
                    { clave_prefijo_economico }{ numero_economico } &nbsp;
                    <span className='text-muted'>
                        ({ modalidad_prefijo_economico })
                    </span>
                </div>
            </td>
            
            <td>
                <p>
                    No. serie: { numero_serie }
                    <br/>
                    Placas: { placas }
                    <br/>
                    Operador: { nombre_operador }
                    <br/>
                </p>
            </td>

            <td>
                {
                    numero_poliza ? numero_poliza : '-'
                }
            </td>

            <td>
                $ {dollarUSLocale.format(importe)}
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ `${estadoEstatus == 2 && 'table__estatus-inactive'} ${estadoEstatus > 2 && 'table__estatus-warning'}` }>
                        {nombre_estado_siniestro}
                    </span>
                </div>
            </td>
        </tr>
    )
    
}