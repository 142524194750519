import React from "react";
import "./LateralMenu.css";
import logo from "./../../assets/images/logo.png";
import { useLocation, Link } from 'react-router-dom';
import $ from 'jquery/dist/jquery.min.js';

function LateralMenu({children, logout}){

    const location = useLocation();
    const rutaActual = location.pathname.split('/')[1];
    const subRutaActual = location.pathname.split('/')[2];

    $(document).ready(function(){
        
        // LIMPIAMOS EL INPUT DE BUSQUEDA POR SI LAS DUDAS
        if(rutaActual !== "resultados"){
            $(".search").val("");
        }
        
        
        $(".option_enlace").removeClass("selected");
        
        $(".option_enlace").click(function(){
            $(this).addClass("selected");
        });
        $("."+rutaActual+"Link").addClass("selected")
        
        
        if(rutaActual === "catalogos"){
            $("."+subRutaActual+"Link").addClass("selected")
        }
    })

    return(
        <nav className="menu__side menu__side_move" id="menu_side">

            <div className="name__page">
                <img src={ logo } alt="Logo de la empresa" />
                {/* <svg viewBox="0 0 100 100" role="img">
                        <clipPath id="geisha">
                            <path d="M29.826,94.377c1.329,0.607,2.687,1.159,4.075,1.648V65.512h-4.075V94.377z"/>
                    <rect x="29.826" y="43.442" width="4.075" height="20.993"/>
                    <path d="M20.95,21.551h3.725V7.973c-1.282,0.782-2.524,1.623-3.725,2.516V21.551z"/>
                    <path d="M29.826,21.551h4.075V3.601c-1.389,0.489-2.747,1.041-4.075,1.648V21.551z"/>
                    <path d="M25.213,21.551h4.075V5.493c-1.396,0.659-2.754,1.383-4.075,2.166V21.551z"/>
                    <path d="M25.213,91.967c1.32,0.783,2.679,1.508,4.075,2.167V65.512h-4.075V91.967z"/>
                    <rect x="25.213" y="43.442" width="4.075" height="20.993"/>
                    <rect x="25.213" y="22.627" width="4.075" height="19.739"/>
                    <path d="M34.439,96.208c1.334,0.452,2.692,0.851,4.074,1.188V65.512h-4.074V96.208z"/>
                    <rect x="29.826" y="22.627" width="4.075" height="19.739"/>
                    <path d="M39.052,21.551h4.075V1.335c-1.378,0.198-2.736,0.459-4.075,0.77V21.551z"/>
                    <rect x="39.052" y="22.627" width="4.075" height="19.739"/>
                    <path d="M43.665,98.359c1.177,0.156,2.364,0.276,3.566,0.347V65.512h-3.566V98.359z"/>
                    <rect x="43.665" y="22.627" width="3.566" height="19.739"/>
                    <path d="M39.052,97.521c1.339,0.311,2.697,0.571,4.075,0.77V65.512h-4.075V97.521z"/>
                    <rect x="34.439" y="22.627" width="4.074" height="19.739"/>
                    <path d="M34.439,21.551h4.074V2.229c-1.382,0.337-2.74,0.736-4.074,1.188V21.551z"/>
                    <rect x="34.439" y="43.442" width="4.074" height="20.993"/>
                    <rect x="39.052" y="43.442" width="4.075" height="20.993"/>
                    <rect x="43.665" y="43.442" width="3.566" height="20.993"/>
                    <path d="M4.688,43.442H1.567c-0.272,2.087-0.428,4.21-0.428,6.37c0,5.092,0.782,10.003,2.23,14.623h1.327L4.688,43.442z"/>
                    <path d="M3.735,65.512c0.298,0.878,0.618,1.745,0.964,2.6l-0.001-2.6H3.735z"/>
                    <path d="m8.802 23.557c-1.358 2.13-2.559 4.369-3.58 6.706l4e-3 12.104h3.584l-8e-3 -18.81z"/>
                    <rect transform="matrix(.00039537 1 -1 .00039537 60.959 46.895)" x="-3.474" y="52.147" width="20.993" height="3.583"/>
                    <path d="m5.235 65.512l1e-3 3.884c1.023 2.337 2.226 4.576 3.587 6.706l-4e-3 -10.59h-3.584z"/>
                    <rect x="20.95" y="22.627" width="3.725" height="19.739"/>
                    <path d="M4.683,31.554c-1.384,3.432-2.402,7.049-2.979,10.812h2.983L4.683,31.554z"/>
                    <path d="m13.763 17.029c-1.292 1.432-2.493 2.945-3.61 4.522h3.612l-2e-3 -4.522z"/>
                    <path d="M20.95,89.137c1.201,0.893,2.443,1.733,3.725,2.516V65.512H20.95V89.137z"/>
                    <path d="m14.301 16.451l2e-3 5.1h3.417v-8.429c-1.193 1.055-2.334 2.165-3.419 3.329z"/>
                    <polygon points="14.312 42.366 17.72 42.366 17.72 22.627 14.304 22.627"/>
                    <rect x="20.95" y="43.442" width="3.725" height="20.993"/>
                    <path d="M9.357,65.512L9.362,76.94c1.338,2.004,2.817,3.905,4.427,5.687l-0.007-17.115H9.357z"/>
                    <polygon points="14.32 64.436 17.72 64.436 17.72 43.442 14.312 43.442"/>
                    <rect x="9.353" y="43.442" width="4.425" height="20.993"/>
                    <path d="m13.765 22.627h-4.366c-0.021 0.03-0.039 0.062-0.06 0.092l8e-3 19.646h4.425l-7e-3 -19.738z"/>
                    <path d="m14.328 83.202c1.077 1.154 2.209 2.255 3.392 3.302v-20.992h-3.399l7e-3 17.69z"/>
                    <polygon points="86.359 22.627 82.693 22.627 82.693 42.366 86.367 42.366"/>
                    <path d="m63.287 33.413s-0.104-0.432 0.633-0.647c0.598-0.175 0.987 0.337 1.331 0.597v-10.736h-4.154v10.503c0.956-0.046 2.19 0.283 2.19 0.283z"/>
                    <path d="m64.391 54.406c-0.13-1.395-1.456-3.057-1.859-2.844-0.628 0.331-0.896 0.553-1.435 0.788v10.4c0.642-3.022 1.075-5.636 1.491-6.282 0.668-1.042 1.889-1.125 1.803-2.062z"/>
                    <path d="M60.559,1.957c-1.364-0.297-2.75-0.533-4.153-0.713v20.307h4.153V1.957z"/>
                    <path d="m59.853 35.995s-0.064-1.167 0.159-2.284c0.053-0.26 0.262-0.412 0.547-0.497v-10.587h-4.153v12.649c0.973 0.646 2.007 1.331 2.059 1.245 0.359-0.602 1.388-0.526 1.388-0.526z"/>
                    <path d="m74.635 55.814v-5.641c-1.519 0.345-3.146 0.047-3.613-0.619-0.211-0.301-0.391-0.505-0.541-0.644v4.944c0.354 0.303 0.772 0.559 1.271 0.729 0.972 0.334 1.967 0.786 2.883 1.231z"/>
                    <path d="M65.251,3.228c-1.359-0.442-2.746-0.823-4.154-1.146v19.469h4.154V3.228z"/>
                    <path d="M69.943,5.031c-1.354-0.602-2.74-1.142-4.155-1.621v18.141h4.155V5.031z"/>
                    <path d="m57.909 40.404c7e-3 -0.174-0.293 0.026-0.182-0.686 0.045-0.283 0.214-0.713 0.309-1.213 0 0-1.068-0.61-1.631-1.03v4.892h1.012c0.198-1.044 0.492-1.963 0.492-1.963z"/>
                    <path d="m67.158 33.841c0.22-0.573 1.418-1.343 1.418-1.343l-2.432-4.71 0.699-0.45 2.318 4.764 0.157-0.116-1.149-4.824 0.763-0.26 1.011 4.723v-8.998h-4.155v10.79c0.521-0.447 1.253 0.729 1.37 0.424z"/>
                    <path d="m55.867 93.154v-27.642h-3.252v33.224c0.939-0.047 1.87-0.126 2.796-0.227-0.063-0.962 0.144-2.978 0.456-5.355z"/>
                    <path d="M43.665,21.551h3.566V0.92c-1.202,0.07-2.389,0.19-3.566,0.347V21.551z"/>
                    <path d="m74.635 32.498v-9.871h-4.155v8.852s0.317-0.334 1.334-0.472c0.97-0.132 2.168 0.483 2.821 1.491z"/>
                    <rect x="52.615" y="43.442" width="3.252" height="20.993"/>
                    <path d="m55.867 36.744c-0.146-0.175-0.314-0.317-0.314-0.317s-0.265 5.012-0.282 5.219c-0.024 0.288-0.422 0.282-0.403-0.018 0.018-0.286 0.368-5.439 0.368-5.439l-0.175-0.177s-1.002 5.202-1.039 5.363c-0.084 0.386-0.37 0.269-0.345 0 0.017-0.191 0.618-3.528 1.093-5.568 0 0-0.251-0.188-0.372-0.298-0.122-0.111 0.094-1.282 0.3-1.298 0.073-6e-3 0.566 0.31 1.17 0.708v-12.292h-3.252v19.739h3.252v-5.622z"/>
                    <path d="m57.406 79.969c-0.52-0.522-0.521-1.006-0.715-1.528-0.196-0.519 0.228-2.204 0.527-3.472 0.296-1.264 0.031-2.5-0.036-3.373-0.062-0.799 0.747-0.896 0.747-1.415 0-0.522 0.146-0.969 1.709-2.382 0.346-0.312 0.648-1.003 0.92-1.882v-0.405h-4.153v23.808c0.677-4.631 1.253-9.099 1.001-9.351z"/>
                    <path d="m60.559 52.556c-0.47 0.147-0.752-0.04-0.996-0.193-0.592-0.372-0.297-0.939-0.609-1.034-0.437-0.135-0.374-0.54-0.309-0.587 0 0-0.464-0.226-0.317-0.561 0.111-0.248 0.191-0.874-0.061-0.924 0 0-1.138-0.174-0.674-0.846 0.688-0.996 0.651-1.358 0.403-1.925-0.178-0.103-0.588-0.71-0.738-2.149-0.027-0.269-0.021-0.574 5e-3 -0.894h-0.857v20.993h4.153v-11.88z"/>
                    <path d="M55.867,1.175c-1.075-0.126-2.157-0.229-3.252-0.285v20.661h3.252V1.175z"/>
                    <path d="M74.635,7.427c-1.346-0.781-2.731-1.5-4.154-2.152v16.276h4.154V7.427z"/>
                    <rect x="91.262" y="43.442" width="3.815" height="20.993"/>
                    <path d="m91.267 65.512l5e-3 10.833c1.455-2.25 2.735-4.621 3.812-7.104l-2e-3 -3.729h-3.815z"/>
                    <path d="m95.072 42.366l-5e-3 -12.018c-1.078-2.48-2.359-4.852-3.817-7.1l8e-3 19.118h3.814z"/>
                    <path d="m98.546 42.366c-0.57-3.728-1.575-7.312-2.939-10.715l4e-3 10.715h2.935z"/>
                    <path d="m95.62 67.938c0.319-0.799 0.618-1.607 0.896-2.427h-0.896v2.427z"/>
                    <path d="m68.695 50.235c-8e-3 0.188 0.22 1.805 1.248 3.066v-4.658c-0.064 7e-3 -0.1 0.032-0.1 0.032s-1.126 1.133-1.148 1.56z"/>
                    <path d="m95.619 64.436h1.262c1.448-4.62 2.23-9.531 2.23-14.623 0-2.16-0.155-4.283-0.428-6.37h-3.073l9e-3 20.993z"/>
                    <path d="m79.464 44.802v-1.36h-2.146c0.774 0.548 1.717 1.083 2.146 1.36z"/>
                    <path d="m86.915 65.512l8e-3 16.584c1.367-1.558 2.647-3.193 3.812-4.915l-5e-3 -11.669h-3.815z"/>
                    <path d="m86.356 16.878c-1.155-1.27-2.382-2.473-3.663-3.615v8.288h3.665l-2e-3 -4.673z"/>
                        <rect transform="matrix(-.00041871 -1 1 -.00041871 34.916 142.78)" x="78.321" y="52.032" width="20.993" height="3.814"/>
                    <path d="m75.931 41.132c-0.252 0.031-0.37 0.438-0.288 0.767 0.025 0.101 0.177 0.267 0.402 0.467h3.419v-19.739h-4.292v10.846c1.045 2.158 1.568 4.94 1.622 5.212 0.206 1.044-0.157 2.363-0.863 2.447z"/>
                    <path d="m79.464 58.937v-13.036l-2.693-2.035s0.967 1.42 0.806 3.317c-0.13 1.53-1.178 2.418-2.404 2.838v6.058c1.302 0.645 2.468 1.015 2.927 1.3 0.511 0.318 0.946 0.793 1.364 1.558z"/>
                    <path d="m85.694 83.443c0.297-0.311 0.401-0.409 0.69-0.728l-8e-3 -17.204h-3.684v9.98c0.639 0.532 1.639 1.215 2.035 1.57 0.59 0.531 0.864 4.591 0.967 6.382z"/>
                    <path d="M79.464,10.609c-1.376-1.032-2.811-1.988-4.292-2.873v13.814h4.292V10.609z"/>
                    <path d="m90.099 21.551c-0.998-1.407-2.066-2.76-3.204-4.053l2e-3 4.053h3.202z"/>
                    <rect x="86.901" y="22.627" width="3.815" height="19.739"/>
                    <polygon points="86.368 43.442 82.693 43.442 82.693 64.436 86.377 64.436"/>
                    </clipPath>
                    <g clip-path="url(#geisha)">
                        <image href="https://media.giphy.com/media/9CihaEMp5sORi/giphy.gif" x="-20%" y="-18%"  width="135%" height="135%"/>
                </g>
                </svg> */}
                {/* <h4>Autobuses Urbanos de Ecatepec</h4> */}
            </div>

            <div className="options__menu">

                <Link to={"/"} className="option_enlace Link">
                    <div className="option">
                        <i className="fas fa-home" title="Dashboard"></i>
                        <p>Dashboard</p>
                    </div>
                </Link>

                { children }
                
            </div>
        </nav>
    )
}

export default LateralMenu;