import Swal from 'sweetalert2'
import { Link } from "react-router-dom";
import changeEstatusVehiculoService from './../../services/changeEstatusVehiculoService';
import React from "react";
export default function TableElementUnidad({ element, permisosUsuario }) {

    let { 
        id_vehiculo, 
        vigencia_seguro, 
        numero_serie, 
        socios, 
        operador, 
        fecha_vigencia_seguro, 
        key,
        date, 
        hour, 
        activo,
        clave_concesion,
        prefijo_clave,
        modalidad_prefijo_economico,
        numero_economico,
        nombre_derrotero,
        clave_derrotero,
        is_concesion_activo,
        is_derrotero_activo,
        is_economico_activo,
        is_funcionamiento
    } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(activo);
    const [mensajeVigencia, setMensajeVigencia] = React.useState('');
    const [estatusVigencia, setEstatusVigencia] = React.useState('');

    const onClickEstatusAcceso = (activo) => {
        let msg = (activo) ? 'Quieres habilitar el vehículo?' : 'Quieres deshabilitar el vehículo?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_vehiculo', id_vehiculo)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                console.log(objectValues)
                changeEstatusVehiculoService({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);

                    if(_respuesta.response === "pre_siniestro_activo"){
                        Swal.fire({
                            title:'No se puede deshabilitar el vehiculo',
                            text:"Tiene un pre siniestro en curso, hay que cerrarlo o eliminarlo para poder deshabilitar el vehiculo",
                            showConfirmButton:true,
                            icon:'info'
                        })
                    }

                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo ? "1" : "0")
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    } 

    const getFlagVigencia = () =>{
        let fechaVigencia = new Date(fecha_vigencia_seguro).getTime()
        let fechaActual = new Date().getTime()
        let diferenciaEnDias = ((fechaVigencia - fechaActual)/(1000*60*60*24)).toFixed(0)
        if(diferenciaEnDias <= 0){
            setEstatusVigencia('table__estatus-inactive')
            setMensajeVigencia('Expiró')
            return;
        }
        
        if(diferenciaEnDias <= 30 && diferenciaEnDias >= 7){
            setMensajeVigencia('Próximo a vencer')
            setEstatusVigencia('table__estatus-warning')
            return;
        }
        
        if(diferenciaEnDias <= 7 && diferenciaEnDias >= 1){
            console.log('vence en '+diferenciaEnDias+' dias')
            if(diferenciaEnDias > 1)
                setMensajeVigencia('Vence en '+diferenciaEnDias+' días')
            else
                setMensajeVigencia('Vence en '+diferenciaEnDias+' día')
            
            setEstatusVigencia('table__estatus-warning')
            return;
        }

        setMensajeVigencia('Vigente')

    }

    React.useEffect(getFlagVigencia,[])

    return (
        <tr key={id_vehiculo}>
            <th className='table-minwidth'>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={`/vehiculos/${id_vehiculo}`} className="dropdown-item">Ver</Link>
                            {/* <a className="dropdown-item" href="#">Asignar permisionario</a>
                            <a className="dropdown-item" href="#">Asignar concesión</a> */}
                            {
                                permisosUsuario[0].editar === '1' &&
                                (estadoEstatus === '1' ?
                                <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatusAcceso(false)} href>Deshabilitar</a>
                                :
                                <a className="dropdown-item bg-success text-white" onClick={() => onClickEstatusAcceso(true)} href>Habilitar</a>)
                            }
                        </div>
                    </div>
                    <p>{numero_serie}</p>
                </div>
            </th>

            <td>
                {
                    is_funcionamiento && 
                        <p>
                            <i className="fa-solid fa-file-invoice" title="Concesión"></i> : { clave_concesion }
                            &nbsp;
                            { is_concesion_activo === '1' && <i className="fa-solid fa-circle-check color-verde" title="Activo"></i> }
                            { is_concesion_activo === '0' && <i className="fa-solid fa-times-circle color-rojo" title="Inactivo"></i> }
                            
                            <br/>
                            
                            <i className="fa-solid fa-hashtag" title="Económico"></i> : { prefijo_clave }{ numero_economico } <span className='text-muted'>({modalidad_prefijo_economico})</span>
                            &nbsp;
                            { is_economico_activo === '1' && <i className="fa-solid fa-circle-check color-verde" title="Activo"></i> }
                            { is_economico_activo === '0' && <i className="fa-solid fa-times-circle color-rojo" title="Inactivo"></i> }

                            <br/>
                            
                            <small>
                                <i className="fa-solid fa-signs-post" title="Derrotero"></i> : { nombre_derrotero }
                                &nbsp;
                                { is_derrotero_activo === '1' && <i className="fa-solid fa-circle-check color-verde" title="Activo"></i> }
                                { is_derrotero_activo === '0' && <i className="fa-solid fa-times-circle color-rojo" title="Inactivo"></i> }
                            </small>
                        </p>
                }

                {
                    !is_funcionamiento && 
                        <p>
                            <span className='text-muted'>Sin asignar</span>
                        </p>
                }
            </td>

            <td>
                <div className="table__fechaAlta">
                    <p>{vigencia_seguro}</p>
                </div>
                <div className="table__estatus mt-2">
                    <span className={ estatusVigencia }>
                        {mensajeVigencia}
                    </span>
                </div>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ estadoEstatus === '1' ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus === '1' ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}