import React from "react";
import AddButton from "../../components/AddButton";
import AlertWarningSearch from "../../components/AlertWarningSearch";
import LoadingSearchIcon from "../../components/LoadingSearchIcon";
import TableBody from "../../components/TableCatalogo/TableBody";
import TableHead from "../../components/TableCatalogo/TableHead";
import TableCatalogo from "../../components/TableCatalogo";
import getReglasProrratas from "../../services/catalogos/reglas_prorrata/getReglasProrratas";
import addReglaProrrata from "../../services/catalogos/reglas_prorrata/addReglaProrrata";
import $ from 'jquery';
import Swal from "sweetalert2";
import ModalAddReglaProrrateo from "../../components/Catalogos/ModalAddReglaProrrateo";

export default function ReglasProrrateo(props){
    const [elements, setElements] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    const rows = [
        { id: 1, name: "Fecha de aplicación" },
        { id: 2, name: "Mínimo en UMA's" },
        { id: 3, name: "Máximo en UMA's" },
        { id: 4, name: "Estatus" }
    ];

    function getReglasProrratasService(){
        setLoading(true)
        getReglasProrratas().then(res => {
            let _res = JSON.parse(res);
            console.log(_res)
            if(!(_res.response === 'error')){
                setElements(_res.data);
            } else {
                setElements([])
            }
            setLoading(false);
        })
    }

    const onSubmit = (e)=>{
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        addReglaProrrata({values:objectValues}).then(res=>{
            console.log({res})
            let _res = JSON.parse(res)
            if(_res.response === 'success'){
                Swal.fire({
                    title:'Agregado correctamente',
                    timer:1200,
                    showConfirmButton:false,
                    icon:'success'
                })
                $("#modalAddReglaProrrateo").modal("hide")
                $("#formAddReglaProrrateo")[0].reset();
                getReglasProrratasService()
            }
        })
    }

    React.useEffect(getReglasProrratasService, []);

    return(
        <section>

            <ModalAddReglaProrrateo
                onSubmit={onSubmit}
            />

            <div className="d-flex justify-content-between">

                <h1>Reglas de UMAs para la prorrata</h1>

                {
                    props.permisos[0].escribir === '1' &&
                        <AddButton text="Nueva regla de prorrata" onClick={ async () => {
                            $('#modalAddReglaProrrateo').modal('show')
                        } } />
                }

            </div>
            
            <p className="text-muted my-3">Estos valores son los criterios que utilizaremos al momento de calcular la prorrata de un siniestro. Es decir, el mínimo en UMA es el monto necesario para que la empresa pague un siniestro, así mismo el máximo en UMA.</p>

            <hr className="my-4" />
            
            <LoadingSearchIcon show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <TableCatalogo>
                        <TableHead rows={rows} />

                        <TableBody 
                            elements={elements} 
                            typeElement="reglas_prorratas" 
                            permisosUsuario={props.permisos} 
                            getReglasProrratasService={getReglasProrratasService}  
                        />
                    </TableCatalogo>
            }

            {
                ( Object.keys(elements).length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }


        </section>
    )
}