import $ from 'jquery';

import config from '../../../config';
const URL_API = config.urlApi+'controlador/catalogos/tipo_gasto_siniestro/getTiposGastoSiniestroActive.php';

export default function getTiposGastoSiniestroActive(){
    return $.ajax({
        url: URL_API,
        type: 'GET',
    })
}