import TableElementAseguradoras from './TableElementAseguradoras';
import TableElementCantidadFianza from './TableElementCantidadFianza';
import TableElementCreenciasReligiosas from './TableElementCreenciasReligiosas';
import TableElementEntidadLegalSiniestro from './TableElementEntidadLegalSiniestro';
import TableElementEstadosCiviles from './TableElementEstadosCiviles';
import TableElementGeneros from './TableElementGeneros';
import TableElementMarcasVehiculos from './TableElementMarcasVehiculos';
import TableElementMetodoPago from './TableElementMetodoPago';
import TableElementPrefijosEconomicos from './TableElementPrefijosEconomicos';
import TableElementProrratas from './TableElementProrratas';
import TableElementReglasProrratas from './TableElementReglasProrratas';
import TableElementSubmarcasVehiculos from './TableElementSubmarcasVehiculos';
import TableElementTipoDeBienes from './TableElementTipoDeBienes';
import TableElementTipoGastoSiniestro from './TableElementTipoGastoSiniestro';
import TableElementTiposCombustibles from './TableElementTiposCombustibles';
import TableElementTiposLicencias from './TableElementTiposLicencias';
import TableElementTiposPermisionarios from './TableElementTiposPermisionarios';
import TableElementTiposTrabajadores from './TableElementTiposTrabajadores';
import TableElementTiposVehiculos from './TableElementTiposVehiculos';
import TableElementUMAs from './TableElementUMAs';
// import TableElementConcesion from './TableElementConcesion';
// import TableElementUnidad from './TableElementUnidad';
// import TableElementUsuario from './TableElementUsuario';
// import TableElementTrabajadores from './TableElementTrabajadores';
// import TableElementDerroteros from './TableElementDerroteros';
// import TableElementEconomicos from './TableElementEconomicos';
// import TableElementClave from './TableElementClave';
// import TableElementOperador from './TableElementOperador';
// import TableElementProyecto from './TableElementProyecto';
// import TableElementTerminal from './TableElementTerminal';

export default function TableBody(props){

    function renderTableElements(){
        if(props.typeElement === "tipos_vehiculos"){
            return props.elements.map(
                (element) => 
                    <TableElementTiposVehiculos 
                        key={element.id_tipo_vehiculo} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getTiposVehiculosService={props.getTiposVehiculosService} 
                    />
                );
        }

        if(props.typeElement === "prefijos_economicos"){
            return props.elements.map(
                (element) => 
                    <TableElementPrefijosEconomicos 
                        key={element.id_prefijo_economico} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getPrefijosEconomicosService={props.getPrefijosEconomicosService} 
                    />
                );
        }

        if(props.typeElement === "creencias_religiosas"){
            return props.elements.map(
                (element) => 
                    <TableElementCreenciasReligiosas
                        key={element.id_creencia_religiosa} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getCreenciasReligiosasService={props.getCreenciasReligiosasService} 
                    />
                );
        }
        
        if(props.typeElement === "aseguradoras"){
            return props.elements.map(
                (element) => 
                    <TableElementAseguradoras
                        key={element.id_aseguradora} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getAseguradorasService={props.getAseguradorasService} 
                    />
                );
        }
        
        if(props.typeElement === "tipos_licencias"){
            return props.elements.map(
                (element) => 
                    <TableElementTiposLicencias
                        key={element.id_tipo_licencia} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getTiposLicenciasService={props.getTiposLicenciasService} 
                    />
                );
        }
        
        if(props.typeElement === "umas"){
            return props.elements.map(
                (element) => 
                    <TableElementUMAs
                        key={element.id_uma} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getUMAsService={props.getUMAsService} 
                    />
                );
        }
        
        if(props.typeElement === "reglas_prorratas"){
            return props.elements.map(
                (element) => 
                    <TableElementReglasProrratas
                        key={element.id_regla_prorrata} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getReglasProrratasService={props.getReglasProrratasService} 
                    />
                );
        }
        
        if(props.typeElement === "prorratas"){
            return props.elements.map(
                (element) => 
                    <TableElementProrratas
                        key={element.id_prorrata} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getProrratasService={props.getProrratasService} 
                    />
                );
        }
        
        if(props.typeElement === "tipos_combustibles"){
            return props.elements.map(
                (element) => 
                    <TableElementTiposCombustibles
                        key={element.id_tipo_combustible} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getTiposCombustibleService={props.getTiposCombustibleService} 
                    />
                );
        }
        
        if(props.typeElement === "estados_civiles"){
            return props.elements.map(
                (element) => 
                    <TableElementEstadosCiviles
                        key={element.id_estado_civil} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getEstadosCivilesService={props.getEstadosCivilesService} 
                    />
                );
        }
        
        if(props.typeElement === "generos"){
            return props.elements.map(
                (element) => 
                    <TableElementGeneros
                        key={element.id_genero} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getGenerosService={props.getGenerosService} 
                    />
                );
        }
        
        if(props.typeElement === "tipos_permisionarios"){
            return props.elements.map(
                (element) => 
                    <TableElementTiposPermisionarios
                        key={element.id_tipo_permisionario} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getTiposPermisionariosService={props.getTiposPermisionariosService} 
                    />
                );
        }

        if(props.typeElement === "tipos_trabajadores"){
            return props.elements.map(
                (element) => 
                    <TableElementTiposTrabajadores
                        key={element.id_tipo_trabajador} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getTiposTrabajadoresService={props.getTiposTrabajadoresService} 
                    />
                );
        }

        if(props.typeElement === "marcas_vehiculos"){
            return props.elements.map(
                (element) => 
                    <TableElementMarcasVehiculos
                        key={element.id_marca_vehiculo} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getMarcasVehiculosService={props.getMarcasVehiculosService} 
                    />
                );
        }

        if(props.typeElement === "submarcas_vehiculos"){
            return props.elements.map(
                (element) => 
                    <TableElementSubmarcasVehiculos
                        key={element.id_submarca_vehiculo} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getSubmarcasVehiculosService={props.getSubmarcasVehiculosService} 
                    />
                );
        }


        if(props.typeElement === "tipos_de_bien"){
            return props.elements.map(
                (element) => 
                    <TableElementTipoDeBienes
                        key={element.id_tipo_de_bien} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getTipoDeBienesService={props.getTipoDeBienesService} 
                    />
                );
        }

        if(props.typeElement === "metodo_pago"){
            return props.elements.map(
                (element) => 
                    <TableElementMetodoPago
                        key={element.id_tipo_de_bien} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getMetodosPagoService={props.getMetodosPagoService} 
                    />
                );
        }

        if(props.typeElement === "tipo_gasto_siniestro"){
            return props.elements.map(
                (element) => 
                    <TableElementTipoGastoSiniestro
                        key={element.id_tipo_de_bien} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getTiposGastosSiniestroService={props.getTiposGastosSiniestroService} 
                    />
                );
        }

        if(props.typeElement === "cantidad_fianza"){
            return props.elements.map(
                (element) => 
                    <TableElementCantidadFianza
                        key={element.id_cantidad_fianza} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getCantidadFianzaService={props.getCantidadFianzaService} 
                    />
                );
        }
        
        if(props.typeElement === "entidad_legal_siniestro"){
            return props.elements.map(
                (element) => 
                    <TableElementEntidadLegalSiniestro
                        key={element.id_entidad_legal_siniestro} 
                        element={element} 
                        permisosUsuario={props.permisosUsuario} 
                        getEntidadesLegalesSiniestroService={props.getEntidadesLegalesSiniestroService} 
                    />
                );
        }
        
    }

    return(
        <tbody>

            {   
                renderTableElements()
            }

        </tbody>
    )
}