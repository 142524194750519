import "./ModalAsignacionVehiculo.css";
import React from "react";
import $, { data } from "jquery";
import config from '../../config';
import getDerroterosService from './../../services/getDerroterosService';
import getEconomicosActivosService from './../../services/getEconomicosActivosService';
import Swal from 'sweetalert2'
import addAsignacionConcesionEconomicoVehiculoService from "../../services/addAsignacionConcesionEconomicoVehiculoService";
import getVehiculosByConcesionEconomico from "../../services/historial_vehiculo_concesion_economico/getVehiculosByConcesionEconomico";
import { text } from "@fortawesome/fontawesome-svg-core";
const URL_API = config.urlApi;

export default function ModalAsignacionVehiculo(props){

    const { operadores, getConcesionesEconomicosByVehiculo, getOperadoresByVehiculo } = props;

    const [concesiones, setConcesiones] = React.useState([]);
    const [derroteros, setDerroteros] = React.useState([]);
    const [economicos, setEconomicos] = React.useState([]);

    const getConcesiones = () => {
        $.ajax({
            url: URL_API+'controlador/getConcesionesActivas.controlador.php',
            success: function(response){
                // console.log(response)
                let _respuesta = JSON.parse(response);
                if(!(_respuesta.response == 'error')){
                    console.log(_respuesta)
                    setConcesiones(_respuesta);
                    $(".selectpicker").selectpicker();
                }
            }
        })
    }

    // function getDerroteros(){
    //     getDerroterosService().then(response => {
    //         let _respuesta = JSON.parse(response);
    //         if(_respuesta.response === "error"){
    //             // Swal.fire(
    //             //     'Error al cargar derroteros',
    //             //     'No existe ningún derrotero',
    //             //     'error'
    //             // )
    //         } else {
    //             setDerroteros(_respuesta.data);
    //             $(".selectpicker").selectpicker();
    //         }

    //     }).catch((error) => {
    //         console.log(error)
    //         console.log("ha petado")
    //         Swal.fire(
    //             'Error del servidor',
    //             'Inténtalo de nuevo en unos momentos.',
    //             'error'
    //         )
    //     })
    // }

    function getEconomicos(){
        getEconomicosActivosService().then(response => {
            console.log(response)
            let _respuesta = JSON.parse(response);
            if(_respuesta.response === "error"){
                setEconomicos([])
            } else {
                setEconomicos(_respuesta.data);
            }
            $(".selectpicker").selectpicker('refresh');

        }).catch((error) => {
            console.log(error)
            console.log("ha petado")
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    const addAsignacionConcesionEconomicoVehiculo = (values)=>{
        addAsignacionConcesionEconomicoVehiculoService({values:values}).then(res=>{
            console.log(res)
            let _respuesta = JSON.parse(res);
            if (_respuesta.response === "success") {
                // Recargamos las concesiones y economicos vinculados al vehículo
                getConcesionesEconomicosByVehiculo();
                // Recargamos los operadores del vehículo
                getOperadoresByVehiculo();
                $("#modalAsignacionVehiculo").modal('hide');
                Swal.fire({
                    title: 'Exito',
                    text: 'Asignado correctamente',
                    icon: 'success',
                    timer: 1200,
                    showConfirmButton: false
                })
            }
        })
    }

    const onSubmit = (e)=>{
        e.preventDefault()
        let values = new FormData(e.target)
        values.append('id_vehiculo', props.id_vehiculo)
        let objectValues = Object.fromEntries(values);
        // PRIMERO VERIFICAR SI YA EXISTE VINCULADA LA CONCESION Y EL ECONOMICO VINCULADA A UN VEHICULO
        // PARA SU FUNCIONAMIENTO. EN ESE CASO, MOSTRAR UNA ALERTA DE QUE SE DESVINCULARÁ LA CONCESIÓN Y EL
        // ECONÓMICO DE LA OTRA UNIDAD Y SI ACEPTA, QUE LO HAGA.

        // SI NO EXISTE NINGUNA QUE ESTÉ VINCULADA, QUE LA VINCULE SIN PROBLEMAS.
        getVehiculosByConcesionEconomico({values:objectValues})
            .then(res => {
                console.log(res)
                let _respuesta = JSON.parse(res);
                if(_respuesta.response === 'error')
                    Swal.fire({
                        title: "¿Seguro que deseas continuar?",
                        text: 'Este vehículo se pondrá en funcionamiento con la concesión, económico, ruta y operador seleccionados',
                        icon: 'info',
                        confirmButtonText: 'Si, estoy seguro',
                        showCancelButton: true,
                        cancelButtonText: 'No, cancelar'
                    }).then(value=>{
                        if(value.isConfirmed)
                            addAsignacionConcesionEconomicoVehiculo(objectValues)
                    })
                
                if(_respuesta.response === 'success'){
                    if(props.concesionesEconomicos.some((value => (value.activo === 1)))){
                        Swal.fire({
                            title: "¿Seguro que deseas continuar?",
                            text: 'Este vehículo ya está en funcionamiento con su concesión y económico, por lo que se detendrá y funcionará ahora con los seleccionados. Recuerda que el económico y la concesión ya vinculados a un vehículo en funcionamiento también se detendrán',
                            icon: 'warning',
                            confirmButtonText: 'Si, estoy seguro',
                            showCancelButton: true,
                            cancelButtonText: 'No, cancelar'
                        }).then(value=>{
                            if(value.isConfirmed)
                                addAsignacionConcesionEconomicoVehiculo(objectValues)
                        })
                    }
                    
                    if(props.concesionesEconomicos.length === 0 || props.concesionesEconomicos.every((value => (value.activo !== 1)))){
                        Swal.fire({
                            title: "¿Seguro que deseas continuar?",
                            text: 'Sí la concesión ya se encuentra en funcionamiento, se detendrá y sólo funcionará en esta unidad. Lo mismo aplica para el número económico',
                            icon: 'warning',
                            confirmButtonText: 'Si, estoy seguro',
                            showCancelButton: true,
                            cancelButtonText: 'No, cancelar'
                        }).then(value=>{
                            if(value.isConfirmed)
                                addAsignacionConcesionEconomicoVehiculo(objectValues)
                        })
                    }
                    
                }
            })
    }

    React.useEffect(() => {
        getConcesiones()
        getEconomicos()
    } , []);

    return(
        <div className="modal" id="modalAsignacionVehiculo" tabIndex="-1" role="dialog" aria-labelledby={`modalAsignacionVehiculoLabel`} aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form className="modal-content" onSubmit={onSubmit}>
                    <div className="modal-header">
                        <h5 className="modal-title" id={`modalAsignacionVehiculoLabel`}>{`Poner la unidad en funcionamiento`}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <label htmlFor={`id_concesion`}>
                            Elige una concesión:
                        </label>
                        {
                            economicos.length === 0 &&
                                <div class="alert alert-warning" role="alert">
                                    No hay concesiones activas para vincular
                                </div>
                        }
                        <select 
                            className="input selectpicker" 
                            name={`id_concesion`}
                            id={`id_concesion`}
                            data-live-search="true"
                            required
                        >
                            <option value="">
                                {
                                    concesiones.length === 0 ? "No hay concesiones" : "Elige una concesión"
                                }
                            </option>
                            {
                                concesiones.length !== 0 &&
                                concesiones.map((element) => {
                                    return <option key={element.id_concesion} value={element.id_concesion}>{element.name}</option>
                                })
                            }
                        </select>

                        <hr className="my-2" />

                        <label htmlFor={`id_economico`}>
                            Elige un número económico:
                        </label>
                        {
                            economicos.length === 0 &&
                                <div class="alert alert-warning" role="alert">
                                    No hay económicos activos para vincular
                                </div>
                        }
                        <select 
                            className="input selectpicker" 
                            name={`id_economico`}
                            id={`id_economico`}
                            data-live-search="true"
                            required
                        >
                            <option value="">
                                {
                                    economicos.length === 0 ? "No hay números económicos" : "Elige un número económico"
                                }
                            </option>
                            {
                                economicos.length !== 0 &&
                                economicos.map((element) => {
                                    return <option key={element.id_economico} value={element.id_economico}>
                                        {element.clave_prefijo_economico}{element.numero} - {element.derrotero}
                                    </option>
                                })
                            }
                        </select>

                        <hr className="my-2" />

                        <label htmlFor={`id_operador`}>
                            Elige un operador:
                        </label>
                        {
                            operadores.length === 0 &&
                                <div class="alert alert-warning" role="alert">
                                    El permisionario no tiene operadores vinculados
                                </div>
                        }
                        <select 
                            className="input selectpicker" 
                            name={`id_operador`}
                            id={`id_operador`}
                            data-live-search="true"
                            required
                        >
                            <option value="">
                                {
                                    operadores.length === 0 ? "No hay operadores" : "Elige un operador"
                                }
                            </option>
                            {
                                operadores.length !== 0 &&
                                operadores.map((element) => {
                                    return <option key={element.id} value={element.id}>
                                        {element.name}
                                    </option>
                                })
                            }
                        </select>
                        <small>Podrás agregar más operadores después de esto</small>
                        
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-naranja">
                            Asignar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}