import React from "react";
import $ from 'jquery'

export default function ModalAddAsignacionDerroteroEconomico(props){

    React.useEffect(()=>{
        $(".selectpicker").selectpicker('refresh');
    },[props.economicos])

    return(
        <div className="modal" id="modalAddAsignacionDerroteroEconomico" tabIndex="-1" role="dialog" aria-labelledby="modalAddAsignacionDerroteroEconomicoLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={props.onSubmit} id='formAddAsignacionDerroteroEconomico' className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddAsignacionDerroteroEconomicoLabel">Agrega una nueva religión</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="id_economico">
                                    Económico:
                                </label>
                                <select 
                                    className="input selectpicker"
                                    id="id_economico"
                                    name="id_economico"
                                    data-live-search="true"
                                    required
                                >  
                                    {
                                        props.economicos && 
                                        props.economicos.map( economico => (
                                            <option key={ economico.id_economico } value={ economico.id_economico }>
                                                { economico.numero }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-primario">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}