import React from 'react';
import "./FormularioDocumentosVehículo.css"
import Icon from '../../components/Icon';
import $ from 'jquery';
import changeDocumentosVehiculoService from './../../services/changeDocumentosVehiculoService';
import Swal from 'sweetalert2'

export default function FormularioDocumentosVehículo({ vehiculo, permisosUsuario, rutaServidor }){
    
    let{
        url_poliza_seguro,
        url_tarjeta_circulacion,
        url_tabla_amortizacion,
        url_baja_placas,
        url_documento_vobo,
        id_documento_vehiculo,
        id_vehiculo,
        descripcion_documentos_vehiculo
    } = vehiculo;

    function setDataFormulario(){
        document.getElementById('anotacionesDocumentosUnidad').value = descripcion_documentos_vehiculo;

        if(permisosUsuario[0].editar === "0"){
            $("form :input, :input[form]").prop("readonly", true);
        }
    }

    function onSubmitVehiculo(e){
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_documento_vehiculo', id_documento_vehiculo)
        values.append('id_vehiculo', id_vehiculo)
        // let objectValues = Object.fromEntries(values);
        // console.log({objectValues})
        changeDocumentosVehiculoService({values:values})
            .then(response => {
                console.log(response);
                let _response = JSON.parse(response);
                // console.log(_response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: '¡Listo!',
                        text: 'Se han actualizado los docuementos del vehículo',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No haz actualizado nada',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => {})
    }

    function onChangeFileInput(e, nombreInput){
        const file = e.target.files[0];
        document.getElementById(nombreInput+'Preview').src = URL.createObjectURL(file);
        $('#'+nombreInput+'Preview').removeClass("hidden")
    }

    React.useEffect(() => {
        setDataFormulario();
    }, []);

    return(

        <form className="formulario" onSubmit={ onSubmitVehiculo }>

            {/* ********************************* */}
            {/* BLOQUE DE LA SUBIDA DE DOCUMENTOS */}
            {/* ********************************* */}

            <div className="subidaDocumentos__formulario row mt-3">
                <div className="col-4">
                    <input type="file" id="url_poliza_seguro" name="url_poliza_seguro" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "url_poliza_seguro") } />
                    <label htmlFor="url_poliza_seguro">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Subir póliza de seguro
                        </a>
                    </label>
                    <embed
                        id="url_poliza_seguroPreview"
                        src={`${rutaServidor}${url_poliza_seguro}`}
                        className={`previewPDF ${url_poliza_seguro ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>
                
                <div className="col-4">
                    <input type="file" id="url_tarjeta_circulacion" name="url_tarjeta_circulacion" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "url_tarjeta_circulacionPDF") } />
                    <label htmlFor="url_tarjeta_circulacion">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Subir tarjeta de circulación
                        </a>
                    </label>
                    <embed
                        id="url_tarjeta_circulacionPDFPreview"
                        src={`${rutaServidor}${url_tarjeta_circulacion}`}
                        className={`previewPDF ${url_tarjeta_circulacion ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-4">
                    <input type="file" id="url_tabla_amortizacion" name="url_tabla_amortizacion" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "url_tabla_amortizacion") } />
                    <label htmlFor="url_tabla_amortizacion">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Subir tabla de amortización
                        </a>
                    </label>
                    <embed
                        id="url_tabla_amortizacionPreview"
                        src={`${rutaServidor}${url_tabla_amortizacion}`}
                        className={`previewPDF ${url_tabla_amortizacion ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-4">
                    <input type="file" id="url_baja_placas" name="url_baja_placas" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "url_baja_placas") } />
                    <label htmlFor="url_baja_placas">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Documento de baja de placas
                        </a>
                    </label>
                    <embed
                        id="url_baja_placasPreview"
                        src={`${rutaServidor}${url_baja_placas}`}
                        className={`previewPDF ${url_baja_placas ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>
                
                <div className="col-8">
                    <input type="file" id="url_documento_vobo" name="url_documento_vobo" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "url_documento_vobo") } />
                    <label htmlFor="url_documento_vobo">
                        <a type="button" className="btnFile">
                            <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                            Documento de VoBo Secretaría de movilidad
                        </a>
                    </label>
                    <embed
                        id="url_documento_voboPreview"
                        src={`${rutaServidor}${url_documento_vobo}`}
                        className={`previewPDF ${url_documento_vobo ? "" : "hidden"}`}
                        width="250"
                        height="200">
                    </embed>
                </div>

                <div className="col-12">
                    <label htmlFor="anotacionesDocumentosUnidad">
                        Anotaciones:
                    </label>
                    <textarea 
                        rows={5}
                        cols={5}
                        type="text"
                        placeholder="(Opcional) Aquí puedes anotar cualquier detalle o comentario extra sobre los documentos del vehículo" 
                        className="input textarea"
                        id="anotacionesDocumentosUnidad"
                        name="anotacionesDocumentosUnidad"
                    />
                </div>

            </div>

            {/* ******************************* */}
            {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
            {/* ******************************* */}

            {
            (permisosUsuario[0].editar !== "0") &&
            <React.Fragment>
                <div className="d-flex justify-content-end">
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus" />
                        <Icon icon="fa-solid fa-file mr-3" />
                        Actualizar documentos de la unidad
                    </button>
                </div>
            </React.Fragment>
            }


        </form>
    );
}