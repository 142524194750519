import React from 'react';
import "./FormularioAccesoSistema.css";
import getPermisosSistemaService from "./../../services/getPermisosSistemaService.js";
import getUsuariosSinAsignarService from "./../../services/getUsuariosSinAsignarService.js";
import assignUsuarioTrabajadorService from "./../../services/assignUsuarioTrabajadorService.js";
import addNewUsuarioTrabajadorService from "./../../services/addNewUsuarioTrabajadorService.js";
import Swal from 'sweetalert2'
import $ from 'jquery';
import Icon from '../../components/Icon';

export default function FormularioAccesoSistema({trabajador, id_trabajador, permisosUsuario}){

    const [permisos, setPermisos] = React.useState([]);
    const [usuariosSinAsignar, setUsuariosSinAsignar] = React.useState([]);

    function getPermisosSistema(){
        getPermisosSistemaService().then(response => {
            let _respuesta = JSON.parse(response);
            // console.log(_respuesta)
            if(_respuesta.response === "error"){
                Swal.fire(
                    'Error al cargar permisos',
                    'No existe ningún permiso o hubo un error en la conexión, inténtalo de nuevo recargando la página.',
                    'error'
                )
            } else {
                setPermisos(_respuesta.permisos);
                $('.selectpicker').selectpicker('refresh')
            }

        }).catch((error) => {
            console.log(error)
            console.log("ha petado")
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    function getUsuariosSinAsignar(){
        getUsuariosSinAsignarService().then(response => {
            let _respuesta = JSON.parse(response);
            if(_respuesta.response === "error"){
                // Se comenta la alerta porque no siempre habrá usuarios disponibles para asignar al trabajador
                
                // Swal.fire(
                //     'Error al cargar usuarios',
                //     'No existe ningún usuario o hubo un error en la conexión, inténtalo de nuevo recargando la página.',
                //     'error'
                // )
            } else {
                setUsuariosSinAsignar(_respuesta.usuarios);
                $('.selectpicker').selectpicker('refresh')
            }
        }).catch((error) => {
            console.log(error)
            console.log("ha petado")
        })
    }

    function onSubmitForm(e){
        e.preventDefault();
        let values = new FormData(e.target)
        let permisosSistemaSelected = $("#permisosSistema").val();
        values.append("permisosSistemaSelected",JSON.stringify(permisosSistemaSelected));
        values.append("id_trabajador", id_trabajador);
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        addNewUsuarioTrabajadorService({values:objectValues})
            .then(response => {
                console.log(response)
                let _respuesta = JSON.parse(response);
                if(_respuesta.response === "error"){
                    Swal.fire(
                        'Error al asignar',
                        _respuesta.message,
                        'error'
                    )
                } else {
                    Swal.fire(
                        'Asignado',
                        _respuesta.message,
                        'success'
                    ).then(() => {
                        window.location.reload();
                    })
                }
            })
            .catch(error => {})
    }
    
    function onSubmitFormTrabajador(e){
        e.preventDefault();
        let values = new FormData(e.target)
        values.append("id_trabajador", trabajador.id_trabajador);
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        assignUsuarioTrabajadorService({values:objectValues})
            .then(response => {
                let _respuesta = JSON.parse(response);
                if(_respuesta.response === "error"){
                    Swal.fire(
                        'Error al asignar',
                        _respuesta.message,
                        'error'
                    )
                } else {
                    Swal.fire(
                        'Asignado',
                        _respuesta.message,
                        'success'
                    ).then(() => {
                        window.location.reload();
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                console.log("ha petado")
            })
    }

    React.useEffect(() => {
        getPermisosSistema();
        getUsuariosSinAsignar();
    }, []);
    
    return(
        <div className="datosAccesoSistema mb-5">

            <h3>
                O, selecciona un usuario que aún no ha sido asignado a un trabajador:
            </h3>

            <form onSubmit={onSubmitFormTrabajador}>
                <label htmlFor="id_usuario">
                    Usuario:
                </label>
                <select 
                    className="input selectpicker"
                    id="id_usuario"
                    name="id_usuario"
                    required
                    data-live-search="true"
                >
                    {
                        usuariosSinAsignar && 
                        usuariosSinAsignar.map( usuario => (
                            <option key={ usuario.id_usuario } value={ usuario.id_usuario }>
                                { `ID Usuario: ${usuario.id_usuario} - Correo: ${usuario.correo}` }
                            </option>
                        ))
                    }
                </select>

                <div className="d-flex justify-content-end mt-4">
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus" />
                        <Icon icon="fa-solid fa-circle-user mr-3" />
                        Asignar usuario a { trabajador.nombre }
                    </button>
                </div>
            </form>

            <hr></hr>

            <h3>
                Ingresa sus datos de acceso al sistema:
            </h3>

            <p>
                { trabajador.nombre } <i>no tiene acceso al sistema</i>
            </p>

            <p>
                Sí quieres que <span className="font-weight-bold">tenga acceso</span>, completa los siguientes campos:
            </p>

            <form onSubmit={onSubmitForm}>
                <div className="datosAccesoSistema__formulario row mt-4">
                    <div className="col-4">
                        <label htmlFor="correo">
                            Correo de acceso:
                        </label>
                        <input 
                            type="email"
                            className="input"
                            id="correo"
                            name="correo"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="password">
                            Contraseña:
                        </label>
                        <input 
                            type="text"
                            className="input"
                            id="password"
                            name="password"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="permisosSistema">
                            Permisos:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="permisosSistema"
                            multiple
                            name="permisosSistema"
                            required
                        >
                            {
                                permisos &&
                                permisos.map( (permiso) => (
                                    <optgroup key={ permiso.id_permiso } label={ permiso.nombre_modulo }>
                                        <option value={ `${permiso.id_permiso}_leer` }>Leer</option>
                                        <option value={ `${permiso.id_permiso}_escribir` }>Escribir</option>
                                        <option value={ `${permiso.id_permiso}_editar` }>Editar</option>
                                        <option value={ `${permiso.id_permiso}_eliminar` }>Eliminar</option>
                                    </optgroup>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <div className="d-flex justify-content-end mt-4">
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus" />
                        <Icon icon="fa-solid fa-circle-user mr-3" />
                        Agregar nuevo usuario para { trabajador.nombre }
                    </button>
                </div>
            </form>

        </div>
    )
}