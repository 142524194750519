import $ from 'jquery';
import config from './../../config';
const URL_API = config.urlApi+'controlador/siniestro/getSaldosTrabajadoresSiniestro.php';

export default function getSaldosTrabajadoresSiniestro({values}){
    return $.ajax({
        url: URL_API,
        type: 'POST',
        data: values,
    })
}