import React from 'react';
import logo from "./../../assets/images/logo.png";

// Componentes
import { Document, Page, Image, Text, View, Font } from "@react-pdf/renderer";
import getMovimientosCuentaByIdBancoDigital from '../../services/banco_digital/getMovimientosCuentaByIdBancoDigital';

export default function PDFGeneratorEstadoCuentaSiniestro({ id_banco_digital }){

    const [loading, setLoading] = React.useState(true)
    const [user, setUser] = React.useState({})
    const [gastos, setElements] = React.useState([])

    function getMovimientosCuentaService(){
        setLoading(true)
        let data = {
            id_banco_digital: id_banco_digital
        }
        getMovimientosCuentaByIdBancoDigital({values:data})
            .then(res =>{
                console.log(res)
                let _res = JSON.parse(res);
                console.log(_res)
                if(_res.response === 'success'){
                    calculateSaldoOfMovimientos(_res.data)
                    setUser(_res.data_usuario)
                    // setElements(_res.data);
                }
                setLoading(false);
            })
    }

    const calculateSaldoOfMovimientos = (elements)=>{
        let saldo = 0;
        let movimientosConSaldo = elements.slice().reverse().map(movimiento => {
            // Verifica si es un ingreso o un egreso y suma/resta el cantidad al saldo
            if (movimiento.id_movimiento_ingreso) {
                saldo += parseFloat(movimiento.cantidad);
            } else if (movimiento.id_movimiento_egreso) {
                saldo += parseFloat(movimiento.cantidad);
            }

            // Crea una copia del objeto 'movimiento' y añade el campo 'saldo' con el valor actualizado
            return {
                ...movimiento,
                saldo: saldo
            };
        }).reverse();
        console.log(movimientosConSaldo)
        setElements(movimientosConSaldo);
    }

    Font.register({
        family: 'Open Sans',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 }
        ]
    });

    const SubtituloPDF = (text)=>{
        return (
            <View 
                style={{backgroundColor: "#FF7925", height: 20, width: '100%', borderTopLeftRadius: 10, borderTopRightRadius: 10}}
            >
                <Text style={{ color: 'white', textAlign: 'left', paddingLeft: 10, fontSize: 13, fontWeight: 'bold' }}>
                    { text }
                </Text>
            </View>
        )
    }

    const HeaderPDF = ()=>{
        return (
            <View 
                style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'top', marginBottom: 20 }}
                fixed
            >   
                <View 
                    style={{ width: '100%', alignItems: 'flex-start' }}
                >
                    <Image src={logo} style={{ width: '70px', height: 'auto'}} />
                </View>

                <View style={{alignItems: 'center', justifyContent: 'center', width:'100%'}}>
                    <Text style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 16 }}>
                        ESTADO DE CUENTA
                    </Text>
                    <Text style={{ color: 'gray', fontSize: 9 }}>
                        SINIESTROS
                    </Text>
                </View>

                <View style={{ justifyContent: 'center', width: '100%'}}>
                    <Text style={{ fontWeight: 'bold', textAlign: 'right', fontSize: 10 }}>
                        {user.clave !== null && user.clave} {user.nombre}
                    </Text>
                    <Text style={{ textAlign: 'right', fontSize: 10 }}>
                        {user.tipo_usuario} - {user.activo === '1' ? 'Activo' : "Inactivo"}
                    </Text>
                    <Text style={{ color: 'gray', textAlign: 'right', fontSize: 9 }}>Impresión:
                        <Text style={{ color: 'black', fontSize: 9 }}> { new Date().toLocaleString() } </Text>
                    </Text>
                </View>
            </View>
              
        )
    }
    
    const ItemTableGastoPDF = (gasto) =>{

        return (
            <View 
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    alignItems: 'center', 
                    fontSize: 9, 
                    marginTop: 5, 
                    marginBottom: 5, 
                    paddingLeft:10, 
                    paddingRight: 10 
                }}
            >
                <View style={{ width: '20%', display: 'flex', flexDirection: 'column'  }}>
                    <Text>
                        { `${gasto.fecha_alta.split(" ")[0]} ` }
                        <Text style={{ color: '#818181' }}>
                            { `${gasto.fecha_alta.split(" ")[1]}` }
                        </Text>
                    </Text>
                </View>
                <Text style={{ width: '35%', paddingRight: 5 }}>
                    { gasto.concepto }
                </Text>
                <Text style={{ width: '15%', fontWeight: 'bold', fontSize: 10, color: '#D15253' }}>
                    {
                        (gasto.id_movimiento_egreso !== undefined) &&
                            parseFloat(gasto.cantidad).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                    }
                </Text>
                <Text style={{ width: '15%', fontWeight: 'bold', fontSize: 10, color: '#659226' }}>
                    {
                        (gasto.id_movimiento_ingreso !== undefined) &&
                            parseFloat(gasto.cantidad).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                    }
                </Text>
                <Text style={{ width: '15%', fontWeight: 'bold', fontSize: 10, color: `${gasto.saldo > 0 ? '#659226' : '#D15253'}` }}>
                    { parseFloat(gasto.saldo).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                </Text>
            </View>
        )

    }

    const GeneratePDFTest = (props) => {
        return (
            <Document>
                <Page 
                    size="A4"
                    style={{ padding: 25, fontSize: 13, fontFamily: 'Open Sans' }}
                >

                    {/* CABECERA DE LA PÁGINA */}
                    { HeaderPDF() }
                    {/* ************************ */}

                    {/* SUBTITULO: GASTOS DEL SINIESTRO */}
                    { SubtituloPDF("Detalles de movimientos") }
                    {/* ********************************* */}

                    {/* TABLA DE GASTOS DEL SINIESTRO */}
                    <View style={{ marginBottom: 10 }}>
                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 10, fontWeight: 'bold', height: 18, backgroundColor: 'gray', color: 'white', paddingLeft: 10, paddingRight: 10 }}>
                            <Text style={{ width: '20%' }}>
                                Fecha
                            </Text>
                            <Text style={{ width: '35%' }}>
                                Concepto
                            </Text>
                            <Text style={{ width: '15%' }}>
                                Cargo
                            </Text>
                            <Text style={{ width: '15%' }}>
                                Abono
                            </Text>
                            <Text style={{ width: '15%' }}>
                                Saldo
                            </Text>
                        </View>
                        
                        {
                            gastos !== null && gastos.length > 0 && 
                                gastos.map((gasto)=>{
                                    return ItemTableGastoPDF(gasto)
                                })
                        }
                    </View>
                    {/* ******************* */}
                    
                    {/* PIE DE PÁGINA */}
                    <Text style={{ position: 'absolute', fontSize: 9, bottom: 15, left: 0, right: 0, textAlign: 'center', color:'grey' }} render={({ pageNumber, totalPages}) => (`${pageNumber} / ${totalPages}`)} fixed/>
                    {/* ********************************* */}
                </Page>
            </Document>
        )
    }

    React.useEffect(getMovimientosCuentaService, [])

    return(
        !loading &&
            <GeneratePDFTest />
    );
}