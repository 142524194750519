import React from 'react';
import Icon from "./../../components/Icon";
import Swal from 'sweetalert2'
import changeEstatusEconomicoService from './../../services/changeEstatusEconomicoService';
import { Link } from "react-router-dom";

export default function TableElementEconomico({ element, permisosUsuario }) {

    const { id_economico, numero, derrotero, clave_prefijo_economico, modalidad_prefijo_economico, fecha_alta, hora_alta, activo } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(activo);

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitar el económico?' : 'Quieres deshabilitar el económico?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_economico', id_economico)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                changeEstatusEconomicoService({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo)
                        Swal.fire({
                            title:"Estatus actualizado",
                            icon:"success",
                            timer:1200,
                            showConfirmButton:false
                        })
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_economico}>
            <th className='table-minwidth'>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={`/economicos/${id_economico}`} className="dropdown-item" >Ver</Link>

                            {
                                permisosUsuario[0].editar == 1 &&
                                (estadoEstatus == 1 ?
                                <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatus(false)}>Deshabilitar</a>
                                :
                                <a className="dropdown-item bg-success text-white" onClick={() => onClickEstatus(true)}>Habilitar</a>)
                            }
                        </div>
                    </div>

                    <div className="table__nameDescription">
                        {/* <p>
                            { `${clave_prefijo_economico !== null && clave_prefijo_economico}${numero}` }</p> */}
                        <p>
                            {clave_prefijo_economico}
                            {numero}
                        </p>
                        <small>
                            ID: { id_economico }
                        </small>
                    </div>
                </div>
            </th>

            <td>
                <div className="table__derrotero">
                    <p>{derrotero}</p>
                </div>
            </td>

            <td>
                <div className="table__fechaAlta">
                    <p>{fecha_alta}</p>
                    <small>{hora_alta}</small>
                </div>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ estadoEstatus == 1 ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus == 1 ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}