import React from "react";
import $ from 'jquery'
import Icon from "../Icon";
import getDerroterosActivosService from "../../services/getDerroterosActivosService";

export default function FormularioTerminal({terminal, onSubmit}){
    
    const [derroteros, setDerroteros] = React.useState([])

    if(terminal !== undefined)
        setDataFormulario()

    const getDerroterosActivos = () => {
        getDerroterosActivosService().then((respuesta) =>{
            let _respuesta = JSON.parse(respuesta)
            console.log(_respuesta)
            setDerroteros(_respuesta.derroteros)
            $('.selectpicker').selectpicker('refresh');
        })
    }

    const setDataFormulario = ()=>{
        document.getElementById("nombre").value = terminal.nombre;
        document.getElementById("clave").value = terminal.clave;
        document.getElementById("descripcion").value = terminal.descripcion;
        document.getElementById("id_derrotero").value = terminal.id_derrotero;
    }

    React.useEffect(getDerroterosActivos, []);

    return(
        <form className="formulario" onSubmit={ onSubmit } id='formularioTerminal'>

            {/* ******************************* */}
            {/* BLOQUE DE LOS DATOS PRINCIPALES */}
            {/* ******************************* */}

            <div className='mt-4'>
                <h3 className='my-4'>
                    Datos de la terminal
                </h3>

                <div className="row">
                    <div className="col-6">
                        <label htmlFor="nombre">
                            Nombre:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="nombre"
                            name="nombre"
                            required
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="clave">
                            Clave:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="clave"
                            name="clave"
                            required
                        />
                    </div>
                    <div className="col-12">
                        <label htmlFor="descripcion">
                            Descripción:
                        </label>
                        <input 
                            type="text"
                            className="input" 
                            id="descripcion"
                            name="descripcion"
                            placeholder='Opcional'
                        />
                    </div>
                </div>
            </div>
            
            <div className='mt-4'>
                <h3 className='my-4'>
                    ¿A qué derrotero pertenece la terminal?
                </h3>

                <div className="row">
                    <div className="col-5">
                        <label htmlFor="id_derrotero">
                            Derrotero:
                        </label>
                        <br></br>
                        <select 
                            className="input selectpicker"
                            id="id_derrotero"
                            name="id_derrotero"
                            data-live-search="true"
                            required
                        >
                            {
                                derroteros &&
                                derroteros.map( (derrotero) => (
                                    <option key={ derrotero.id_derrotero } value={ derrotero.id_derrotero }>{ derrotero.nombre }</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
            
            {/* ******************************* */}
            {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
            {/* ******************************* */}

            <hr className='my-4' /> 

            <div className="d-flex justify-content-end">
                
                {
                    (terminal !== undefined) &&
                        <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                            <Icon icon="fa-solid fa-fax mr-3" />
                            Actualizar terminal
                        </button>
                }

                { 
                (terminal === undefined) &&
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus mr-2" />
                        <Icon icon="fa-solid fa-fax mr-2" />
                        Agregar terminal
                    </button>
                }
            </div>


        </form>
    )
}