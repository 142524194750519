import React from 'react';

import { validateNoNumbers } from './../../validators/validateNoNumbers';
import Icon from '../../components/Icon';
import $ from 'jquery';

import getPermisosSistemaService from "./../../services/getPermisosSistemaService.js";
import getTiposUsuarioService from "./../../services/getTiposUsuarioService.js";
import addUsuarioService from "./../../services/addUsuarioService.js";
import FormularioUsuario from './../../components/FormularioUsuario';
import Swal from 'sweetalert2'

export default function AddUsuario({ permisosUsuario }){
    
    React.useEffect(() => {
        $('.selectpicker').selectpicker();
    }, []);

    const onSubmitUsuario = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        let permisosSistemaSelected = $("#permisosSistema").val();
        values.append("permisosSistemaSelected",JSON.stringify(permisosSistemaSelected));
        let objectValues = Object.fromEntries(values);
        addUsuarioService({values:objectValues}).then((response) => {
            let _response = JSON.parse(response);
            if(_response.response === "exist"){
                Swal.fire({
                    icon: 'warning',
                    title: "El correo ya está registrado",
                    text: "No puedes registrar un usuario dos veces con el mismo correo"
                })
            }

            if(_response.response === "error"){
                Swal.fire(
                    'Ocurrió un error',
                    'Revisar la conexión a internet e inténtalo de nuevo.',
                    'error'
                )
            } 
            
            if(_response.response === "success"){
                $("#formularioUsuario")[0].reset();
                $('.selectpicker').selectpicker('refresh');
                Swal.fire(
                    'Usuario agregado',
                    '',
                    'success'
                )
            }
        }).catch((error) => {
            console.log(error)
            console.log("ha petado")
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    return(
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
            />

            <h2 className="mt-5">Agregar nuevo usuario</h2>

            <p>
                Al agregar uno nuevo debes indicar a qué trabajador se vinculará las credenciales de acceso al sistema. Sí no quieres que se vincule a un trabajador deja el campo vacío.
            </p>

            <hr></hr>

            <FormularioUsuario
                onSubmit={onSubmitUsuario}
                permisosUsuario={permisosUsuario}
            />
        </section>
    );
}