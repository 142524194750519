import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2'
import PDFGeneratorEstadoCuentaSiniestro from '../PDFGeneratorEstadoCuentaSiniestro';
import { BlobProvider } from "@react-pdf/renderer";

export default function TableElementPermisionarioSaldoSiniestros({ element, permisosUsuario }) {

    let { id_permisionario, id_banco_digital, name, nombre_tipo_permisionario, urlImageProfile, key, units, date, hour, saldo_total, status } = element;

    const history = useHistory();
    const [showPDF, setShowPDF] = React.useState(false)

    const handleViewMovimientos = ()=>{
        if(id_banco_digital !== null)
            history.push(`/saldos-siniestro/${id_banco_digital}`)

        if(id_banco_digital === null)
            Swal.fire({
                title: 'No hay ningún movimiento',
                text: 'El permisionario no tiene movimientos hasta la fecha',
                icon: 'info',
            })
        
    }


    const blob = () =>{
        return (
            <BlobProvider document={<PDFGeneratorEstadoCuentaSiniestro id_banco_digital={id_banco_digital}/>}>
                {({ blob, url, loading, error }) =>{
                    try{

                        if (loading) {
                            return (
                                <button className="btn btn-sm btn-light mt-2 d-flex align-items-center justify-content-center color-naranja">
                                    <i className="fa-solid fa-spinner fa-spin mr-2"></i>
                                    Generando estado de cuenta...
                                </button>
                            )
                        } else if (error) {
                            return <div>Ocurrió un error: {error.message}</div>;
                        } else {
                            return (
                                <button 
                                    onClick={()=>{
                                        const pdfWindow = window.open();
                                        pdfWindow.location.href = url;
                                        setTimeout(()=>{
                                            setShowPDF(false);
                                        }, 200)
                                    }}
                                    className="btn btn-sm btn-light mt-2 d-flex align-items-center justify-content-center color-naranja"
                                >
                                    <i className="fa-solid fa-download mr-2"></i>
                                    Descargar PDF
                                </button>
                            )
                        }
                    } catch(err){
                        Swal.fire({
                            icon:"warning",
                            title: "Hubo un error al generar el PDF",
                            text:'Vuelve a intentarlo'
                        }).then(()=>{
                            window.location.reload()
                        })
                    }
                }}
            </BlobProvider>
        )
    }

    const generateEstadoCuenta = ()=>{
        if(id_banco_digital === null)
            Swal.fire({
                title: 'No existen movimientos',
                text: 'El permisionario no tiene movimientos hasta la fecha',
                icon: 'info',
            })

        if(id_banco_digital !== null)
            setShowPDF(true)
    }

    return (
        <tr key={id_permisionario}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <button onClick={handleViewMovimientos} className="dropdown-item" >Ver detalles de movimientos</button>
                            <button onClick={generateEstadoCuenta} className="dropdown-item bg-color-naranja" >Generar PDF estado de cuenta</button>
                        </div>
                    </div>

                    <div className="table__nameDescription">
                        <p>{name}</p>
                        { (key !== '') && <small>Clave: {key}</small> }
                        { (key === '') && <small>Clave no asignada</small> }
                        {
                            showPDF && blob()
                        }
                    </div>
                </div>
            </th>
            
            <td className={`font-size-lg ${saldo_total > 0 && 'color-verde'} ${saldo_total < 0 && 'color-rojo'} ${saldo_total === 0 && 'text-black'}`}>
                { parseFloat(saldo_total).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ status === '1' ? '' : 'table__estatus-inactive' }>
                        { status === '1' ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}