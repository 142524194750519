import React from 'react';
import Icon from "./../../components/Icon";
import Swal from 'sweetalert2'
import changePasswordService from './../../services/changePasswordService';
import changeEstatusUsuarioService from './../../services/changeEstatusUsuarioService';
import { Link } from "react-router-dom";

export default function TableElementUsuario({ element, permisosUsuario }) {

    const { id_usuario, nombre, correo, fecha_alta, hora_alta, urlFotoPerfil, status } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(status);

    // console.log(permisosUsuario[0].editar)

    const onClickChangePassword = () => {
        Swal.fire({
            title: 'Escribe la nueva contraseña',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Actualizar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_usuario', id_usuario)
                values.append('password', result.value)
                let objectValues = Object.fromEntries(values);
                changePasswordService({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        Swal.fire(
                            'Contraseña actualizada',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    const onClickEstatusAcceso = (activo) => {
        let msg = (activo) ? 'Quieres activar el acceso al sistema?' : 'Quieres revocarle el acceso al sistema?';
        let txt = (activo) ? 'Activar' : 'Revocar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_usuario', id_usuario)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                changeEstatusUsuarioService({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo)
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_usuario}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={`/usuarios/${id_usuario}`} className="dropdown-item" >Ver</Link>
                            {
                                permisosUsuario[0].editar == 1 &&
                                <a className="dropdown-item" onClick={onClickChangePassword}>Restablecer contraseña</a>
                            }

                            {
                                permisosUsuario[0].editar == 1 &&
                                (estadoEstatus == 1 ?
                                <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatusAcceso(false)}>Revocar acceso</a>
                                :
                                <a className="dropdown-item bg-success text-white" onClick={() => onClickEstatusAcceso(true)}>Activar acceso</a>)
                            }
                        </div>
                    </div>
                    {
                        (urlFotoPerfil) &&
                        (<img src={urlFotoPerfil} className="pedidos__imgTabla img-fluid rounded-circle "/>)
                    }

                    <div className="table__nameDescription">
                        <p>{ 
                            correo
                        }</p>
                        <small>
                            {nombre !== null ? nombre : 'Este usuario no está vinculado a ningún trabajador'}
                        </small>
                    </div>
                </div>
            </th>

            <td>
                <div className="table__fechaAlta">
                    <p>{fecha_alta}</p>
                    <small>{hora_alta}</small>
                </div>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ estadoEstatus == 1 ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus == 1 ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}