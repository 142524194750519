import $ from 'jquery';

import config from './../config';
const URL_API = config.urlApi+'controlador/login.php';

export default function login({values}){
    // console.log(values);
    return $.ajax({
        url: URL_API,
        type: 'POST',
        data: values
    })
}