import React from "react";
import Icon from "./../../components/Icon";
import { Link } from "react-router-dom";
import './Operadores.css';
import Table from "./../../components/Table";
import TableHead from "./../../components/Table/TableHead";
import TableBody from "./../../components/Table/TableBody";
import $ from 'jquery';
import config from '../../config';
const URL_API = config.urlApi;
export default function Operadores({permisosUsuario}) {

    React.useEffect(() => {
        getOperadores()
    },[]);

    const [elements, setElements] = React.useState([]);

    const rows = [
        { id: 1, name: "Nombre" },
        { id: 2, name: "Permisionario" },
        { id: 3, name: "Fecha de ingreso" },
        { id: 4, name: "Estatus" }
    ];

    function getOperadores(){
        $.ajax({
            url: URL_API+'controlador/getOperadores.controlador.php',
            success: function(response){
                console.log(response)
                let _respuesta = JSON.parse(response);
                console.log(_respuesta)
                if(!(_respuesta.response == 'error')){
                    setElements(_respuesta);
                }
            }
        })
    }

    return (
        <section>

            <h1 className="">Operadores</h1>

            <div className="operadores__header">
                <p>
                    Operadores registrados puedes consultarlos aquí.
                </p>

                {/* <Link to="/permisionarios/agregar" className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario">
                    <Icon icon="fas fa-user" />
                    <p>Agregar un permisionario</p>
                </Link> */}
            </div>

            <Table>
                <TableHead rows={rows} />

                <TableBody elements={elements} typeElement="operadores" permisosUsuario={permisosUsuario}  />
            </Table>

        </section>
    );
}