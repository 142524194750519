import React from 'react';
import Swal from 'sweetalert2'
import changeEstatusPermisionarioService from './../../services/changeEstatusPermisionarioService';

export default function TableElementHistorialPermisionario({ element, permisosUsuario }) {

    let { id_permisionario, nombre, urlImageProfile, fecha_asignacion, fecha_baja_asignacion, hora_baja_asignacion, status } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(status);

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitar al permisionario?' : 'Quieres deshabilitar al permisionario?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_permisionario', id_permisionario)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                console.log({objectValues})
                changeEstatusPermisionarioService({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo)
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_permisionario}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {/* {
                                permisosUsuario[0].editar == 1 &&
                                (status == 1 ?
                                <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatus(false)}>Deshabilitar</a>
                                :
                                <a className="dropdown-item bg-success text-white" onClick={() => onClickEstatus(true)}>Habilitar</a>)
                            } */}
                        </div>
                    </div>
                    {
                        (urlImageProfile) &&
                        (<img src={urlImageProfile} className="pedidos__imgTabla img-fluid rounded-circle " alt='Foto de perfil'/>)
                    }
                    {/* <img src={urlImageProfile} className="pedidos__imgTabla img-fluid rounded-circle "/> */}

                    <div className="table__nameDescription">
                        <p>{nombre}</p>
                    </div>
                </div>
            </th>

            <td>
                <div className="table__fechaAlta">
                    <p>{fecha_asignacion}</p>
                    <small className='color-rojo font-weight-bold'>
                        { fecha_baja_asignacion !== null && `Baja: ${fecha_baja_asignacion} ${hora_baja_asignacion !== undefined ? '- '+hora_baja_asignacion : '' }` }
                        
                    </small>
                </div>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ status == 1 ? '' : 'table__estatus-inactive' }>
                        { status == 1 ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}