import React from 'react';
import Icon from '../../components/Icon';
import $ from 'jquery';
import "./FormularioEconomico.css"
import getDerroterosActivosService from './../../services/getDerroterosActivosService';
import getPrefijosEconomicos from '../../services/catalogos/prefijos_economico/getPrefijosEconomicos';
import Swal from 'sweetalert2';

export default function FormularioEconomico({ economico, permisosUsuario, onSubmit }){
    
    const [derroteros, setDerroteros] = React.useState([]);
    const [prefijos, setPrefijos] = React.useState([]);

    function setDataFormulario(){
        document.getElementById('numero').value = economico.numero;
        document.getElementById('id_prefijo_economico').value = economico.id_prefijo_economico;

        $('.selectpicker').selectpicker('refresh')

        if(permisosUsuario[0].editar === "0"){
            $("form :input, :input[form]").prop("readonly", true);
        }
    }

    function getDerroteros(){
        getDerroterosActivosService()
            .then(response => {
                let _respuesta = JSON.parse(response);
                // Agregamos el derrotero nativo del econímico
                let derroteros = [{
                    id_derrotero: economico.derrotero.id_derrotero,
                    nombre: economico.derrotero.nombre,
                }];
                if(_respuesta.response == 'success'){
                    // Se verifica sí ya existe el derrotero actual en el arreglo
                    const exist = _respuesta.derroteros.some(derrotero => derrotero.id_derrotero === economico.derrotero.id_derrotero)
                    // Sí no existe, agregarlo, sí ya existe simplemente ignoramos
                    if(!exist) _respuesta.derroteros.push(derroteros[0])
                    // Copiamos los derroteros a la variable
                    derroteros = [..._respuesta.derroteros]
                }
                setDerroteros(derroteros);
                setDataFormulario();
                $('.selectpicker').selectpicker('refresh');
            })
            .catch((error) => {
                console.log(error)
                console.log("ha petado getDerroteros()")
            })
    }

    React.useEffect(() => {
        getPrefijosService();
    }, []);

    function getPrefijosService(){
        getPrefijosEconomicos()
            .then(response => {
                let _respuesta = JSON.parse(response);
                if(_respuesta.response === 'success'){
                    setPrefijos(_respuesta.data);
                    $('.selectpicker').selectpicker('refresh');
                }
                setDataFormulario()
            })
            .catch((error) => {
                console.log(error)
                console.log("ha petado getPrefijosService()")
                Swal.fire(
                    'Error del servidor',
                    'Inténtalo de nuevo en unos momentos.',
                    'error'
                )
            })
    }

    return(
        <form className="formulario" onSubmit={ onSubmit } id="formularioEconomico">

            {/* ****************************** */}
            {/* BLOQUE DE LOS DATOS PERSONALES */}
            {/* ****************************** */}

            <div className="economico__formulario row">
                <div className="col-6">
                    <label htmlFor="numero">
                        Número de económico:
                    </label>
                    <input 
                        type="text" 
                        className="input"
                        id="numero"
                        name="numero"
                        required
                    />
                </div>
                <div className="col-6">

                    <label htmlFor="id_prefijo_economico">
                        Clave del económico:
                    </label>
                    <select 
                        className="input selectpicker"
                        id="id_prefijo_economico"
                        name="id_prefijo_economico"
                        data-live-search="true"
                        required
                    >
                        {
                            prefijos &&
                            prefijos.map( (prefijo) => (
                                <option key={ prefijo.id_prefijo_economico } value={ prefijo.id_prefijo_economico }>
                                    { `${prefijo.clave} - ${prefijo.modalidad}` }
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div>

            {/* ******************************* */}
            {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
            {/* ******************************* */}

            {
            (permisosUsuario[0].editar !== "0") &&
            <React.Fragment>
                <hr></hr>
                <div className="d-flex justify-content-end">
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus" />
                        <Icon icon="fa-solid fa-hashtag mr-3" />
                        Actualizar información del económico
                    </button>
                </div>
            </React.Fragment>
            }

            


        </form>
    );
}