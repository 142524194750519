import React from 'react';
import "./FormularioFacturasVehiculo.css"
import Icon from '../../components/Icon';
import $ from 'jquery';
import changeFacturaVehiculoService from './../../services/changeFacturaVehiculoService';
import Swal from 'sweetalert2'

export default function FormularioFacturasVehiculo({ factura, permisosUsuario, rutaServidor }){
    
    let{
        descripcion,
        es_origen,
        fecha_alta,
        fecha_emision,
        id_factura,
        id_vehiculo,
        url_factura,
    } = factura;

    function setDataFormulario(){

        if(permisosUsuario[0].editar === "0"){
            $("form :input, :input[form]").prop("readonly", true);
        }
    }

    function onSubmitVehiculo(e){
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_factura', id_factura)
        // let objectValues = Object.fromEntries(values);
        // console.log({objectValues})
        changeFacturaVehiculoService({values:values})
            .then(response => {
                console.log(response);
                let _response = JSON.parse(response);
                // console.log(_response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: '¡Listo!',
                        text: 'Se ha actualizado la factura',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No haz actualizado nada',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => {})
    }

    function onChangeFileInput(e, nombreInput){
        const file = e.target.files[0];
        document.getElementById(nombreInput+'Preview').src = URL.createObjectURL(file);
        $('#'+nombreInput+'Preview').removeClass("hidden")
    }

    React.useEffect(() => {
        setDataFormulario();
    }, []);

    return(

        <form className="formulario" onSubmit={ onSubmitVehiculo }>

            <div className="perfil__fotoPerfil justify-content-center flex-column">
                <input type="file" name="facturaUnidad" id="facturaUnidad" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "facturaUnidad") } />
                <div>
                    <label htmlFor="facturaUnidad" className="perfil__cambiarFoto text-center">
                        <h4>
                            Factura origen de la unidad
                        </h4>
                        <p>
                            Da clic aquí para cargar un nuevo pdf de la factura origen
                        </p>
                    </label>
                </div>
                <embed 
                    id="facturaUnidadPreview" 
                    type="application/pdf" 
                    src={`${rutaServidor}${url_factura}`}
                    className={`previewPDF ${url_factura ? "" : "hidden"}`}
                />
            </div>

            {/* **************************************** */}
            {/* BLOQUE DE LOS DATOS DE LA FACTURA ORIGEN */}
            {/* **************************************** */}

            <div className="datosFacturaOrigen__formulario row">
                <div className="col-4">
                    <label htmlFor="fechaEmisionFactura">
                        Fecha emisión factura:
                    </label>
                    <input 
                        type="date" 
                        className="input"
                        id="fechaEmisionFactura"
                        name="fechaEmisionFactura"
                        defaultValue={ fecha_emision }
                        required
                    />
                </div>
                <div className="col-8">
                    <label htmlFor="anotacionesFactura">
                        Anotaciones factura:
                    </label>
                    <input 
                        type="text" 
                        className="input" 
                        id="anotacionesFactura"
                        name="anotacionesFactura"
                        defaultValue={ descripcion }
                    />
                </div>
            </div>

            {/* ******************************* */}
            {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
            {/* ******************************* */}

            {
            (permisosUsuario[0].editar !== "0") &&
            <React.Fragment>
                <hr></hr>
                <div className="d-flex justify-content-end">
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus" />
                        <Icon icon="fa-solid fa-file mr-3" />
                        Actualizar factura origen de la unidad
                    </button>
                </div>
            </React.Fragment>
            }


        </form>
    );
}