import React from "react";
import AddButton from "../../components/AddButton";
import AlertWarningSearch from "../../components/AlertWarningSearch";
import LoadingSearchIcon from "../../components/LoadingSearchIcon";
import TableBody from "../../components/TableCatalogo/TableBody";
import TableHead from "../../components/TableCatalogo/TableHead";
import TableCatalogo from "../../components/TableCatalogo";
import getUMAs from "../../services/catalogos/uma/getUMAs";
import addUMA from "../../services/catalogos/uma/addUMA";
import $ from 'jquery';
import Swal from "sweetalert2";
import ModalAddUMA from "../../components/Catalogos/ModalAddUMA";

export default function UMA(props){
    const [elements, setElements] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    const rows = [
        { id: 1, name: "Año" },
        { id: 2, name: "Valor por UMA" },
        { id: 3, name: "Estatus" }
    ];

    function getUMAsService(){
        setLoading(true)
        getUMAs().then(res => {
            let _res = JSON.parse(res);
            console.log(_res)
            if(!(_res.response === 'error')){
                setElements(_res.data);
            } else {
                setElements([])
            }
            setLoading(false);
        })
    }

    const onSubmit = (e)=>{
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        addUMA({values:objectValues}).then(res=>{
            console.log({res})
            let _res = JSON.parse(res)
            if(_res.response === 'success'){
                Swal.fire({
                    title:'Agregado correctamente',
                    timer:1200,
                    showConfirmButton:false,
                    icon:'success'
                })
                $("#modalAddUMA").modal("hide")
                $("#formAddUMA")[0].reset();
                getUMAsService()
            }
        })
    }

    React.useEffect(getUMAsService, []);

    return(
        <section>

            <ModalAddUMA
                onSubmit={onSubmit}
            />

            <div className="d-flex justify-content-between">

                <h1>Valores de UMA por cada año</h1>

                {
                    props.permisos[0].escribir === '1' &&
                        <AddButton text="Nuevo valor de UMA" onClick={ async () => {
                            $('#modalAddUMA').modal('show')
                        } } />
                }

            </div>

            <p className="text-muted my-3">
                Este valor se tomará como base para los cálculos de gastos en la prorrata. Es decir, sí el monto mínimo en UMAs es de 50, se multiplicará por el valor correspondiente del año en curso registrado aquí.
            </p>

            <hr className="my-4" />
            
            <LoadingSearchIcon show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <TableCatalogo>
                        <TableHead rows={rows} />

                        <TableBody 
                            elements={elements} 
                            typeElement="umas" 
                            permisosUsuario={props.permisos} 
                            getUMAsService={getUMAsService}  
                        />
                    </TableCatalogo>
            }

            {
                ( Object.keys(elements).length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }


        </section>
    )
}