import React from 'react';
import Icon from "./../../components/Icon";
import Swal from 'sweetalert2'
import $ from 'jquery';
import approveGastoContralorSiniestro from '../../services/gasto_contralor/approveGastoContralorSiniestro';

export default function TableElementGastoContralorSiniestro({ element, permisosUsuario, updateElements, saldoTotalSiniestros }) {

    const { 
        id_gasto_contralor, 
        id_gasto_siniestro, 
        motivo_rechazo,
        aprobado, 
        fecha_revisado, 
        fecha_alta,
        nombre_permisionario_siniestro,
        nombre_operador_siniestro,
        id_siniestro,
        nombre_metodo_pago,
        nombre_tipo_gasto_siniestro,
        nombre_solicitante_recurso,
        cantidad
    } = element;
    
    React.useEffect(()=>{
        $('[data-toggle="tooltip"]').tooltip()
    },[])

    const onClickAprobarGasto = async (aprobado)=>{
        if(aprobado){
            // VALIDAR PRIMERO SI EL SALDO DE SINIESTROS DISPONIBLE ES SUFICIENTE
            // PARA APROBAR EL GASTO
            if(parseFloat(saldoTotalSiniestros) >= parseFloat(cantidad)){
                Swal.fire({
                    title: 'Seguro que deseas aprobarlo?',
                    icon: 'question',
                    confirmButtonText: 'Si, seguro',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true
                }).then(value =>{
                    if(value.isConfirmed){
                        approveGastoContralor('1', null)
                    }
                })
            } else {
                Swal.fire({
                    title: 'No puedes aprobar el gasto',
                    text: "El gasto excede el saldo total de siniestros",
                    icon: 'warning',
                    confirmButtonText: 'Entendido',
                })
            }
        } else {
            let motivo_rechazo;
            const { value: motivo } = await Swal.fire({
                title: 'Por qué lo rechazas?',
                input: 'text',
                inputPlaceholder: 'Escríbelo aquí',
                confirmButtonText: 'Rechazar'
            })
            
            if (motivo) {
                motivo_rechazo = motivo;
                approveGastoContralor('0', motivo_rechazo)
            }
        }
    }

    const approveGastoContralor = (aprobado, motivo_rechazo) =>{
        let data = {
            aprobado: aprobado,
            id_gasto_contralor: id_gasto_contralor,
            motivo_rechazo: motivo_rechazo
        }

        approveGastoContralorSiniestro({values:data})
            .then(res =>{
                console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    let title = (aprobado === '1') ? 'Gasto aprobado' : 'Gasto rechazado'
                    Swal.fire({
                        title: title,
                        icon: 'success',
                        // text: '',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    updateElements()
                }
            })
            .catch(err =>{
                console.error(err)
                Swal.fire({
                    text: 'Ocurrió un error, repórtalo con el desarrollador.',
                    icon: 'error',
                    showConfirmButton: false,
                    timer:1500
                })
            })
    }

    return (
        <tr key={id_gasto_contralor}>
            <th>
                <div className="table__name">

                    <div className="table__nameDescription">
                        <p>{ `${nombre_tipo_gasto_siniestro}` }</p>
                        <small>
                            Método de pago: { `${nombre_metodo_pago}` }
                        </small>
                        <br/>
                        <small>
                            Se solicitó el: { `${fecha_alta}` }
                            <br/>
                            por: { `${nombre_solicitante_recurso}` }
                        </small>
                    </div>
                </div>
            </th>

            <td>
                <div className="table__derrotero">
                    <p>{`ID siniestro: ${id_siniestro}`}</p>
                    <small>
                        Permisionario: { `${nombre_permisionario_siniestro}` }
                    </small>
                    <br/>
                    <small>
                        Operador: { `${nombre_operador_siniestro}` }
                    </small>
                </div>
            </td>

            <td>
                <div className="table__derrotero">
                    <p>{`${parseFloat(cantidad).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}</p>
                </div>
            </td>

            <td>

                {
                (aprobado === null) && 
                <>
                    <button 
                        className='btn btn-success btn-sm mr-2' 
                        data-toggle="tooltip" 
                        data-placement="top" 
                        title="Aprobar"
                        onClick={() => onClickAprobarGasto(true)}
                    >
                        <Icon icon={'fa-solid fa-check'} />
                    </button>
                    <button 
                        className='btn btn-danger btn-sm' 
                        data-toggle="tooltip" 
                        data-placement="top" 
                        title="Rechazar"
                        onClick={() => onClickAprobarGasto(false)}
                    >
                        <Icon icon={'fa-solid fa-x'} />
                    </button>
                </>
                }

                {
                    aprobado !== null &&
                    <>
                        <div 
                            className="table__estatus" 
                            data-toggle={(aprobado === '0') ? 'tooltip' : ''}
                            data-placement="top" 
                            title={`Motivo: ${motivo_rechazo}`} 
                        >
                            <span className={ aprobado === '1' ? '' : 'table__estatus-inactive' }>
                                { aprobado === '1' ? 'Aprobado' : 'Rechazado' }
                            </span>
                        </div>
                        <small className='ml-2 text-muted'>
                                {fecha_revisado}
                        </small>
                    </>
                }
            </td>
        </tr>
    )
    
}