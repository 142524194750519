import "./ModalAsignacion.css";
import React from "react";
import $, { data } from "jquery";

export default function ModalAsignacion({ titulo, labelSelect, dataSelect, dataFiltered, idSelect, idModal, onSubmit }){

    const [data, setData] = React.useState(dataSelect)

    React.useEffect(() => {
        $(".selectpicker").selectpicker();
        // if(dataFiltered !== undefined && dataFiltered.length > 0){
        //     // console.log(dataFiltered[0].activo, idSelect)
        //     console.log({dataSelect})
        //     for(let i = 0; i < dataFiltered.length; i++){
        //         if(dataFiltered[i].status == 1){
        //             dataSelect.splice(dataSelect.findIndex(function(j){
        //                 return j[idSelect] === dataFiltered[i][idSelect];
        //             }), 1);
        //         }
        //     }
        //     console.log({titulo, dataSelect})
        // }
        setData(dataSelect)

        $(".selectpicker").selectpicker('refresh');
    } , [dataSelect]);

    return(
        <div className="modal fade" id={`${idModal}`} tabIndex="-1" role="dialog" aria-labelledby={`${idModal}Label`} aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form className="modal-content" onSubmit={onSubmit}>
                    <div className="modal-header">
                        <h5 className="modal-title" id={`${idModal}Label`}>{titulo}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <label htmlFor={idSelect}>
                            {labelSelect}
                        </label>
                        <select 
                            className="input selectpicker" 
                            name={idSelect}
                            id={idSelect}
                            data-live-search="true"
                            required
                        >
                            {
                                dataSelect.length !== 0 &&
                                dataSelect.map((element) => {
                                    return <option key={element.id} value={element.id}>{element.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" className="btn btn-info">Asignar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}