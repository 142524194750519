import React from 'react';
import Icon from "./../../components/Icon";
import Swal from 'sweetalert2'
import changeEstatusDerroteroService from './../../services/changeEstatusDerroteroService';
import { Link } from "react-router-dom";

export default function TableElementDerrotero({ element, permisosUsuario }) {

    const { id_derrotero, clave, nombre, capacidad_vehicular, fecha_alta, hora_alta, activo } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(activo);

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitar el derrotero?' : 'Quieres deshabilitar el derrotero?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_derrotero', id_derrotero)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                changeEstatusDerroteroService({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo)
                        Swal.fire({
                            title:"Estatus actualizado",
                            icon:"success",
                            timer:1200,
                            showConfirmButton:false
                        })
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_derrotero}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={`/derrotero/${id_derrotero}`} className="dropdown-item" >Ver</Link>

                            {
                                permisosUsuario[0].editar == 1 &&
                                (estadoEstatus == 1 ?
                                <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatus(false)}>Deshabilitar</a>
                                :
                                <a className="dropdown-item bg-success text-white" onClick={() => onClickEstatus(true)}>Habilitar</a>)
                            }
                        </div>
                    </div>

                    <div className="table__nameDescription">
                        <p>{ 
                            clave
                        }</p>
                        <small>
                            { nombre }
                        </small>
                    </div>
                </div>
            </th>

            <td>
                <div className="table__fechaAlta">
                    <p>{capacidad_vehicular}</p>
                </div>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ estadoEstatus == 1 ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus == 1 ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}