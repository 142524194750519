import React from "react";
import Icon from "./../../components/Icon";
import { useHistory } from "react-router-dom";
import './Checadores.css';
import Table from "./../../components/Table";
import TableHead from "./../../components/Table/TableHead";
import TableBody from "./../../components/Table/TableBody";
import PDFGenerator from "../../components/PDFGenerator";
import $ from 'jquery';
import Swal from "sweetalert2";
import InputSearch from "./../../components/InputSearch";
import AddButton from "./../../components/AddButton";
import LoadingSearchIcon from "../../components/LoadingSearchIcon";
import AlertWarningSearch from "../../components/AlertWarningSearch";
import CardPermisionarioTerminal from "../../components/CardPermisionarioTerminal";
import searchConcesionesService from "../../services/searchConcesionesService";
import FilterButton from "../../components/FilterButton";
import ButtonAgregarHorario from "../../components/ButtonAgregarHorario";
import getChecadoresService from "../../services/getChecadoresService";
import getTerminalesService from "../../services/getTerminalesService";
import getHorarioTerminalService from "../../services/getHorarioTerminalService";
import addTerminalHorarioService from "../../services/addTerminalHorarioService";
import existTurnoOcupadoTerminalService from "../../services/terminal/existTurnoOcupadoTerminalService";
import deleteTerminalHorarioService from "../../services/terminal/deleteTerminalHorarioService";

export default function Checadores({permisosUsuario}) {

    // History de la navegación
    let history = useHistory();

    const [terminales, setTerminales] = React.useState([])
    const [checadores, setChecadores] = React.useState([])
    const [horarios, setHorarios] = React.useState([])
    const [tipoSeleccionado, setTipoSeleccionado] = React.useState("")
    const [nombreSeleccionado, setNombreSeleccionado] = React.useState("")
    const [esTerminal, setEsTerminal] = React.useState(null)
    const [valorSeleccionado, setValorSeleccionado] = React.useState(null)
    const [diaState, setDiaState] = React.useState(null)
    const [turnoState, setTurnoState] = React.useState(null)

    const [loading, setLoading] = React.useState(false);

    const onSubmitAgregarHorario = (e)=>{
        e.preventDefault()
        let values = new FormData(e.target)
        values.append("dia", diaState)
        values.append("turno", turnoState)
        values.append("id_terminal", (esTerminal) ? valorSeleccionado : null)
        values.append("id_trabajador", (!esTerminal) ? valorSeleccionado : null)
        let objectValues = Object.fromEntries(values);
        existTurnoOcupadoTerminalService({values:objectValues}).then(res=>{
            console.log(res)
            let _res= JSON.parse(res)
            if(_res.response === 'exist'){
                let message = (esTerminal) ? 'El checador ya esta asignado en otra terminal el mismo turno y dia': 'Ya existe otro checador en el mismo turno y dia'
                Swal.fire({
                    title:message,
                    icon: 'info',
                    showCancelButton:true,
                    confirmButtonText:'Reemplazar'
                }).then((res)=>{
                    if(res.isConfirmed){
                        addTerminalHorario(objectValues)
                    }
                })
            }

            if(_res.response === 'success'){
                addTerminalHorario(objectValues)
            }
        })

    }

    const handleDeleteHorario = (dia, turno, id_terminal, id_trabajador)=>{
        let values = new FormData()
        values.append("dia", dia)
        values.append("turno", turno)
        values.append("id_terminal", (esTerminal) ? valorSeleccionado : id_terminal)
        values.append("id_trabajador", (!esTerminal) ? valorSeleccionado : id_trabajador)
        let objectValues = Object.fromEntries(values);
        deleteTerminalHorarioService({values:objectValues})
        .then(respuesta=>{
            console.log(respuesta)
            let _respuesta = JSON.parse(respuesta)
            if(_respuesta.response === 'success'){
                if(esTerminal){
                    handleTerminal(valorSeleccionado)
                } else {
                    handleChecador(valorSeleccionado)
                }
            }
        })

    }

    const addTerminalHorario = (objectValues)=>{
        addTerminalHorarioService({values:objectValues})
        .then(respuesta=>{
            console.log(respuesta)
            let _respuesta = JSON.parse(respuesta)
            if(_respuesta.response === 'success'){
                $("#modalAgregarHorario").modal("hide");
                if(esTerminal){
                    handleTerminal(valorSeleccionado)
                } else {
                    console.log({valorSeleccionado})
                    handleChecador(valorSeleccionado)
                }
            }
        })
    }

    const handleTerminal = (value)=>{

        if(value === "0"){
            setHorarios([])
            return; 
        }

        document.getElementById("checador").selectedIndex = 0;
        $('.selectpicker').selectpicker('refresh')
        let values = {
            id_terminal: value
        }
        setLoading(true)
        setTipoSeleccionado("terminal")
        setEsTerminal(true)
        setValorSeleccionado(value)
        let terminal = document.getElementById('terminal')
        let text_valor_seleccionado = terminal.options[terminal.selectedIndex].text
        setNombreSeleccionado(text_valor_seleccionado)
        getHorarioTerminalService({values:values})
            .then(respuesta =>{
                console.log(respuesta)
                let _respuesta = JSON.parse(respuesta)
                setHorarios(_respuesta.data)
                setLoading(false)
            })
    }
    
    const handleChecador = (value)=>{
        console.log({value})
        if(value === "0"){
            setHorarios([])
            return; 
        }
        document.getElementById("terminal").selectedIndex = 0;
        $('.selectpicker').selectpicker('refresh')
        let values = {
            id_trabajador: value
        }
        console.log({values})
        setLoading(true)
        setTipoSeleccionado("checador")
        setEsTerminal(false)
        setValorSeleccionado(value)
        let checador = document.getElementById('checador')
        let text_valor_seleccionado = checador.options[checador.selectedIndex].text
        setNombreSeleccionado(text_valor_seleccionado)
        getHorarioTerminalService({values:values})
            .then(respuesta =>{
                console.log(respuesta)
                let _respuesta = JSON.parse(respuesta)
                $("#checador").val("")
                setHorarios(_respuesta.data)
                setLoading(false)
            })
    }

    const getChecadores = ()=>{
        getChecadoresService()
        .then(respuesta=>{
            console.log(respuesta)
            let _respuesta = JSON.parse(respuesta)
            if(_respuesta.response === "success"){
                setChecadores(_respuesta.data)
                $('.selectpicker').selectpicker('refresh')
            }
        })
    }

    const getTerminales = ()=>{
        getTerminalesService()
        .then(respuesta=>{
            console.log(respuesta)
            let _respuesta = JSON.parse(respuesta)
            if(_respuesta.response === "success"){
                setTerminales(_respuesta.data)
                $('.selectpicker').selectpicker('refresh')
            }
        })
    }

    React.useEffect(() => {
        $('.selectpicker').selectpicker('')
        getChecadores()
        getTerminales()
    },[]);

    return (
        <section>

            <div className="d-flex justify-content-between">

                <h1 className="">Checadores</h1>

                <div className="d-flex align-items-center">
                    <AddButton text="Agregar terminal" onClick={ async () => {
                        history.push("/terminales/agregar")
                    } } />
                    <AddButton text="Ver terminales" onClick={ async () => {
                        history.push("/terminales")
                    } } />
                    
                    {/* <AddButton text="Agregar checador" onClick={ async () => {
                        history.push("/trabajador/agregar")
                    } } /> */}
                </div>

            </div>

            <p>Para continuar, selecciona una terminal o un checador:</p>

            <hr className="my-4"/>

            <div className="d-flex justify-space-evenly">
                <div className="mr-5">
                    <label htmlFor="terminal">
                        Terminal:
                    </label>
                    <select 
                        className="input selectpicker"
                        id="terminal"
                        name="terminal"
                        value={ (esTerminal) && valorSeleccionado }
                        onChange={ (e) => handleTerminal(e.target.value) }
                        data-live-search="true"
                        required
                    >
                        <option value="0">
                            Elige una terminal
                        </option>
                        {
                            terminales &&
                            terminales.map( (terminal) => (
                                <option key={ terminal.id_terminal } value={ terminal.id_terminal }>
                                    { terminal.nombre }
                                </option>
                            ))
                        }
                    </select>
                </div>

                <div>
                    <label htmlFor="checador">
                        Checador:
                    </label>
                    <select 
                        className="input selectpicker"
                        id="checador"
                        name="checador"
                        value={ (!esTerminal) && valorSeleccionado }
                        onChange={ (e) => handleChecador(e.target.value) }
                        data-live-search="true"
                        required
                    >
                        <option value="0">
                            Elige un checador
                        </option>
                        {
                            checadores &&
                            checadores.map( (checador) => (
                                <option key={ checador.id_trabajador } value={ checador.id_trabajador }>
                                    { checador.nombre }&nbsp;
                                    { checador.apellido_paterno }&nbsp;
                                    { checador.apellido_materno }&nbsp;
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div>

            <LoadingSearchIcon show={loading} />

            {
                ( horarios.length !== 0 && !loading ) &&

                <>
                    <h3 className="my-3">
                        {
                            tipoSeleccionado
                        }
                        :&nbsp;
                        {
                            nombreSeleccionado
                        }
                    </h3>
                    <div id="tablaChecador" className="mt-3 shadow">

                        {/* Columna de los dias */}
                        <div className="columnaDiasChecador">
                            <div className="elementoChecador">
                                <span className='font-weight-bold'>
                                    Días/Turno
                                </span>
                            </div>
                            <div className="elementoChecador">
                                <p>
                                    Lunes
                                </p>
                            </div>
                            <div className="elementoChecador">
                                <p>
                                    Martes
                                </p>
                            </div>
                            <div className="elementoChecador">
                                <p>
                                    
                                    Miércoles
                                </p>
                            </div>
                            <div className="elementoChecador">
                                <p>
                                    Jueves
                                </p>
                            </div>
                            <div className="elementoChecador">
                                <p>
                                    Viernes
                                </p>
                            </div>
                            <div className="elementoChecador">
                                <p>
                                    Sábado
                                </p>
                            </div>
                            <div className="elementoChecador">
                                <p>
                                    Domingo
                                </p>
                            </div>
                        </div>

                        {/* Columna del primer turno */}
                        <div className="columnaPrimerTurnoChecador">
                            <div className="elementoChecador bg-color-gris">
                                <span className='font-weight-bold'>
                                    Primer turno
                                </span>
                            </div>

                            <div className="elementoChecador">
                                {
                                    (horarios.horario.lunes.primer_turno !== null && horarios.horario.lunes.primer_turno !== undefined) && 
                                        <CardPermisionarioTerminal 
                                            nombre={horarios.horario.lunes.primer_turno.nombre}
                                            foto_perfil={horarios.horario.lunes.primer_turno.foto_perfil}
                                            onClickDelete={
                                                ()=>handleDeleteHorario(
                                                    'lunes', 
                                                    1, 
                                                    horarios.horario.lunes.primer_turno.id_terminal,
                                                    horarios.horario.lunes.primer_turno.id_trabajador
                                                )}
                                        />
                                }
                                {
                                    (horarios.horario.lunes.primer_turno === null || horarios.horario.lunes.primer_turno === undefined) &&

                                    <ButtonAgregarHorario
                                        terminales={ (tipoSeleccionado) !== "terminal" &&  terminales}
                                        checadores={ (tipoSeleccionado) !== "checador" &&  checadores}
                                        esTerminal={esTerminal}
                                        onClick={
                                            () => {
                                                setDiaState('lunes')
                                                setTurnoState(1)
                                            }
                                        }
                                        onSubmit={onSubmitAgregarHorario}
                                    />
                                }
                            </div>
                            <div className="elementoChecador">
                                {
                                    (horarios.horario.martes.primer_turno !== null && horarios.horario.martes.primer_turno !== undefined) &&
                                        <CardPermisionarioTerminal 
                                            nombre={horarios.horario.martes.primer_turno.nombre}
                                            onClickDelete={
                                                ()=>handleDeleteHorario(
                                                    'martes', 
                                                    1, 
                                                    horarios.horario.martes.primer_turno.id_terminal,
                                                    horarios.horario.martes.primer_turno.id_trabajador
                                            )}
                                            foto_perfil={horarios.horario.martes.primer_turno.foto_perfil}
                                        />
                                }
                                {
                                    (horarios.horario.martes.primer_turno === null || horarios.horario.martes.primer_turno === undefined) &&
                                    <ButtonAgregarHorario
                                        terminales={ (tipoSeleccionado) !== "terminal" &&  terminales}
                                        checadores={ (tipoSeleccionado) !== "checador" &&  checadores}
                                        esTerminal={esTerminal}
                                        onClick={
                                            () => {
                                                setDiaState('martes')
                                                setTurnoState(1)
                                            }
                                        }
                                        onSubmit={onSubmitAgregarHorario}
                                    />
                                }
                            </div>
                            <div className="elementoChecador">
                                {
                                    (horarios.horario.miercoles.primer_turno !== null && horarios.horario.miercoles.primer_turno !== undefined) &&
                                        <CardPermisionarioTerminal 
                                            nombre={horarios.horario.miercoles.primer_turno.nombre}
                                            onClickDelete={
                                                ()=>handleDeleteHorario(
                                                    'miercoles', 
                                                    1, 
                                                    horarios.horario.miercoles.primer_turno.id_terminal,
                                                    horarios.horario.miercoles.primer_turno.id_trabajador
                                            )}
                                            foto_perfil={horarios.horario.miercoles.primer_turno.foto_perfil}
                                        />
                                }
                                {
                                    (horarios.horario.miercoles.primer_turno === null || horarios.horario.miercoles.primer_turno === undefined) &&
                                    <ButtonAgregarHorario
                                        terminales={ (tipoSeleccionado) !== "terminal" &&  terminales}
                                        checadores={ (tipoSeleccionado) !== "checador" &&  checadores}
                                        esTerminal={esTerminal}
                                        onClick={
                                            () => {
                                                setDiaState('miercoles')
                                                setTurnoState(1)
                                            }
                                        }
                                        onSubmit={onSubmitAgregarHorario}
                                    />
                                }
                            </div>
                            <div className="elementoChecador">
                                {
                                    (horarios.horario.jueves.primer_turno !== null && horarios.horario.jueves.primer_turno !== undefined) &&
                                        <CardPermisionarioTerminal 
                                            nombre={horarios.horario.jueves.primer_turno.nombre}
                                            onClickDelete={
                                                ()=>handleDeleteHorario(
                                                    'jueves', 
                                                    1, 
                                                    horarios.horario.jueves.primer_turno.id_terminal,
                                                    horarios.horario.jueves.primer_turno.id_trabajador
                                            )}
                                            foto_perfil={horarios.horario.jueves.primer_turno.foto_perfil}
                                        />
                                }
                                {
                                    (horarios.horario.jueves.primer_turno === null || horarios.horario.jueves.primer_turno === undefined) &&
                                    <ButtonAgregarHorario
                                        terminales={ (tipoSeleccionado) !== "terminal" &&  terminales}
                                        checadores={ (tipoSeleccionado) !== "checador" &&  checadores}
                                        esTerminal={esTerminal}
                                        onClick={
                                            () => {
                                                setDiaState('jueves')
                                                setTurnoState(1)
                                            }
                                        }
                                        onSubmit={onSubmitAgregarHorario}
                                    />
                                }
                            </div>
                            <div className="elementoChecador">
                                {
                                    (horarios.horario.viernes.primer_turno !== null && horarios.horario.viernes.primer_turno !== undefined) &&
                                        <CardPermisionarioTerminal 
                                            nombre={horarios.horario.viernes.primer_turno.nombre}
                                            onClickDelete={
                                                ()=>handleDeleteHorario(
                                                    'viernes', 
                                                    1, 
                                                    horarios.horario.viernes.primer_turno.id_terminal,
                                                    horarios.horario.viernes.primer_turno.id_trabajador
                                            )}
                                            foto_perfil={horarios.horario.viernes.primer_turno.foto_perfil}
                                        />
                                }
                                {
                                    (horarios.horario.viernes.primer_turno === null || horarios.horario.viernes.primer_turno === undefined) &&
                                    <ButtonAgregarHorario
                                        terminales={ (tipoSeleccionado) !== "terminal" &&  terminales}
                                        checadores={ (tipoSeleccionado) !== "checador" &&  checadores}
                                        esTerminal={esTerminal}
                                        onClick={
                                            () => {
                                                setDiaState('viernes')
                                                setTurnoState(1)
                                            }
                                        }
                                        onSubmit={onSubmitAgregarHorario}
                                    />
                                }
                            </div>
                            <div className="elementoChecador">
                                {
                                    (horarios.horario.sabado.primer_turno !== null && horarios.horario.sabado.primer_turno !== undefined) &&
                                        <CardPermisionarioTerminal 
                                            nombre={horarios.horario.sabado.primer_turno.nombre}
                                            onClickDelete={
                                                ()=>handleDeleteHorario(
                                                    'sabado', 
                                                    1, 
                                                    horarios.horario.sabado.primer_turno.id_terminal,
                                                    horarios.horario.sabado.primer_turno.id_trabajador
                                            )}
                                            foto_perfil={horarios.horario.sabado.primer_turno.foto_perfil}
                                        />
                                }
                                {
                                    (horarios.horario.sabado.primer_turno === null || horarios.horario.sabado.primer_turno === undefined) &&
                                    <ButtonAgregarHorario
                                        terminales={ (tipoSeleccionado) !== "terminal" &&  terminales}
                                        checadores={ (tipoSeleccionado) !== "checador" &&  checadores}
                                        esTerminal={esTerminal}
                                        onClick={
                                            () => {
                                                setDiaState('sabado')
                                                setTurnoState(1)
                                            }
                                        }
                                        onSubmit={onSubmitAgregarHorario}
                                    />
                                }
                            </div>
                            <div className="elementoChecador">
                                {
                                    (horarios.horario.domingo.primer_turno !== null && horarios.horario.domingo.primer_turno !== undefined) &&
                                        <CardPermisionarioTerminal 
                                            nombre={horarios.horario.domingo.primer_turno.nombre}
                                            onClickDelete={
                                                ()=>handleDeleteHorario(
                                                    'domingo', 
                                                    1, 
                                                    horarios.horario.domingo.primer_turno.id_terminal,
                                                    horarios.horario.domingo.primer_turno.id_trabajador
                                            )}
                                            foto_perfil={horarios.horario.domingo.primer_turno.foto_perfil}
                                        />
                                }
                                {
                                    (horarios.horario.domingo.primer_turno === null || horarios.horario.domingo.primer_turno === undefined) &&
                                    <ButtonAgregarHorario
                                        terminales={ (tipoSeleccionado) !== "terminal" &&  terminales}
                                        checadores={ (tipoSeleccionado) !== "checador" &&  checadores}
                                        esTerminal={esTerminal}
                                        onClick={
                                            () => {
                                                setDiaState('domingo')
                                                setTurnoState(1)
                                            }
                                        }
                                        onSubmit={onSubmitAgregarHorario}
                                    />
                                }
                            </div>
                        </div>

                        {/* Columna del segundo turno */}
                        <div className="columnaSegundoTurnoChecador">
                            <div className="elementoChecador bg-color-gris">
                                <span className='font-weight-bold'>
                                    Segundo turno
                                </span>
                            </div>

                            <div className="elementoChecador">
                                {
                                    (horarios.horario.lunes.segundo_turno !== null && horarios.horario.lunes.segundo_turno !== undefined) && 
                                        <CardPermisionarioTerminal 
                                            nombre={horarios.horario.lunes.segundo_turno.nombre}
                                            onClickDelete={
                                                ()=>handleDeleteHorario(
                                                    'lunes', 
                                                    2, 
                                                    horarios.horario.lunes.segundo_turno.id_terminal,
                                                    horarios.horario.lunes.segundo_turno.id_trabajador
                                            )}
                                            foto_perfil={horarios.horario.lunes.segundo_turno.foto_perfil}
                                        />
                                }
                                {
                                    (horarios.horario.lunes.segundo_turno === null || horarios.horario.lunes.segundo_turno === undefined) &&
                                    <ButtonAgregarHorario
                                        terminales={ (tipoSeleccionado) !== "terminal" &&  terminales}
                                        checadores={ (tipoSeleccionado) !== "checador" &&  checadores}
                                        esTerminal={esTerminal}
                                        onClick={
                                            () => {
                                                setDiaState('lunes')
                                                setTurnoState(2)
                                            }
                                        }
                                        onSubmit={onSubmitAgregarHorario}
                                    />
                                }
                            </div>
                            <div className="elementoChecador">
                            {
                                    (horarios.horario.martes.segundo_turno !== null && horarios.horario.martes.segundo_turno !== undefined) && 
                                        <CardPermisionarioTerminal 
                                            nombre={horarios.horario.martes.segundo_turno.nombre}
                                            onClickDelete={
                                                ()=>handleDeleteHorario(
                                                    'martes', 
                                                    2, 
                                                    horarios.horario.martes.segundo_turno.id_terminal,
                                                    horarios.horario.martes.segundo_turno.id_trabajador
                                            )}
                                            foto_perfil={horarios.horario.martes.segundo_turno.foto_perfil}
                                        />
                                }
                                {
                                    (horarios.horario.martes.segundo_turno === null || horarios.horario.martes.segundo_turno === undefined) &&
                                    <ButtonAgregarHorario
                                        terminales={ (tipoSeleccionado) !== "terminal" &&  terminales}
                                        checadores={ (tipoSeleccionado) !== "checador" &&  checadores}
                                        esTerminal={esTerminal}
                                        onClick={
                                            () => {
                                                setDiaState('martes')
                                                setTurnoState(2)
                                            }
                                        }
                                        onSubmit={onSubmitAgregarHorario}
                                    />
                                }
                            </div>
                            <div className="elementoChecador">
                                {
                                    (horarios.horario.miercoles.segundo_turno !== null && horarios.horario.miercoles.segundo_turno !== undefined) &&
                                        <CardPermisionarioTerminal 
                                            nombre={horarios.horario.miercoles.segundo_turno.nombre}
                                            onClickDelete={
                                                ()=>handleDeleteHorario(
                                                    'miercoles', 
                                                    2, 
                                                    horarios.horario.miercoles.segundo_turno.id_terminal,
                                                    horarios.horario.miercoles.segundo_turno.id_trabajador
                                            )}
                                            foto_perfil={horarios.horario.miercoles.segundo_turno.foto_perfil}
                                        />
                                }
                                {
                                    (horarios.horario.miercoles.segundo_turno === null || horarios.horario.miercoles.segundo_turno === undefined) &&
                                    <ButtonAgregarHorario
                                        terminales={ (tipoSeleccionado) !== "terminal" &&  terminales}
                                        checadores={ (tipoSeleccionado) !== "checador" &&  checadores}
                                        esTerminal={esTerminal}
                                        onClick={
                                            () => {
                                                setDiaState('miercoles')
                                                setTurnoState(2)
                                            }
                                        }
                                        onSubmit={onSubmitAgregarHorario}
                                    />
                                }
                            </div>
                            <div className="elementoChecador">
                                {
                                    (horarios.horario.jueves.segundo_turno !== null && horarios.horario.jueves.segundo_turno !== undefined) &&
                                        <CardPermisionarioTerminal 
                                            nombre={horarios.horario.jueves.segundo_turno.nombre}
                                            onClickDelete={
                                                ()=>handleDeleteHorario(
                                                    'jueves', 
                                                    2, 
                                                    horarios.horario.jueves.segundo_turno.id_terminal,
                                                    horarios.horario.jueves.segundo_turno.id_trabajador
                                            )}
                                            foto_perfil={horarios.horario.jueves.segundo_turno.foto_perfil}
                                        />
                                }
                                {
                                    (horarios.horario.jueves.segundo_turno === null || horarios.horario.jueves.segundo_turno === undefined) &&
                                    <ButtonAgregarHorario
                                        terminales={ (tipoSeleccionado) !== "terminal" &&  terminales}
                                        checadores={ (tipoSeleccionado) !== "checador" &&  checadores}
                                        esTerminal={esTerminal}
                                        onClick={
                                            () => {
                                                setDiaState('jueves')
                                                setTurnoState(2)
                                            }
                                        }
                                        onSubmit={onSubmitAgregarHorario}
                                    />
                                }
                            </div>
                            <div className="elementoChecador">
                                {
                                    (horarios.horario.viernes.segundo_turno !== null && horarios.horario.viernes.segundo_turno !== undefined) &&
                                        <CardPermisionarioTerminal 
                                            nombre={horarios.horario.viernes.segundo_turno.nombre}
                                            onClickDelete={
                                                ()=>handleDeleteHorario(
                                                    'viernes', 
                                                    2, 
                                                    horarios.horario.viernes.segundo_turno.id_terminal,
                                                    horarios.horario.viernes.segundo_turno.id_trabajador
                                            )}
                                            foto_perfil={horarios.horario.viernes.segundo_turno.foto_perfil}
                                        />
                                }
                                {
                                    (horarios.horario.viernes.segundo_turno === null || horarios.horario.viernes.segundo_turno === undefined) &&
                                    <ButtonAgregarHorario
                                        terminales={ (tipoSeleccionado) !== "terminal" &&  terminales}
                                        checadores={ (tipoSeleccionado) !== "checador" &&  checadores}
                                        esTerminal={esTerminal}
                                        onClick={
                                            () => {
                                                setDiaState('viernes')
                                                setTurnoState(2)
                                            }
                                        }
                                        onSubmit={onSubmitAgregarHorario}
                                    />
                                }
                            </div>
                            <div className="elementoChecador">
                                {
                                    (horarios.horario.sabado.segundo_turno !== null && horarios.horario.sabado.segundo_turno !== undefined) &&
                                        <CardPermisionarioTerminal 
                                            nombre={horarios.horario.sabado.segundo_turno.nombre}
                                            onClickDelete={
                                                ()=>handleDeleteHorario(
                                                    'sabado', 
                                                    2, 
                                                    horarios.horario.sabado.segundo_turno.id_terminal,
                                                    horarios.horario.sabado.segundo_turno.id_trabajador
                                            )}
                                            foto_perfil={horarios.horario.sabado.segundo_turno.foto_perfil}
                                        />
                                }
                                {
                                    (horarios.horario.sabado.segundo_turno === null || horarios.horario.sabado.segundo_turno === undefined) &&
                                    <ButtonAgregarHorario
                                        terminales={ (tipoSeleccionado) !== "terminal" &&  terminales}
                                        checadores={ (tipoSeleccionado) !== "checador" &&  checadores}
                                        esTerminal={esTerminal}
                                        onClick={
                                            () => {
                                                setDiaState('sabado')
                                                setTurnoState(2)
                                            }
                                        }
                                        onSubmit={onSubmitAgregarHorario}
                                    />
                                }
                            </div>
                            <div className="elementoChecador">
                                {
                                    (horarios.horario.domingo.segundo_turno !== null && horarios.horario.domingo.segundo_turno !== undefined) &&
                                        <CardPermisionarioTerminal 
                                            nombre={horarios.horario.domingo.segundo_turno.nombre}
                                            onClickDelete={
                                                ()=>handleDeleteHorario(
                                                    'domingo', 
                                                    2, 
                                                    horarios.horario.domingo.segundo_turno.id_terminal,
                                                    horarios.horario.domingo.segundo_turno.id_trabajador
                                            )}
                                            foto_perfil={horarios.horario.domingo.segundo_turno.foto_perfil}
                                        />
                                }
                                {
                                    (horarios.horario.domingo.segundo_turno === null || horarios.horario.domingo.segundo_turno === undefined) &&
                                    <ButtonAgregarHorario
                                        terminales={ (tipoSeleccionado) !== "terminal" &&  terminales}
                                        checadores={ (tipoSeleccionado) !== "checador" &&  checadores}
                                        esTerminal={esTerminal}
                                        onClick={
                                            () => {
                                                setDiaState('domingo')
                                                setTurnoState(2)
                                            }
                                        }
                                        onSubmit={onSubmitAgregarHorario}
                                    />
                                }
                            </div>
                        </div>

                    </div>
                </>
                    
                    
            }

            {
                ( horarios.length === 0 && !loading ) && <AlertWarningSearch />
            }


        </section>
    );
}