import React from 'react'
import './LoadingSearchIcon.css'

export default function LoadingSearchIcon({ show }){
    return(
        <div className={`d-flex justify-content-center align-items-center flex-column mt-5 ${show ? '' : 'hidden'}`}>
            <div className="loadingSearch"></div>
            <p>
                Buscando...
            </p>
        </div>
    )
}