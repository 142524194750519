import $ from 'jquery';
import config from './../config';
import Swal from 'sweetalert2'
const URL_API = config.urlApi+'controlador/changeDocumentosFianzaTrabajador.controlador.php';

export default function changeDocumentosFianzaTrabajadorService({values}){
    const user = JSON.parse(localStorage.getItem("user"))
    const id = user.id_usuario
    values.append("id",id)
    
    return $.ajax({
        xhr: function() {

            var xhr = new window.XMLHttpRequest();

            xhr.upload.addEventListener("progress", function(evt) {
                if (evt.lengthComputable) {
                    Swal.fire({
                        title: 'Actualizando documentos...',
                        html: '<b></b>% de 100%',
                        timerProgressBar: true,
                        showConfirmButton: false,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer()
                            console.log(b)
                            var percentComplete = evt.loaded / evt.total;
                            percentComplete = parseInt(percentComplete * 100);
                            b.textContent = `${percentComplete}%`
                    
                            if (percentComplete === 100) {
                                // Swal.close()
                                Swal.hideLoading();
                            }
                        },
                    })
                }
            }, false);
        
            return xhr;
        },
        contentType: false,
        processData: false,
        url: URL_API,
        type: 'POST',
        data: values
    })
}