import React from "react";
export default function TableElementHistorialBajasAltasPermisionario({ element, permisosUsuario, getReloadElements }) {

    let { 
        id_history,
        correo,
        nombre_usuario,
        fecha_alta,
        valor_actual
    } = element;

    valor_actual = JSON.parse(valor_actual);

    return (
        <tr key={id_history}>
            <th>
                <div className="table__name">
                    <div className="table__estatus justify-content-start">
                        { 
                            valor_actual.activo === "1" && 
                            <>
                                <span className='table__estatus-inactive'>
                                    Inactivo
                                </span>
                                <i className='fas fa-arrow-right mx-2' />
                                <span>
                                    Activo
                                </span>
                            </>
                        }
                        { 
                            valor_actual.activo === "0" && 
                            <>
                                <span>
                                    Activo
                                </span>
                                <i className='fas fa-arrow-right mx-2' />
                                <span className='table__estatus-inactive'>
                                    Inactivo
                                </span>
                            </>
                        }
                    </div>
                </div>
            </th>

            <td>
                <p>
                    {correo}
                </p>
                
                <small>
                    {nombre_usuario}
                </small>
            </td>

            <td>
                <p>{fecha_alta}</p>
            </td>
        </tr>
    )
    
}