import './TableCatalogo.css'

export default function TableCatalogo({ children }){
    return(
        <table className="table table-hover shadow">

            { children }

        </table>
    );
}