import React from 'react'
import "./AlertNoData.css"

export default function AlertNoData({textAlert}){
    return(
        <div className="d-flex justify-content-center align-items-center flex-column" id="alertNoData">
            <i>🧐</i>
            <p>{textAlert}</p>
        </div>
    )
}