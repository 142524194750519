import React from "react";
import Icon from "./../../components/Icon";
import './Presiniestros.css';
import Table from "./../../components/Table";
import TableHead from "./../../components/Table/TableHead";
import TableBody from "./../../components/Table/TableBody";
import InputSearch from "./../../components/InputSearch";
import AddButton from "./../../components/AddButton";
import $ from 'jquery';
import LoadingSearchIcon from "../../components/LoadingSearchIcon";
import searchClavesService from "../../services/searchClavesService";
import AlertWarningSearch from "../../components/AlertWarningSearch";
import FilterButton from "../../components/FilterButton";
import { useHistory } from "react-router";
import getPresiniestros from "../../services/presiniestro/getPresiniestros";
import searchPresiniestro from "../../services/presiniestro/searchPresiniestro";

export default function Presiniestros({permisosUsuario}) {
    
    const [elements, setElements] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filters, setFilters] = React.useState([])
    const [orderBy, setOrderBy] = React.useState({})
    const [terminoBusqueda, setTerminoBusqueda] = React.useState("")
    const [showDeleteFilters, setShowDeleteFilters] = React.useState(false)
    const [loadingFilters, setLoadingFilters] = React.useState(false)

    // History de la navegación
    let history = useHistory();

    const rows = [
        { id: 1, name: "Fecha" },
        { id: 2, name: "Permisionario" },
        { id: 3, name: "Unidad" },
        { id: 4, name: "Confirmación gestor" }
    ];

    function getPresiniestrosService(){
        setLoading(true)
        if(filters.length !== 0 || Object.keys(orderBy).length !== 0 || terminoBusqueda !== ""){
            search()
        } else {
            getPresiniestros()
                .then(res=>{
                    let _res = JSON.parse(res)
                    if(_res.response === 'success'){
                        setElements(_res.data)
                    }
                    setLoading(false)
                })
        }
    }
    
    const search = () =>{
        setLoading(true)
        let data = {}

        if(terminoBusqueda !== "")
            data.q = terminoBusqueda
        if(filters.length !== 0)
            data.filtros = filters
        if(Object.keys(orderBy).length !== 0)
            data.ordenar = orderBy

        searchPresiniestro({values:data})
            .then((response) =>{
                console.log(response)
                let _response = JSON.parse(response)
                if(_response.response === "success"){
                    setElements(_response.data)
                } else {
                    setElements([])
                }
                setLoading(false)
            })
            .catch((error)=>{
                console.log(error)
            })
    }

    const onSubmitSearch = (e) =>{
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values)
        let terminoBusqueda = objectValues.q
        setTerminoBusqueda(terminoBusqueda)
    }

    const onSubmitFilter = (tipoFiltro, e) => {
        e.preventDefault()
        setShowDeleteFilters(true)
        let values = new FormData(e.target)
        let object = Object.fromEntries(values)

        let nuevosFiltros = [...filters]

        let newFiltros = nuevosFiltros.filter((value) =>{
            return value.campo !== tipoFiltro
        })

        newFiltros.push({ campo: tipoFiltro, data: object })
        setFilters(newFiltros)
    }

    const onClickDeleteFilters = () => {
        setFilters([])
        setShowDeleteFilters(false)
        // Ocultamos y mostramos de nuevo los filtros para limpiar los valores seleccionados
        setLoadingFilters(true)
        setTimeout(()=>{
            setLoadingFilters(false)
        }, 50)
    }

    const onClickOrderBy = (campo, orden) => {
        let data = {
            campo: campo,
            orden: orden
        }
        setOrderBy(data)
    }

    React.useEffect(() => {
        getPresiniestrosService()
    },[filters, terminoBusqueda, orderBy]);

    return (
        <section>

            <div className="d-flex justify-content-between">

                <h1 className="">Pre siniestros reportados</h1>

                <AddButton text="Agregar pre-siniestro" onClick={ async () => {
                    history.push("/pre-siniestros/agregar")
                } } />

            </div>

            <div className="d-flex justify-content-between mt-3">
                <InputSearch textPlaceholder="Buscar pre siniestro" onSubmit={onSubmitSearch}/>
                <div className="d-flex align-items-center">

                    <div className="dropdown mx-2">
                        <button className="btn btn-sm btn-light dropdown-toggle text-muted" data-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-arrow-down-short-wide mr-2"></i>
                                Ordenar
                        </button>

                        <div className="dropdown-menu">
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('nombre','ASC')}>Nombre permisionario de A-Z</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('nombre','DESC')}>Nombre permisionario de Z-A</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_alta','ASC')}>Fecha alta de A-Z</button>
                            <button className="dropdown-item btn-sm" onClick={()=>onClickOrderBy('fecha_alta','DESC')}>Fecha alta de Z-A</button>
                        </div>
                    </div>
                    
                </div>
            </div>

            <hr className="my-3" />

            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {
                        !loadingFilters &&
                            <>
                                <FilterButton text="Estatus" type="estatus" onSubmit={onSubmitFilter} />
                                <FilterButton text="Confirmado" type="pre_siniestro_confirmado" onSubmit={onSubmitFilter} />
                                <FilterButton text="Fecha alta" type="fecha_alta" onSubmit={onSubmitFilter} />
                            </>
                    }
                </div>

                <button className={`btn btn-sm btn-light text-muted ${!showDeleteFilters && 'hidden'}`} onClick={()=>onClickDeleteFilters()}>
                    <Icon icon='fa-solid fa-x' /> Eliminar
                </button>

            </div>

            <LoadingSearchIcon show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rows} />

                        <TableBody elements={elements} typeElement="pre_siniestros" permisosUsuario={permisosUsuario}  />
                    </Table>
            }

            {
                ( Object.keys(elements).length === 0 && !loading ) &&
                    
                    <AlertWarningSearch />
            }


        </section>
    );
}