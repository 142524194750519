import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Icon from '../../components/Icon';
import FormularioTrabajador from '../../components/FormularioTrabajador';
import FormularioDocumentosTrabajador from '../../components/FormularioDocumentosTrabajador';
import FormularioAccesoSistema from '../../components/FormularioAccesoSistema';
import getTrabajadorService from "./../../services/getTrabajadorService.js";
import changeEstatusTrabajadorService from '../../services/changeEstatusTrabajadorService';
import Swal from 'sweetalert2'
import FormularioDocumentosFianzaTrabajador from '../../components/FormularioDocumentosFianzaTrabajador';
import useTrabajador from '../../hooks/useTrabajador';
import Table from '../../components/Table';
import TableHead from '../../components/Table/TableHead';
import TableBodyHistorial from '../../components/Table/TableBodyHistorial';

export default function ViewTrabajador({permisosUsuario}) {

    const location = useLocation();
    const id_trabajador = location.pathname.split('/')[2];

    const [trabajador, setTrabajador] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    const { historialBajasAltas, getHistorialBajasAltasService } = useTrabajador();
    const rowsTableBajasAltas = [
        { id: 1, name: "Cambio" },
        { id: 2, name: "Hecho por" },
        { id: 3, name: "Fecha" }
    ];

    const getTrabajador = () => {
        setLoading(true)
        setTrabajador({})
        let data = {
            id_trabajador: id_trabajador
        }
        getTrabajadorService({values:data})
            .then(response => {
                let _response = JSON.parse(response);
                // console.log(_response);
                getHistorialBajasAltasService({id_trabajador: id_trabajador})
                setTrabajador(_response.trabajador);
                setLoading(false)
            })
            .catch(error => {
                console.log(`Ha petado getTrabajador(): ${error}`)
            })
    }

    const onClickDisableTrabajador = (activo) => {
        let msg = (activo) ? '¿Seguro que deseas deshabilitar al trabajador?' : '¿Seguro que deseas habilitar al trabajador?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_trabajador', id_trabajador)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                changeEstatusTrabajadorService({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getTrabajador()
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    React.useEffect(() => {
        getTrabajador()
    } , []);

    if(!loading){
        return(
            <section>
                <input
                    action="action"
                    onClick={() => {window.history.go(-1); return false;}}
                    type="submit"
                    value="Regresar"
                    className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
                />
    
                <h1 className="my-4">Información del trabajador:</h1>
    
                <hr></hr>
    
                <div className="row">
                    <div className="col-3 text-center">
    
                        <img className='img-fluid' id="fotoPerfilTrabajador" alt="Foto de perfil trabajador" src={trabajador.foto_perfil} />
    
                        <h2 className="my-2">
                            {
                                `${trabajador.nombre} ${trabajador.apellido_paterno} ${trabajador.apellido_materno}`
                            }
                        </h2>
    
                        <div className="d-flex flex-column justify-content-between align-items-center">
                            <span className={`flag ${ trabajador.activo == 1 ? '' : 'flag-inactive' }`}>
                                { trabajador.activo == 1 ? 'Activo' : 'Inactivo' }
                            </span>
                            {
                                historialBajasAltas.length > 0 &&
                                    <p className="mt-2">
                                        <small>
                                            Fecha de baja: <br/>{historialBajasAltas[0].fecha_alta}
                                        </small>
                                    </p>
                            }
                            <small className='mt-2'>
                                Fecha de ingreso: {trabajador.fecha_ingreso_formato}
                            </small>
                            <small className='mt-2'>
                                Alta en sistema: {trabajador.fecha_alta}
                            </small>
                        </div>
    
                        <hr></hr>
    
                        <div className="my-2 text-left nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                <Icon icon="fa-solid fa-user mr-2" />
                                Trabajador
                            </a>
                            <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                <Icon icon="fa-solid fa-file-circle-check mr-2" />
                                Documentos
                            </a>
                            <a className="nav-link" id="v-pills-fianza-tab" data-toggle="pill" href="#v-pills-fianza" role="tab" aria-controls="v-pills-fianza" aria-selected="false">
                                <Icon icon="fa-solid fa-file-circle-check mr-2" />
                                Documentos fianza
                            </a>
                            <a className="nav-link" id="v-pills-historial-bajas-tab" data-toggle="pill" href="#v-pills-historial-bajas" role="tab" aria-controls="v-pills-historial-bajas" aria-selected="false">
                                <Icon icon="fa-solid fa-exchange-alt mr-2" />
                                Historial bajas/altas
                            </a>
                            {
                                (trabajador.id_usuario !== null) && permisosUsuario.usuario[0].leer == 1 &&
                                    <Link to={`/usuarios/${trabajador.id_usuario}`} className="nav-link">
                                        <Icon icon="fa-solid fa-key mr-2" />
                                        Acceso al sistema
                                    </Link>
                            }
                            {
                                (trabajador.id_usuario === null) && permisosUsuario.usuario[0].leer == 1 && permisosUsuario.usuario[0].escribir == 1 
                                     &&
                                    <a className="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                        <Icon icon="fa-solid fa-key mr-2" />
                                        Acceso al sistema
                                    </a>
                            }
                            {
                                permisosUsuario.trabajador[0].editar == 1 &&
                                    (trabajador.activo == 1 ?
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickDisableTrabajador(false)}>
                                        <Icon icon="fa-solid fa-user-slash mr-2" />
                                        Deshabilitar
                                    </a>
                                    :
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickDisableTrabajador(true)}>
                                        <Icon icon="fa-solid fa-user-plus mr-2" />
                                        Habilitar
                                    </a>)
                            }
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                {
                                    Object.keys(trabajador).length !== 0 && 
                                        <FormularioTrabajador 
                                            trabajador={trabajador}
                                            permisosUsuario={permisosUsuario.trabajador}
                                        />
    
                                }
                            </div>
                            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                {
                                    Object.keys(trabajador).length !== 0 && 
                                        <FormularioDocumentosTrabajador 
                                            trabajador={trabajador}
                                            permisosUsuario={permisosUsuario.trabajador}
                                        />
                                }
                            </div>
                            <div className="tab-pane fade" id="v-pills-fianza" role="tabpanel" aria-labelledby="v-pills-fianza-tab">
                                {
                                    Object.keys(trabajador).length !== 0 && 
                                        <FormularioDocumentosFianzaTrabajador
                                            trabajador={trabajador}
                                            permisosUsuario={permisosUsuario.trabajador}
                                        />
                                }
                            </div>
                            <div className="tab-pane fade" id="v-pills-historial-bajas" role="tabpanel" aria-labelledby="v-pills-historial-bajas-tab">
                                <h3>
                                    Historial de bajas y altas
                                </h3>

                                {
                                    historialBajasAltas.length === 0 &&
                                    <p className="text-muted">
                                        No existen cambios de estatus en este trabajador
                                    </p>
                                }

                                {
                                    historialBajasAltas.length >= 1 && 
                                    <React.Fragment>
                                        <Table>
                                            <TableHead rows={rowsTableBajasAltas} />

                                            <TableBodyHistorial 
                                                elements={historialBajasAltas} 
                                                type="historial_bajas_altas_permisionario" 
                                                permisosUsuario={permisosUsuario.permisionario}  
                                            />
                                        </Table>
                                    </React.Fragment>
                                }
                            </div>
                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                {
                                    Object.keys(trabajador).length !== 0 && 
                                        <FormularioAccesoSistema 
                                        trabajador={trabajador}
                                        id_trabajador={id_trabajador}
                                        permisosUsuario={permisosUsuario.trabajador}
                                    />
    
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

    } else {
        return(
            <div className="text-center mt-5">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }

}