import React from 'react';
import Icon from '../../components/Icon';
import $ from 'jquery';
import "./FormularioClave.css"

export default function FormularioClave({ clave, permisosUsuario, onSubmit }){
    
    function setDataFormulario(){
        document.getElementById('clave').value = clave.clave;
        document.getElementById('fecha_emision').value = clave.fecha_emision;

        if(permisosUsuario[0].editar === "0"){
            $("form :input, :input[form]").prop("readonly", true);
        }
    }

    React.useEffect(() => {
        setDataFormulario();
    }, []);

    return(
        <form className="formulario" onSubmit={ onSubmit } id="formularioClave">

                {/* ****************************** */}
                {/* BLOQUE DE LOS DATOS PERSONALES */}
                {/* ****************************** */}

                <div className="clave__formulario text-left row">
                    <div className="col-6">
                        <label htmlFor="clave">
                            Clave:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="clave"
                            name="clave"
                            readOnly={ true }
                            required
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="fecha_emision">
                            Fecha de emisión:
                        </label>
                        <input 
                            type="date" 
                            className="input"
                            id="fecha_emision"
                            name="fecha_emision"
                            required
                        />
                    </div>
                </div>
               
                {/* ******************************* */}
                {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
                {/* ******************************* */}

                {
                (permisosUsuario[0].editar !== "0") &&
                <React.Fragment>
                    <hr></hr>
                    <div className="d-flex justify-content-end">
                        <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                            <Icon icon="fa-solid fa-pen-to-square mr-3" />
                            Actualizar información de la clave
                        </button>
                    </div>
                </React.Fragment>
            }


            </form>
    );
}