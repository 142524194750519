import React from 'react';
import CardDeducibleProrrata from '../CardDeducibleProrrata';
import { calculateAfterProrrata } from '../../validators/calculateAfterProrrata';

export default function ProrrataSiniestro({prorratas, gastosTotales}){

    console.log({prorratas});

    return (
        <div>
            <h3>
                Deducible prorrata
            </h3>

            {
                prorratas.map((prorrata) =>{
                    return <>
                        <p className='mt-4'>
                            {prorrata.nombre}
                        </p>
                        <small className='text-muted'>
                            Siniestro cerrado el: {prorrata.fecha_cierre_siniestro}
                        </small>
                        <br/>
                        {
                            (prorrata.fecha_abierto_de_nuevo !== null) &&
                                <small className='text-muted'>
                                    Siniestro abierto de nuevo el: {prorrata.fecha_abierto_de_nuevo}
                                </small>
                        }
                        <CardDeducibleProrrata 
                            porcentaje_obligado_empresa={prorrata.porcentaje_empresa}
                            porcentaje_obligado_permisionario={prorrata.porcentaje_permisionario}
                            porcentaje_obligado_operador={prorrata.porcentaje_operador}
                            total_empresa={prorrata.total_empresa_prorrata}
                            total_operador={prorrata.total_operador_prorrata}
                            total_permisionario={prorrata.total_permisionario_prorrata}
                            total_prorrata={prorrata.total_siniestro_prorrata}
                        />
                    </>
                })
            }
        </div>
    )
}