import React from 'react'
import { useParams } from 'react-router-dom'
import "./Resultados.css"
import busquedaService from './../../services/busquedaService'

import CardAccordionSearch from '../../components/CardAccordionSearch'
import SearchListResult from '../../components/SearchListResult'
import LoadingSearchIcon from '../../components/LoadingSearchIcon'

export default function Resultados({loadingSearch, setLoadingSearch}){

    // Estado de los resultados
    const [resultados, setResultados] = React.useState([])

    // Esta es la variable donde obtenemos el término de búsqueda del usuario
    let { q } = useParams()

    const search = () =>{
        let data = { q : q }
        // Mostramos el loading de la busqueda
        setLoadingSearch(true)
        // Enviamos la data al controlador para la búsqueda
        busquedaService({values:data})
            .then((response) => getSearchData(response))
            .catch((error) =>{
                console.log(error)
            })

    }

    const getSearchData = (response) => {
        // console.log(response)
        let _response = JSON.parse(response)
        console.log(_response)
        setResultados(_response)
        setLoadingSearch(false)
    }

    const getRutaPorTipoResultado = (data) => {
        let respuesta = "";
        switch(data){
            case "claves":
                respuesta = "claves"
            break;
            case "permisionarios":
                respuesta = "permisionarios"
            break;
            case "vehículos":
                respuesta = "vehiculos"
            break;
            case "derroteros":
                respuesta = "derrotero"
            break;
            case "concesiones":
                respuesta = "concesiones"
            break;
            case "económicos":
                respuesta = "economicos"
            break;
            case "trabajadores":
                respuesta = "trabajador"
            break;
            case "proyectos":
                respuesta = "proyectos"
            break;
            case "usuarios":
                respuesta = "usuarios"
            break;

            default:
                respuesta = ""
            break;
        }

        return respuesta;
    }

    React.useEffect(() => {
        search()
    }, [q])

    return(
        <section>

            <h1>
                Búsqueda
            </h1>

            <LoadingSearchIcon show={loadingSearch} />

            <div className="accordion" id="accordionResultados">

                {
                    ( Object.keys(resultados).length !== 0 && !loadingSearch ) &&
                    <>
                        <p className="text-muted mb-4">
                            Resultados encontrados para <span className="font-weight-bold">{q}</span>
                        </p>
                        
                        {resultados.map((resultado, index) => {
                            let titulo = resultado.nombre.toUpperCase();
                            let numeroResultados = resultado.numeroResultados;
                            let tipoRuta = getRutaPorTipoResultado(resultado.nombre)
                            
                            return (
                                <CardAccordionSearch
                                    titulo={titulo + ` - ${numeroResultados} ${numeroResultados === 1 ? 'resultado' : 'resultados'}`}
                                    show={(index === 0 ? true : false)}
                                    id={titulo}
                                    idAccordion="accordionResultados"
                                    key={index}
                                >
                                    {
                                        resultado.resultados.map((data) => {
                                            return <SearchListResult key={data.id} data={data} tipo={tipoRuta} />
                                        })
                                    }
                                </CardAccordionSearch>
                            );

                        })}
                    </>
                }

                {
                    ( Object.keys(resultados).length === 0 && !loadingSearch ) &&
                    <p className="text-muted">
                        Sin resultados de búsqueda para <span className="font-weight-bold">{q}</span>. Inténtalo de nuevo con otro término de búsqueda.
                    </p>
                }

            </div>

        </section>
    )
}