import React from 'react';
import Icon from '../../components/Icon';
import $ from 'jquery';
import "./FormularioDerrotero.css"
import { validateNoNumbers } from '../../validators/validateNoNumbers';

export default function FormularioDerrotero({ derrotero, permisosUsuario, onSubmit }){
    
    function setDataFormulario(){
        document.getElementById('nombre').value = derrotero.nombre;
        document.getElementById('clave').value = derrotero.clave;
        document.getElementById('descripcion').value = derrotero.descripcion;
        document.getElementById('fecha_autorizacion').value = derrotero.fecha_autorizacion;
        document.getElementById('capacidad_vehicular').value = derrotero.capacidad_vehicular;

        if(permisosUsuario[0].editar === "0"){
            $("form :input, :input[form]").prop("readonly", true);
        }
    }

    React.useEffect(() => {
        setDataFormulario();
    }, []);

    return(
        <form className="formulario" onSubmit={ onSubmit } id="formularioDerrotero">

                {/* ****************************** */}
                {/* BLOQUE DE LOS DATOS PERSONALES */}
                {/* ****************************** */}

                <div className="derrotero__formulario row">
                    <div className="col-6">
                        <label htmlFor="clave">
                            Clave:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="clave"
                            name="clave"
                            required
                        />
                    </div>

                    <div className="col-6">
                        <label htmlFor="nombre">
                            Nombre:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="nombre"
                            name="nombre"
                            required
                        />
                    </div>

                    <div className="col-6">
                        <label htmlFor="descripcion">
                            Descripción:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="descripcion"
                            name="descripcion"
                            placeholder='Opcional'
                        />
                    </div>

                    <div className="col-3">
                        <label htmlFor="fecha_autorizacion">
                            Fecha de autorización:
                        </label>
                        <input 
                            type="date" 
                            className="input"
                            id="fecha_autorizacion"
                            name="fecha_autorizacion"
                            placeholder='Opcional'
                        />
                    </div>

                    <div className="col-3">
                        <label htmlFor="capacidad_vehicular">
                            Capacidad vehicular:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="capacidad_vehicular"
                            name="capacidad_vehicular"
                            maxLength={4}
                            onKeyPress={ validateNoNumbers }
                            placeholder='Opcional'
                        />
                    </div>
                </div>
               
                {/* ******************************* */}
                {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
                {/* ******************************* */}

                {
                (permisosUsuario[0].editar !== "0") &&
                <React.Fragment>
                    <hr></hr>
                    <div className="d-flex justify-content-end">
                        <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                            <Icon icon="fa-solid fa-pen-to-square mr-3" />
                            Actualizar información del derrotero
                        </button>
                    </div>
                </React.Fragment>
            }


            </form>
    );
}