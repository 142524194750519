import React from "react";
import './CardDeducibleProrrata.css'

export default function CardDeducibleProrrata(props){

    let dollarUSLocale = Intl.NumberFormat('en-US');

    return(
        <div className='d-flex justify-content-between globalCardsDeducibleProrrata'>
            <div className='cardDeducibleProrrata cardDeducibleProrrata--no-border-left text-center'>
                <small className='font-weight-bold'>Empresa:</small>
                <h2>$&nbsp;
                {
                    props.total_empresa !== undefined ?
                        dollarUSLocale.format(props.total_empresa) : 0
                }
                </h2>
                {
                    (props.porcentaje_obligado_empresa !== null && props.porcentaje_obligado_empresa !== undefined) && 
                        <small>Obligado: {props.porcentaje_obligado_empresa} %</small>
                }

            </div>
            <div className='cardDeducibleProrrata text-center'>
                <small className='font-weight-bold'>Permisionario:</small>
                <h2>$&nbsp;
                {
                    props.total_permisionario !== undefined ?
                        dollarUSLocale.format(props.total_permisionario) : 0
                }
                </h2>
                {
                    (props.porcentaje_obligado_permisionario !== null && props.porcentaje_obligado_permisionario !== undefined) && 
                        <small>Obligado: {props.porcentaje_obligado_permisionario} %</small>
                }
            </div>
            <div className='cardDeducibleProrrata text-center'>
                <small className='font-weight-bold'>Operador:</small>
                <h2>$&nbsp;
                {
                    props.total_operador !== undefined ?
                        dollarUSLocale.format(props.total_operador) : 0
                }
                </h2>
                {
                    (props.porcentaje_obligado_operador !== null && props.porcentaje_obligado_operador !== undefined) && 
                        <small>Obligado: {props.porcentaje_obligado_operador} %</small>
                }

            </div>
            <div className='cardDeducibleProrrata text-center'>
                <small className='font-weight-bold'>Total:</small>
                <h2>$&nbsp;
                {
                    props.total_prorrata !== undefined ?
                        dollarUSLocale.format(props.total_prorrata) : 0
                }
                </h2>

            </div>
        </div>
    )
}