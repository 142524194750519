import Icon from "./../../components/Icon";
import { Link } from 'react-router-dom';
import React from 'react';
import Swal from 'sweetalert2'
import changeEstatusPermisionarioService from './../../services/changeEstatusPermisionarioService';

export default function TableElement({ element, permisosUsuario }) {

    let { id_permisionario, name, nombre_tipo_permisionario, urlImageProfile, key, units, date, hour, status } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(status);

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitar al permisionario?' : 'Quieres deshabilitar al permisionario?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_permisionario', id_permisionario)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                console.log({objectValues})
                changeEstatusPermisionarioService({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo)
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_permisionario}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={`/permisionarios/${id_permisionario}`} className="dropdown-item" >Ver</Link>
                            {/* <a className="dropdown-item" href="#">Historial de la clave</a>
                            <a className="dropdown-item" href="#">Asignar económico</a>
                            <a className="dropdown-item" href="#">Asignar unidad</a>
                            <a className="dropdown-item" href="#">Asignar concesión</a> */}
                            {
                                permisosUsuario[0].editar == 1 &&
                                (estadoEstatus == 1 ?
                                <a className="dropdown-item bg-danger text-white" onClick={() => onClickEstatus(false)}>Deshabilitar</a>
                                :
                                <a className="dropdown-item bg-success text-white" onClick={() => onClickEstatus(true)}>Habilitar</a>)
                            }
                        </div>
                    </div>
                    {
                        (urlImageProfile) &&
                        (<img src={urlImageProfile} className="pedidos__imgTabla img-fluid rounded-circle "/>)
                    }
                    {/* <img src={urlImageProfile} className="pedidos__imgTabla img-fluid rounded-circle "/> */}

                    <div className="table__nameDescription">
                        <p>{name}</p>
                        { (key !== '') && <small>Clave: {key}</small> }
                        { (key === '') && <small>Clave no asignada</small> }
                    </div>
                </div>
            </th>
            
            <td>
                <p className="table__unidades">{nombre_tipo_permisionario}</p>
            </td>

            {/* <td>
                <p className="table__unidades">{units}</p>
            </td> */}

            <td>
                <div className="table__fechaAlta">
                    <p>{date}</p>
                    <small>{hour}</small>
                </div>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ estadoEstatus == 1 ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus == 1 ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}