import React, { useState } from 'react'
import Icon from '../Icon'
import "./FilterButton.css"
import getTiposPermisionarioService from '../../services/getTiposPermisionarioService'
import getGenerosService from '../../services/getGenerosService'
import getTiposTrabajadorService from '../../services/getTiposTrabajadorService'
import $ from 'jquery';
import getDerroterosActivosService from '../../services/getDerroterosActivosService'
import getEstadosSiniestroActivos from '../../services/catalogos/estado_siniestro/getEstadosSiniestroActivos'

export default function FilterButton({ text, type, selectedValues, onSubmit, children }){

    const [selected, setSelected] = React.useState(selectedValues)

    const [tiposPermisionario, setTiposPermisionario] = React.useState([])
    const getTiposPermisionario = () => {
        getTiposPermisionarioService().then((respuesta) =>{
            let _respuesta = JSON.parse(respuesta)
            setTiposPermisionario(_respuesta.data)
            $('.selectpicker').selectpicker('refresh');
        })
    }

    const [generos, setGeneros] = React.useState([])
    const getGeneros = () => {
        getGenerosService().then((respuesta) =>{
            let _respuesta = JSON.parse(respuesta)
            setGeneros(_respuesta.data)
            $('.selectpicker').selectpicker('refresh');
        })
    }

    const [tiposTrabajador, setTiposTrabajador] = React.useState([])
    const getTiposTrabajador = () => {
        getTiposTrabajadorService().then((respuesta) =>{
            let _respuesta = JSON.parse(respuesta)
            setTiposTrabajador(_respuesta.data)
            $('.selectpicker').selectpicker('refresh');
        })
    }

    const [derroteros, setDerroteros] = React.useState([])
    const getDerroterosActivos = () => {
        getDerroterosActivosService().then((respuesta) =>{
            let _respuesta = JSON.parse(respuesta)
            console.log(_respuesta)
            setDerroteros(_respuesta.derroteros)
            $('.selectpicker').selectpicker('refresh');
        })
    }

    const [estadosSiniestro, setEstadosSiniestro] = React.useState([])
    const getEstadosSiniestroActivosService = () => {
        getEstadosSiniestroActivos().then((respuesta) =>{
            let _respuesta = JSON.parse(respuesta)
            console.log(_respuesta)
            setEstadosSiniestro(_respuesta.data)
            $('.selectpicker').selectpicker('refresh');
        })
    }

    const onChangeOptionsDate = (e) => {
        if(e.target.value === "2"){
            setShowInputsDate(true)
            $("#fecha_a_filtrar").attr("required",false)
            $("#fecha_inicial").attr("required",true)
            $("#fecha_final").attr("required",true)
        } else {
            setShowInputsDate(false)
            $("#fecha_a_filtrar").attr("required",true)
            $("#fecha_inicial").attr("required",false)
            $("#fecha_final").attr("required",false)
        }
    }
    const [showInputsDate, setShowInputsDate] = React.useState(false)
    let optionsDate = [
        { id: "1", name: "Es igual a" },
        { id: "2", name: "Entre" },
        { id: "3", name: "Es posterior a" },
        { id: "4", name: "En esa fecha o después" },
        { id: "5", name: "Es anterior a" },
        { id: "6", name: "Es anterior o igual a" }
    ]

    let inputs;

    // SÍ ES DE fecha alta AGREGAMOS SUS INPUT
    if(type === "fecha_alta" || type === "fecha_asignacion" || type === "fecha_otorgamiento" || type === "vigencia" || type === "fecha_ingreso" || type === "fecha_operacion"){

        inputs = 
        <>
            <select className="input" name="tipoBusqueda" onChange={onChangeOptionsDate}>
                {
                    optionsDate.map((option) => {
                        return <option key={option.id} value={option.id}>{option.name}</option>
                    })
                }
            </select>
            <div className={`${!showInputsDate && 'hidden'} d-flex justify-content-between align-items-center`}>
                <input id="fecha_inicial" name="fecha_inicial" className="input" type="date" onChange={(e) => setSelected(e.target.value)} />
                <p className="mx-2">y</p>
                <input id="fecha_final" name="fecha_final" className="input" type="date" onChange={(e) => setSelected(e.target.value)} />
            </div>
            <div className={`${showInputsDate && 'hidden'} d-flex justify-content-between align-items-center`}>
                <input id="fecha_a_filtrar" name="fecha" className="input" type="date" onChange={(e) => setSelected(e.target.value)} />
            </div>
        </>
    }

    // SÍ ES DE ESTATUS AGREGAMOS SUS INPUT
    if(type === "estatus"){
        inputs = 
        <>
            <select className="input" name="activo" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
            </select>
        </>
    }

    // SÍ ES DE REVISADO AGREGAMOS SUS INPUT
    if(type === "pre_siniestro_confirmado"){
        inputs = 
        <>
            <select className="input" name="revisado" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                <option value="1">Confirmado por el gestor</option>
                <option value="0">No confirmado por el gestor</option>
            </select>
        </>
    }
    
    // SÍ ES DE APROBADO AGREGAMOS SUS INPUT
    if(type === "aprobado"){
        inputs = 
        <>
            <select className="input" name="aprobado" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                <option value="1">Aprobado</option>
                <option value="0">Rechazado</option>
            </select>
        </>
    }

    // SÍ ES DE ENTREGADO AGREGAMOS SUS INPUT
    if(type === "entregado"){
        inputs = 
        <>
            <select className="input" name="entregado" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                <option value="1">Entregado</option>
                <option value="0">No entregado</option>
            </select>
        </>
    }
    
    // SI ES DEL ESTADO DEL SINIESTRO, AGREGAMOS SUS DIFERENTES ESTADOS
    if(type === "id_estado_siniestro"){
        inputs = 
        <>
            <select className="input" name="id_estado_siniestro" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                {
                    estadosSiniestro &&
                        estadosSiniestro.map((estado_siniestro) => {
                            return <option value={estado_siniestro.id_estado_siniestro} key={estado_siniestro.id_estado_siniestro}>{estado_siniestro.nombre}</option>
                        })
                }
            </select>
        </>
    }

    // SÍ ES DE asignado AGREGAMOS SUS INPUT
    if(type === "asignado"){
        inputs = 
        <>
            <select className="input" name="asignado" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                <option value="1">Si</option>
                <option value="0">No</option>
            </select>
        </>
    }

    // SÍ ES DE acceso del sistema AGREGAMOS SUS INPUT
    if(type === "acceso_sistema"){
        inputs = 
        <>
            <select className="input" name="acceso_sistema" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                <option value="1">Si</option>
                <option value="0">No</option>
            </select>
        </>
    }

    // SÍ ES DE número de unidades AGREGAMOS SUS INPUT
    if(type === "numero_unidades"){
        inputs = 
        <>
            <input name="numero_unidades" className="input" type="num" onChange={(e) => setSelected(e.target.value)} required />
        </>
    }

    // SÍ ES DE número de unidades AGREGAMOS SUS INPUT
    if(type === "tipo_permisionario"){
        inputs = 
        <>
            <select className="input" name="id_tipo_permisionario" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                {
                    tiposPermisionario.length > 0 &&
                        tiposPermisionario.map((tipo) => {
                            return <option value={tipo.id_tipo_permisionario} key={tipo.id_tipo_permisionario}>{tipo.nombre}</option>
                        })
                }
            </select>
        </>
    }

    // SÍ ES DE número de unidades AGREGAMOS SUS INPUT
    if(type === "tipo_trabajador"){
        inputs = 
        <>
            <select className="input" name="id_tipo_trabajador" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                {
                    tiposTrabajador.length > 0 &&
                        tiposTrabajador.map((tipo) => {
                            return <option value={tipo.id_tipo_trabajador} key={tipo.id_tipo_trabajador}>{tipo.nombre}</option>
                        })
                }
            </select>
        </>
    }

    // SÍ ES DE genero AGREGAMOS SUS INPUT
    if(type === "genero"){
        inputs = 
        <>
            <select className="input" name="id_genero" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                {
                    generos.length > 0 &&
                        generos.map((genero) => {
                            return <option value={genero.id_genero} key={genero.id_genero}>{genero.nombre}</option>
                        })
                }
            </select>
        </>
    }
   
    // SÍ ES DE derrotero AGREGAMOS SUS INPUT
    if(type === "derrotero"){
        inputs = 
        <>
            <select className="input" name="id_derrotero" onChange={(e) => setSelected(e.target.selectedOptions[0].innerText)} required>
                <option value="" hidden>
                    {'--'}
                </option>
                {
                    derroteros.length > 0 &&
                        derroteros.map((derrotero) => {
                            return <option value={derrotero.id_derrotero} key={derrotero.id_derrotero}>{derrotero.nombre}</option>
                        })
                }
            </select>
        </>
    }
   
    // SÍ ES DE siniestro AGREGAMOS SUS INPUT
    if(type === "siniestro"){
        inputs = 
        <>
            <input name="id_siniestro" placeholder='Escribe el ID del siniestro' className="input" type="num" onChange={(e) => setSelected(e.target.value)} required />
        </>
    }

    React.useEffect(()=>{
        if(type === "genero"){ getGeneros() }
        if(type === "tipo_permisionario"){ getTiposPermisionario() }
        if(type === "tipo_trabajador"){ getTiposTrabajador() }
        if(type === "derrotero"){ getDerroterosActivos() }
        if(type === "id_estado_siniestro"){ getEstadosSiniestroActivosService() }
        $(document).on('click', '.dropdownFilter .dropdown-menu button', function (e) {
            e.stopPropagation();
        });
    },[])

    return(
        <div className="dropdown mr-3 dropdownFilter d-inline">
            <button className="dropdown-button" id={`${text.replace(/\s/g, '')}-dropdown`} data-toggle="dropdown">
                <Icon icon="fa-solid fa-circle-plus mr-2"/>
                { text } 
                <span>
                    {
                        (selected !== undefined && selected !== null) &&
                        ` | ${selected}`
                    }
                </span>
                {
                    (selected !== undefined && selected !== null) &&
                        <Icon icon="fa-solid fa-chevron-down ml-2"/>
                }
            </button>
            <div className="dropdown-menu">
                <p className="font-weight-bold tituloFilter">
                    Filtrar por { text } 
                </p>
                <form onSubmit={ (e) => onSubmit(type, e) }>
                    { inputs }
                    <button onClick={() => { $(`#${text.replace(/\s/g, '')}-dropdown`).dropdown("toggle"); }} className="btn btn-primario btn-block btn-sm btnAplicarFiltro">
                        Aplicar
                    </button>
                </form>
            </div>
        </div>
    )
}