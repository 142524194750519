import React from "react";
import Icon from "./../../components/Icon";
import { Link } from "react-router-dom";
import './Proyectos.css';
import Table from "./../../components/Table";
import TableHead from "./../../components/Table/TableHead";
import TableBody from "./../../components/Table/TableBody";
import $ from 'jquery';
import config from '../../config';
const URL_API = config.urlApi;
export default function Proyectos({permisosUsuario}) {

    React.useEffect(() => {
        // getProyectos()
    },[]);

    const [elements, setElements] = React.useState([]);

    const rows = [
        { id: 1, name: "Clave del proyecto" },
        { id: 2, name: "Fecha inicio" },
        { id: 3, name: "Monto recaudado" },
        { id: 4, name: "Total de inversionistas" },
        { id: 5, name: "Estatus" }
    ];

    function getProyectos(){
        $.ajax({
            url: URL_API+'controlador/getProyectos.controlador.php',
            success: function(response){
                console.log(response)
                let _respuesta = JSON.parse(response);
                console.log(_respuesta)
                if(!(_respuesta.response == 'error')){
                    setElements(_respuesta);
                }
            }
        })
    }

    return (
        <section>

            <h1 className="">Proyectos</h1>

            <div className="proyectos__header">
                <p className="mr-5">
                    Los proyectos que han registrado puedes consultarlos aquí. Puedes ver más a detalle cada proyecto haciendo click en el botón de "Ver más".
                </p>

                <Link to="/proyectos/agregar" className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario">
                    <Icon icon="fa-solid fa-hands-holding-circle" />
                    <p>Agregar un proyecto</p>
                </Link>
            </div>

            <Table>
                <TableHead rows={rows} />

                <TableBody elements={elements} typeElement="proyectos" permisosUsuario={permisosUsuario}  />
            </Table>

        </section>
    );
}